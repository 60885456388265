import { all } from "async-saga";
import app from "./app";
import auth from "./auth";
import bookit from "./bookit";
import notificationHub from "./notificationHub";
import packageFeeds from "./packageFeeds";
import pingHub from "./pingHub";
import uc from "./uc";

export default all(
    auth,
    bookit,
    app,
    uc,
    pingHub,
    packageFeeds,
    notificationHub
)