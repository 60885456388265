import { AddIcon, Button, Dialog, Flex, Loader } from '@fluentui/react-northstar';
import { compare } from 'fast-json-patch';
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from 'react-redux';
import { BookItAttribute } from '../../model';
import { createBookItAttribute, deleteBookItAttribute, fetchBookItAttributes, patchBookItAttribute } from '../../store/resourceActions';
import { getAttributes } from "../../store/selectors";
import { useSelector } from '../../store/utils';
import ConfirmationDialog from "../controls/ConfirmationDialog";
import AddAttribute, { CreateAttribute } from './AddAttribute';
import './AttributesPage.scss';
import AttributeThumb from './AttributeThumb';

const AttributesPage: React.FC<{}> = (props) => {
    const isCreating = useSelector(s => s.bookit.attributes.isCreating)
    const isLoading = useSelector(s => s.bookit.attributes.isLoading);

    const [addFormOpen, setAddFormOpen] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => { dispatch(fetchBookItAttributes.request({})) }, [dispatch])

    const attributes = useSelector(getAttributes);

    const handleAddUpdateAttribute = (createAttribute: CreateAttribute) => {
        setAddFormOpen(false)
        if (attributeToEdit) {
            const updatedAttribute = { name: createAttribute.name, url: URL.createObjectURL(createAttribute.file) }
            const original = { name: attributeToEdit.name, url: attributeToEdit.url }
            const diff = compare(original, updatedAttribute);
            dispatch(patchBookItAttribute.request({ id: {attribute_id: attributeToEdit.attribute_id}, operations: diff }))
        } else {
            const newAttribute = { name: createAttribute.name, file: createAttribute.file as File }
            dispatch(createBookItAttribute.request(newAttribute))
        }
        setAttributeToEdit(undefined)
    }

    const [attributeToEdit, setAttributeToEdit] = useState<BookItAttribute>()
    const handleAttributeToEditDelete = (attribute: BookItAttribute) => {
        setAttributeToEdit(attribute)
        setAttributeToDelete(attribute)
        setAddFormOpen(true)
    }

    const [attributeToDelete, setAttributeToDelete] = useState<BookItAttribute>()
    const handleAttributeDelete = () => {
        dispatch(deleteBookItAttribute.request(attributeToDelete!))
        setAttributeToDelete(undefined)
    }

    const [confirmDelete, setConfirmDelete] = useState(false)


    const existingAttributes = useMemo(() => {
        return attributeToEdit ? attributes.filter(a => a.name !== attributeToEdit.name).map(a => a.name) : attributes.map(a => a.name)
    }, [attributes, attributeToEdit])
const addAttributeButton = <Button primary content='Add Attribute' icon={<AddIcon/>} iconPosition='before' disabled={isCreating} styles={{ marginBottom: '0.5rem' }} />
    const addAttributeContent = isCreating
        ? <Flex hAlign='end'>{addAttributeButton}</Flex>
        : (<Flex hAlign='end'>
            <Dialog
                content={<AddAttribute
                    initial={attributeToEdit}
                    onSubmit={handleAddUpdateAttribute}
                    onCancel={() => {
                        setAttributeToEdit(undefined)
                        setAddFormOpen(false)
                    }}
                    onDelete={() => {
                        setConfirmDelete(true)
                        setAddFormOpen(false)
                    }}
                    existingAttributes={existingAttributes}
                />}
                header={attributeToEdit ? "Edit attribute" : "Add a new attribute"}
                open={addFormOpen}
                onOpen={() => setAddFormOpen(true)}
                trigger={addAttributeButton}
            />
        </Flex>)

    return <Flex className="AttributesPage">
        <h5>Attributes</h5>
        {addAttributeContent}
        {isLoading && <Loader label='Loading...' />}
        <div className="attributes">
            {attributes.map(a => (
                <AttributeThumb key={a.attribute_id.toString()} attribute={a} onClick={() => handleAttributeToEditDelete(a)} />
            ))}
        </div>

        <ConfirmationDialog onConfirm={() => handleAttributeDelete()}
            onCancel={() => {
                setAttributeToDelete(undefined)
                setConfirmDelete(false)
            }}
            message={`Are you sure you wish to delete the attribute ${(attributeToDelete ? attributeToDelete!.name : "")}?\n Deleting this attribute will remove it from all resources that have it assigned to them.`}
            heading='Delete Attribute'
            confirmButtonText='Delete'
            isOpen={confirmDelete}
        />
    </Flex>
}

export default AttributesPage;