import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import RoomScreenShots from "../components/videofx/room/RoomScreenShots";
import { Room } from "../model";
import { HalResource } from "../services/Hal";
import { adminFetchScreenShot } from "../store/actions";
import { ReduxPropsType, RootState } from "../store/types";

function mapStateToProps(state: RootState, ownProps: {room: HalResource<Room> } ) {
    return {
        screenShots: state.adminLogs.screenShots[ownProps.room.name.toLowerCase()],
        roomAttributes: state.rooms.roomAttributes[ownProps.room.name.toLowerCase()]
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    fetchScreenShot: adminFetchScreenShot.request
}, dispatch);
  
  const withRedux = connect(mapStateToProps, mapDispatchToProps);
  export type RoomScreenShotsProps = ReduxPropsType<typeof withRedux>;
  export default withRedux(RoomScreenShots);