import { combineReducers } from "redux";
import { ConferenceClient } from "../../model";
import { confenenceClientReducer } from "../resourceActions";
import { Actions } from "../types";
import { ApiResourceState, defaultState } from "./ApiResourceState";

type ConferenceClientsState = ApiResourceState<ConferenceClient>

function conferenceClients(state: ConferenceClientsState = defaultState, action: Actions): ConferenceClientsState {
    switch (action.type) {
        default:
            return confenenceClientReducer(state, action);
    }
}

export default combineReducers({
    conferenceClients
})

