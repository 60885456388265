import { Button, Flex, FormField } from "@fluentui/react-northstar";
import * as React from 'react';

interface Props {
    onSubmit?: () => void
    onCancel?: () => void
    cancelText?: string
    submitText?: string
    submitEnabled?: boolean
    className?: string
}
const FormButtons: React.FC<Props> = (props) => {
    const { onSubmit, onCancel, cancelText, submitText, submitEnabled: submitDisabled, className } = props

    function handleCancel() {
        if (onCancel) {
            onCancel()
        }
    }

    function handleSubmit() {
        if (onSubmit) {
            onSubmit()
        }
    }

    const disabled = submitDisabled === undefined ? false : !submitDisabled

    return <Flex gap="gap.smaller" hAlign="end" className={className}>
        <FormField
            control={{
                as: Button,
                onClick: handleCancel,
                type: 'button',
                content: cancelText || 'Cancel',
            }}
        />
        <FormField
            control={{
                as: Button,
                disabled: disabled,
                onClick: handleSubmit,
                primary: true,
                type: 'submit',
                content: submitText || 'Submit',
            }}
        />
    </Flex>
}

export default FormButtons