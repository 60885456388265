
import React, { useState } from 'react';
import { List} from "@fluentui/react-northstar";
import css from './SupportSettingsPage.module.scss';



type UserSelectorProps = ({
    // TODO: Add onSelected here
    //onSave: (settings: BookItSettings) => void
    mailboxes: string[] | undefined
    onUserSelection: (mailbox: string) => void
})

function UserSelector(props: UserSelectorProps) {
    
    console.log('UC: Loading users', props);
    
    
    function onSelectionChanged(mailbox: string) {
        
        if (mailbox !== undefined) {
            props.onUserSelection(mailbox)     
        }
     
    }

    return <>
        <div className={css.selectorCard}>
            <div className={css.horizontalContainer}>
                <h5>Support mailboxes</h5>
                
            </div>
            <div className={css.infoText}>Selection sets the current active mailbox assignments</div>
            
            <List className={css.userList} selectable defaultSelectedIndex={-1} 
                    items={props.mailboxes} 
                    onSelectedIndexChange={(e, newProps) => onSelectionChanged(newProps!.items![newProps!.selectedIndex!] as string)} />
        </div>
    </>
}

export default UserSelector;