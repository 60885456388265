import { getType } from "typesafe-actions";
import { Actions, actions } from "../types";

interface LicenseFiles {
    isUploadingLicenseFile: boolean;
    uploadError?: Error;
}

export default function licenseFiles(state: LicenseFiles = { isUploadingLicenseFile: false }, action: Actions) {

    switch (action.type) {
        case getType(actions.uploadLicenseFile.request):
            return {
                ...state,
                uploadError: undefined,
                isUploadingLicenseFile: true
            };
        case getType(actions.uploadLicenseFile.success):
            return {
                ...state,
                isUploadingLicenseFile: false,
                uploadError: undefined
            };
        case getType(actions.uploadLicenseFile.failure):
            return {
                ...state,
                isUploadingLicenseFile: false,
                uploadError: action.payload
            };
        default:
            return state;
    }
}
