import { Button } from '@fluentui/react-northstar';
import classNames from 'classnames';
import * as React from 'react';
import { FaSignOutAlt, FaUserAltSlash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/types';
import css from './NotAdminPage.module.scss';


export function NotAdminPage() {
  const dispatch = useDispatch()
  const handleSignout = () => {
    dispatch(actions.signOutAuthedAccount())
  }

  return <div className={classNames(css.fullscreen)}>
    <FaUserAltSlash className={css.icon} />
    <span className={css.errorTitle}>User Not Authorised</span>
    <span className={css.errorText}>The user not been assigned an administrative role to access this application. Please contact your IPFX MRS administrator. If this has already been done you may need to login and out for these changes to become available</span>
    <Button icon={<FaSignOutAlt size='1em' />} content="Sign out" iconPosition="after" text
      onClick={handleSignout} />
  </div>

}
