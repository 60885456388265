import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { Services } from "../../services";
import { errorHandler, fetchRoomAttributes, fetchSettingsCategory } from "../actions";
import { RootAction, RootState } from "../types";

export const fetchSettingsCategoryEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchSettingsCategory.request)),
        mergeMap(action =>
            serverApi.fetchSettingsCategory(action.payload.application, action.payload.category).pipe(
                map(hal => fetchSettingsCategory.success(hal)),
                catchError(err => of(fetchRoomAttributes.failure(err), errorHandler(err)))
            )));
}