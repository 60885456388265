import { Checkbox, EditIcon, RetryIcon, Segment, TrashCanIcon, MenuItemProps, Menu, Flex, Loader } from "@fluentui/react-northstar";
import ReactTable, { CellInfo, Column, RowInfo } from 'react-table';
import { compare } from "fast-json-patch";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { bookItSettingsDefaults, CreateOrganisation, DefaultRoles, Organisation, OrganisationSettings, GenericReportSchedule } from "../../model";
import { ApiResult } from "../../store/reducers/ApiResult";
import { createOrganisation, deleteOrganisation, fetchOrganisations, patchOrganisation } from "../../store/resourceActions";
import { getLoggedInUser } from "../../store/selectors";
import { actions } from "../../store/types";
import { useSelector } from "../../store/utils";
import ConfirmationDialog from "../controls/ConfirmationDialog";
import { FormikNorthstarCheckbox } from "../controls/FormikNorthstarCheckbox";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";
import ResourceAdminForm from "./ResourceAdminForm";
import ResouceAdminPage from './ResourceAdminPage';
import { RolesPicker } from "./RolesPicker";
import css from './OrganisationsPage.module.scss';
import { FailedRetry } from '../admin/ResourceAdminPage';
import { settings } from "cluster";
import { ToolbarWithTooltips } from '../controls/ToolbarWithTooltips';
import { VideoFxHealthReportEditor } from './VideoFxHealthReportEditor';


interface Props { }

type OrganisationEditModel = Partial<Pick<Organisation, "name" | "is_enabled" | "is_resource_only" | "icon_url" | "mobile_client_settings" | "settings">>

const OrganisationsPage: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const onOrganisationEnabledChanged = useCallback((organisation: Organisation) => {
        const updatedOrg = { ...organisation, is_enabled: !organisation.is_enabled }
        const diff = compare(organisation, updatedOrg)
        dispatch(patchOrganisation.request({ id: organisation, operations: diff }))
    }, [dispatch])

    const renderEnabled = useCallback((cellInfo: CellInfo) => {
        const organisation = cellInfo.original
        return <Checkbox checked={cellInfo.value} toggle onChange={() => onOrganisationEnabledChanged(organisation)} />
    }, [onOrganisationEnabledChanged])

    const cols = useMemo(() => [
        { Header: "Name", id: "name", minWidth: 40, accessor: (row: Organisation) => row.name },
        { Header: "Owner", id: "owner", accessor: (row: Organisation) => row?.owner?.email_address ?? "Not Set", minWidth: 50 },
        { Header: "Enabled", id: "is_enabled", minWidth: 10, accessor: (row: Organisation) => row.is_enabled, Cell: renderEnabled },
    ], [renderEnabled])

    const currentUser = useSelector(getLoggedInUser)

    const reloading = useSelector(s => s.organisations.reloading)

    const handleAddOrUpdate = (organisation: OrganisationEditModel, closeDialog: () => void, original: Organisation | undefined) => {
        if (original) {
            const updatedOrganisation = {
                ...original,
                name: organisation.name,
                is_enabled: organisation.is_enabled,
                is_resource_only: organisation.is_resource_only,
                icon_url: organisation.icon_url,
                settings: organisation.settings,
                mobile_client_settings: organisation.mobile_client_settings
            }

            const { mobile_client_settings, settings, ...initial } = original // remove mobile_client_settings, settings as we don't support array patch ops so force a complete replace of the object
            const diff = compare(initial, updatedOrganisation);

            console.log('handleAddOrUpdate Org diff', diff);

            dispatch(patchOrganisation.request({ id: initial, operations: diff }))
        } else {
            const newOrganisation: CreateOrganisation = {
                name: organisation.name!,
                is_enabled: organisation.is_enabled!,
                is_resource_only: organisation.is_resource_only!,
                icon_url: organisation.icon_url!,
                owner_user_account_id: currentUser.user_account_id,
                book_it_settings: bookItSettingsDefaults,
                mobile_client_settings: organisation.mobile_client_settings,
                settings: organisation.settings
            }

            dispatch(createOrganisation.request(newOrganisation))
        }
        closeDialog()
    }

    const [organisationToReload, setOrganisationToReload] = useState<Organisation>()

    const confirmReload = (organisation: Organisation) => {
        setOrganisationToReload(organisation)
    }

    const handleReloadOrganisation = () => {
        dispatch(actions.reloadOrganisation.request(organisationToReload!.organisation_id))
        setOrganisationToReload(undefined)
    }

    return <>
        <ResouceAdminPage<Organisation, {}>
            resourceName='Organisation'
            parentKey={{}}
            selectState={s => s.organisations.organisations}
            fetchAllAction={fetchOrganisations}
            deleteAction={deleteOrganisation}
            confirmMessage={org => `Are you sure you wish to delete the organisation ${org.name}?`}
            resourceForm={(original, closeDialog) =>
                <OrganisationForm initial={createEditModel(original)} onSubmit={edit => handleAddOrUpdate(edit, closeDialog, original)} onCancel={closeDialog} />
            }
            toolbarActionItems={(resource, onAddUpdate, onDelete) =>
                [{
                    key: 'reload',
                    icon: <RetryIcon outline />,
                    tooltip: `Reload ${resource.name}`,
                    disabled: reloading[resource.organisation_id.toString()],
                    onClick: () => confirmReload(resource)
                }, {
                    key: 'edit',
                    icon: <EditIcon outline />,
                    tooltip: `Edit ${resource.name}`,
                    onClick: () => onAddUpdate(resource)
                },
                {
                    key: 'delete',
                    icon: <TrashCanIcon outline />,
                    tooltip: `Delete ${resource.name}`,
                    onClick: () => onDelete(resource)
                }]
            }
            columns={cols}
            defaultSortOrder={[{ id: "is_enabled", desc: true }, { id: "name", desc: false }]}
        />

        {organisationToReload && <ConfirmationDialog onConfirm={handleReloadOrganisation}
            onCancel={() => setOrganisationToReload(undefined)}
            message={`Do you wish to reload the '${organisationToReload?.name}' organisation? This will cause all tablets to reload and lose all state.`}
            heading={`Reload Organisation ${organisationToReload?.name}`}
            confirmButtonText='Reload'
            isOpen={organisationToReload !== undefined} />
        }
    </>
}

function createEditModel(resource?: Organisation): OrganisationEditModel | undefined {
    if (resource === undefined) return undefined
    const { organisation_id, created_time_utc, providers, online_meeting_providers, ...edit } = resource
    return edit
}

interface OrganisationFormProps {
    initial?: OrganisationEditModel
    onSubmit: (edit: OrganisationEditModel) => void
    onCancel: () => void,
}

export const defaultOrganisationSettings = (site: string) => ({
    managed_engine_settings: {
        use_email_command: false,
        account: 'NZ TaaS',
        site: site,
        operation: 'AddRequest',
        requester: 'TaaS Support',
        category: 'Meeting Room System'
    },
    feature_settings: {
        licence_file_upload: false
    },
    vfx_health_report: {
        enabled: false,
        report_am_time: '8:00',
        report_pm_time: '16:00',
        report_mailbox: '',
        timezone: 'New Zealand Standard Time'
    }
}) as OrganisationSettings

const OrganisationForm: React.FC<OrganisationFormProps> = ({ initial, onSubmit, onCancel }) => {

    const initialOrganisation = useMemo(() => ({
        ...initial,
        settings: initial?.settings ?? defaultOrganisationSettings(initial?.name ?? ''),
        mobile_client_settings: initial?.mobile_client_settings ??
            { default_roles: DefaultRoles }
    }) ??
    {
        is_enabled: false, mobile_client_settings: { default_roles: DefaultRoles },
        settings: defaultOrganisationSettings('')
    }, [initial])
    console.log("initialOrganisation", initialOrganisation)
    console.log("from", initial)

    const formSchema = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .required("The organisation name cannot be empty")
    }), [])

    const dispatch = useDispatch()
    const rolesResource = useSelector(s => s.userAccounts.roles)
    useEffect(() => {
        if (!rolesResource.isRequested) {
            dispatch(actions.fetchRoles.request())
        }
    }, [dispatch, rolesResource])
    const roles = useMemo(() => {
        return ApiResult.getValueOrDefault(rolesResource, [])
    }, [rolesResource])

    const [useEmailCommandEnabled, setUseEmailCommandEnabled] = useState(initialOrganisation.settings.managed_engine_settings.use_email_command)

    const handleEmailCommandChange = (enabled: boolean) => {
        setUseEmailCommandEnabled(enabled)
    }

    const [activeIndex, setActiveIndex] = useState(0)

    const handleMenuItemClick = useCallback((event: React.SyntheticEvent<HTMLElement>, data?: MenuItemProps) => {
        console.log("handleMenuItemClick", event)
        console.log("handleMenuItemClick", data)
        if (data) {
            setActiveIndex(data.index!)
        }
    }
    , [])

    const tabItems = [
        {
            "key": "managed_engine",
            "content": "Managed Engine"
        },
        {
            "key": "default_mobile_roles",
            "content": "Default Mobile Roles"
        },
        {
            "key": "tenant_selection",
            "content": "Tenant Selection"
        },       
        {
            "key": "health_report",
            "content": "Health Report"
        },
        {
            "key": "features",
            "content": "Features"
        }
    ]

    return <ResourceAdminForm
        initial={initialOrganisation}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formSchema={formSchema}   
        width="600px"     
    >
        <Menu defaultActiveIndex={0} 
                activeIndex={activeIndex} 
                onItemClick={handleMenuItemClick}
                items={tabItems} 
                underlined primary
            />

            {activeIndex === 0 && 
                <Segment styles={{ width: '600px', height: '600px' }}>
                    <p className={css.sectionTitle}>Managed Engine</p>
                    <FormikNorthstarInput fluid label="Organisation Name" name="name" id="name" />
                    <FormikNorthstarCheckbox toggle label="Enabled" name="is_enabled" />               
                    <FormikNorthstarCheckbox toggle label="Use Managed Engine Email Command" name="settings.managed_engine_settings.use_email_command" onChange={handleEmailCommandChange} />
                    <FormikNorthstarInput fluid label="Account" name="settings.managed_engine_settings.account" id="settings.managed_engine_settings.account" disabled={!useEmailCommandEnabled} />
                    <FormikNorthstarInput fluid label="Site" name="settings.managed_engine_settings.site" id="settings.managed_engine_settings.site" disabled={!useEmailCommandEnabled} />
                    <FormikNorthstarInput fluid label="Operation" name="settings.managed_engine_settings.operation" id="settings.managed_engine_settings.operation" disabled={!useEmailCommandEnabled} />
                    <FormikNorthstarInput fluid label="Requester" name="settings.managed_engine_settings.requester" id="settings.managed_engine_settings.requester" disabled={!useEmailCommandEnabled} />
                    <FormikNorthstarInput fluid label="Category" name="settings.managed_engine_settings.category" id="settings.managed_engine_settings.category" disabled={!useEmailCommandEnabled} />
                
                             
                </Segment>}

            {activeIndex === 1 && <Segment styles={{ width: '600px', height: '600px' }}>
            
                    <p className={css.sectionTitle}>Mobile user roles</p>
                    <RolesPicker                    
                        roles={roles}
                        name="mobile_client_settings.default_roles"
                        label="Select the default roles for Mobile Client Users"
                        trustedOrganisation={false}/>
                    </Segment> }
            
            {activeIndex === 2 && 
                <Segment styles={{ width: '600px', height: '600px' }}> 
                    <p className={css.sectionTitle}>Ad hoc tenant selection</p>
                    <FormikNorthstarCheckbox toggle label="Is a Resource-only Organisation (No users)." name="is_resource_only" />
                    <FormikNorthstarInput fluid label="Icon Url" name="icon_url" />
                </Segment> }
            
            
            {activeIndex === 3 && 
                <Segment styles={{ width: '600px', height: '600px' }}> 
                    <VideoFxHealthReportEditor  settings={initialOrganisation.settings} /> 
                </Segment> }

            {activeIndex === 4 && 
                <Segment styles={{ width: '600px', height: '600px' }}> 
                    <p className={css.sectionTitle}>Features</p>
                    <FormikNorthstarCheckbox toggle label="VideoFX Licence Upload" name="settings.feature_settings.licence_file_upload" />      
                </Segment> }

    </ResourceAdminForm>

}


export default OrganisationsPage