import { Button, Form } from '@fluentui/react-northstar';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FaRegSave } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { AllDaysOfWeek, BookItSettings, bookItSettingsDefaults, BookItSettingsOverride, DayOfWeek, ThresholdAction } from '../../../model';
import { fetchOrganisation } from '../../../store/resourceActions';
import { getActiveOrganisation, getOrganisation } from '../../../store/selectors';
import { useSelector } from '../../../store/utils';
import { Extend, toArrayNumber } from '../../../utils/utils';
import css from './BookItSettingsPage.module.scss';
import DeviceSettings from './DeviceSettings';
import MeetingSettings from './MeetingSettings';
import HealthCheckReportSettings from './HealthCheckReportSettings'
import { useSettingOverride } from './Settings';
import WorkingHoursSettings, { TimeZoneItem } from './WorkingHoursSettings';
import { defaultIfEmpty } from 'rxjs/operators';

const BookItSettingSchema = Yup.object().shape({
    AdHocEnabled: Yup.boolean().required(),
    MaxEarlyStartMinutes: Yup.number()
        .min(-1, 'MaxEarlyStartMinutes must be -1 or greater')
        .required('MaxEarlyStartMinutes is required'),
    AutoEndMeetingEnabled: Yup.boolean().required(),
    AutoEndMeetingMinutes: Yup.number().when('AutoEndMeetingEnabled', {
        is: true,
        then: Yup.number().min(1, "Must be 1 or greater"),
        otherwise: Yup.number().optional().notRequired()
    }),
    ReplaceMeetingSubjectEnabled: Yup.boolean().required(),
    ReplacementMeetingSubject: Yup.string().when('ReplaceMeetingSubjectEnabled', {
        is: true,
        then: Yup.string().required('Must provider a replacement meeting subject'),
        otherwise: Yup.string().optional().notRequired()
    }),
    MeetingSummaryEnabled: Yup.boolean().required(),
    MeetingSummaryBaseUrl: Yup.string().when('MeetingSummaryEnabled', {
        is: true,
        then: Yup.string().url('Must be a valid URL'),
        otherwise: Yup.string().optional().notRequired()
    }),
    RoomSizesFilter: Yup.string()
        .required("Must provider a valid room sizes filter definition")
        .test('is-comma-separated', 'Not a valid room size filter definition', val => {
            if (val)
                return toArrayNumber(val) !== undefined
            else
                return false
        }),
    AutoEndStartedMeetingDurationMinutes: Yup.number().optional().notRequired(),
    WorkingHoursEnabled: Yup.boolean().required(),
    WorkingHoursDaysOfWeek: Yup.array().of(Yup.mixed().oneOf(AllDaysOfWeek)),
    WorkingHoursStartTime: Yup.string().when('WorkingHoursEnabled', {
        is: true,
        then: Yup.string().required("Must provide a valid start time"),
        otherwise: Yup.string().optional().notRequired()
    }),
    WorkingHoursEndTime: Yup.string().when('WorkingHoursEnabled', {
        is: true,
        then: Yup.string().required("Must provide a valid end time"),
        otherwise: Yup.string().optional().notRequired()
    }),
    WorkingHoursTimeZone: Yup.string().when('WorkingHoursEnabled', {
        is: true,
        then: Yup.string().required("Must provide a valid timezone"),
        otherwise: Yup.string().optional().notRequired()
    }),
})


export type BookItSettingsEdit = Extend<Omit<BookItSettings, "default_working_hours" | "overrides" | "recurrence_auto_end_settings">, {
    auto_end_meeting_enabled: boolean
    auto_end_started_meeting_enabled: boolean
    room_sizes_filter: string,
    auto_end_started_meeting_at_midnight: boolean

    working_hours_days_of_week: string[]
    working_hours_start_time: string
    working_hours_end_time: string
    working_hours_time_zone: string

    enabled: boolean
    report_am_time: string
    report_pm_time: string
    report_mailbox: string
    timezone: string

    recurrence_auto_end_enabled: boolean
    recurrence_auto_end_missed_instances: number
}>

type BookItSettingsPageProps = ({
    onSave: (settings: BookItSettings) => void
    source: BookItSettings & { overrides?: undefined }
    base?: undefined
} | {
    onSave: (settings: Partial<BookItSettings> & { overrides: BookItSettingsOverride }) => void
    source: Partial<BookItSettings> & { overrides: BookItSettingsOverride }
    base: BookItSettings
})

type SettingsContextType = {
    values: Partial<BookItSettingsEdit>
    base: BookItSettingsEdit | undefined
    overrides: BookItSettingsOverride | undefined
    dirty: boolean
    onChange: <T extends keyof BookItSettingsEdit>(name: T, value: BookItSettingsEdit[T]) => void
    onOverride: (name: keyof BookItSettingsOverride, value: boolean) => void
}

const SettingsContext = React.createContext<SettingsContextType | null>(null)
SettingsContext.displayName = 'SettingsContext'

function BookItSettingsPage(props: BookItSettingsPageProps) {
    const dispatch = useDispatch()
    const activeOrganisation = useSelector(getActiveOrganisation)
    const organisation = useSelector(s => getOrganisation(s, activeOrganisation.organisation_id))

    const { isUpdating } = useSelector(s => s.organisations.organisations)

    useEffect(() => {
        if (organisation === undefined) {
            dispatch(fetchOrganisation.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation.organisation_id, dispatch, organisation])

    const timezoneData = useSelector(s => s.app.timezone)
    const timezones = useMemo(() => {
        return timezoneData
            ? timezoneData.TimeZoneList.map(tz => ({ ...tz, header: tz.Name }))
            : []
    }, [timezoneData])


    const [overrides, setOverrides] = useState<BookItSettingsOverride | undefined>(props.source.overrides)
    const [values, setValues] = useState<Partial<BookItSettingsEdit>>(toEdit(props.source, timezones))
    const [dirty, setDirty] = useState(false)
    
    console.log("props.source", props.source)
    console.log("values", values)
    

    const handleSubmit = useCallback(() => {
        if (props.base === undefined) {
            props.onSave(fromEdit(values as any))

        } else {
            props.onSave(fromEditWithOverride(values, overrides!))
        }
        setDirty(false)
    }, [props, values, overrides])

    const onOverride = useCallback((name: keyof BookItSettingsOverride, value: boolean) => {
        if (props.source.overrides !== undefined) {
            setOverrides(o => ({ ...o!, [name]: value }))
        }
    }, [props.source.overrides])
    const onChange = useCallback(<T extends keyof BookItSettingsEdit>(name: T, value: BookItSettingsEdit[T]) => {
        setDirty(true)
        setValues(o => ({ ...o, [name]: value }))
    }, [])

    const context = useMemo<SettingsContextType>(() => ({
        base: props.base !== undefined ? toEdit(props.base, timezones) : undefined,
        values,
        overrides,
        onOverride,
        onChange,
        dirty,
    }), [props.base, timezones, values, overrides, onOverride, onChange, dirty])

    return <div className={css.formContainer}>
        <SettingsContext.Provider value={context}>
            <Form className={css.form} onSubmit={e => {
                handleSubmit()
            }}>
                <Button
                    className={css.saveButton}
                    primary
                    icon={<FaRegSave />}
                    content="Save"
                    iconPosition="before"
                    type="submit"
                    disabled={!dirty}
                    loading={isUpdating}
                />
                <MeetingSettings />
                <DeviceSettings />
                <WorkingHoursSettings timezones={timezones} />
                <HealthCheckReportSettings />
            </Form>
        </SettingsContext.Provider>
    </div>
}

export function useSettingsContext() {
    const ctx = useContext(SettingsContext)
    if (!ctx) {
        throw new Error("Parent SettingsContext wasn't found")
    }
    return ctx
}

export function useSettingOverrideState(name: keyof BookItSettingsOverride): [boolean | null, (value: boolean) => void] {
    const { overrides, onOverride } = useSettingsContext()

    const onChange = useCallback((value: boolean) => onOverride(name, value), [name, onOverride])

    return [
        overrides !== undefined ? overrides[name] ?? false : null,
        onChange
    ]
}

export function useSetting<T extends keyof BookItSettingsEdit>(name: T,
    disabled: boolean,
    defaultValue: Partial<BookItSettingsEdit>[T],
    validateFn?: (value: Partial<BookItSettingsEdit>[T]) => [boolean, string?],
) {
    const { overrides, values, onChange, base } = useSettingsContext()
    const onChangeCb = useCallback((value: BookItSettingsEdit[T]) => onChange(name, value), [name, onChange])

    const overrideName = useSettingOverride()
    const override: boolean | null = overrideName !== null && overrides !== undefined ? overrides[overrideName] ?? false : null
    const isDisabled = override === false ? true : disabled

    const validate = useCallback((value: Partial<BookItSettingsEdit>[T]) => {
        if (validateFn !== undefined) {
            return validateFn(value)
        }
        return [true, undefined] as const
    }, [validateFn])

    const overrideRef = useRef<boolean | null>()


    useEffect(() => {
        if (overrideRef.current === override) { return }
        overrideRef.current = override

        if (override) {
            onChange(name, values[name] ?? base?.[name] ?? defaultValue as any)
        }

    }, [base, defaultValue, name, onChange, override, values])

    const value = override === true || override === null ? values[name] : base![name]
    console.log({ value, override, name })

    return {
        value,
        onChange: onChangeCb,
        validate,
        isDisabled
    }
}

function toEdit(settings: BookItSettings, timeZones: TimeZoneItem[]): BookItSettingsEdit
function toEdit(settings: Partial<BookItSettings>, timeZones: TimeZoneItem[]): Partial<BookItSettingsEdit>
function toEdit(settings: BookItSettings | Partial<BookItSettings>, timeZones: TimeZoneItem[]): BookItSettingsEdit | Partial<BookItSettingsEdit> {
    return {
        ...settings,
        auto_end_meeting_enabled: settings.auto_end_meeting_minutes !== undefined,
        auto_end_started_meeting_enabled: settings.auto_end_started_meeting_duration_minutes !== undefined,
        room_sizes_filter: settings.room_sizes_filter !== undefined ? settings.room_sizes_filter.join(', ') : '',
        auto_end_started_meeting_at_midnight: settings.auto_end_started_meeting_duration_minutes !== undefined ? settings.auto_end_started_meeting_duration_minutes === -1 : false,
        working_hours_days_of_week: settings.default_working_hours?.days_of_week,
        working_hours_start_time: settings.default_working_hours?.start_time,
        working_hours_end_time: settings.default_working_hours?.end_time,
        working_hours_time_zone: settings.default_working_hours?.time_zone,
        enabled: settings.health_check_report?.enabled,
        report_am_time: settings.health_check_report?.report_am_time,
        report_pm_time: settings.health_check_report?.report_pm_time,
        report_mailbox: settings.health_check_report?.report_mailbox,
        timezone: settings.health_check_report?.timezone,
        recurrence_auto_end_enabled: settings.recurrence_auto_end_settings !== undefined  &&  settings.recurrence_auto_end_settings.enabled,
        recurrence_auto_end_missed_instances: settings.recurrence_auto_end_settings !== undefined ? settings.recurrence_auto_end_settings.missed_instances : bookItSettingsDefaults.recurrence_auto_end_settings.missed_instances,
    }
}

function fromEdit(s: BookItSettingsEdit): BookItSettings {
    const settings = {
        ...s,
        auto_end_meeting_minutes: s.auto_end_meeting_enabled ? s.auto_end_meeting_minutes : undefined,
        auto_end_started_meeting_duration_minutes: s.auto_end_started_meeting_duration_minutes ? s.auto_end_started_meeting_duration_minutes : 1440,
        room_sizes_filter: toArrayNumber(s.room_sizes_filter)!,
        default_working_hours: {
            days_of_week: s.working_hours_days_of_week as DayOfWeek[],
            start_time: s.working_hours_start_time,
            end_time: s.working_hours_end_time,
            time_zone: s.working_hours_time_zone
        },
        health_check_report: {
            enabled: s.enabled,
            report_am_time: s.report_am_time,
            report_pm_time: s.report_pm_time,
            report_mailbox: s.report_mailbox,
            timezone: s.timezone
        }, 
        recurrence_auto_end_settings: {
            enabled: s.recurrence_auto_end_enabled,
            missed_instances: s.recurrence_auto_end_missed_instances,
            actions: bookItSettingsDefaults.recurrence_auto_end_settings.actions
        }
    }

    const { auto_end_meeting_enabled,
        auto_end_started_meeting_enabled,
        working_hours_days_of_week,
        working_hours_start_time,
        working_hours_end_time,
        working_hours_time_zone,
        enabled,
        report_am_time,
        report_pm_time,
        report_mailbox,
        timezone,
        recurrence_auto_end_enabled, 
        recurrence_auto_end_missed_instances,
        ...res } = settings

    return res

}

function fromEditWithOverride(s: Partial<BookItSettingsEdit>, overrides: BookItSettingsOverride): Partial<BookItSettings> & { overrides: BookItSettingsOverride } {
    return {
        ad_hoc_enabled: overrides.ad_hoc_enabled ? s.ad_hoc_enabled : undefined,
        max_early_start_minutes: overrides.max_early_start_minutes ? s.max_early_start_minutes : undefined,
        auto_end_meeting_minutes: overrides.auto_end_meeting_minutes ? s.auto_end_started_meeting_duration_minutes! : undefined,
        meeting_summary_enabled: overrides.meeting_summary_enabled ? s.meeting_summary_enabled : undefined,
        meeting_summary_base_url: overrides.meeting_summary_enabled ? s.meeting_summary_base_url : undefined,
        replace_meeting_subject_enabled: overrides.replace_meeting_subject_enabled ? s.replace_meeting_subject_enabled : undefined,
        replacement_meeting_subject: overrides.replace_meeting_subject_enabled ? s.replacement_meeting_subject : undefined,
        room_sizes_filter: overrides.room_sizes_filter ? toArrayNumber(s.room_sizes_filter ?? '')  : undefined,
        auto_end_started_meeting_duration_minutes: overrides.auto_end_started_meeting_override
            ? (s.auto_end_started_meeting_at_midnight ? -1 : s.auto_end_started_meeting_duration_minutes!) : undefined,
        working_hours_enabled: overrides.working_hours_enabled ? s.working_hours_enabled : undefined,
        default_working_hours: overrides.default_working_hours ? {
            days_of_week: s.working_hours_days_of_week! as DayOfWeek[],
            start_time: s.working_hours_start_time!,
            end_time: s.working_hours_end_time!,
            time_zone: s.working_hours_time_zone!
        } : undefined,
        health_check_report: overrides.health_check_report ? {
            enabled: s.enabled!,
            report_am_time: s.report_am_time!,
            report_pm_time: s.report_pm_time!,
            report_mailbox: s.report_mailbox!,
            timezone: s.timezone!
        } : undefined,
        device_background_url: overrides.device_background_url ? s.device_background_url : undefined,
        allow_attendee_editing: overrides.allow_attendee_editing ? s.allow_attendee_editing : undefined,
        allow_secondary_login_auto_complete: overrides.allow_secondary_login_auto_complete ? s.allow_secondary_login_auto_complete : undefined,
        invite_external_attendees_enabled: overrides.invite_external_attendees_enabled ? s.invite_external_attendees_enabled : undefined,
        recurrence_auto_end_settings: overrides.recurrence_auto_end ? {
            enabled: s.recurrence_auto_end_enabled!,
            missed_instances: s.recurrence_auto_end_missed_instances!,
            actions: bookItSettingsDefaults.recurrence_auto_end_settings.actions
        } : undefined,
        overrides: overrides
    }
}

export default BookItSettingsPage;
