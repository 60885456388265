import { Button, ButtonProps, Flex, Form, FormField, FormLabel, FormProps, Input, InputProps, Text } from '@fluentui/react-northstar';
import moment from "moment";
import React, { useState } from 'react';
import { ComponentType, PackageFeedType } from '../../model';
import DropdownControlled from '../controls/DropdownControlled';

export interface CreateFeed {
    feed_name?: string;
    feed_type?: PackageFeedType,
    component_type: ComponentType,
    description?: string;
    automatic_update?: string;
}


const FeedTypes: PackageFeedType[] =
    [
        'Msi',
        'JavascriptPackage',
        'Other'
    ];

const CompnentTypes: ComponentType[] =
    [
        'None',
        'Room',
        'Admin',
        'JavascriptPackage'
    ];


interface Props {
    onSubmit: (feed: CreateFeed) => void
    onCancel: () => void
}

const AddFeedForm: React.FC<Props> = (props) => {

    const [state, setState] = useState<CreateFeed>({ component_type: 'None' })
    const [automaticUpdateValidationError, setAutomaticUpdateValidationError] = useState<string>()

    const handleSubmit = (event: React.SyntheticEvent<HTMLElement>, data?: FormProps) => {
        if (state.automatic_update) {
            const autoUpdate = moment(state.automatic_update!, "HH:mm");
            if (!autoUpdate.isValid()) {
                setAutomaticUpdateValidationError("Automatic update must be a valid time between 00:00 and 23:59");
                return;
            }
            state.automatic_update = autoUpdate.utc().format("HH:mm");
        }
        console.log('AddFeedForm', state)
        props.onSubmit(state)
    }

    const handleCancel = (event: React.SyntheticEvent<HTMLElement>, data?: ButtonProps) => {
        props.onCancel()
    }

    const handleChange = (event: React.SyntheticEvent<HTMLElement>, data?: InputProps & { value: string }) => {
        const name = (event.currentTarget as any).name
        const { value } = data!
        setState(prevState => ({ ...prevState, [name]: value }))
    }

    const handleFeedTypeDropdownChange = (value: string | null): void => {
        const feedType = value as PackageFeedType
        console.log('feedType:', feedType)
        setState(prevState => ({ ...prevState, feed_type: feedType || undefined }))
    }


    const handleComponentTypeDropdownChange = (value: string | null): void => {
        const type = value as ComponentType
        console.log('componentType:', type)
        setState(prevState => ({ ...prevState, component_type: type || undefined }))
    }

    return (<Form onSubmit={handleSubmit}>
        <Flex column gap="gap.medium" fill styles={{ width: '40vw' }}>

            <FormField label="Feed Name" id="feed-name" required control={<Input onChange={handleChange} value={state.feed_name} name="feed_name" fluid />} />

            <FormField>
                <FormLabel id='feed_type_label'>Feed Type</FormLabel>
                <DropdownControlled
                    dataItems={FeedTypes} 
                    onSelectionChanged={handleFeedTypeDropdownChange} 
                    selectedKey='MSI' allowNone={false}
                    getHeader={getFeedTypeHeader} 
                    placeholder='Choose a the package feed type' />
            </FormField>

            <FormField label="Description" id="description" required control={<Input onChange={handleChange} name="description" value={state.description} fluid />} />


            <FormField>
                <FormLabel id='component_type_label'>Component Type</FormLabel>
                <DropdownControlled dataItems={CompnentTypes} 
                    onSelectionChanged={handleComponentTypeDropdownChange} 
                    selectedKey='None' 
                    allowNone={false}
                    getHeader={getComponentTypeHeader} 
                    placeholder='Choose a the component type' />
            </FormField>

            <FormField label="Automatic Update" id="automatic_update"
                control={<Input onChange={handleChange}
                    name="automatic_update"
                    value={state.automatic_update}
                    fluid />}
                message={() => (<Text error content={automaticUpdateValidationError} />)} />
            <Flex gap="gap.smaller" hAlign="end">
                <FormField
                    control={{
                        as: Button,
                        onClick: handleCancel,
                        content: 'Cancel',
                    }}
                />
                <FormField
                    control={{
                        as: Button,
                        primary: true,
                        content: 'Submit',
                    }}
                />
            </Flex>
        </Flex>
    </Form>)
}

function getFeedTypeHeader(feedType: PackageFeedType) {
    switch (feedType) {
        case 'Msi': return "MSI"
        case 'JavascriptPackage': return "Javascript Package"
        case 'Other': return "Other"
    }
}

function getComponentTypeHeader(componentType: ComponentType) {
    switch (componentType) {
        case 'None': return "None"
        case 'Room': return "Room"
        case 'Admin': return "Admin"
        case 'JavascriptPackage': return "Javascript Package"
    }
}

export default AddFeedForm