import * as Yup from 'yup';
import { HalResource } from './services/Hal';
import { Guid } from './utils/guid';


export type RunningStatus = "NeverRun" | "Running" | "Stopped"

export type ProvisioningStatus = "AwaitingProvisioning" | "AwaitingInstall" | "AwaitingReinstall" | "Installing" | "InstallFailed" | "Installed";

export type StopReason = "ByUser" | "ByApi" | "Settings" | "Reboot" | "System" | "Crashed" | "Unknown" | "Unresponsive" | "NotSpecified"

export type EventEscalationSelection = "active" | "closed" | "all"

export type LoginType = 'BookIt' | 'AzureAd' | 'None'
export const AllLoginTypes: LoginType[] = ['BookIt', 'AzureAd', 'None']

export type ProviderType = 'Ews' | 'Google' | 'Graph'
export const AllProviderTypes = ['Ews', 'Google', 'Graph']

export type OnlineMeetingProviderType = "SkypeForBusiness" | "Teams" | "Zoom" | "WebEx" | "GoogleMeet" | "Jitsi"
export const AllOnlineMeetingProviderTypes = ['SkypeForBusiness', 'Teams', 'Zoom', 'WebEx', 'GoogleMeet', 'Jitsi']

export type PermissionType = "ReadWrite" | "Read"

export type ExchangeVersionType = 'Exchange2007_SP1' | 'Exchange2010' | 'Exchange2010_SP1' | 'Exchange2010_SP2' | 'Exchange2013' | 'Exchange2013_SP1'
    | 'Exchange2015' | 'Exchange2016' | 'V2015_10_05'
export const AllExchangeVersions: ExchangeVersionType[] = ['Exchange2007_SP1', 'Exchange2010', 'Exchange2010_SP1', 'Exchange2010_SP2', 'Exchange2013', 'Exchange2013_SP1'
    , 'Exchange2015', 'Exchange2016', 'V2015_10_05']


export type IdType = Guid
export interface Organisation {
    organisation_id: IdType
    name: string
    is_enabled: boolean,
    is_resource_only: boolean
    icon_url: string
    created_time_utc: string
    trusted: boolean
    providers?: Array<Provider>
    online_meeting_providers?: Array<OnlineMeetingProvider>
    owner: UserAccountSummary
    primary_users: UserAccountSummary[]
    book_it_settings?: BookItSettings
    mobile_client_settings?: MobileClientSettings
    settings?: OrganisationSettings
    uc_support_settings?: UcSupportSettings
}


export type CreateOrganisation = Omit<Organisation, "organisation_id" | "owner" | "primary_users" | "created_time_utc" | "trusted"> & {
    owner_user_account_id: IdType
}

export interface Resource {
    organisation_id: IdType
    resource_id: IdType
    identifier: string
    display_name: string
    email_address: string
    description: string
    video_fx_room_id?: IdType
    bookable_resource_id?: IdType
    managed_engine_override?: ManagedEnginSettings
    organisation: OrganisationSummary
}

export type CreateResource = Omit<Resource, "organisation_id" | "resource_id" | "primary_users" | "video_fx_room_id" | "bookable_resource_id" | "organisation">


export interface PortalSettings {
    feature_settings: {
        licence_file_upload: boolean
    },
    vfx_health_report: {
        enabled: boolean,
        report_am_time?:string,
        report_pm_time?: string,
        report_mailbox?: string,
        timezone?: string
    },
    scheduled_generic_report_settings: GenericReportSchedule[]
}

export type GenericReportSchedule = {
    account?: string,
    due_day_of_month?: string,
    due_day_of_week?: string,
    due_time: string,
    exclude_weekends: boolean,
    is_enabled: boolean,
    location?: string,
    mailboxes?: string,
    report_name?: string,
    reporting_period?: string,
    resource_type?: string,
    timezone?: string,
    working_hours_only: boolean,
    schedule_name?: string,
    owner_mailbox?: string
}

export type OrganisationSettings = PortalSettings & {
    managed_engine_settings: ManagedEnginSettings

}

export interface ManagedEnginSettings {
    use_email_command: boolean
    account?: string
    site?: string
    operation?: string
    requester?: string
    category?: string
}

export type BookItSettings = {
    ad_hoc_enabled: boolean
    max_early_start_minutes: number
    auto_end_meeting_minutes?: number
    meeting_summary_enabled: boolean
    meeting_summary_base_url?: string
    replace_meeting_subject_enabled: boolean
    replacement_meeting_subject?: string
    room_sizes_filter: number[],
    auto_end_started_meeting_duration_minutes: number
    working_hours_enabled: boolean
    default_working_hours: WorkingHours,
    device_background_url?: string,
    allow_attendee_editing: boolean
    allow_secondary_login_auto_complete: boolean
    invite_external_attendees_enabled: boolean
    health_check_report: HealthCheckReportSettings
    recurrence_auto_end_settings: RecurrenceAutoEndSettings
}
export interface RecurrenceAutoEndSettings {
    enabled: boolean
    missed_instances: number
    actions: ThresholdAction[]
}

export type ThresholdAction = 'EmailOrganiser' | 'TruncateRecurrenceOnResourceCalendar'
export interface HealthCheckReportSettings {
    enabled: boolean,
    report_am_time: string,
    report_pm_time: string,
    report_mailbox: string,
    timezone: string
}

export const bookItSettingsDefaults: BookItSettings = {
    ad_hoc_enabled: true,
    max_early_start_minutes: 15,
    auto_end_meeting_minutes: 5,
    meeting_summary_enabled: false,
    replace_meeting_subject_enabled: false,
    room_sizes_filter: [4, 9],
    auto_end_started_meeting_duration_minutes: 1440,
    working_hours_enabled: true,
    device_background_url: undefined,
    allow_attendee_editing: false,
    allow_secondary_login_auto_complete: false,
    invite_external_attendees_enabled: false,
    default_working_hours: {
        days_of_week: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        start_time: "9:00",
        end_time: "17:00",
        time_zone: "New Zealand Standard Time"
    },
    health_check_report: {
        enabled: false,
        report_am_time: "08:00",
        report_pm_time: "16:00",
        report_mailbox: "",
        timezone: "New Zealand Standard Time"
    },
    recurrence_auto_end_settings: {
        enabled: false,
        missed_instances: 3,
        actions: ['EmailOrganiser', 'TruncateRecurrenceOnResourceCalendar' ]
    }
}

export interface BookItSettingsOverride {
    ad_hoc_enabled: boolean
    max_early_start_minutes: boolean
    auto_end_meeting_minutes: boolean
    meeting_summary_enabled: boolean
    replace_meeting_subject_enabled: boolean
    room_sizes_filter: boolean,
    auto_end_started_meeting_override: boolean
    device_background_url: boolean
    allow_attendee_editing: boolean,
    allow_secondary_login_auto_complete: boolean,
    invite_external_attendees_enabled: boolean,
    working_hours_enabled: boolean
    default_working_hours: boolean
    health_check_report: boolean
    recurrence_auto_end: boolean

}

export const defaultOverrides: BookItSettingsOverride = {
    ad_hoc_enabled: false,
    max_early_start_minutes: false,
    auto_end_meeting_minutes: false,
    meeting_summary_enabled: false,
    replace_meeting_subject_enabled: false,
    room_sizes_filter: false,
    auto_end_started_meeting_override: false,
    working_hours_enabled: false,
    device_background_url: false,
    default_working_hours: false,
    allow_attendee_editing: false,
    allow_secondary_login_auto_complete: false,
    invite_external_attendees_enabled: false,
    health_check_report: false,
    recurrence_auto_end: false
}


export const DefaultRoles = ["BookItDeviceNfcTag",
    "HotDeskNfcTag",
    "QuickBookNfcTag",
    "NearbyNfcTag",
    "VideoFxRoomNfcTag",
    "MeetingRoomBook",
    "HotDeskBook",
    "CarparkBook",
    "LockerBook"]
export interface MobileClientSettings {
    default_roles: string[]
}

export interface SharingGroup {
    sharing_group_id: IdType
    name: string
    organisation_id: IdType
    members: OrganisationSummary[]
    shared_bookable_resources: BookableResourceSummary[]
}

export interface SharingCode {
    sharing_code_id: IdType
    code: string
    organisation_id: IdType
    owner: OrganisationSummary
    usages: SharingCodeUsage[]
}

export interface CreateSharingCode {
    organisation_id: IdType

}

export interface CreateSharingCodeUsage {
    organisation_id: IdType
    sharing_code_id: IdType
    usage_type: UsageType
}

export interface SharingCodeUsage {
    sharing_code_id: IdType
    owner_organisation_id: IdType
    owner_organisation: OrganisationSummary
    shared_to: OrganisationSummary
    usage_type: UsageType
    code: string
}

export type UsageType = 'None' | 'FederatedContacts' | 'LoginCompletionSharedDevices'

export interface BookableResourceSummary {
    bookable_resource_id: IdType
    name: string
    email_address: string
}

export interface CreateSharingGroup {
    name: string
    organisation_id: IdType
    member_organisation_ids: IdType[]
    shared_bookable_resource_ids: IdType[]
}

export interface UserAccountSummary {
    user_account_id: IdType
    email_address: string
    given_name: string
    family_name: string
}

export interface ConferenceClient {
    video_conference_client_id: IdType
    version: string
    additional_info?: string
    status: ConferenceClientStatus
    type: ConferenceClientType
    implementation: ConferenceClientImplemenation
    created_date_utc: string
    status_modified_utc: string
}

export type CreateConferenceClient = Omit<ConferenceClient, 'video_conference_client_id' | 'created_date_utc' | 'status_modified_utc'>

export type ConferenceClientStatus = 'Unknown' | 'Approved' | 'KnownIssues'
export type ConferenceClientType = 'None' | 'SkypeForBusiness' | 'Teams' | 'Webex' | 'WebexTeams' | 'Zoom' | 'GoogleMeet' | 'Skype' | 'GoToMeeting' | 'RealPresence' | 'Jitsi'
export type ConferenceClientImplemenation = 'None' | 'Web' | 'Desktop'

export type Provider = {
    provider_id: IdType
    description: string
    login_type: LoginType
    azure_login_client_id?: string
    azure_login_tenant_id?: string
    ping_email_address?: string
    organisation_id: IdType
    domain_maps: string[]
} & ({
    provider_type: 'Graph'
    configuration_json: GraphProviderConfiguration
} | {
    provider_type: 'Ews'
    configuration_json: EwsProviderConfiguration
} | {
    provider_type: 'Google'
    configuration_json: GoogleCalendarProviderConfiguration

})

export type CreateProvider = Omit<Provider, "provider_id">


export interface ProviderTestResult {
    provider_id: IdType,
    results: Array<{
        success: boolean
        operation: string
        message: string
        detail: string
    }>
}

export interface OnlineMeetingProvider {
    online_meeting_provider_id: IdType
    description: string
    meeting_provider_type: OnlineMeetingProviderType
    configuration_json: TeamsProviderConfiguration | SkypeForBusinessProviderConfiguration | GoogleMeetProviderConfiguration | WebExProviderConfiguration | ZoomProviderConfiguration | JitsiProviderConfiguration
    organisation_id: IdType
    domain_maps: string[]
}

export type CreateOnlineMeetingProvider = Omit<OnlineMeetingProvider, "online_meeting_provider_id">

export type TeamsProviderConfiguration = AzureAppIdConfiguration & {
    UseGraphCalendarApi: boolean
}
export const GraphOnlineProviderConfigurationSchema: Yup.ObjectSchemaDefinition<{ UseGraphCalendarApi: boolean }> = {
    UseGraphCalendarApi: Yup.boolean().required(),
}

export interface SkypeForBusinessProviderConfiguration {
    UseGraphCalendarApi: boolean
    ForgottenPinUrl: string
    LocalPhoneUrl: string
    MaxMeetingSize: number
    TollUri: string
    OnPremiseDomains: string[]
    Username?: string
    Password?: string
    Tenant: string
    ClientID: string
}

export interface GoogleMeetProviderConfiguration {
    // n/a
}

export interface WebExProviderConfiguration {
    ClientId: string
    ClientSecret: string
    RedirectUrl: string
    ServiceAccount: string
}

export interface ZoomProviderConfiguration {
    ApiKey: string
    ApiSecret: string
    BaseApiUrl: string
    ProxyDomainUser: string
}

export interface JitsiProviderConfiguration {
    HostUrl: string                               // "https://mobility-dev.ipfx.com/webrtc/jitsi",
    ShowPreJoin: boolean                          //  True
}

export type GraphProviderConfiguration = AzureAppIdConfiguration

export const AzureAppIdConfigurationSchema: Yup.ObjectSchemaDefinition<AzureAppIdConfiguration> = {
    ClientId: Yup.string()
        .required('Client ID cannot be empty'),
    ClientSecret: Yup.string()
        .required('Client secret cannot be empty'),
    TenantId: Yup.string()
        .required('Tenant ID cannot be empty'),
}

export interface AzureAppIdConfiguration {
    ClientSecret: string
    ClientId: string
    TenantId: string
}

export interface EwsProviderConfiguration {
    ServiceAccount: string
    Password: string
    ExchangeVersion: ExchangeVersionType
    InternalExchangeUriHints: string
    OnlineExchangeUriHints: string
    AlwaysUseSuppliedUri: boolean
    ExchangeWebServiceUri: string
    EnableTracing: boolean
}

export const EwsProviderConfigurationSchema: Yup.ObjectSchemaDefinition<EwsProviderConfiguration> = {
    ServiceAccount: Yup.string()
        .required('Service Account cannot be empty'),
    Password: Yup.string()
        .required('Password cannot be empty'),
    ExchangeVersion: Yup.mixed().required().oneOf(AllExchangeVersions),
    InternalExchangeUriHints: Yup.string()
        .required('InternalExchangeUriHints cannot be empty'),
    OnlineExchangeUriHints: Yup.string()
        .required('OnlineExchangeUriHints cannot be empty'),
    AlwaysUseSuppliedUri: Yup.boolean().required(),
    ExchangeWebServiceUri: Yup.string()
        .required('ExchangeWebServiceUri cannot be empty'),
    EnableTracing: Yup.boolean().required()
}


export interface GoogleCalendarProviderConfiguration {
    CalendarId: string
    AppId: string
    ProxyDomainUser: string
    ServiceAccountKey: string
}

export const GoogleProviderConfigurationSchema: Yup.ObjectSchemaDefinition<GoogleCalendarProviderConfiguration> = {
    CalendarId: Yup.string().required('Calendar Id cannot be empty'),
    AppId: Yup.string().required('App Id cannot be empty'),
    ProxyDomainUser: Yup.string().required('Proxy Domain User cannot be empty'),
    ServiceAccountKey: Yup.string().required('Service Account Key cannot be empty'),
}


export interface Room {
    room_id?: IdType;
    name: string;
    display_name: string;
    organisation_id: IdType;
    email_address: string;
    sip_address?: string;
    room_api_url?: string;
    admin_api_url?: string;
    skype_client_version?: string;
    teams_client_version?: string;
    creation_time?: string;
    provisioning_status: ProvisioningStatus;
    is_in_meeting?: boolean;
    team_viewer_id?: string;
    ip_addresses?: string[];
    template_room_name?: string;
    pin?: string;
    provisioning_pin?: string;
    has_license_file: boolean;
    has_event_escalations?: boolean
    next_meeting_start?: string
    escalations_muted?: boolean

    // creation only
    room_msi_package_feed_name?: string;
    admin_msi_package_feed_name?: string;
    javascript_package_feed_name?: string;

    room_component?: HalResource<Component>
    admin_component?: HalResource<Component>
    javascript_package_component?: HalResource<Component>
}

export interface RoomNames {
    names: string[]
}

export interface ServerInfo {
    version: string
    server_datetime_utc: string
    root_url: string
    authority: string
}


export interface Component {
    last_restart?: string;
    last_heartbeat?: string;
    running_status: RunningStatus;
    stop_reason?: StopReason;
    version?: string;

    package_feed_name?: string;
    update_available?: boolean;
    update_version?: string;
}

export type ComponentType = 'None' | 'Room' | 'Admin' | 'JavascriptPackage'

export type DeploymentState = "Initialising" | "BuildingConfiguration" | "DeployingConfiguration" | "DeployingPackage" | "Failed" | "Complete";

export interface Deployment {
    created_date_utc: string,
    last_modified_date_utc: string,
    state: DeploymentState,
    failed_state?: DeploymentState,
    is_recovery: boolean
}

export interface RoomConfiguration {
    version: number
    created_date_utc: string
    is_latest?: boolean
}

export interface RoomAttributes {
    [name: string]: RoomAttribute;
}
export type AttributeType = "Room" | "Provisioning" | "Provisioning";
export type AttributeDataType = "String" | "Boolean" | "Integer" | "Decimal" | "DateTime" | "Password";

export interface RoomAttribute {
    value: string | number | boolean | Date;
    data_type: AttributeDataType;
    type: AttributeType;
}

export interface SettingsValues {
    name: string;
    value: string;
}

export type SettingsCategory = SettingsValues & {
    application: string;
    category: string;
}

export type ApiType = 'Server' | 'Room' | 'Admin';

export interface CorsAllowedOrigin {
    cors_allowed_origin_id?: IdType; // allow undefined for POST
    origin?: string;
    description?: string;
    api: ApiType;
}

export type BookItAggregateColumnType = 'leaderboard_by_duration' | 'leaderboard_by_count'

export type AggregateColumnType = 'duration' | 'room_by_meeting_count' | 'room_by_usage_duration' | 'start_time' | 'participant_count' | 'booking_type' | 'meeting_type' | 'meeting_booking_type' | 'booking_meeting_type';
interface KeyValuePair<TValue> {
    key: string;
    value: TValue;
}
export interface AggregateData {
    by: AggregateColumnType;
    data: Array<KeyValuePair<number>>;
}

export type ScanStatus = 'Queued' | 'InProgress' | 'NoThreat' | 'ThreatDetected' | 'Timeout'

export interface VirusScan {
    package_virus_scan_id: IdType
    status: ScanStatus
    analysis_id: string
    sha_256: string
    date_created_utc: string
    last_updated_utc: string
    version: string
    package_filename: string
    package_feed_id: IdType
    package_feed_name: string
    background_operation_id: IdType
}

export type PackageFeedType = 'Msi' | 'JavascriptPackage' | 'Other'

export interface PackageFeed {
    feed_name: string;
    description: string;
    default: boolean;
    date_created_utc: string;
    automatic_update_time_utc?: string
    last_automatic_update_check_utc?: string
    type: PackageFeedType
    component_type: ComponentType
}

export interface BackgroundOperationResult {
    background_operation_id: IdType
}

export function isPackageVersion(result: HalResource<PackageVersion> | BackgroundOperationResult): result is HalResource<PackageVersion> {
    return (result as HalResource<PackageVersion>).feed_name !== undefined
}

export interface PackageVersion {
    feed_name: string;
    version: string;
    package_filename: string;
    date_created_utc: string;
}


export function makePackageVersionKey(version: PackageVersion) {
    return `${version.feed_name}_${version.version}`;
}

export type OperationState = 'InFlight' | 'InProgress' | 'Complete' | 'Failed' | 'Cancelled' | 'Timeout' | 'Dismissed'

export function isFinalState(state: OperationState): boolean {
    return state === 'Complete' ||
        state === 'Failed' ||
        state === 'Cancelled' ||
        state === 'Timeout' ||
        state === 'Dismissed'
}

export interface BackgroundOperation {
    background_operation_id: IdType
    state: OperationState
    status: string
    started_time_utc: string
    completed_time_utc?: string
    is_indeterminate: boolean
    current?: number
    maximum?: number
    minimum?: number
    result?: any
}

export interface FeedUpdateComponentsResult {
    deployed_rooms: string[]
    failed_rooms: Array<{
        name: string
        message: string
    }>
}

export interface ProblemReport {
    api_error_code: string;
    detail?: string;
    instance: string;
    status: number;
    title: string;
    type: string;
}

export interface Process {
    process_type: string;
    running: boolean;
}

export interface LogFileMetadata {
    size: number;
    last_modified_date_utc: string
}

const tuple = <T extends string[]>(...args: T) => args;

const LogFilesTuple = tuple('RoomSystem', 'RoomSystemSettings', 'RoomSystemShell', 'InstallTool', 'SupportTool', 'LockedMeetingAdmit', 'AdminService', 'RoomSystemUpdater');
export type LogFilesType = typeof LogFilesTuple[number];
export const LogFiles: LogFilesType[] = LogFilesTuple;

const RoomsFiltersTuple = tuple('All Rooms', 'Stopped Rooms', 'Rooms Requiring Install', 'Rooms Requiring Upgrade', 'Unresponsive Rooms');
export type RoomsFilterType = typeof RoomsFiltersTuple[number];
export const RoomsFilters: RoomsFilterType[] = RoomsFiltersTuple;

export interface BlobData {
    isLoading: boolean;
    name?: string | undefined;
    data?: Blob | undefined;
    failed: boolean;
}

export interface UserInfo {
    portal_login_allowed: boolean
    nfctag_notifications_allowed: boolean
    user: UserAccount
    allowed_organisations: AllowedOrganisation[]
}

export interface AllowedOrganisation {
    organisation_id: IdType
    name: string
    permission: PermissionType
}

export interface UserAccount {
    user_account_id: IdType
    email_address: string
    given_name: string
    family_name: string
    date_created_utc: string
    account_locked: boolean
    account_locked_until?: string
    failed_password_attempts?: number
    password?: string
    roles: string[]
    primary_organisation: OrganisationSummary
    owned_organisations: OrganisationSummary[]
    tenant?: OrganisationTenant
}

export type CreateUserAccount = Omit<UserAccount, "user_account_id" | "date_created_utc" | "account_locked"
    | "primary_organisation" | "owned_organisations"> & {
        primary_organisation_id: IdType
    }

export interface OrganisationSummary {
    organisation_id: IdType,
    trusted: boolean,
    name: string
}

export type OrganisationTenant = {
    organisation_tenant_id: IdType
    domains: string[]
    organisation_id: IdType
} & ({
    type: 'AzureAdToken'
    tenant_id: string
    client_id: string
    client_secret: string
    allow_portal_login: boolean
} | {
    type: Exclude<OrganisationTenantType, 'AzureAdToken'>
})

export type AzureAdOrganisationTenant = Extract<OrganisationTenant, { type: 'AzureAdToken' }>

export type CreateOrganisationTenant = Omit<AzureAdOrganisationTenant, "organisation_tenant_id">


export type OrganisationTenantType = "Local" | "AzureAdToken" | "LocalAd" | "ConnectorAd"

export interface Role {
    name: string,
    description: string
}

export type NfcTagType = 'BookItDevice' | 'VideoFxRoom' | 'Nearby' | 'Visitor'
export const AllNfcTagTypes: NfcTagType[] = ['BookItDevice', 'VideoFxRoom', 'Nearby', 'Visitor']

export type NfcTag = {
    nfc_tag_id: IdType
    tag_name: string
    serial_number?: string
    location_code?: string
    unique_id?: Guid
    organisation_id: IdType
} & ({
    tag_type: 'Nearby'
    configuration_json: NearbyNfcTagConfiguration
} | {
    tag_type: NfcTagType
    configuration_json: undefined
})

export interface NearbyNfcTagConfiguration {
    DefaultResourceType: RoomSubType
    NoPrimaryDevice: boolean
}


export type CreateNfcTag = Omit<NfcTag, 'nfc_tag_id'>


export interface EventEscalation {
    event_escalation_id: IdType
    escalation_title: string
    escalation_cleared_title?: string
    escalation_route_id: string
    raised_date_utc: string
    cleared_date_utc?: string
    triggering_archived_event: ArchivedEvent
    clearing_archived_event?: ArchivedEvent
    room_name: string // not from API
}


export interface ArchivedEvent {
    category: string,
    event_type: "Success" | "Failure" | "Information" | "Error",
    id: string,
    roomName: string,
    title: string,
    message: string,
    timestamp: string,
    "properties"?: {
        [key: string]: any
    }
}

export interface RoomChangedEvent {
    roomName: string;
    rel: string;
    properties: string[];
}


export type HealthStatus = 'Degraded' | 'Healthy' | 'Unhealthy';

export interface RoomHealthData {
    status: string,
    health: HealthStatus,
    url?: string
}

export interface RoomsHealthData {
    Room: { [key: string]: RoomHealthData },
    Admin: { [key: string]: RoomHealthData },
}

export interface RoomHealthCheckResult {
    status: HealthStatus,
    description: string,
    data: RoomsHealthData
}

export interface HealthReport {
    status: HealthStatus,
    results: {
        room_running: RoomHealthCheckResult,
        room_tunnel: RoomHealthCheckResult
    }
}

export interface NfcTagScannedEvent {
    tagName: String
    tagType: String
    emailAddress: String
}

export interface RoomWindowsUpdate {
    windows_update_id: IdType
    update_id: string
    revision_number: number
    title: string
    description: string
    categories: string[]
    kb_article_ids: string[]
    more_info_urls: string[]
    msrc_severity?: string
    is_installed: boolean
    is_downloaded: boolean
    reboot_required: boolean
    modified_date_utc: string
    created_date_utc: string
    install_requested: boolean
    state: string
}

export interface WindowsUpdate {
    windows_update_id: IdType
    update_id: string
    title: string
    description: string
    pending_rooms?: string[]
    categories: string[]
    kb_article_ids: string[]
    more_info_urls: string[]
    msrc_severity?: string
    reboot_required: boolean
    modified_date_utc: string
    created_date_utc: string
}

export interface WindowsUpdateInstallRequest {
    windows_update_ids: number[]
}

export interface WindowsUpdateInstallRequestResponse {
    data: WindowsUpdateInstallRequestResult[]
}

export interface WindowsUpdateInstallRequestResult {
    resource: RoomWindowsUpdate
    error_code?: string
}

export type RoomSubType = "MeetingRoom" | "HotDesk" | "ParkingSpace" | "Locker" | "Vehicle";
export const AllRoomSubTypes = ["MeetingRoom", "HotDesk", "ParkingSpace", "Locker", "Vehicle"]


export interface BookableResource {
    bookable_resource_id: IdType
    email_address: string
    name: string
    location_name?: string
    is_active: boolean
    is_closed: boolean
    capacity: number
    sub_type: RoomSubType
    floor_plan_url?: string
    floor_plan_data?: FloorPlanData
    floor: string | null
    enable_nfc_authentication?: boolean

    // rels
    location_id?: IdType
    attribute_ids?: IdType[]
    organisation_id: IdType
    provider_id: IdType

    settings: Partial<BookItSettings> & { overrides: BookItSettingsOverride }

}

export type CreateBookableResource = Omit<BookableResource, "bookable_resource_id">

export interface FloorPlanData {
    base64_data: string
    content_type: string
}

export interface BookItAttribute {
    attribute_id: IdType
    name: string
    url: string
}

export type CreateBookItAttribute = Pick<BookItAttribute, "name"> & {
    file: File
}

export interface BookItLocation {
    location_id: IdType
    name: string
    organisation_id: IdType
}

export type CreateBookitLocation = Omit<BookItLocation, "location_id">

export type DefaultScreenType = "Timeline" | "RoomList" | "DisplayBoard"

export interface BookItDevice {
    device_id: IdType
    name: string
    default_screen: DefaultScreenType
    pin?: string
    primary_room_id: IdType
    organisation_id: IdType
    bookable_resources: Record<string, string | undefined>
}

export type CreateBookItDevice = Omit<BookItDevice, "device_id" | "bookable_resources"> & {
    bookable_resource_ids?: IdType[]
}

export interface ConnectedDevice {
    device_id: IdType
    email_address: string
    organisation_id: IdType
    connections: [
        {
            connection_id: string
            client_type: "iOS" | "Android"
            client_version?: string
            connected_utc: string
        }
    ]
}

export interface HeatMap {
    start_range: string,
    end_range: string,
    number_of_week_days_in_range: number,
    room_type_usage: Array<{
        attendee_type: string,
        room_usage: Array<{
            room_name: string,
            usage_by_hour: HeatMapRoomUsageByHour,
        }>
    }>
}

export type HeatMapRoomUsageByHour = Record<string, { actual: number, scaled: number }>

export interface PeriodSummary {
    attendee_type: string
    period: SummaryInfo
    previous_period: SummaryInfo
}

interface SummaryInfo {
    bookings_count: number,
    resource_count: number,
    hours_booked: number
}

export interface BookingCount {
    attendee_type: string
    count: number,
}

export interface MinMedianMax {
    name: string,
    min: number,
    median: number,
    max: number
}

export interface BookingStats {
    organiser: string
    booked: number
    under_booked: number
    over_booked: number
    started_early: number
    started_late: number
    cancellation_within_15: number
    cancellation_after: number
    auto_cancellation: number
}

export interface OrganiserBookingStats {
    booked_start: Date
    booked_end: Date
    actual_start: Date
    actual_end: Date
    duration: number
    actual_duration: number
    attendees: number
    no_show_leave: number
    next_meeting_start_leave: number
    resources: string
    recurring: number
    cancelled: number
    is_all_day_event: number
}

export interface Tag {
    tag_id: IdType
    tag_name: string
    type: string
}

export interface RoomTag {
    room_id: IdType
    tag_id: IdType
}

export interface SecurityReport {
    timeStampUtc: Date
    userAccount: string
    securityAction: string
    additionalInfo: string
}

export interface SecurityUserReport {
    notneeded: string
}

export interface EscalationReport {
    room_id: IdType
    room_name: string
    display_name: string

    total_escalations: number
    unresolved: number
    total_resolved_escalations: number
    average_resolution_seconds: number

    total_loss_heartbeat: number
    total_resolved_loss_heartbeat: number
    average_loss_heartbeat_resolution_seconds: number

    total_loss_critical_device: number
    total_resolved_loss_critical_device: number
    average_loss_critical_device_resolution_seconds: number
}


export type DayOfWeek = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday'
export const AllDaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export interface WorkingHours {
    days_of_week: DayOfWeek[]
    start_time: string
    end_time: string
    time_zone: string
}


export interface TimeZoneData {
    TimeZoneList: Array<TimeZoneInfo>
}

export interface TimeZoneInfo {
    Name: string
    Value: string
}

export interface EscalationRule {
    escalation_rule_id: IdType
    description: string
    title_format_escalate: string
    title_format_escalation_cleared?: string
    enabled: boolean
    disabled_days_of_week: DayOfWeek[]
    disabled_start_time?: string
    disabled_end_time?: string
    rules_json: any
    organisation_id: IdType
}

export type CreateEscalationRule = Omit<EscalationRule, "escalation_rule_id" | "organisation_id">

export interface Exclusions {
    base: Record<string, string>
    additions: string[]
    subtractions: number[]
}

export interface UcSite {
    site_id: string
    organisation_id: IdType
    customer_id: string
    customer_name: string
    server_name: string
    display_name: string
    is_enabled: boolean
    last_activity_utc: Date
    last_event_count: number
    server_group: string

    // The processed exclusions (built from base plus additions, less subtractions)
    exclusion_list: string[]
    exclusions: Exclusions
    attributes: Record<string, UcSiteAttribute>
}

export type CreateUcSite = Omit<UcSite, "site_id" | "organisation_id" | "exclusion_list" | "attributes" | "last_activity_utc" | "last_event_count"> & {
    attributes: Record<string, string | number | boolean | Date>
}

export interface UcSiteAttribute {
    value: string | number | boolean | Date;
    data_type: AttributeDataType;
    type: UcAttributeType;
}

export type UcAttributeType = "Client";

// =====================================================
// Roomote devices
// =====================================================
export interface RoomoteDevice {
    roomote_device_id: IdType
    organisation_id: IdType
    device_name: string
    room_id: IdType
    device_pin: string
}

export type CreateRoomoteDevice = Omit<RoomoteDevice, "roomote_device_id"> & {
    room_id?: IdType
}

// =====================================================
// UC Support Settings
// =====================================================

export interface UcSupportApplication {
    name: string
}

export interface UcSupportServer {
    site_id: string
    name: string
    applications: UcSupportApplication[]
}

export interface UcServerGroup {
    name: string
    applications: UcSupportApplication[]
}

export interface UcSupportAssignment {
    mailbox: string
    servers?: UcSupportServer[]
    groups: UcServerGroup[]
}

export interface UcSupportSettings {
    applications?: string[]
    mailboxes?: string[]
    assignments?: UcSupportAssignment[]
}

export interface OfficeLocation {
    office_location_id: IdType
    organisation_id: IdType

    display_name: string
    geofence_id: string
    latitude: number
    longitude: number
    radius_in_meters: number
    photo_url?: string
    book_it_location_id?: IdType
}

export type CreateOfficeLocation = Omit<OfficeLocation, "office_location_id" | "organisation_id">


export interface CalendarDelegation {
    email_address: string
    all_rooms: boolean
    all_people: boolean
    room_list: string[]
    people_list: string[]
    organisation_id: IdType
}

export type CreateCalendarDelegation = Omit<CalendarDelegation, "organisation_id">

