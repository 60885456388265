import { Loader } from '@fluentui/react-northstar';
import { Operation } from 'fast-json-patch';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BookItSettings, bookItSettingsDefaults, BookItSettingsOverride, IdType } from '../../../model';
import { fetchBookableResource, fetchOrganisation, patchBookableResource } from '../../../store/resourceActions';
import { getActiveOrganisation, getBookableResourceById, getOrganisation } from '../../../store/selectors';
import { useSelector } from '../../../store/utils';
import css from './BookableResourceSettings.module.scss';
import BookItSettingsPage from './BookItSettingsPage';

interface BookableResourceSettingsPageProps {
    bookable_resource_id: IdType
    onSubmit: () => void
}

function BookableResourceSettingsPage({ bookable_resource_id }: BookableResourceSettingsPageProps) {
    const dispatch = useDispatch()

    const activeOrganisation = useSelector(getActiveOrganisation)
    const organisation = useSelector(s => getOrganisation(s, activeOrganisation.organisation_id))
    useEffect(() => {
        if (organisation === undefined) {
            dispatch(fetchOrganisation.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation.organisation_id, dispatch, organisation])

    const bookableResource = useSelector(s => getBookableResourceById(s, bookable_resource_id))

    useEffect(() => {
        if (bookable_resource_id && activeOrganisation && bookableResource === undefined) {
            dispatch(fetchBookableResource.request({ organisation_id: activeOrganisation.organisation_id, bookable_resource_id }))
        }
    }, [dispatch, bookable_resource_id, activeOrganisation, bookableResource])



    const handleSubmit = useCallback((settings: Partial<BookItSettings> & {
        overrides: BookItSettingsOverride}) => {
        const patch: Operation[] = [{
            op: 'replace',
            value: settings,
            path: '/settings'
        }]

        dispatch(patchBookableResource.request({ id: { organisation_id: activeOrganisation.organisation_id, bookable_resource_id }, operations: patch }))
    }, [activeOrganisation.organisation_id, bookable_resource_id, dispatch])


    return <div className={css.bookableResourceSettings}>
        {(organisation === undefined || bookableResource === undefined) && <Loader />}
        {organisation && bookableResource && <BookItSettingsPage 
                    onSave={handleSubmit} 
                    source={bookableResource.settings} 
                    base={organisation.book_it_settings ?? bookItSettingsDefaults}
                    />}
    </div>
}

export default BookableResourceSettingsPage