import React from 'react';
import config from './GraphApiConfig';
import { postCreateSession, getCharts, getChartString, postApplyValuesFilter, postClearFilter } from './GraphApiService';
import { Button } from 'reactstrap';

export default class GraphApiOneDrive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
      chartString: null,
      chartName: '',
      chartArray: [],
      sessionId: null,

      chartFrequency: 1,
      actualChartFrequency: 1,

      listFrequency: 5,
      actualListFrequency: 5,

      textFilter: '',
    };

    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);

    this.handleChangeChartFrequency = this.handleChangeChartFrequency.bind(this);
    this.handleChangeListFrequency = this.handleChangeListFrequency.bind(this);
    this.handleChangeTextFilter = this.handleChangeTextFilter.bind(this);
    this.handleButtonTextFilter = this.handleButtonTextFilter.bind(this);
    this.handleButtonClearFilter = this.handleButtonClearFilter.bind(this);

    this.getSessionId = this.getSessionId.bind(this);
    this.getChartList = this.getChartList.bind(this);
    this.getCurrentChart = this.getCurrentChart.bind(this);
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.onLoad();
    }

    this.timerID = setInterval(
      () => this.tick(),
      1000
    );

    //chartPolling
    this.timerIDChart = setInterval(
      () => this.tickChart(),
      this.state.actualChartFrequency * 1000
    );

    //chartListPolling
    this.timerIDList = setInterval(
      () => this.tickList(),
      this.state.actualListFrequency * 1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    clearInterval(this.timerIDChart);
    clearInterval(this.timerIDList);
  }

  handleChangeDropdown(event) {
    clearInterval(this.timerIDChart);

    this.setState({chartName: event.target.value})

    this.timerIDChart = setInterval(
      () => this.tickChart(),
      this.state.actualChartFrequency * 1000
    );

    this.getCurrentChart();
  }

  async onLoad() {
    if (this.props.isAuthenticated) {
      this.getSessionId();
      this.getChartList();
    }
  }

  handleChangeChartFrequency(event) {
    if (event.target.value >= 1) {
      clearInterval(this.timerIDChart);

      this.setState({chartFrequency: event.target.value});
      this.setState({actualChartFrequency: event.target.value});

      this.timerIDChart = setInterval(
        () => this.tickChart(),
        event.target.value * 1000
      );

      this.getCurrentChart();
    }
    else {
      //only set the displayed value, leave actual alone
      this.setState({chartFrequency: event.target.value});
    }
  }

  handleChangeListFrequency(event) {
    if (event.target.value >= 1) {
      clearInterval(this.timerIDList);

      this.setState({listFrequency: event.target.value});
      this.setState({actualListFrequency: event.target.value});

      this.timerIDList = setInterval(
        () => this.tickList(),
        event.target.value * 1000
      );
    }
    else {
      //only set the displayed value, leave actual alone
      this.setState({listFrequency: event.target.value});
    }
  }

  handleChangeTextFilter(event) {
    this.setState({textFilter: event.target.value});
  }

  async handleButtonTextFilter(event) {
    event.persist();
    if (this.props.isAuthenticated && this.state.sessionId) {
      try {
        var accessToken = await window.msal.acquireTokenSilent({
          scopes: config.scopes
        });

        if (this.state.textFilter !== " " && this.state.textFilter.length > 0) {
          await postApplyValuesFilter(accessToken, this.state.sessionId, this.state.textFilter);
        }
      }
      catch(err) {
        //this.props.showError('ERROR', JSON.stringify(err));
        console.log(err);
      }
    }
  }

  async handleButtonClearFilter(event) {
    event.persist();
    this.setState({textFilter: ''});
    if (this.props.isAuthenticated && this.state.sessionId) {
      try {
        var accessToken = await window.msal.acquireTokenSilent({
          scopes: config.scopes
        });

        await postClearFilter(accessToken, this.state.sessionId);
      }
      catch(err) {
        //this.props.showError('ERROR', JSON.stringify(err));
        console.log(err);
      }
    }
  }

  async getSessionId() {
    if (this.props.isAuthenticated) {
      try {
        var accessToken = await window.msal.acquireTokenSilent({
          scopes: config.scopes
        });

        const sessionId = await postCreateSession(accessToken);

        if (sessionId) {
          this.setState({sessionId: sessionId});
        }
      }
      catch(err) {
        //this.props.showError('ERROR', JSON.stringify(err));
        console.log(err);
      }
    }
  }

  async getChartList() {
    if (this.props.isAuthenticated && this.state.sessionId) {
      try {
        var accessToken = await window.msal.acquireTokenSilent({
          scopes: config.scopes
        });
  
        const chartList = await getCharts(accessToken, this.state.sessionId);

        if (chartList.value) {
          this.setState({chartArray: chartList.value});
        }
      }
      catch(err) {
        //this.props.showError('ERROR', JSON.stringify(err));
        console.log(err);
      }
    }
  }

  async tick() {
    this.setState({
      date: new Date()
    });

    if (!this.state.sessionId) {
      this.getSessionId();
    }
    if (this.state.chartArray.length === 0)
    {
      this.getChartList();
    }
  }

  async tickChart() {
    //console.log("tickChart: ", this.state.date.toLocaleTimeString());

    if (!this.state.sessionId) {
      this.getSessionId();
    }

    this.getCurrentChart();
  }

  async tickList() {
    //console.log("tickList: ", this.state.date.toLocaleTimeString());

    if (!this.state.sessionId) {
      this.getSessionId();
    }

    this.getChartList();
  }

  async getCurrentChart() {
    if (this.props.isAuthenticated && this.state.sessionId) {
      try {
        var accessToken = await window.msal.acquireTokenSilent({
          scopes: config.scopes
        });

        if (this.state.chartName !== "")
        {
          const chartString = await getChartString(accessToken, this.state.sessionId, this.state.chartName);
          this.setState({chartString: chartString.value});
        }
        else
        {
          this.setState({chartString: null});
        }
      }
      catch(err) {
        //this.props.showError('ERROR', JSON.stringify(err));
        console.log(err);
      }
    }
  }

  renderDropDown() {
    if (this.state.chartArray.length > 0) {
      return (
        <div>
          <select
            className="graph_api_dropdown"
            value={this.state.chartName}
            onChange={this.handleChangeDropdown}
          >
            <option key="-1" value="">Select graph</option>
            {this.state.chartArray.map((chartArrayItem, index) => (
              <option
                key={index}
                value={chartArrayItem.name}
              >
                {chartArrayItem.name}
              </option>
            ))}
          </select>
        </div>
      );
    }
    else{
      return "Fetching Charts from demo.xlsx";
    }
    
  }

  renderChart() {
    if (this.state.chartString) {
      return (
        <img className="graph_api" src={`data:image/png;base64,${this.state.chartString}`} alt=""></img>
      );
    }
  }

  render(props) {
    
    if (!this.props.isAuthenticated) {
      return <Button color="primary" onClick={this.props.authButtonMethod}>Click here to sign in</Button>;
    }

    //sessionId: {this.state.sessionId}<br></br>
    return (
      <div>
        <div>
          <input className="inputtext" maxLength="4" id="chartFrequency" type="text" value={this.state.chartFrequency} onChange={this.handleChangeChartFrequency}></input> Chart Frequency<br></br>
          <input className="inputtext" maxLength="4" id="listFrequency" type="text" value={this.state.listFrequency} onChange={this.handleChangeListFrequency}></input> List Frequency<br></br>
          <br></br>
          Filter: <input className="inputtext_filter" maxLength="100" id="textFilter" type="text" value={this.state.textFilter} onChange={this.handleChangeTextFilter}></input><button onClick={this.handleButtonTextFilter}>Filter</button><br></br>
          <br></br>
          <button onClick={this.handleButtonClearFilter}>Clear Filter</button>
          <br></br>
        </div>
        <br></br>
        {this.renderDropDown()}
        <br></br>
        {this.renderChart()}
        <br></br>
        <br></br>
        <iframe title="Demo.xlsx" width="1900" height="1650" frameborder="0" scrolling="no" src="https://ictfx-my.sharepoint.com/personal/david_adams_ipfx_com/_layouts/15/Doc.aspx?sourcedoc={52cb7564-1a8d-4530-b896-8960f7d3b711}&action=embedview&AllowTyping=True&ActiveCell='Sheet2'!L1&wdDownloadButton=True&wdInConfigurator=True&wdAllowInteractivity=True&wdHideSheetTabs=True"></iframe>
      </div>
    );
  }
}
