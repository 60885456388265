import { ComponentSlotStyle, Flex, FlexProps, Header, ProviderConsumer, ThemePrepared } from '@fluentui/react-northstar';
import React from 'react';
import './Card.css';

interface Props extends FlexProps {
    headerContent?: React.ReactNode
    headerText?: string
    children?: React.ReactNode
    styles?: ComponentSlotStyle<any, any>;
}

const Card: React.FC<Props> = ({ headerContent, headerText, children, styles, ...rest }) => {
    const renderHeader = (theme: ThemePrepared) => {
        if (headerContent || headerText) {
            return <Flex className='card-header' vAlign='center'
             styles={{ backgroundColor: theme.siteVariables.colorScheme.default.background, borderBottom: `1px solid ${theme.siteVariables.colorScheme.default.border3}` }}>
                {headerText ? <Header as="h5" content={headerText} /> : { headerContent }}
            </Flex>
        }
        return null
    }


    return <ProviderConsumer render={theme => {
        const baseStyles = { border: `1px solid ${theme.siteVariables.colorScheme.default.border3}` }
        const cardStyles = styles 
            ? {...baseStyles, ...styles}
            : baseStyles

        return (
            <Flex column styles={cardStyles}>
                {renderHeader(theme)}
                <Flex className='card-content' styles={{ backgroundColor: theme.siteVariables.colorScheme.default.background }} {...rest} >
                    {children}
                </Flex>
            </Flex>
        )
    }} />
}
export default Card
