import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { Role } from "../../model";
import { Services } from "../../services";
import { errorHandler, fetchRoles } from "../actions";
import { RootAction, RootState } from "../types";

export const fetchRolesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, services) => {
    return action$.pipe(
        filter(isActionOf(fetchRoles.request)),
        mergeMap(action => from(services.apiClient!.get<Array<Role>>("/user_accounts/roles")).pipe(
                mergeMap(res => of(fetchRoles.success(res))),
                catchError(err => of(fetchRoles.failure(err), errorHandler(err)))
            )));
}
