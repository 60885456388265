import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { Services } from "../../services";
import { closeEventEscalation, errorHandler, fetchEventEscalations, fetchEventEscalationsForRoom } from "../actions";
import { RootAction, RootState } from "../types";

export const fetchEventEscalationsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchEventEscalations.request)),
        mergeMap(action => {
            console.log("fetchEventEscalations.request action epic")
            return serverApi.fetchEventEscalations(action.payload.selection, action.payload.organisationId).pipe(
                switchMap(hal => of(fetchEventEscalations.success(hal))),
                catchError(err => of(fetchEventEscalations.failure(err)))
            )}));
}

export const fetchEventEscalationsForRoomEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchEventEscalationsForRoom.request)),
        mergeMap(action =>
            serverApi.fetchEventEscalationsForRoom(action.payload.selection, action.payload.organisationId, action.payload.roomName).pipe(
                switchMap(hal => of(fetchEventEscalationsForRoom.success(hal))),
                catchError(err => of(fetchEventEscalationsForRoom.failure(err), errorHandler(err)))
            )));
}

export const closeEventEscalationsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(closeEventEscalation.request)),
        mergeMap(action =>
            serverApi.closeEventEscalation(action.payload.eventEscalationId, action.payload.organisationId, action.payload.reason).pipe(
                switchMap(hal => of(
                    closeEventEscalation.success(hal)
                )),
                catchError(err => of(closeEventEscalation.failure({eventEscalationId: action.payload.eventEscalationId, error: err}), errorHandler(err)))
            )));
}


