import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { Services } from "../../services";
import { errorHandler, fetchHealth } from "../actions";
import { RootAction, RootState } from "../types";

export const fetchHealthEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchHealth.request)),
        mergeMap(action =>
            serverApi.fetchHealth().pipe(
                map(report => fetchHealth.success(report)),
                catchError(err => of(fetchHealth.failure(err), errorHandler(err)))
            )));
}