import { combineEpics } from "redux-observable";
import {
        AzureAdOrganisationTenant, BookableResource, BookItAttribute, BookItDevice, BookItLocation,
        CalendarDelegation, ConferenceClient, CreateBookableResource, CreateBookItAttribute, CreateBookItDevice, CreateBookitLocation,
        CreateCalendarDelegation, CreateConferenceClient, CreateEscalationRule, CreateNfcTag, CreateOfficeLocation, CreateOnlineMeetingProvider,
        CreateOrganisation, CreateOrganisationTenant, CreateProvider, CreateResource, CreateRoomoteDevice, CreateSharingCode, CreateSharingCodeUsage, CreateSharingGroup, CreateUcSite, CreateUserAccount,
        defaultOverrides, EscalationRule, IdType, NfcTag, OfficeLocation, OnlineMeetingProvider, Organisation, OrganisationSummary, Provider, Resource, RoomoteDevice, SharingCode, SharingCodeUsage, SharingGroup,
        UcSite, UserAccount
} from "../model";
import { ApiClient } from "../services/api";
import { ApiFunctions, createApiResourceActions } from "./createApiResourceActions";


export function generateId(...args: any[]) {
        return args.join("_")
}

export const { fetchAllAction: fetchLocations,
        fetchAction: fetchLocation,
        createAction: createLocation,
        deleteAction: deleteLocation,
        patchAction: patchLocation,
        reducer: bookItLocationsReducer,
        epics: bookItLocationsEpics } = createApiResourceActions<BookItLocation, CreateBookitLocation>()
                ('BOOKIT_LOCATION'
                        , (d: { organisation_id: IdType, location_id: IdType }) => `/organisations/${d.organisation_id}/locations/${d.location_id}`
                        , (d: { organisation_id: IdType }) => `/organisations/${d.organisation_id}/locations`
                        , (location) => generateId(location.organisation_id, location.location_id))

export const { fetchAllAction: fetchBookItDevices,
        fetchAction: fetchBookItDevice,
        createAction: createBookItDevice,
        deleteAction: deleteBookItDevice,
        patchAction: patchBookItDevice,
        reducer: bookItDeviceReducer,
        epics: bookItDeviceEpics } = createApiResourceActions<BookItDevice, CreateBookItDevice>()
                ('BOOKIT_DEVICE'
                        , (d: { organisation_id: IdType, device_id: IdType }) => `/organisations/${d.organisation_id}/devices/${d.device_id.toString()}`
                        , (d: { organisation_id: IdType }) => `/organisations/${d.organisation_id.toString()}/devices`
                        , (dev) => generateId(dev.organisation_id, dev.device_id))

export const { fetchAllAction: fetchBookableResources,
        fetchAction: fetchBookableResource,
        createAction: createBookableResource,
        deleteAction: deleteBookableResource,
        patchAction: patchBookableResource,
        reducer: bookItBookableResourcesReducer,
        epics: bookItBookableResourcesEpics } = createApiResourceActions<BookableResource, CreateBookableResource>()
                ('BOOKABLE_RESOURCE'
                        , (b: { organisation_id: IdType, bookable_resource_id: IdType }) => `/organisations/${b.organisation_id.toString()}/bookable_resources/${b.bookable_resource_id.toString()}`
                        , (b: { organisation_id: IdType }) => `/organisations/${b.organisation_id}/bookable_resources`
                        , (br) => generateId(br.organisation_id, br.bookable_resource_id)
                        , { 
                                apiGet: bookableResourceApiGet,
                                apiGetAll: bookableResourceApiGetAll
                        } as ApiFunctions<BookableResource, CreateBookableResource>
                )

async function bookableResourceApiGet(apiClient: ApiClient, urlPath: string) {
        let result = await apiClient.get<BookableResource>(urlPath)
        if (result.settings === undefined) {
                result.settings = {
                        overrides: defaultOverrides
                }
        }
        console.log("bookableResourceApiGet", result)
        return result
}

async function bookableResourceApiGetAll(apiClient: ApiClient, urlPath: string) {
        const res = await apiClient.get<Array<BookableResource>>(urlPath)

        return res.map(br => {
                if (br.settings === undefined) {
                        br.settings = {
                                overrides: defaultOverrides
                        }
                }
                return br
        })
}

export const { fetchAllAction: fetchProviders,
        fetchAction: fetchProvider,
        createAction: createProvider,
        deleteAction: deleteProvider,
        patchAction: patchProvider,
        reducer: bookItProviderReducer,
        epics: bookItProviderEpics } = createApiResourceActions<Provider, CreateProvider>()
                ('PROVIDER'
                        , (o: { organisation_id: IdType, provider_id: IdType }) => `/organisations/${o.organisation_id}/providers/${o.provider_id}`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id}/providers`
                        , (p) => generateId(p.organisation_id, p.provider_id))


export const { fetchAllAction: fetchOnlineMeetingProviders,
        fetchAction: fetchOnlineMeetingProvider,
        createAction: createOnlineMeetingProvider,
        deleteAction: deleteOnlineMeetingProvider,
        patchAction: patchOnlineMeetingProvider,
        reducer: bookItOnlineMeetingProviderReducer,
        epics: bookItOnlineMeetingProviderEpics } = createApiResourceActions<OnlineMeetingProvider, CreateOnlineMeetingProvider>()
                ('ONLINE_MEETING_PROVIDER'
                        , (o: { organisation_id: IdType, online_meeting_provider_id: IdType }) => `/organisations/${o.organisation_id}/online_meeting_providers/${o.online_meeting_provider_id}`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id}/online_meeting_providers`
                        , (p) => generateId(p.organisation_id, p.online_meeting_provider_id))

export const { fetchAllAction: fetchSharingGroups,
        fetchAction: fetchSharingGroup,
        createAction: createSharingGroup,
        deleteAction: deleteSharingGroup,
        patchAction: patchSharingGroup,
        reducer: sharingGroupReducer,
        epics: sharingGroupEpics } = createApiResourceActions<SharingGroup, CreateSharingGroup>()
                ('SHARING_GROUP'
                        , (o: { organisation_id: IdType, sharing_group_id: IdType }) => `/organisations/${o.organisation_id}/sharing_groups/${o.sharing_group_id}`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id}/sharing_groups`
                        , (p) => generateId(p.organisation_id, p.sharing_group_id))

export const { fetchAllAction: fetchSharingCodes,
        fetchAction: fetchSharingCode,
        createAction: createSharingCode,
        deleteAction: deleteSharingCode,
        reducer: sharingCodeReducer,
        epics: sharingCodeEpics } = createApiResourceActions<SharingCode, CreateSharingCode>()
                ('SHARING_CODE'
                        , (o: { organisation_id: IdType, sharing_code_id: IdType }) => `/organisations/${o.organisation_id}/sharing_codes/${o.sharing_code_id}`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id}/sharing_codes`
                        , (p) => generateId(p.organisation_id, p.sharing_code_id))                  

export const { fetchAllAction: fetchSharingCodeUsages,
        fetchAction: fetchSharingCodeUsage,
        createAction: createSharingCodeUsage,
        deleteAction: deleteSharingCodeUsage,
        reducer: sharingCodeUsageReducer,
        epics: sharingCodeUsageEpics } = createApiResourceActions<SharingCodeUsage, CreateSharingCodeUsage>()
                ('SHARING_CODE_USAGE'
                        , (o: { shared_to: OrganisationSummary, sharing_code_id: IdType }) => `/organisations/${o.shared_to.organisation_id}/sharing_codes/${o.sharing_code_id}/usages`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id}/sharing_codes/usages`
                        , (p) => generateId(p.owner_organisation_id, p.sharing_code_id))         

export const { fetchAllAction: fetchOrganisationTenants,
        fetchAction: fetchOrganisationTenant,
        createAction: createOrganisationTenant,
        deleteAction: deleteOrganisationTenant,
        patchAction: patchOrganisationTenant,
        reducer: organisationTenantReducer,
        epics: organisationTenantEpics } = createApiResourceActions<AzureAdOrganisationTenant, CreateOrganisationTenant>()
                ('ORGANISATION_TENANTS'
                        , (o: { organisation_id: IdType, organisation_tenant_id: IdType }) => `/organisations/${o.organisation_id}/tenants/${o.organisation_tenant_id}`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id}/tenants`
                        , (p) => generateId(p.organisation_id, p.organisation_tenant_id))

export const { fetchAllAction: fetchNfcTags,
        fetchAction: fetchNfcTag,
        createAction: createNfcTag,
        deleteAction: deleteNfcTag,
        patchAction: patchNfcTag,
        reducer: nfcTagsReducer,
        epics: nfcTagEpics } = createApiResourceActions<NfcTag, CreateNfcTag>()
                ('NFC_TAGS'
                        , (o: { organisation_id: IdType, nfc_tag_id: IdType }) => `/organisations/${o.organisation_id.toString()}/nfctags/${o.nfc_tag_id.toString()}`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id.toString()}/nfctags`
                        , (p) => generateId(p.organisation_id, p.nfc_tag_id))

export const { fetchAllAction: fetchUserAccounts,
        fetchAction: fetchUserAccount,
        createAction: createUserAccount,
        deleteAction: deleteUserAccount,
        patchAction: patchUserAccount,
        reducer: userAccountReducer,
        epics: userAccountEpics } = createApiResourceActions<UserAccount, CreateUserAccount>()
                ('USER_ACCOUNT'
                        , (u: { user_account_id: IdType }) => `/user_accounts/${u.user_account_id.toString()}`
                        , (u: {}) => "/user_accounts"
                        , u => `${u.user_account_id.toString()}`)

export const { fetchAllAction: fetchResources,
        fetchAction: fetchResource,
        createAction: createResource,
        deleteAction: deleteResource,
        patchAction: patchResource,
        reducer: resourcesReducer,
        epics: resourcesEpics } = createApiResourceActions<Resource, CreateResource>()
                ('RESOURCES'
                        , (o: { organisation_id: IdType, resource_id: IdType }) => `/organisations/${o.organisation_id.toString()}/resources/${o.resource_id.toString()}`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id.toString()}/resources`
                        , (p) => generateId(p.organisation_id, p.resource_id))

export const { fetchAllAction: fetchConferenceClients,
        fetchAction: fetchConferenceClient,
        createAction: createConferenceClient,
        deleteAction: deleteConferenceClient,
        patchAction: patchConferenceClient,
        reducer: confenenceClientReducer,
        epics: confenenceClientEpics } = createApiResourceActions<ConferenceClient, CreateConferenceClient>()
                ('CONFERENCE_CLIENT'
                        , (u: { video_conference_client_id: IdType }) => `/conference_clients/${u.video_conference_client_id.toString()}`
                        , (u: {}) => "/conference_clients"
                        , cc => `${cc.video_conference_client_id.toString()}`)


export const { fetchAllAction: fetchOrganisations,
        fetchAction: fetchOrganisation,
        createAction: createOrganisation,
        deleteAction: deleteOrganisation,
        patchAction: patchOrganisation,
        reducer: organisationReducer,
        epics: organisationEpics } = createApiResourceActions<Organisation, CreateOrganisation>()
                ('ORGANISATION'
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id.toString()}?expand=owner`
                        , (o: {}) => "/organisations?expand=owner"
                        , o => `${o.organisation_id.toString()}`)

export const { fetchAllAction: fetchBookItAttributes,
        fetchAction: fetchBookItAttribute,
        createAction: createBookItAttribute,
        deleteAction: deleteBookItAttribute,
        patchAction: patchBookItAttribute,
        reducer: bookItAttributeReducer,
        epics: bookItAttributeEpics } = createApiResourceActions<BookItAttribute, CreateBookItAttribute>()
                ('BOOKIT_ATTRIBUTE'
                        , (o: { attribute_id: IdType }) => `/attributes/${o.attribute_id.toString()}`
                        , (o: {}) => "/attributes"
                        , o => `${o.attribute_id.toString()}`
                        , { apiCreate: attributeApiCreate } as ApiFunctions<BookItAttribute, CreateBookItAttribute>
                )
function attributeApiCreate<TResource, TCreateResource>(apiClient: ApiClient, urlPath: string, body: CreateBookItAttribute) {
        console.log("attributeApiCreate", urlPath, body)
        const data = new FormData();
        data.append("file", body.file);
        return apiClient.postRaw<BookItAttribute>(`${urlPath}?name=${body.name}`, data)
}

export const { fetchAllAction: fetchEscalationRules,
        fetchAction: fetchEscalationRule,
        createAction: createEscalationRule,
        deleteAction: deleteEscalationRule,
        patchAction: patchEscalationRule,
        reducer: escalationRulesReducer,
        epics: escalationRuleEpics } = createApiResourceActions<EscalationRule, CreateEscalationRule>()
                ('ESCALATION_RULES'
                        , (o: { organisation_id: IdType, escalation_rule_id: IdType }) => `/organisations/${o.organisation_id.toString()}/escalation_rules/${o.escalation_rule_id.toString()}`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id.toString()}/escalation_rules`
                        , (p) => generateId(p.organisation_id.toString(), p.escalation_rule_id.toString()))

                        
export const { fetchAllAction: fetchUcSites,
        fetchAction: fetchUcSite,
        createAction: createUcSite,
        deleteAction: deleteUcSite,
        patchAction: patchUcSite,
        reducer: ucSitesReducer,
        epics: ucSitesEpics } = createApiResourceActions<UcSite, CreateUcSite>()
                ('UC_SITE'
                        , (o: { organisation_id: IdType, site_id: string }) => `/organisations/${o.organisation_id.toString()}/ucsites/${o.site_id.toString()}`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id.toString()}/ucsites`
                        , (p) => generateId(p.organisation_id.toString(), p.site_id.toString()))

                                                
export const { fetchAllAction: fetchOfficeLocations,
        fetchAction: fetchOfficeLocation,
        createAction: createOfficeLocation,
        deleteAction: deleteOfficeLocation,
        patchAction: patchOfficeLocation,
        reducer: officeLocationsReducer,
        epics: officeLocationsEpics } = createApiResourceActions<OfficeLocation, CreateOfficeLocation>()
                ('OFFICE_LOCATION'
                        , (o: { organisation_id: IdType, office_location_id: IdType }) => `/organisations/${o.organisation_id.toString()}/office_locations/${o.office_location_id.toString()}`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id.toString()}/office_locations`
                        , (p) => generateId(p.organisation_id.toString(), p.office_location_id.toString()))

export const { fetchAllAction: fetchCalendarDelegations,
        fetchAction: fetchCalendarDelgation,
        createAction: createCalendarDelegation,
        deleteAction: deleteCalendarDelegation,
        patchAction: patchCalendarDelegation,
        reducer: calendarDelegationsReducer,
        epics: calendarDelegationsEpics } = createApiResourceActions<CalendarDelegation, CreateCalendarDelegation>()
                ('CALENDAR_DELEGATION'
                        , (o: { organisation_id: IdType, email_address: string }) => `/organisations/${o.organisation_id.toString()}/calendar_delegations/${o.email_address}`
                        , (o: { organisation_id: IdType }) => `/organisations/${o.organisation_id.toString()}/calendar_delegations`
                        , (p) => generateId(p.organisation_id.toString(), p.email_address.toString()))
                       
export const { fetchAllAction: fetchRoomoteDevices,
                                fetchAction: fetchRoomoteDevice,
                                createAction: createRoomoteDevice,
                                deleteAction: deleteRoomoteDevice,
                                patchAction: patchRoomoteDevice,
                                reducer: roomoteDeviceReducer,
                                epics: roomoteDeviceEpics } = createApiResourceActions<RoomoteDevice, CreateRoomoteDevice>()
                                        ('ROOMOTE_DEVICE'
                                                , (d: { organisation_id: IdType, roomote_device_id: IdType }) => `/organisations/${d.organisation_id.toString()}/roomote/devices/${d.roomote_device_id.toString()}`
                                                , (d: { organisation_id: IdType }) => `/organisations/${d.organisation_id.toString()}/roomote/devices`
                                                , (dev) => generateId(dev.organisation_id.toString(), dev.roomote_device_id.toString()))
export const resourceEpics = combineEpics(
        bookItDeviceEpics
        , bookItLocationsEpics
        , bookItBookableResourcesEpics
        , bookItProviderEpics
        , bookItOnlineMeetingProviderEpics
        , sharingGroupEpics
        , sharingCodeEpics
        , sharingCodeUsageEpics
        , organisationTenantEpics
        , userAccountEpics
        , confenenceClientEpics
        , organisationEpics
        , bookItAttributeEpics
        , nfcTagEpics
        , escalationRuleEpics
        , ucSitesEpics
        , officeLocationsEpics
        , calendarDelegationsEpics
        , roomoteDeviceEpics
        , resourcesEpics
)