import { Flex, Form, Grid, Segment } from '@fluentui/react-northstar';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { BookableResource, DefaultScreenType, IdType } from '../../model';
import FormButtons from '../controls/FormButtons';
import { FormikNorthstarDropdown } from '../controls/FormikNorthstarDropdown';
import { FormikNorthstarInput } from '../controls/FormikNorthstarInput';
import { FormikNorthstarRadioGroup } from '../controls/FormikNorthstarRadioGroup';


export interface CreateDevice {
    name: string
    default_screen: DefaultScreenType
    pin?: string
    rooms?: BookableResource[]
    primary_room_id: IdType
}

interface Props {
    initial: CreateDevice
    onSubmit: (location: CreateDevice) => void
    onCancel: () => void
    existingDevices?: string[]
    allRooms: BookableResource[]
}

const AddDevice: React.FC<Props> = (props) => {
    const { initial, onSubmit, onCancel, allRooms } = props

    const formSchema = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .required('Device name cannot be empty')
    }), [])

    const handleSubmit = (values: CreateDevice) => {
        onSubmit(values)
    }

    const handleCancel = () => {
        onCancel()
    }

    console.log('Editing device', initial)

    // sort so primary room is first in the list
    const selectedRoomItems = useMemo(() => 
            initial.rooms && initial.rooms!.map(r => ({ header: r.name, ...r })).sort((a,b) => { 
                if (a.bookable_resource_id === initial.primary_room_id) return -1;
                if (b.bookable_resource_id === initial.primary_room_id) return 1;
                return a.bookable_resource_id.toString().localeCompare(b.bookable_resource_id.toString())
            }), [initial])
    const roomItems = useMemo(() => 
        allRooms && allRooms.map(r => ({ header: r.name, ...r }))
    , [allRooms])
    const defaultScreens = useMemo(() => ['Timeline', 'RoomList', 'DisplayBoard'].map(screen => ({
        key: screen,
        value: screen,
        name: screen,
        label: screen,
        'aria-label': screen
    })), [])

    console.log('selectedRoomItems', selectedRoomItems)

    return <Flex column gap="gap.medium" fill>
        <Formik
            initialValues={{...initial, rooms: selectedRoomItems}}
            validationSchema={formSchema}
            onSubmit={handleSubmit}>
            {formik => <Form styles={{ width: '850px' }} onSubmit={e => formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)}>
                <Flex gap='gap.medium' fill column>
                    <Grid columns={2}>
                        <Segment>
                            <FormikNorthstarInput fluid label="Device Name" name="name" id="name"/>
                            <FormikNorthstarInput fluid label="Pin" name="pin" id="pin"/>
                            <FormikNorthstarRadioGroup label="Default Screen" name="default_screen" items={defaultScreens}/>
                        </Segment>
                        <Segment>
                            <FormikNorthstarDropdown multiple fluid label="Choose Meeting Rooms" items={roomItems} name="rooms" placeholder="Select rooms. First room will be primary room"/>
                        </Segment>
                    </Grid>
                    <FormButtons onCancel={handleCancel} />
                    {/* <FormikDebug /> */}
                </Flex>
            </Form>
            }
        </Formik>
    </Flex>
}


export default AddDevice