import { Flex } from "@fluentui/react-northstar";
import ReactTable from "react-table";
import { VirusScan } from "../../model";


interface Props {
    virusScans: VirusScan[]
}
function VirusScansTable({virusScans}: Props) {

    const cols = [
        { Header: "Feed Name", id: "feed_name", accessor: (row: VirusScan) => row.package_feed_name },
        { Header: "Package", id: "package-name", accessor: (row: VirusScan) => row.package_filename },
        { Header: "Version", id: "version", accessor: (row: VirusScan) => row.version },
        { Header: "Status", id: "status", accessor: (row: VirusScan) => row.status },
    ]


    return <Flex column>
        <h5>Virus Scans In Progress</h5>
        <ReactTable<VirusScan>
            showPagination={false}
            columns={cols}
            data={virusScans ?? []}
            defaultPageSize={virusScans === undefined || virusScans.length === 0 ? 0 : virusScans.length}
            noDataText="No data available"
            minRows={1}
        />
    </Flex>

}

export default VirusScansTable