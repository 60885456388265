import { AnyAction } from "redux";
import { ActionCreator, getType, PayloadActionCreator } from "typesafe-actions";
import { ApiError } from "../../utils/error";
import { ApiResult } from "./ApiResult";

interface ApiAction<T> {
    request: ActionCreator
    success: PayloadActionCreator<string, T>
    failure: PayloadActionCreator<string, ApiError>
}

export function createApiReducer<T>(apiAction: ApiAction<T>) {
    return createApiReducerWithTransform(apiAction, d => d)
}

export function createApiReducerWithTransform<T, U>(apiAction: ApiAction<T>, transform: (d: T) => U) {
    return (state: ApiResult<U> = ApiResult.default(), action: AnyAction): ApiResult<U> => {
        switch (action.type) {
            case getType(apiAction.request):
                return ApiResult.loading(state)
            case getType(apiAction.success):
                return ApiResult.success(transform(action.payload))
            case getType(apiAction.failure):
                return ApiResult.failure(action.payload)
            default:
                return state;
        }
    }
}