import { Checkbox, Dropdown, Flex, Loader, Button, DownloadIcon } from "@fluentui/react-northstar";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable, { CellInfo, Column } from "react-table";
import { fetchBookingStats } from "../../../store/actions";
import { getBookingStats, getBookingStatsIsLoading } from "../../../store/selectors";
import { minutesToDhm, ReportFilter } from "../MetricsPage";
import './BookingStatsReport.scss';
import OrganiserSummary from "./OrganiserSummary";
import services from "../../../services";
import { fileDownloadFromBlob } from "../../../utils/fileDownload";

const HighlightOver = {
    actual_vs_under_booked: true,
    actual_vs_on_time: false,
    actual_vs_over_booked: true,
    meeting_start_early: true,
    meeting_on_time: false,
    meeting_start_late: true,
    total_cancellations: true,
    less_than_threshold_cancellations: true,
    no_show: true,
    booked_count: true,
    booked_sum: true,
    booked_avg: true,
}

type HighlightType = keyof typeof HighlightOver
type Highlight = number | null

const percentages: Highlight[] = []
for (let i = 95; i > 0; i = i - 5) {
    percentages.push(i);
}
percentages.push(null);

function renderPercent(value: number) {
    return `${value}%`
}

function renderFixed(value: number, fractionDigits?: number) {
    return value.toFixed(fractionDigits)
}

function BookingStatsReport({ filter, orgChanging }: { filter: ReportFilter, orgChanging: boolean }) {
    function highlightedCellProps(header: React.ReactNode, type: HighlightType, list: Highlight[] = percentages, renderText: (value: number) => React.ReactNode = renderPercent): Column {
        const highlight = hightlights[type]

        return {
            accessor: type,
            headerClassName: 'headerWithHighlight',
            Cell(cellInfo: CellInfo) {
                if (cellInfo.value < 0.5)
                    return null

                const rounded = Math.round(cellInfo.value)
                if (!showHighlights) {
                    if (type === 'booked_sum' || type === 'booked_avg') {
                        return minutesToDhm(rounded)
                    } else {
                        return renderText(rounded)
                    }
                }

                const should = highlight !== null && rounded !== highlight && HighlightOver[type] === (rounded > highlight)

                var dhmOrNumber
                if (type === 'booked_sum' || type === 'booked_avg') {
                    dhmOrNumber = minutesToDhm(rounded)
                } else {
                    dhmOrNumber = renderText(rounded)
                }
                return <span className={classNames(should && 'highlight')}>
                    {dhmOrNumber}
                </span>
            },
            Header() {
                if (!showHighlights) {
                    return header
                }

                return <>
                    {header}
                    <div className='dropdownHeader' onClick={e => e.stopPropagation()}>
                        <Dropdown className={classNames(highlight !== null && 'noCaret')} 
                                  inline items={list.map(p => ({
                                        key: p,
                                        content: render(p),
                                        active: highlight === p,
                                        onClick() { setHighlights({ ...hightlights, [type]: p }) },
                                        }))} 
                                  value={render(highlight)}
                                 />
                    </div>
                </>
            },
        }

        function render(p: Highlight) {
            return p === null ? ' ' : `${HighlightOver[type] ? '>' : '<'}${renderText(p)}`
        }

      

    }

    //const [cancellationBeforeMeetingValue, setCancellationBeforeMeeting] = useState(120);
    const [cancellationBeforeMeetingValue] = useState(120);
    //const cancellationBeforeMeetingList = [120, 90, 60, 30, 15];
    const [hightlights, setHighlights] = useState<Record<HighlightType, Highlight>>(toObject(keysOf(HighlightOver), () => null))
    const [ showHighlights, setShowHighlights ] = useState(false)
    const [expandedList, setExpandedList] = useState({})
    const [isReportRunning, setIsReportRunning] = useState(false)

    const dispatch = useDispatch()
    
    useEffect(() => {
        if (!orgChanging) {
            dispatch(fetchBookingStats.request({
                organisationId: filter.organisationId,
                startRange: filter.startDate!,
                endRange: filter.endDate!,
                location: filter.location,
                resourceType: filter.resourceType,
                bookableResource: filter.bookableResource,
                cancellationBefore: cancellationBeforeMeetingValue,
                excludeWeekends: filter.excludeWeekends,
                workingHoursOnly: filter.isWorkingHoursOnly
            })) 
            //reset the expanded list for filter change
            setExpandedList({})
        }
    }, [dispatch, filter.endDate, filter.location, filter.resourceType, filter.bookableResource, filter.startDate, cancellationBeforeMeetingValue, filter.organisationId, filter.excludeWeekends, filter.isWorkingHoursOnly, orgChanging])

    const bookingStatsData = useSelector(getBookingStats);
    const isLoading = useSelector(getBookingStatsIsLoading);

     // Export the dataset as a CSV file
     async function doExport() {
            
        console.log('do Occupancy csv export');

        setIsReportRunning(true)

        try {

            const {data,fileName} = await services.bookItApi.fetchBookingStatsDataCsv(
                filter.organisationId,
                filter.startDate!,
                filter.endDate!,
                filter.location,
                filter.resourceType,
                filter.bookableResource,
                cancellationBeforeMeetingValue,
                filter.excludeWeekends,
                filter.isWorkingHoursOnly)

            fileDownloadFromBlob(data, fileName ?? 'bookit_organiser_booking_stats_report.csv')

        } catch (e) {
            console.error('doExport', e)
        }

        setIsReportRunning(false)
    }

    if (isLoading) { return <Loader label="Loading Booking Statistics" /> }

    if (bookingStatsData === undefined)
        return <div>No data to show. Please change your filters</div>
    if (bookingStatsData.length === 0)
        return <div>No data to show. Please change your filters</div>

    const columns: Column[] = [{
        Header: 'Organiser',
        headerStyle: {
            textAlign: 'left',
        },
        columns: [{
            minWidth: 200,
            accessor: "organiser",
            headerClassName: 'organiserHeader',
            Header: <>
                Email
                {showHighlights && <div className='highlightHeader'>Highlight</div>}
            </>,
        }]
    }, {
        Header: "Meetings",
        minWidth: 65,
        headerStyle: {
            borderLeft: '2px solid #fff'
        },
        columns: [{
            minWidth: 65,
            className: 'centerAlign',
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '2px solid #fff'
            },
            ...highlightedCellProps('Booked', 'booked_count', [ 1, 5, 10, 20, null ], renderFixed),
        }]
    }, {
        Header: "Total",
        columns: [{
            minWidth: 65,
            className: 'centerAlign',
            defaultSortDesc: true,
            ...highlightedCellProps('Minutes', 'booked_sum', [ 15, 30, 45, 60, 90, 120, 9000, null ], renderFixed),
        }]
    }, {
        Header: "Meeting",
        headerStyle: {
            borderRight: '1px solid #fff'
        },
        columns: [{
            minWidth: 65,
            className: 'centerAlign',
            defaultSortDesc: true,
            headerStyle: {
                borderRight: '1px solid #fff'
            },
            ...highlightedCellProps('Average', 'booked_avg', [  15, 30, 45, null ], renderFixed),
        }]
    }, {
        Header: "Average",
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff'
        },
        columns: [{
            Header: "Persons",
            minWidth: 65,
            className: 'centerAlign',
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff'
            },
            accessor: "avg_participants",
            Cell: (cell) => cell.value.toFixed(1),
        }]
    }, {
        Header: "Actual vs Booked",
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff'
        },
        columns: [{
            minWidth: 55,
            className: 'centerAlign',
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff'
            },
            ...highlightedCellProps("Underbook", "actual_vs_under_booked"),
        }, {
            minWidth: 55,
            className: 'centerAlign',
            ...highlightedCellProps("On time", "actual_vs_on_time"),
        }, {
            minWidth: 55,
            className: 'centerAlign',
            defaultSortDesc: true,
            headerStyle: {
                borderRight: '1px solid #fff'
            },
            ...highlightedCellProps("Overbook", "actual_vs_over_booked"),
        }]
    }, {
        Header: "Meeting Starts",
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff'
        },
        columns: [{
            minWidth: 55,
            className: 'centerAlign',
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff'
            },
            ...highlightedCellProps("Early", "meeting_start_early"),
        }, {
            minWidth: 55,
            className: 'centerAlign',
            ...highlightedCellProps("On Time", "meeting_on_time"),
        }, {
            minWidth: 55,
            className: 'centerAlign',
            defaultSortDesc: true,
            headerStyle: {
                borderRight: '1px solid #fff'
            },
            ...highlightedCellProps("Late", "meeting_start_late"),
        }]
    /*}, {
        Header: "Cancellations",
        headerStyle: {
            borderLeft: '1px solid #fff'
        },
        columns: [{
            minWidth: 55,
            className: 'centerAlign',
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff'
            },
            ...highlightedCellProps("Total", "total_cancellations"),
        }, {
            minWidth: 55,
            className: 'centerAlign',
            defaultSortDesc: true,
            ...highlightedCellProps("< " + cancellationBeforeMeetingValue.toString(), "less_than_threshold_cancellations"),
        }, {*/
    }, {
        Header: "Cancellations",
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff'
        },
        columns: [{
            minWidth: 55,
            className: 'centerAlign',
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff'
            },
            ...highlightedCellProps("No show", "no_show"),
        }], 
    },
    {
        Header: "All-day",
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff'
        },
        columns: [{
            Header: "Events",
            minWidth: 55,
            className: 'centerAlign',
            defaultSortDesc: true,
            headerStyle: {
                borderLeft: '1px solid #fff'
                
            },
            accessor: "all_day_count",
            Cell: (cell) => cell.value.toFixed(0),
        }],
    } 

]
    /*
                <div className="highlightCancellationLabel">
                    Cancellation Before Meeting &lt;
                </div>
                <div className="highlightDropdown">
                    <Dropdown items={cancellationBeforeMeetingList} placeholder={""}
                        value={cancellationBeforeMeetingValue}
                        onSelectedChange={(e, d) => d && setCancellationBeforeMeeting(d.value as number)}
                    />
                </div>
    */
    return (
        <div className="bookingStats">

            <Flex className="highlights">
                <Checkbox toggle label='Highlights' checked={showHighlights} onChange={(_, v) => v && setShowHighlights(Boolean(v.checked))} />
            </Flex>

            <div className="bookingStatsReport">

                <div className='allInARow'>
                    <div className="bookingStatsTitle">
                        <h2>User Booking Statistics</h2>
                    </div>
                    {isReportRunning ? <Loader className='spaceLeft' /> : 
                        <Button primary content='Export as CSV' 
                            onClick={() => { doExport() }} 
                            icon={<DownloadIcon />} 
                            iconPosition='before' 
                            className={['downloadButton', 'spaceLeft', 'spaceBottom' ].join(' ')} />
                    }
                </div>

                <ReactTable
                    className="-striped -highlight"
                    showPagination={false}
                    key={bookingStatsData.length === 0 ? "nodata" : "havedata"}
                    defaultPageSize={bookingStatsData.length === 0 ? 15 : bookingStatsData.length}
                    columns={columns}
                    defaultSorted={([{ id: "organiser", desc: false }])}
                    data={bookingStatsData}
                    loadingText={<Loader label='Loading...' />}
                    loading={isLoading}
                    noDataText={<span>...</span>}
                    collapseOnDataChange={false}
                    SubComponent={ row => {
                        return (
                            <OrganiserSummary filter={filter} organiser={row.row.organiser} />
                        )
                    }}
                    expanded={expandedList}
                    onExpandedChange={ (expandedList, indexArray, event) => {
                        var newExpandedList = {}
                        Object.keys(expandedList).forEach(function(key) {
                            if (parseInt(key) === parseInt(indexArray[0])) {
                                //toggle current subcomponent
                                newExpandedList[key] = expandedList[key] ? true : false
                            } else {
                                //other subcomponents set to not expanded
                                newExpandedList[key] = false
                            }
                        })
                        setExpandedList(newExpandedList)
                    }}
                />
            </div>
        </div>
    )
}

export default BookingStatsReport

function keysOf<T extends object>(obj: T): Array<keyof T> {
    return Object.keys(obj) as Array<keyof T>
}

function toObject<K extends string, V>(keys: K[], getValue: (key: K) => V): Record<K, V> {
    const obj: Record<K, V> = {} as any
    for (const key of keys) {
        obj[key] = getValue(key)
    }
    return obj
}