import { Dialog, EditIcon, FlagIcon, Flex, Menu, tabListBehavior, TrashCanIcon } from "@fluentui/react-northstar";
import { compare, Operation } from "fast-json-patch";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import {
    AllExchangeVersions, AllLoginTypes, AllProviderTypes, AzureAppIdConfigurationSchema, CreateProvider,
    EwsProviderConfiguration, EwsProviderConfigurationSchema, GoogleCalendarProviderConfiguration,
    GoogleProviderConfigurationSchema, GraphProviderConfiguration, IdType, Provider
} from "../../model";
import { createProvider, deleteProvider, fetchProviders, patchProvider } from "../../store/resourceActions";
import { getActiveOrganisation } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { FlattenUnion } from "../../utils/utils";
import ResourceAdminForm from "../admin/ResourceAdminForm";
import ResouceAdminPage from '../admin/ResourceAdminPage';
import { FormikNorthstarCheckbox } from "../controls/FormikNorthstarCheckbox";
import { FormikNorthstarDropdown } from "../controls/FormikNorthstarDropdown";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";
import { TestGraphProviderForm } from './TestGraphProviderForm';
interface Props { }

type P = Omit<Provider, "provider_id" | "organisation_id" | "domain_maps">
type ProviderRest = Partial<Omit<P, "configuration_json">>
type ConfigJsonUnion = P["configuration_json"]
type ConfigJson = FlattenUnion<ConfigJsonUnion>
type ProviderEditModel = ProviderRest & ConfigJson & { domain_names?: string }

const ProvidersPage: React.FC<Props> = (props) => {

    const dispatch = useDispatch()


    const cols = useMemo(() => [
        { Header: "Description", id: "description", minWidth: 40, accessor: (row: Provider) => row.description },
        { Header: "Provider Type", id: "provider_type", accessor: (row: Provider) => row.provider_type, minWidth: 50 },
        { Header: "Login Type", id: "login_type", accessor: (row: Provider) => row.login_type, minWidth: 50 },
    ], [])

    const activeOrganisation = useSelector(getActiveOrganisation)

    const handleAddOrUpdate = (provider: ProviderEditModel, closeDialog: () => void, original: Provider | undefined) => {
        if (activeOrganisation === undefined) {
            console.error("Active organisation is not defined!. Cannot create provider")
            return
        }
        if (original) {
            const updatedEntity = {
                ...original, 
                description: provider.description,
                provider_type: provider.provider_type,
                login_type: provider.login_type,
                azure_login_client_id: provider.login_type === 'AzureAd' ? provider.azure_login_client_id : undefined,
                azure_login_tenant_id: provider.login_type === 'AzureAd' ? provider.azure_login_tenant_id : undefined,
                ping_email_address: provider.login_type === 'AzureAd' ? provider.ping_email_address : undefined,
                domain_maps: getDomainMaps(provider.domain_names!), 
                configuration_json: getConfiguration(provider)
            }
            let diff = compare(original, updatedEntity);
            diff = fixDiff(diff, updatedEntity.domain_maps, updatedEntity.configuration_json)
            dispatch(patchProvider.request({ id: original, operations: diff }))
        } else {
            const newProvider: CreateProvider = {
                description: provider.description!,
                login_type: provider.login_type!,
                provider_type: provider.provider_type!,
                azure_login_client_id: provider.azure_login_client_id,
                azure_login_tenant_id: provider.azure_login_tenant_id,
                ping_email_address: provider.ping_email_address,
                domain_maps: getDomainMaps(provider.domain_names!),
                configuration_json: getConfiguration(provider),
                organisation_id: activeOrganisation.organisation_id
            }

            dispatch(createProvider.request(newProvider))
        }
        closeDialog()
    }

    const [providerUnderTest, setProviderUnderTest] = useState<Provider>()
    const onTestProvider = useCallback((provider: Provider) => {
        setProviderUnderTest(provider)
    }, [setProviderUnderTest])


    return <>

        {providerUnderTest && <Dialog
            content={<TestGraphProviderForm provider={providerUnderTest} 
            onDismiss={() => setProviderUnderTest(undefined)}/>}
            header={`Test provider  ${providerUnderTest.description}`}
            open={providerUnderTest !== undefined}
        />}

        <ResouceAdminPage<Provider, { organisation_id: IdType }>
            resourceName='Provider'
            parentKey={{ organisation_id: activeOrganisation!.organisation_id }}
            selectState={s => s.bookit.providers}
            fetchAllAction={fetchProviders}
            deleteAction={deleteProvider}
            confirmMessage={prov => `Are you sure you wish to delete the provider '${prov.description}'?`}
            resourceForm={(original, closeDialog) =>
                <ProviderForm initial={createEditModel(original)} onSubmit={edit => handleAddOrUpdate(edit, closeDialog, original)} onCancel={closeDialog} />
            }
            toolbarActionItems={(resource, onAddUpdate, onDelete) =>
                [{
                    key: 'test',
                    icon: <FlagIcon outline />,
                    tooltip: `Test ${resource.description}`,
                    disabled: resource.provider_type !== 'Graph',
                    onClick: () => onTestProvider(resource)
                },
                {
                    key: 'edit',
                    icon: <EditIcon outline />,
                    tooltip: `Edit ${resource.description}`,
                    onClick: () => onAddUpdate(resource)
                },
                {
                    key: 'delete',
                    icon: <TrashCanIcon outline />,
                    tooltip: `Delete ${resource.description}`,
                    onClick: () => onDelete(resource)
                }]
            }
            columns={cols}
            defaultSortOrder={[{ id: "description", desc: false }]}
        />
    </>
}

function fixDiff(diff: Operation[], domainMaps: string[], jsonConfiguration: ConfigJsonUnion): Operation[] {
    let updatedDiff = diff
    // dont use add/remove just replace whole array in domain_maps and configuration_json

    const updateDomainMaps = diff.some(op => op.path.startsWith("/domain_maps"))
    if (updateDomainMaps) {
        updatedDiff = diff.filter(op => !op.path.startsWith("/domain_maps"))
        updatedDiff = [...updatedDiff, {
            op: 'replace',
            path: '/domain_maps',
            value: domainMaps
        }]
    }

    const updateJsonConfiguration = diff.some(op => op.path.startsWith("/configuration_json"))
    if (updateJsonConfiguration) {
        updatedDiff = diff.filter(op => !op.path.startsWith("/configuration_json"))
        updatedDiff = [...updatedDiff, {
            op: 'replace',
            path: '/configuration_json',
            value: jsonConfiguration
        }]
    }

    return updatedDiff
}

function getDomainMaps(domainStr: string): string[] {
    return domainStr ? domainStr.split(',').filter(s => s && s.length > 0).map(s => s.trim()) : []
}

function getConfiguration(edit: ProviderEditModel): ConfigJsonUnion {
    switch (edit.provider_type!) {
        case 'Graph': return { ClientId: edit.ClientId!, ClientSecret: edit.ClientSecret, TenantId: edit.TenantId! } as GraphProviderConfiguration
        case 'Ews': return {
            ServiceAccount: edit.ServiceAccount!,
            Password: edit.Password,
            ExchangeVersion: edit.ExchangeVersion!,
            InternalExchangeUriHints: edit.InternalExchangeUriHints!,
            OnlineExchangeUriHints: edit.OnlineExchangeUriHints!,
            AlwaysUseSuppliedUri: edit.AlwaysUseSuppliedUri!,
            ExchangeWebServiceUri: edit.ExchangeWebServiceUri!,
            EnableTracing: edit.EnableTracing!
        } as EwsProviderConfiguration
        case 'Google': return {
            CalendarId: edit.CalendarId!,
            AppId: edit.AppId!,
            ProxyDomainUser: edit.ProxyDomainUser!,
            ServiceAccountKey: edit.ServiceAccountKey!
        } as GoogleCalendarProviderConfiguration
    }
}

function createEditModel(resource?: Provider): ProviderEditModel | undefined {
    if (resource === undefined) return undefined
    const { provider_id, organisation_id, configuration_json, domain_maps, ...edit } = resource
    const str = domain_maps.join(", ")
    const result = { ...edit, ...configuration_json as ConfigJson, domain_names: str }
    return result
}

interface ProviderFormProps {
    initial?: ProviderEditModel
    onSubmit: (edit: ProviderEditModel) => void
    onCancel: () => void,
}
const ProviderForm: React.FC<ProviderFormProps> = ({ initial, onSubmit, onCancel }) => {

    const initialProvider = useMemo(() => initial ?? { login_type: 'BookIt', provider_type: 'Graph', ExchangeVersion: 'Exchange2016', AlwaysUseSuppliedUri: false, EnableTracing: false } as ProviderEditModel, [initial])

    const [providerType, setProviderType] = useState<string>(initialProvider.provider_type!)
    const [loginType, setLoginType] = useState<string>(initialProvider.login_type!)

    const formSchema = useMemo(() => {
        let schema = Yup.object({
            description: Yup.string()
                .required('Description cannot be empty'),
            domain_names: Yup.string().optional().notRequired(),
            login_type: Yup.string()
                .required('Must provider a login type')
                .oneOf(AllLoginTypes),
            azure_login_client_id: Yup.string().when('login_type', {
                is: 'AzureAd',
                then: Yup.string().required('Must set a ClientID when using AzureAd login'),
                otherwise: Yup.string().optional().notRequired()

            }),
            azure_login_tenant_id: Yup.string().when('login_type', {
                is: 'AzureAd',
                then: Yup.string().required('Must set a TenantID when using AzureAd login'),
                otherwise: Yup.string().optional().notRequired()
            }),
        })
        if (providerType === 'Graph') {
            schema = schema.shape(AzureAppIdConfigurationSchema)
        }
        if (providerType === 'Ews') {
            schema = schema.shape(EwsProviderConfigurationSchema)
        }
        if (providerType === 'Google') {
            schema = schema.shape(GoogleProviderConfigurationSchema)
        }
        return schema.defined()
    }, [providerType])

    const handleProviderTypeChange = useCallback((type: string) => {
        setProviderType(type)
    }, [])

    return <ResourceAdminForm
        initial={initialProvider}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <Flex column gap="gap.medium" >
            <FormikNorthstarInput fluid label="Description" name="description" id="description" />
            <FormikNorthstarInput fluid label="Domains (Comma separated. Leave blank for room only)" name="domain_names" />
            <FormikNorthstarDropdown<string> label="Login Type" name="login_type" items={AllLoginTypes} getHeader={i => i} onChange={t => t && setLoginType(t)}/>
            <FormikNorthstarInput fluid label="Azure Login Client ID" name="azure_login_client_id" disabled={loginType !== 'AzureAd'}/>
            <FormikNorthstarInput fluid label="Azure Login Tenant ID" name="azure_login_tenant_id" disabled={loginType !== 'AzureAd'}/>
            <FormikNorthstarInput fluid label="Ping Email Address" name="ping_email_address" disabled={loginType !== 'AzureAd'}/>
            <FormikNorthstarDropdown<string> label="Provider Type" name="provider_type" items={AllProviderTypes} onChange={t => t && handleProviderTypeChange(t)}
                getHeader={i => i}
            />

            <Menu
                defaultActiveIndex={0}
                underlined
                primary
                accessibility={tabListBehavior}
                aria-label="Provider Configuration"
                items={[
                    { key: 'graph', content: 'Graph', disabled: providerType !== 'Graph', active: providerType === 'Graph' },
                    { key: 'ews', content: 'EWS', disabled: providerType !== 'Ews', active: providerType === 'Ews' },
                    { key: 'google', content: 'Google', disabled: providerType !== 'Google', active: providerType === 'Google' },
                ]}
            />
            {providerType === 'Graph' && <AzureAdClientSettings />}
            {providerType === 'Ews' && <EwsSettingsForm />}
            {providerType === 'Google' && <GoogleSettingsForm />}
        </Flex>
    </ResourceAdminForm >
}

export const AzureAdClientSettings: React.FC<{}> = () => {
    return <>
        <FormikNorthstarInput fluid label="Tenant ID" name="TenantId" id="TenantId" />
        <FormikNorthstarInput fluid label="Client ID" name="ClientId" id="ClientId" />
        <FormikNorthstarInput fluid label="Client Secret" name="ClientSecret" id="ClientSecret" />
    </>
}

const EwsSettingsForm: React.FC<{}> = () => {
    return <>
        <FormikNorthstarInput fluid label="Service Account" name="ServiceAccount" id="ServiceAccount" />
        <FormikNorthstarInput fluid label="Password" name="Password" id="Password" />
        <FormikNorthstarDropdown<string> label="Exchange Version" name="ExchangeVersion" items={AllExchangeVersions} getHeader={i => i} />
        <FormikNorthstarInput fluid label="Internal Exchange URI Hints" name="InternalExchangeUriHints" id="InternalExchangeUriHints" />
        <FormikNorthstarInput fluid label="Online Exchange URI Hints" name="OnlineExchangeUriHints" id="OnlineExchangeUriHints" />
        <FormikNorthstarCheckbox toggle label="Always Use Supplied URI" name="AlwaysUseSuppliedUri" />
        <FormikNorthstarInput fluid label="Exchange Web Service URI" name="ExchangeWebServiceUri" id="ExchangeWebServiceUri" />
        <FormikNorthstarCheckbox toggle label="Enable Tracing" name="EnableTracing" />
    </>
}

const GoogleSettingsForm: React.FC<{}> = () => {
    return <>
        <FormikNorthstarInput fluid label="Calendar Id" name="CalendarId" id="CalendarId" />
        <FormikNorthstarInput fluid label="App Id" name="AppId" id="AppId" />
        <FormikNorthstarInput fluid label="Proxy Domain User" name="ProxyDomainUser" id="ProxyDomainUser" />
        <FormikNorthstarInput fluid label="Service Account Key" name="ServiceAccountKey" id="ServiceAccountKey" />
    </>
} 


export default ProvidersPage