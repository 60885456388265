import { Checkbox, EditIcon, TrashCanIcon } from "@fluentui/react-northstar";
import * as React from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { CellInfo } from "react-table";
import * as Yup from 'yup';
import { AllDaysOfWeek, CreateEscalationRule, EscalationRule, IdType } from "../../model";
import { deleteEscalationRule, fetchEscalationRules } from "../../store/resourceActions";
import { getActiveOrganisation } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import ResourceAdminForm from "../admin/ResourceAdminForm";
import ResouceAdminPage from "../admin/ResourceAdminPage";
import { FormikNorthstarCheckbox } from "../controls/FormikNorthstarCheckbox";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";


interface Props { }

type EscalationRuleEditModel = CreateEscalationRule & {quiet_period: boolean}

const EscalationRulesPage: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    function renderToggle(cellInfo: CellInfo, column: any) {
        const escalationRule = cellInfo.original
        return <Checkbox checked={cellInfo.value} toggle /> // onChange={() => onEnabledChanged(escalationRule)} />
    }

    const cols = useMemo(() => [
        { Header: "Description", id: "description", minWidth: 15, accessor: (row: EscalationRule) => row.description },
        { Header: "Enabled", id: "enabled", accessor: (row: EscalationRule) => row.enabled, minWidth: 25, Cell: renderToggle },
    ], [])

    const activeOrganisation = useSelector(getActiveOrganisation)




    const handleAddOrUpdate = (rule: EscalationRuleEditModel, closeDialog: () => void, original: EscalationRule | undefined) => {
        // if (original) {
        //     const updateTenant = {
        //         organisation_tenant_id: original.organisation_tenant_id,
        //         organisation_id: original.organisation_id,
        //         client_id: tenant.client_id,
        //         tenant_id: tenant.tenant_id,
        //         client_secret: tenant.client_secret,
        //         type: original.type,
        //         domains: getDomains(tenant.domains)
        //     }

        //     const { domains: _, ...intiial } = original // remove domains so we generate full replace of array in the patch

        //     console.log("intiial:", intiial)
        //     console.log("tenant:", tenant)
        //     console.log("updateTenant:", updateTenant)
        //     let diff = compare(intiial, updateTenant);
        //     diff = fixDiff(diff, updateTenant.domains)
        //     dispatch(patchOrganisationTenant.request({ id: original, operations: diff }))
        // } else {
        //     const newTenant: CreateOrganisationTenant = {
        //         client_id: tenant.client_id,
        //         tenant_id: tenant.tenant_id,
        //         client_secret: tenant.client_secret,
        //         type: 'AzureAdToken',
        //         domains: getDomains(tenant.domains),
        //         organisation_id: activeOrganisation.organisation_id
        //     }
        //     console.log("newTenant:", newTenant)

        //     dispatch(createOrganisationTenant.request(newTenant))
        // }
        closeDialog()
    }


    return <ResouceAdminPage<EscalationRule, { organisation_id: IdType }>
        resourceName='Escalation Rule'
        parentKey={{ organisation_id: activeOrganisation!.organisation_id }}
        selectState={s => s.escalationRules.escalationRules}
        fetchAllAction={fetchEscalationRules}
        deleteAction={deleteEscalationRule}
        confirmMessage={er => `Are you sure you wish to delete the escalation rule with id ${er.escalation_rule_id}?`}
        resourceForm={(original, closeDialog) =>
            <EscalationRuleForm initial={createEditModel(original)} onSubmit={edit => handleAddOrUpdate(edit, closeDialog, original)} onCancel={closeDialog} />
        }
        toolbarActionItems={(resource, onAddUpdate, onDelete) =>
            [{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: `Edit ${resource.escalation_rule_id}`,
                onClick: () => onAddUpdate(resource)
            },
            {
                key: 'delete',
                icon: <TrashCanIcon outline />,
                tooltip: `Delete ${resource.escalation_rule_id}`,
                onClick: () => onDelete(resource)
            }]
        }
        columns={cols}
        defaultSortOrder={[{ id: "enabled", desc: true }, { id: "name", desc: false }]}
    />
}

// function fixDiff(diff: Operation[], domains: string[]): Operation[] {
//     let updatedDiff = diff
//     // dont use add/remove just replace whole array in domain

//     const updateDomains = diff.some(op => op.path.startsWith("/domains"))
//     if (updateDomains) {
//         updatedDiff = diff.filter(op => !op.path.startsWith("/domains"))
//         updatedDiff = [...updatedDiff, {
//             op: 'replace',
//             path: '/domains',
//             value: domains
//         }]
//     }

//     return updatedDiff
// }

function createEditModel(resource?: EscalationRule): EscalationRuleEditModel | undefined {
    if (resource === undefined) return undefined
    const { organisation_id, escalation_rule_id, ...edit } = resource
    return {quiet_period: resource.disabled_days_of_week.length > 0 && Boolean(resource.disabled_start_time) && Boolean(resource.disabled_end_time), ...edit}
}

// function getDomains(domainStr: string): string[] {
//     return domainStr ? domainStr.split(',').filter(s => s && s.length > 0).map(s => s.trim()) : []
// }

interface EscalationRuleFormProps {
    initial?: EscalationRuleEditModel
    onSubmit: (edit: EscalationRuleEditModel) => void
    onCancel: () => void
}

const EscalationRuleForm: React.FC<EscalationRuleFormProps> = ({ initial, onSubmit, onCancel }) => {
    const initialEscalationRule = useMemo(() =>
        initial ?? {
            description: '',
            title_format_escalate: '',
            title_format_escalation_cleared: '',
            enabled: false,
            disabled_days_of_week: [],
            rules_json: '',
            quiet_period: false
        } as EscalationRuleEditModel, [initial])

    console.log("initialEscalationRule", initialEscalationRule)

    const formSchema = useMemo(() => Yup.object().shape({
        description: Yup.string()
            .required("The description cannot be empty"),
        title_format_escalate: Yup.string()
            .required("The Title Format Escalate cannot be empty"),
        title_format_escalation_cleared: Yup.string()
            .optional(),
        disabled_days_of_week: Yup.array().of(Yup.mixed().oneOf(AllDaysOfWeek)),


    }), [])

    return <ResourceAdminForm
        initial={initialEscalationRule}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <FormikNorthstarInput fluid label="Description" name="description" />
        <FormikNorthstarInput fluid label="Title Format Escalate" name="title_format_escalate" />
        <FormikNorthstarInput fluid label="Title Format Escalation Cleared" name="title_format_escalation_cleared" />
        <FormikNorthstarCheckbox toggle label="Enabled" name="enabled" />
        <FormikNorthstarInput fluid label="Rule JSON" name="rules_json" />
        
    </ResourceAdminForm>
}

export default EscalationRulesPage