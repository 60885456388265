import React, { Component } from 'react';
import { UserAgentApplication } from 'msal';
import config from './GraphApiConfig';
import { getUserDetails } from './GraphApiService';
import GraphApiOneDrive from './GraphApiOneDrive';

class GraphApiApp extends Component {
  constructor(props) {
    super(props);

    //console.log(JSON.stringify(props));

    this.userAgentApplication = new UserAgentApplication({
        auth: {
            clientId: config.appId,
            redirectUri: config.redirectUri
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    });

    var user = this.userAgentApplication.getAccount();

    this.state = {
      isAuthenticated: (user !== null),
      user: {},
      error: null
    };

    if (user) {
      this.getUserProfile();
    }
  }

  render() {
    //let error = null;
    if (this.state.error) {
      //error = <ErrorMessage message={this.state.error.message} debug={this.state.error.debug} />;
      console.log(this.state.error.message);
      console.log(this.state.error.debug);
      return "error";
    }

    return (
      <GraphApiOneDrive {...this.props}
        isAuthenticated={this.state.isAuthenticated}
        authButtonMethod={this.login.bind(this)}
        showError={this.setErrorMessage.bind(this)}
      />
    );
  }

  setErrorMessage(message, debug) {
    this.setState({
      error: {message: message, debug: debug}
    });
  }

  async login() {
    console.log("login");
    try {
      await this.userAgentApplication.loginPopup(
        {
          scopes: config.scopes,
          prompt: "select_account"
      });
      await this.getUserProfile();
    }
    catch(err) {
      var error = {};

      if (typeof(err) === 'string') {
        var errParts = err.split('|');
        error = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }

      this.setState({
        isAuthenticated: false,
        user: {},
        error: error
      });
    }
  }

  logout() {
    console.log("logout");
    this.userAgentApplication.logout();
  }

  async getUserProfile() {
    try {
      var accessToken = await this.userAgentApplication.acquireTokenSilent({
        scopes: config.scopes
      });

      if (accessToken) {
        var user = await getUserDetails(accessToken);
        this.setState({
          isAuthenticated: true,
          user: {
            displayName: user.displayName,
            email: user.mail || user.userPrincipalName
          },
          error: null
        });
      }
    }
    catch(err) {
      var error = {};
      if (typeof(err) === 'string') {
        var errParts = err.split('|');
        error = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }

      this.setState({
        isAuthenticated: false,
        user: {},
        error: error
      });
    }
  }
}

export default GraphApiApp;
