import { Segment } from "@fluentui/react-northstar";
import { useState } from "react";
import { OrganisationSettings } from "../../model";
import { FormikNorthstarCheckbox } from "../controls/FormikNorthstarCheckbox";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";
import css from './OrganisationsPage.module.scss';

interface EditorProps {
    settings: OrganisationSettings 
}

export function VideoFxHealthReportEditor({settings}: EditorProps) {

    const [enabled, setEnabled] = useState(settings.vfx_health_report?.enabled ? settings.vfx_health_report?.enabled : false) 

    const handleEnabledChange = (enabled: boolean) => {
        setEnabled(enabled)
    }

    return  <div> 
        
        <p className={css.sectionTitle}>VideoFX Health Report</p>
        
        <FormikNorthstarCheckbox toggle label="Is enabled" 
                                name="settings.vfx_health_report.enabled" 
                                onChange={handleEnabledChange} />

        <FormikNorthstarInput fluid label="Report AM time" 
                                name="settings.vfx_health_report.report_am_time" 
                                id="settings.vfx_health_report.report_am_time" 
                                disabled={!enabled} />
        
        <FormikNorthstarInput fluid label="Report PM time" 
                                name="settings.vfx_health_report.report_pm_time" 
                                id="settings.vfx_health_report.report_pm_time" 
                                disabled={!enabled} />
        
        <FormikNorthstarInput fluid label="Recipient mailboxes (comma-separated)" 
                                name="settings.vfx_health_report.report_mailbox" 
                                id="settings.vfx_health_report.report_mailbox" 
                                disabled={!enabled} />
        
        <FormikNorthstarInput fluid label="Timezone" 
                                name="settings.vfx_health_report.timezone" 
                                id="settings.vfx_health_report.timezone" 
                                disabled={!enabled} />
    </div>
}