import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { SiteDetail } from './SiteDetail';
import { SitesList } from './SitesList';
import css from './SitesPage.module.scss';

export function SitesPage() {


    return <div className={css.sitePage}>
        <h5>UC Sites</h5>
        <div className={css.pageHeader}>
            <ul className={css.breadcrumbs}>
                <li>
                    <NavLink to="/uc/sites">All</NavLink>
                </li>
                <Route path="/uc/sites/:siteId" children={({ match }) => (match && <li>{match.params.siteId}</li>)} />
            </ul>
        </div>
        <Switch>
            <Route exact path="/uc/sites" children={<SitesList/>} />
            <Route path="/uc/sites/:siteId" children={({ match }) => (match && <SiteDetail siteId={match.params.siteId} />)} />
        </Switch>
    </div>
}

