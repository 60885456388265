import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import RoomLogs from "../components/videofx/room/RoomLogs";
import { Room } from "../model";
import { HalResource } from "../services/Hal";
import { adminDownloadLogComplete, adminFetchLog, adminFetchLogMetadata } from "../store/actions";
import { getLogsMetadataForRoom } from "../store/selectors";
import { ReduxPropsType, RootState } from "../store/types";

function mapStateToProps(state: RootState, ownProps: {room: HalResource<Room> }) {
    return {
        downloadedLogFile: state.adminLogs.downloadedLogFile,
        logFilesMetadata: getLogsMetadataForRoom(state, ownProps.room.name)
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
        downloadComplete: adminDownloadLogComplete,
        fetchLog: adminFetchLog.request,
        fetchLogMetdata: adminFetchLogMetadata.request
}, dispatch);
  
  const withRedux = connect(mapStateToProps, mapDispatchToProps);
  export type RoomLogsProps = ReduxPropsType<typeof withRedux>;
  export default withRedux(RoomLogs);