import { FormField, FormLabel, FormMessage, Input, InputProps } from '@fluentui/react-northstar';
import { useField } from 'formik';
import React from 'react';

type FormikNorthstarInputProps = Omit<InputProps, 'name' | 'onChange'> & {
    name: string;
    onChange?: (value: string) => void

};

export const FormikNorthstarInput: React.FC<FormikNorthstarInputProps> = ({
    name,
    label,
    type,
    onChange,
    ...props
}) => {
    const [field, metadata, { setValue, setTouched }] = useField({
        name,
        type: type,
    });
    const isError = metadata.touched && metadata.error !== undefined
    const id = props.id || name

    return (
        <FormField>
            <FormLabel htmlFor={id} id={`${id}-label`}>
                {label}
            </FormLabel>
            <Input
                error={isError}
                type={type}
                id={id}
                onChange={(_e, p) => {
                    if (p) {
                        setValue(p.value)
                        if (onChange) onChange(p.value)
                    }
                }}
                onBlur={() => setTouched(true)}
                value={field.value}
                {...props}
            />
            {isError && (
                <FormMessage id={`${id}message-id`} role="alert" error={isError}>
                    {metadata.error}
                </FormMessage>
            )}
        </FormField>
    )
}
