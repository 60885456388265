import { bind } from 'bind-decorator';
import * as React from "react";
import { EventEscalationSelection } from "../../../model";
import EscalationList from '../EscalationList';
import EscalationTypeSelector from '../EscalationTypeSelector';

interface Props {
    roomName: string
}

interface State {
    selection: EventEscalationSelection
}

export default class RoomEscalationsTable extends React.PureComponent<Props, State> {
    public state: State = { selection: 'active' }

    public render() {
        console.log(`rendering RoomEscalationsTable selection=${this.state.selection}, roomName=${this.props.roomName}`)
        return (
            <>
                <div className="navbar">
                    <div className="mr-auto">
                        <EscalationTypeSelector onSelectionChange={this.onSelectionChanged} />
                    </div>
                </div>
                <EscalationList escalationSelection={this.state.selection!} roomName={this.props.roomName} />
            </>
        )
    }

    @bind
    private onSelectionChanged(selection: EventEscalationSelection) {
        this.setState({ selection });
    }
}