
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import Collapse from 'reactstrap/lib/Collapse';
import { getLoggedInUserRoles } from '../../store/selectors';

interface Props {
  iconName?: string
  icon?: ReactNode
  title: string
  children: ReactNode
  rolesRequired?: string[]
}

const NavCollapse: React.FC<Props> = ({ iconName, icon, title, children, rolesRequired }) => {
  const [isOpen, setIsOpen] = useState(false)

  const roles = useSelector(getLoggedInUserRoles)
  if (rolesRequired && !rolesRequired.some(reqRole => roles.includes(reqRole))) {
    return null;
  }

  return (
    <li className="nav-item">
      <span className="nav-link" onClick={() => setIsOpen(!isOpen)}>
        {iconName && <i className="feather material-icons">{iconName}</i>}
        {icon === undefined ? null : icon}
        <span>{title}</span>
        <i className="expand_icon feather material-icons ml-3">{isOpen ? 'expand_less' : 'expand_more'}</i>
      </span>
      <Collapse isOpen={isOpen}>
        <ul className="nav flex-column ml-3">
          {children}
        </ul>
      </Collapse>
    </li>
  )
}

export default NavCollapse;