import { all } from "async-saga";
import services from "../../services";
import Config from "../../services/Config";
import { SignalRHub } from "../../services/SignalRHub";
import { ApiResult } from "../reducers/ApiResult";
import { actions } from "../types";
import { takeEvery } from "./async-saga";



const startPingHubSaga = takeEvery(actions.startPingHub.request, async function (ctx) {
    const state = ctx.getState()
    const account = ApiResult.getValueOrDefault(state.auth.account, null)
    if (account === null) return
    
    try {
        const pingHub = new SignalRHub(`${Config.serverApiRoot}/ping?subject=${account.userName}&clientType=AdminPortal`) // , new MessagePackHubProtocol())
        services.pingHub = pingHub
        
        pingHub.connection.on("PingRequest", async (correlationId: string, seqNo: number, startTimestamp: number, paddingData: string) => {
            const t0 = performance.now()
            await pingHub.connection.invoke("PingResponse", correlationId, seqNo, startTimestamp, paddingData)
            const t1 = performance.now()
            console.log(`PingRequestReceived: ${correlationId}:${seqNo}:${atob(paddingData).length}: Elapsed: ${t1 - t0}`)
            console.log("paddingData")

        })
        await pingHub.start()

    } catch (e) {
        console.error("startPingHubSaga: ", e)
        ctx.dispatch(actions.startPingHub.failure(e))

    }
})

export default all(
    startPingHubSaga
)
