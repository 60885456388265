import _ from "lodash";

export interface ApiResourceState<TResource> {
    resources: Record<string, TResource>
    isLoaded: boolean
    isLoading: boolean
    isCreating: boolean
    isUpdating: boolean
    failed: boolean
}

export const defaultState = { resources: {}, isLoaded: false, isLoading: false, isCreating: false, isUpdating: false, failed: false }

export function fetchAllRequest<TState, TResource>(state: TState): TState {
    return {
        ...state,
        isLoaded: false,
        isLoading: true,
    }
}

export function fetchAllSuccess<TState, TResource>(state: TState, result: Array<TResource>, getKey: (res: TResource) => string): TState {
    console.log('keyBy', result)
    console.log('result.length', result?.length)
    if (result && result.length > 0) {
        console.log('k0', getKey(result[0]))
    }
    const res = _.keyBy(result, getKey);

    return {
        ...state,
        resources: res,
        isLoaded: true,
        isLoading: false,
        failed: false
    }
}

export function fetchAllFail<TState, TResource>(state: TState): TState {
    return {
        ...state,
        resources: {},
        isLoading: false,
        failed: true
    }
}

export function creatingRequest<TState>(state: TState): TState {
    return {
        ...state,
        isCreating: true
    }
}

export function patchRequest<TState>(state: TState): TState {
    return {
        ...state,
        isUpdating: true
    }
}
export function patchFail<TState>(state: TState): TState {
    return {
        ...state,
        isUpdating: false,
        failed: true
    }
}


export function creatingFail<TState>(state: TState): TState {
    return {
        ...state,
        isCreating: false,
        isLoaded: false,
        failed: true
    }
}

export function actionFail<TState>(state: TState): TState {
    return {
        ...state,
        failed: true
    }
}

export function fetchCreateOrUpdateSuccess<TResource>(
    state: ApiResourceState<TResource>, key: string, result: TResource): ApiResourceState<TResource> {
    return {
        ...state,
        isCreating: false,
        isUpdating: false,
        failed: false,
        resources: { ...state.resources, [key]: result }
    }
}

export function deleteSuccess<TState extends ApiResourceState<TResource>, TKey extends number | string, TResource>(
    state: TState, key: TKey): TState {
    const { [key]: deleted, ...newState } = state.resources;
    return {
        ...state,
        failed: false,
        resources: newState
    };
}
