import * as React from "react";
import { useCallback, useState } from "react";
import { EventEscalationSelection } from "../../model";
import EscalationList from "./EscalationList";
import "./EscalationPage.css";
import EscalationTypeSelector from "./EscalationTypeSelector";

const EscalationsPage: React.FC<{}> = () => {
    const [selection, setSelection] = useState<EventEscalationSelection>('active')
    const handleSelectionChanged = useCallback((selection: EventEscalationSelection) => {
        setSelection(selection)
    }, [setSelection])
    return (
        <>
            <h5>Escalations</h5>
            <div className="navbar">
                <div className="mr-auto">
                    <EscalationTypeSelector onSelectionChange={handleSelectionChanged} />
                </div>
            </div>
            <EscalationList escalationSelection={selection} />
        </>
    )
}

export default EscalationsPage



