import { Menu } from "@fluentui/react-northstar";
import * as React from "react";
import { useCallback } from "react";
import { EventEscalationSelection } from "../../model";

interface Props {
    onSelectionChange: (selection: EventEscalationSelection) => void
}
const EscalationTypeSelector: React.FC<Props> = ({onSelectionChange}) => {

    const handleMenuClicked = useCallback((event: React.SyntheticEvent<HTMLElement>, data?: any) => {
        const newSelection = data!.name as EventEscalationSelection
        onSelectionChange(newSelection)
    }, [onSelectionChange])

    const items = [
        {
            key: 'active',
            name: 'active',
            content: 'Active',
            onClick: handleMenuClicked
        },
        {
            key: 'closed',
            name: 'closed',
            content: 'Closed',
            onClick: handleMenuClicked
        },
        {
            key: 'all',
            name: 'all',
            content: 'All',
            onClick: handleMenuClicked
        },
    ]

    return <Menu defaultActiveIndex={0} items={items} primary />
}

export default EscalationTypeSelector