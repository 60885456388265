import { EditIcon, Flex, TrashCanIcon } from "@fluentui/react-northstar";
import { compare } from "fast-json-patch";
import * as React from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { BookItLocation, CreateBookitLocation as CreateBookItLocation, IdType } from "../../model";
import { createLocation, deleteLocation, fetchLocations, patchLocation } from "../../store/resourceActions";
import { getActiveOrganisation } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { Guid } from "../../utils/guid";
import ResourceAdminForm from "../admin/ResourceAdminForm";
import ResouceAdminPage from '../admin/ResourceAdminPage';
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";

interface Props { }


const LocationsPage: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const cols = useMemo(() => [
        { Header: "Name", id: "name", minWidth: 40, accessor: (row: BookItLocation) => row.name },
    ], [])

    const activeOrganisation = useSelector(getActiveOrganisation)

    const handleAddOrUpdate = (location: CreateBookItLocation, closeDialog: () => void, original: BookItLocation | undefined) => {
        if (activeOrganisation === undefined) {
            console.error("Active organisation is not defined!. Cannot create location")
            return
        }
        if (original) {
            const updatedEntity = {
                ...original, name: location.name, organisation_id: activeOrganisation.organisation_id
            }
            let diff = compare(original, updatedEntity);
            dispatch(patchLocation.request({ id: original, operations: diff }))
        } else {
            const newLocation: CreateBookItLocation = {
                name: location.name,
                organisation_id: activeOrganisation.organisation_id
            }

            dispatch(createLocation.request(newLocation))
        }
        closeDialog()
    }


    return <ResouceAdminPage<BookItLocation, { organisation_id: IdType }>
        resourceName='Location'
        parentKey={{ organisation_id: activeOrganisation!.organisation_id }}
        selectState={s => s.bookit.locations}
        fetchAllAction={fetchLocations}
        deleteAction={deleteLocation}
        confirmMessage={loc => `Are you sure you wish to delete the location '${loc.name}'?`}
        resourceForm={(original, closeDialog) =>
            <LocationForm initial={createEditModel(original)} onSubmit={edit => handleAddOrUpdate(edit, closeDialog, original)} onCancel={closeDialog} />
        }
        toolbarActionItems={(resource, onAddUpdate, onDelete) =>
            [{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: `Edit ${resource.name}`,
                onClick: () => onAddUpdate(resource)
            },
            {
                key: 'delete',
                icon: <TrashCanIcon outline />,
                tooltip: `Delete ${resource.name}`,
                onClick: () => onDelete(resource)
            }]
        }
        columns={cols}
        defaultSortOrder={[{ id: "name", desc: false }]}
    />
}

function createEditModel(resource?: BookItLocation): CreateBookItLocation | undefined {
    if (resource === undefined) return undefined
    return { name: resource.name, organisation_id: resource.organisation_id }
}

interface LocationFormProps {
    initial?: CreateBookItLocation
    onSubmit: (edit: CreateBookItLocation) => void
    onCancel: () => void,
}
const LocationForm: React.FC<LocationFormProps> = ({ initial, onSubmit, onCancel }) => {

    const initialProvider = useMemo(() => initial ?? { name: '', organisation_id: Guid.createEmpty() } as CreateBookItLocation, [initial])

    const formSchema = useMemo(() => {
        let schema = Yup.object({
            name: Yup.string()
                .required('Name cannot be empty')
        })
        return schema.defined()
    }, [])


    return <ResourceAdminForm
        initial={initialProvider}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <Flex column gap="gap.medium" >
            <FormikNorthstarInput fluid label="Name" name="name" id="name" />
        </Flex>
    </ResourceAdminForm>
}


export default LocationsPage