import { getType } from "typesafe-actions";
import { BlobData, LogFileMetadata, LogFilesType } from "../../model";
import { HalResult } from "../../services/Hal";
import { ApiError } from "../../utils/error";
import { Actions, actions } from "../types";

interface AdminLogs {
    downloadedLogFile?: { data?: Blob, failed: boolean, fileName?: string, logName: LogFilesType },
    logFileMetadata: {
        [roomName: string]: {
            [logFileType: string]: {
                        metadata?: HalResult<LogFileMetadata>
                        error?: ApiError
            } | undefined
        }
    }
    collectedLogs?: { [roomName: string]: BlobData | undefined }

    screenShots: {
        [roomName: string]: {
            [index: number]: { blob?: Blob, error?: boolean }
        }
    }
}

export default function adminLogs(state: AdminLogs = { screenShots: {}, logFileMetadata: {} }, action: Actions): AdminLogs {
    switch (action.type) {
        case getType(actions.adminFetchLog.success):
            return {
                ...state,
                downloadedLogFile: { data: action.payload.data, failed: false, fileName: action.payload.fileName, logName: action.payload.logName }
            };
        case getType(actions.adminFetchLog.failure):
            return {
                ...state,
                downloadedLogFile: { data: undefined, failed: true, fileName: undefined, logName: action.payload.logName }
            };
        case getType(actions.adminDownloadLogComplete):
            return {
                ...state,
                downloadedLogFile: undefined
            };
        case getType(actions.adminFetchLogMetadata.request):
            return {
                ...state,
                logFileMetadata: {
                    ...state.logFileMetadata,
                    [action.payload.room.name.toLowerCase()]: {
                        ...state.logFileMetadata[action.payload.room.name.toLowerCase()],
                        [action.payload.log]: undefined
                    }
                }
            }
        case getType(actions.adminFetchLogMetadata.success):
            return {
                ...state,
                logFileMetadata: {
                    ...state.logFileMetadata,
                    [action.payload.roomName.toLowerCase()]: {
                        ...state.logFileMetadata[action.payload.roomName.toLowerCase()],
                        [action.payload.log]: { metadata: action.payload.result }
                    }
                }
            }
        case getType(actions.adminFetchLogMetadata.failure): {
            return {
                ...state,
                logFileMetadata: {
                    ...state.logFileMetadata,
                    [action.payload.roomName.toLowerCase()]: {
                        ...state.logFileMetadata[action.payload.roomName.toLowerCase()],
                        [action.payload.log]: { error: action.payload.error }
                    }
                }
            }
        }
        case getType(actions.adminCollectLogs.request):
            return {
                ...state,
                collectedLogs: { ...state.collectedLogs, [action.payload.name.toLowerCase()]: { isLoading: true, failed: false } }
            };
        case getType(actions.adminCollectLogs.success):
            return {
                ...state,
                collectedLogs: { ...state.collectedLogs, [action.payload.roomName.toLowerCase()]: { ...state.collectedLogs![action.payload.roomName.toLowerCase()]!, data: action.payload.data, name: action.payload.fileName, failed: false } }
            };
        case getType(actions.adminCollectLogs.failure):
            return {
                ...state,
                collectedLogs: { ...state.collectedLogs, [action.payload.roomName.toLowerCase()]: { isLoading: false, failed: true } }
            };
        case getType(actions.adminCollectedLogsDownloaded):
            return {
                ...state,
                collectedLogs: { ...state.collectedLogs, [action.payload.toLowerCase()]: undefined }
            };
        case getType(actions.adminFetchScreenShot.request):
            return {
                ...state,
                screenShots: {
                    ...state.screenShots,
                    [action.payload.room.name.toLowerCase()]: {
                        ...state.screenShots![action.payload.room.name.toLowerCase()],
                        [action.payload.index]: { blob: undefined, error: false }
                    }
                }
            };
        case getType(actions.adminFetchScreenShot.success):
            return {
                ...state,
                screenShots: {
                    ...state.screenShots,
                    [action.payload.roomName.toLowerCase()]: {
                        ...state.screenShots![action.payload.roomName.toLowerCase()],
                        [action.payload.index]: { blob: action.payload.data, error: false }
                    }
                }
            };
        case getType(actions.adminFetchScreenShot.failure):
            return {
                ...state,
                screenShots: {
                    ...state.screenShots,
                    [action.payload.roomName.toLowerCase()]: {
                        ...state.screenShots![action.payload.roomName.toLowerCase()],
                        [action.payload.index]: { blob: undefined, error: true }
                    }
                }
            };
        default:
            return state;
    }
}