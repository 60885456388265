import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bind from "bind-decorator";
import * as _ from "lodash";
import * as React from "react";
import { ScaleLoader } from "react-spinners";
import { CardBody } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import { RoomScreenShotsProps } from "../../../containers/RoomScreenShots";
import { Room } from "../../../model";
import { HalResource } from "../../../services/Hal";
import './RoomScreenShots.css';

type Props = RoomScreenShotsProps & {
    room: HalResource<Room>
}

interface State {
    numDisplays?: number;
}

export default class RoomScreenShots extends React.PureComponent<Props, State> {
    public state: State = {};

    public componentDidMount() {
        if (this.props.roomAttributes !== undefined &&
            this.props.roomAttributes.resource.displays !== undefined) {
            const num = this.props.roomAttributes.resource.displays.value as number;
            this.setState({ numDisplays: num });
            _.range(num).forEach(idx => this.props.fetchScreenShot({ room: this.props.room, index: idx }));
        }
    }

    public render() {
        return (
            <Card className="mt-3" >
                <CardHeader>
                    <span className="text-dark">Select Screen</span>
                </CardHeader>
                {this.state.numDisplays === undefined && this.renderNoDisplaysError()}
                {this.state.numDisplays !== undefined && this.renderDisplayThumbnails()}

            </Card>

        )
    }

    @bind
    private renderDisplayThumbnails() {
        return (
            <CardBody className='screenshots-container'>
                {_.range(this.state.numDisplays!).map((idx) => {
                    const img = this.props.screenShots !== undefined ? this.props.screenShots[idx] : undefined;
                    return <ScreenshotThumbnail key={idx} index={idx} blob={img === undefined ? undefined : img.blob} error={img === undefined ? undefined : img.error} />
                })}
            </CardBody>);
    }

    private renderNoDisplaysError() {
        return (
            <CardBody className='text-danger'>
                The Room currently is not reporting the number of displays. Please check the configuration
                </CardBody>);
    }

}

interface ScreenshotThumbnailProps {
    blob?: Blob;
    index: number;
    error?: boolean;
}

function ScreenshotThumbnail({ blob, error, index }: ScreenshotThumbnailProps) {

    return (
        <div className='thumbnail-content'>
            <h6 className="mb-2">{`Display ${index + 1}`}</h6>
            <div className='border rounded screenshot-thumbnail'>
                {blob !== undefined
                    ?   (<a target="_blank" href={URL.createObjectURL(blob)}>
                            <img className='img-fluid' src={URL.createObjectURL(blob)} />
                        </a>)
                    : error !== undefined && error
                        ? <FontAwesomeIcon icon={faExclamationTriangle} size='3x' color='red'/>
                        : <ScaleLoader />
                }
            </div>
        </div>);
}
