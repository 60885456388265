import { useEffect } from "react"
import { AnyAction, Dispatch } from "redux"
import { fetchOrganisations } from "../store/resourceActions"
import { getOrganisations } from "../store/selectors"
import { useSelector } from "../store/utils"


export default function useOrganisations(dispatch: Dispatch<AnyAction>) {
    const { isLoaded, isLoading } = useSelector(s => s.organisations.organisations)
    useEffect(() => {
        if (!isLoaded && !isLoading) {
            dispatch(fetchOrganisations.request({}))
        }
    }, [dispatch, isLoaded, isLoading])
    const organisations = useSelector(getOrganisations)

    return {isLoaded, isLoading, organisations}
}