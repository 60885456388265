import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { OrganisationSummary, ProviderTestResult } from "../../model";
import { Services } from "../../services";
import {
    errorHandler,
    fetchBookingCount,
    fetchBookingStats,
    fetchHeatMap,
    fetchMinMedianMax,
    fetchOrganisationSummaries,
    fetchOrganiserBookingStats, fetchSummary, testProvider
} from "../actions";
import { RootAction, RootState } from "../types";

export const testProviderEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, services) => {
    return action$.pipe(
        filter(isActionOf(testProvider.request)),
        mergeMap(action =>
            from(services.apiClient!.post<ProviderTestResult>(`/organisations/${action.payload.organisationId}/providers/${action.payload.providerId}`, {email_address: action.payload.emailAddress})).pipe(
                mergeMap(res => of(testProvider.success(res))),
                catchError(err => of(testProvider.failure(err), errorHandler(err)))
            )));
}

export const fetchOrganisationSummariesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store,  services) => {
    return action$.pipe(
        filter(isActionOf(fetchOrganisationSummaries.request)),
        mergeMap(action =>
            from(services.apiClient!.get<Array<OrganisationSummary>>(`/organisations/summary`)).pipe(
                mergeMap(res => of(fetchOrganisationSummaries.success(res))),
                catchError(err => of(fetchOrganisationSummaries.failure(err), errorHandler(err)))
            )));
}

export const fetchHeatMapEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { bookItApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchHeatMap.request)),
        mergeMap(action =>
            bookItApi.fetchHeatMap(action.payload.organisationId, action.payload.startRange, action.payload.endRange, action.payload.location, action.payload.resourceType, action.payload.bookableResource, action.payload.excludeWeekends, action.payload.workingHoursOnly, action.payload.isActualTime).pipe(
                mergeMap(hal => of(fetchHeatMap.success(hal))),
                catchError(err => of(fetchHeatMap.failure(err), errorHandler(err)))
            )));
}

export const fetchSummaryEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { bookItApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchSummary.request)),
        mergeMap(action =>
            bookItApi.fetchSummary(action.payload.organisationId, action.payload.startRange, action.payload.endRange, action.payload.location, action.payload.resourceType, action.payload.bookableResource, action.payload.excludeWeekends, action.payload.workingHoursOnly).pipe(
                map(res => fetchSummary.success(res)),
                catchError(err => of(fetchSummary.failure(err), errorHandler(err)))
            )));
}

export const fetchBookingCountEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { bookItApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchBookingCount.request)),
        mergeMap(action =>
            bookItApi.fetchBookingCount(action.payload.organisationId, action.payload.startRange, action.payload.endRange, action.payload.location).pipe(
                map(res => fetchBookingCount.success(res)),
                catchError(err => of(fetchBookingCount.failure(err), errorHandler(err)))
            )));
}


export const fetchMinMedianMaxEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { bookItApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchMinMedianMax.request)),
        mergeMap(action =>
            bookItApi.fetchMinMedianMax(action.payload.organisationId, action.payload.startRange, action.payload.endRange, action.payload.resourceType, action.payload.location).pipe(
                map(res => fetchMinMedianMax.success(res)),
                catchError(err => of(fetchMinMedianMax.failure(err), errorHandler(err)))
            )));
}

export const fetchBookingStatsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { bookItApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchBookingStats.request)),
        mergeMap(action =>
            bookItApi.fetchBookingStats(action.payload.organisationId, action.payload.startRange, action.payload.endRange, action.payload.location, action.payload.resourceType, action.payload.bookableResource, action.payload.cancellationBefore, action.payload.excludeWeekends, action.payload.workingHoursOnly).pipe(
                map(res => fetchBookingStats.success(res)),
                catchError(err => of(fetchBookingStats.failure(err), errorHandler(err)))
            )));
}

export const fetchOrganiserBookingStatsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { bookItApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchOrganiserBookingStats.request)),
        mergeMap(action =>
            bookItApi.fetchOrganiserBookingStats(action.payload.organisationId, action.payload.startRange, action.payload.endRange, action.payload.location, action.payload.resourceType, action.payload.bookableResource, action.payload.organiser, action.payload.excludeWeekends, action.payload.workingHoursOnly).pipe(
                map(res => fetchOrganiserBookingStats.success({lastFetch: new Date() , startRange: action.payload.startRange, endRange: action.payload.endRange, location: action.payload.location, resourceType: action.payload.resourceType, bookableResource: action.payload.bookableResource, organiser: action.payload.organiser, excludeWeekends: action.payload.excludeWeekends, workingHoursOnly: action.payload.workingHoursOnly, organisationId: action.payload.organisationId, data: res})),

                catchError(err => of(fetchOrganiserBookingStats.failure(err), errorHandler(err)))
            )));
}
