import { Loader, Input, Button, AddIcon, Dialog, TrashCanIcon, Dropdown } from '@fluentui/react-northstar';
import { Operation } from 'fast-json-patch';
import React, { useCallback, useEffect, useState, useMemo} from 'react';
import { useDispatch } from 'react-redux';
import { fetchOrganisation, patchOrganisation } from '../../../store/resourceActions';
import { getActiveOrganisation, getOrganisation } from '../../../store/selectors';
import { useSelector } from '../../../store/utils';
import { UcSupportAssignment, UcSupportServer, UcServerGroup, UcSite } from '../../../model';
import { fetchUcSites } from '../../../store/resourceActions';

import UserSelector from './UserSelector'
import GroupAssignments from './GroupAssignments'
import ApplicationSelector from './ApplicationSelector'
import css from './SupportSettingsPage.module.scss';




const SupportSettingsPage: React.FC<{}> = () => {
    
    const [selectedMailbox, setSelectedMailbox] = useState<string>() 

    // =================================================================
    // Load organisation, UC Support settings and UC Sites
    // =================================================================
    const dispatch = useDispatch()
    const activeOrganisation = useSelector(getActiveOrganisation)
    const organisation = useSelector(s => getOrganisation(s, activeOrganisation.organisation_id))

    useEffect(() => {
        if (activeOrganisation) {
            dispatch(fetchUcSites.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch])

    const { resources } = useSelector(s => s.uc.sites)
    const sites = useMemo(() => Object.values(resources), [resources])

    useEffect(() => {
        if (organisation === undefined) {
            dispatch(fetchOrganisation.request({ organisation_id: activeOrganisation.organisation_id }))            
        }
    }, [activeOrganisation.organisation_id, dispatch, organisation])

    const allApplications = () => {

        if (organisation !== undefined && organisation.uc_support_settings !== undefined) {
            return organisation.uc_support_settings.applications ?? []
        } else {
            return []
        }
    }

    // =================================================================
    // Add Mailbox
    // =================================================================
    const [mailboxToAdd, setMailboxToAdd] = useState<string>()
    const [addMailboxIsOpen, setAddMailboxIsOpen] = useState(false)
    const addMailboxButton = <Button primary content='Add Mailbox' 
                                     icon={<AddIcon />} 
                                     iconPosition='before'                                      
                                     className={css.menuButton} />

    const addMailbox = <Dialog header='Add mailbox' open={addMailboxIsOpen} 
        confirmButton="Add"
        cancelButton="Cancel"
        onConfirm={(e, p) => {
            handleAddMailbox()
        }}
        trigger={addMailboxButton}
        onOpen={() => setAddMailboxIsOpen(true)}
        onCancel={e => setAddMailboxIsOpen(false)}
        content={<Input fluid onChange={(event, data) => {
            if (data && data.value) {
                setMailboxToAdd(data.value as string)
            }
        }} />}
    />

    const handleAddMailbox = useCallback(() => {
        
        setAddMailboxIsOpen(false)

        if (organisation !== undefined && mailboxToAdd !== undefined && mailboxToAdd.trim() !== '') {
            
            let settings = {...organisation.uc_support_settings}
            settings.mailboxes?.push(mailboxToAdd)
        
            const patch: Operation[] = [{
                op: 'replace',
                value: settings,
                path: '/uc_support_settings'
            }]

            dispatch(patchOrganisation.request({ id: { organisation_id: activeOrganisation.organisation_id }, operations: patch }))
        }

    }, [activeOrganisation.organisation_id, dispatch, mailboxToAdd, organisation])

    // =================================================================
    // Remove Mailbox
    // =================================================================
    const [mailboxToRemove, setMailboxToRemove] = useState<string>()
    const [removeMailboxIsOpen, setRemoveMailboxIsOpen] = useState(false)
    const removeMailboxButton = <Button primary content='Remove Mailbox' icon={<TrashCanIcon />} iconPosition='before' className={css.menuButton} />

    const removeMailbox = <Dialog header='Remove mailbox' open={removeMailboxIsOpen} 
        confirmButton="Remove"
        cancelButton="Cancel"
        onConfirm={(e, p) => {
            handleRemoveMailbox()
        }}
        trigger={removeMailboxButton}
        onOpen={() => setRemoveMailboxIsOpen(true)}
        onCancel={e => setRemoveMailboxIsOpen(false)}
        content={<Dropdown items={getAllMailboxes()} 
            placeholder="Select a mailbox" 
            onChange={(e, d) => setMailboxToRemove(d.value as string)}
            fluid={true}                        
        />}
    />

    const handleRemoveMailbox = useCallback(() => {
        
        setRemoveMailboxIsOpen(false)

        console.log('UC: handleRemoveMailbox', mailboxToRemove);
        
        if (organisation !== undefined && mailboxToRemove !== undefined) {
            
            // Remove from the base list
            let settings = {...organisation.uc_support_settings}
            
            console.log('UC: handleRemoveMailbox settings before', settings);

            settings.mailboxes = settings.mailboxes?.filter(mb => mb.toLowerCase() !== mailboxToRemove.toLowerCase())
        
            // Remove any assignments
            if (settings.assignments !== undefined) {
                settings.assignments = settings.assignments.filter(item => item.mailbox.toLowerCase() !== mailboxToRemove.toLowerCase())
            }

            console.log('UC: handleRemoveMailbox settings after', settings);

            // Last one or the removed mailbox is the current selection?
            if (settings.mailboxes === undefined || settings.mailboxes.length === 0) {
                setSelectedMailbox(undefined)
            }

            if (selectedMailbox === mailboxToRemove) {
                setSelectedMailbox(undefined)
            }

            const patch: Operation[] = [{
                op: 'replace',
                value: settings,
                path: '/uc_support_settings'
            }]

            dispatch(patchOrganisation.request({ id: { organisation_id: activeOrganisation.organisation_id }, operations: patch }))
        }

    }, [activeOrganisation.organisation_id, dispatch, mailboxToRemove, organisation, selectedMailbox])
  
    // // =================================================================
    // Add Application
    // =================================================================
    const [applicationToAdd, setApplicationToAdd] = useState<string>()
    const [addApplicationIsOpen, setAddApplicationIsOpen] = useState(false)
    const addApplicationButton = <Button primary content='Add Application' icon={<AddIcon />} iconPosition='before' className={css.menuButton} />

    const addApplicationContent = 
        <div className={css.verticalContainer}>
            <div>Add one or more application names separated by a pipe (vertical bar) character.</div>
            <Input fluid onChange={(event, data) => {
                if (data && data.value) {
                    setApplicationToAdd(data.value as string)
                }
            }} />
    </div>  

    const addApplication = <Dialog header='Add applications' open={addApplicationIsOpen} 
        confirmButton="Add"
        cancelButton="Cancel"
        onConfirm={(e, p) => {
            handlAddApplication()
        }}
        trigger={addApplicationButton}
        onOpen={() => setAddApplicationIsOpen(true)}
        onCancel={e => setAddApplicationIsOpen(false)}
        content={addApplicationContent}
    />

    const handlAddApplication = useCallback(() => {
        
        setAddApplicationIsOpen(false)

        if (organisation !== undefined && applicationToAdd !== undefined) {
            
            let settings = {...organisation.uc_support_settings}
            let added = 0;
            const appArray = applicationToAdd.split('|')
            
            appArray.forEach(app => {
                if (app != null && app.trim() !== '') {
                    if (!settings.applications?.some(exist => exist.toLowerCase() === app.toLowerCase())) {  
                        settings.applications?.push(app.trim())
                        added++
                    }
                }
            })

            if (added > 0) {
                const patch: Operation[] = [{
                    op: 'replace',
                    value: settings,
                    path: '/uc_support_settings'
                }]

                dispatch(patchOrganisation.request({ id: { organisation_id: activeOrganisation.organisation_id }, operations: patch }))
            }

        }
    },[activeOrganisation.organisation_id, applicationToAdd, dispatch, organisation])
  
    // =================================================================
    // Remove Application
    // =================================================================
    const [applicationToRemove, setApplicationToRemove] = useState<string>()
    const [removeApplicationIsOpen, setRemoveApplicationIsOpen] = useState(false)
    const removeApplicationButton = <Button primary content='Remove Application' icon={<TrashCanIcon />} iconPosition='before' className={css.menuButton} />

    const removeApplication = <Dialog header='Remove mailbox' open={removeApplicationIsOpen} 
        confirmButton="Remove"
        cancelButton="Cancel"
        onConfirm={(e, p) => {
            handleRemoveApplication()
        }}
        trigger={removeApplicationButton}
        onOpen={() => setRemoveApplicationIsOpen(true)}
        onCancel={e => setRemoveApplicationIsOpen(false)}
        content={<Dropdown items={allApplications()} 
            placeholder="Select an application" 
            onChange={(e, d) => setApplicationToRemove(d.value as string)}
            fluid={true}                        
        />}
    />

   
    const handleRemoveApplication = useCallback(() => {
        
        setRemoveApplicationIsOpen(false)

        if (organisation !== undefined && organisation.uc_support_settings !== undefined && applicationToRemove !== undefined) {
            
            let settings = {...organisation.uc_support_settings}
                        
            // Remove from base reference list
            settings.applications = settings.applications!.filter(app => app.toLowerCase() !== applicationToRemove.toLowerCase())
            
            // Remove from all server/group assignments
            if (settings.assignments !== undefined) {
               settings.assignments.forEach(assignment => {
                    if(assignment.groups !== undefined){
                        assignment.groups = assignment.groups.filter(app => app.name.toLowerCase() !== applicationToRemove.toLowerCase())
                    }
                    // Remove from all server/application assignments
                    if (assignment.servers !== undefined) {
                        assignment.servers.forEach(server => {
                            if (server !== undefined) {
                                server.applications = server.applications.filter(app => app.name.toLowerCase() !== applicationToRemove.toLowerCase())
                            }
                        })
                    }
                })
            }

            const patch: Operation[] = [{
                op: 'replace',
                value: settings,
                path: '/uc_support_settings'
            }]

            dispatch(patchOrganisation.request({ id: { organisation_id: activeOrganisation.organisation_id }, operations: patch }))
        }

    }, [activeOrganisation.organisation_id, dispatch, applicationToRemove, organisation])
  
    
    // =================================================================
    // Add or Remove an application from the supplied group. Add=enabled.
    // =================================================================
    const handleAddRemoveGroupApplication = useCallback((groupName: string, applicationName: string, enabled: boolean) => {
        
        if (organisation === undefined) {
            return
        }
        
        console.log('UC:handleAddRemoveGroupApplication', {groupName, applicationName, enabled});

        // Get the mailbox's assignment root (UcSupportAssignment)
        let settings = {...organisation.uc_support_settings}
        let mailboxAssignment = settings.assignments?.find(item => item.mailbox.toLowerCase() === selectedMailbox?.toLowerCase())
        
        console.log('UC:handleAddRemoveGroupApplication', mailboxAssignment);

        if (mailboxAssignment === undefined && enabled) {

            // No current assignments: create one.
            // No need to be concerned about wildcards for this case
            
            mailboxAssignment = {
                mailbox: selectedMailbox!,
                servers: [],
                groups: []
            }

            const serverGroup: UcServerGroup = {
                name: groupName,
                applications: [{name:applicationName}]
            }
            
            mailboxAssignment.groups!.push(serverGroup)
            settings.assignments!.push(mailboxAssignment)

        } else if (mailboxAssignment !== undefined) {

            const serverGroup = mailboxAssignment.groups!.find(s => s.name.toLowerCase() === groupName.toLowerCase())

            if (serverGroup === undefined) {
                
                // No current assignments for this group: create one.
                // If the group is wild, then all other groups get removed first
                if (groupName === '*') {
                    mailboxAssignment.groups = []
                }
                
                console.log('UC:handleAddRemoveGroupApplication: new group');

                const newServerGroup:UcServerGroup = {
                    name: groupName,
                    applications: [{name:applicationName}]
                }
                
                mailboxAssignment.groups!.push(newServerGroup)

            } else {
                if (enabled) {
                
                    // Add the app to the group (wildcard is ok)   
                    serverGroup.applications.push({name:applicationName})
                    console.log('UC:handleAddRemoveGroupApplication: add server');
            
                } else {
                    // remove the app from the group
                    serverGroup.applications = serverGroup.applications.filter(s => s.name.toLowerCase() !== applicationName.toLowerCase())

                    // remove any empty group applications
                    if (mailboxAssignment.groups !== undefined) {
                        mailboxAssignment.groups = mailboxAssignment.groups.filter(s => s.applications.length > 0);
                    }

                    console.log('UC:handleAddRemoveGroupApplication: remove server');

                }
            }
        }

        const patch: Operation[] = [{
            op: 'replace',
            value: settings,
            path: '/uc_support_settings'
        }]

        dispatch(patchOrganisation.request({ id: { organisation_id: activeOrganisation.organisation_id }, operations: patch }))

    },[activeOrganisation.organisation_id, dispatch, organisation, selectedMailbox])


        // =================================================================
    // Add or Remove an application from the supplied customer.server. Add=enabled.
    // =================================================================
    const handleAddRemoveServerApplication = useCallback((customerId: string, serverSite: UcSite | undefined, applicationName: string, enabled: boolean) => {
        
        if (organisation === undefined) {
            return
        }
        
        if (serverSite === undefined) {
            return
        }

        console.log('UC:handleAddRemoveServerApplication', {serverSite, applicationName, enabled});

        // Get the mailbox's assignment root (UcSupportAssignment)
        let settings = {...organisation.uc_support_settings}
        let mailboxAssignment = settings.assignments?.find(item => item.mailbox.toLowerCase() === selectedMailbox?.toLowerCase())
        const siteId = serverSite.site_id

        if (siteId === undefined) {
            return
        }

        console.log('UC:handleAddRemoveServerApplication', mailboxAssignment);

        if (mailboxAssignment === undefined && enabled) {

            // No current assignments: create one
            
            mailboxAssignment = {
                mailbox: selectedMailbox!,
                servers: [],
                groups: []
            }

            const server: UcSupportServer = {
                site_id: siteId,
                name: serverSite.server_name,
                applications: [{name:applicationName}]
            }
            
            mailboxAssignment.servers!.push(server)
            settings.assignments!.push(mailboxAssignment)

        } else if (mailboxAssignment !== undefined) {

            const server = mailboxAssignment.servers!.find(s => s.name.toLowerCase() === serverSite.server_name.toLowerCase())

            if (server === undefined) {
                
                // No current assignments for this group: create one
                console.log('UC:handleAddRemoveServerApplication: new server');

                const newServer:UcSupportServer = {
                    site_id: siteId,
                    name: serverSite.server_name,
                    applications: [{name:applicationName}]
                }
                
                mailboxAssignment.servers!.push(newServer)

            } else {
                if (enabled) {
                    // Add the app to the group    
                    server.applications.push({name:applicationName})
                    console.log('UC:handleAddRemoveServerApplication: add server');

            
                } else {
                    // remove the app from the group
                    server.applications = server.applications.filter(s => s.name.toLowerCase() !== applicationName.toLowerCase())
                    
                    // remove any empty server applications
                    if (mailboxAssignment.servers !== undefined) {
                        mailboxAssignment.servers = mailboxAssignment.servers.filter(s => s.applications.length > 0);
                    }

                    console.log('UC:handleAddRemoveServerApplication: remove server');

                }
            }
        }

        const patch: Operation[] = [{
            op: 'replace',
            value: settings,
            path: '/uc_support_settings'
        }]

        dispatch(patchOrganisation.request({ id: { organisation_id: activeOrganisation.organisation_id }, operations: patch }))

    },[activeOrganisation.organisation_id, dispatch, organisation, selectedMailbox])


    // =================================================================
    // Select a support mailbox 
    // =================================================================
    const handleMailboxSelection = useCallback(mailbox => {
        console.log('uc: SupportSettings UserSelection callback', mailbox)   
        setSelectedMailbox(mailbox)     
    },[])

    // =================================================================
    // =================================================================
    function checkedAllApplicationServers() {
        
            const assignment = getCurrentMailboxAssignment()

            if (assignment === undefined) {
                return []
            }
            
            if (assignment?.servers === undefined) {
                return []
            }

            console.log('UC: checkedAllApplicationServers assignment', assignment);

            const servers = assignment?.servers.filter(s => s.applications.some(a => a.name === '*'))

            console.log('UC: checkedAllApplicationServers assignment', servers);
            const serverList =  servers?.map(s => s.name)
            console.log('UC: checkedAllApplicationServers', serverList);            
            return serverList
       
    }
    
    // =================================================================
    // =================================================================
    function getServerApplicationsForCurrentMailbox() {
        
        const assignment = getCurrentMailboxAssignment()

        if (assignment === undefined) {
            return []
        }

        if (assignment.servers === undefined) {
            return []
        }

        return assignment.servers

    }

    function getGroupAssignmentsForCurrentMailbox() {
        
        const assignment = getCurrentMailboxAssignment()

        if (assignment === undefined) {
            return []
        }

        if (assignment.groups === undefined) {
            return []
        }

        return assignment.groups;

    }

    // =================================================================
    // =================================================================
    function getCurrentMailboxAssignment() : UcSupportAssignment | void {
        if (organisation?.uc_support_settings !== undefined) {
            console.log('UC: getCurrentMailboxAssignment selectedMailbox', selectedMailbox);
            console.log('UC: getCurrentMailboxAssignment assignments', organisation.uc_support_settings.assignments);
            return organisation.uc_support_settings.assignments?.find(item => item.mailbox.toLowerCase() === selectedMailbox?.toLowerCase())
        }
    }

    function getAllMailboxes() {
        console.log('UC: getAllMailboxes', organisation);
        
        if (organisation !== undefined && organisation.uc_support_settings !== undefined) {
            const mailboxes =  organisation.uc_support_settings.mailboxes ?? []
            
            if (mailboxes.length > 1) {
                mailboxes.sort((a,b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
            }

            return mailboxes
        } else {
            return []
        }

    }

    


    // =================================================================
    // =================================================================
    function getAllServers() {
        return sites.map(s => s.server_name)
    }

    // =================================================================
    // =================================================================
    function hasMailboxAnyActiveAssignments(mailbox: string) {
        return true
    }

  

    return <>
        
        <h5>Organisation Support Settings</h5>            
        
        {organisation !== undefined && sites !== undefined &&
            <div className={css.supportSettings}>     
                <UserSelector 
                        mailboxes={getAllMailboxes()} 
                        onUserSelection={handleMailboxSelection} />

                {selectedMailbox !== undefined && <GroupAssignments 
                        group_assignments={getGroupAssignmentsForCurrentMailbox()}
                        all_applications={allApplications()}
                        sites={sites}
                        onChange={handleAddRemoveGroupApplication} />} 

                {selectedMailbox !== undefined && <ApplicationSelector 
                         server_applications={getServerApplicationsForCurrentMailbox()}
                         all_applications={organisation.uc_support_settings?.applications}
                         all_servers={getAllServers()}
                         sites={sites}
                         onChange={handleAddRemoveServerApplication} />}
    
                <div className={css.menuBar} >
                    {addMailbox}                                       
                    {addApplication} 
                    {removeMailbox}
                    {removeApplication}                  
                </div>

            </div>
        }
        
        {organisation === undefined && <Loader/>}
        {/* {organisation !== undefined && <BookItSettingsPage onSave={handleSubmit} source={organisation.book_it_settings ?? bookItSettingsDefaults}/> */}

    </>
}

export default SupportSettingsPage;
