import React, { useState } from 'react';
import css from './SupportSettingsPage.module.scss';
import { UcSupportServer, UcSite } from '../../../model';
import { Dropdown, Checkbox, DropdownItemProps } from "@fluentui/react-northstar";

type ApplicationSelectorProps = ({
    server_applications: UcSupportServer[]
    all_servers: string[]
    all_applications?: string[]
    sites: UcSite[]
    onChange: (customerId: string, server: UcSite | undefined, application: string, enabled: boolean) => void
})

interface UcCustomer {
    id: string
    name: string
    header: string
}

interface UcServer {
    siteId: string
    name: string
    header: string
}

function ApplicationSelector({server_applications, all_servers, all_applications, sites, onChange}: ApplicationSelectorProps) {

    const [selectedServer, setSelectedServer] = useState<UcServer>()
    const [selectedCustomer, setSelectedCustomer] = useState<UcCustomer>()
    
    function getAllApplications() {       
        
        if (all_applications !== undefined && all_applications.length > 1) {
            all_applications.sort((a,b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
        }
        return ["All", ...all_applications ?? []]
    }

    const allCustomers = () => {
        
        const customers:UcCustomer[] = []

        sites.forEach(site => {
            if (!customers.some(c => c.id === site.customer_id)) {
                customers.push({id: site.customer_id, name: site.customer_name, header: site.customer_id})
            }
        })

        return customers.sort((a,b) => a.header.localeCompare(b.header, undefined, {sensitivity: 'base'}))
    }

    const allServersForCustomer = () => {

        console.log('allServersForCustomer', selectedCustomer);
        
        if (sites === undefined) {
            return []
        }

        if (selectedCustomer === undefined) {
            return []
        }

        const servers:UcServer[] = []

        sites.forEach(site => {
            if (site.customer_id === selectedCustomer?.id) {
                servers.push({siteId: site.site_id, name: site.server_name, header: site.server_name})
            }
        })

        servers.sort((a,b) => a.header.localeCompare(b.header, undefined, {sensitivity: 'base'}))
        servers.splice(0, 0, {header:'All', name: '*',  siteId: '*'})
        return servers
        

    }

    const handleCheckedChange = (id: number, enabled: boolean) => {

        if (getAllApplications() !== undefined) {
            const selectedApp = getAllApplications()[id]
            console.log('uc: Server handleSubChange', {id, enabled, selectedServer});  
            if (selectedServer !== undefined) {
                onChange('customerid', findCurrentSiteOrWildcard(), selectedApp === 'All' ? '*' : selectedApp, enabled)
            }
        }

    }

    function findCurrentSiteOrWildcard() {        

        if (selectedServer !== undefined && selectedCustomer !== undefined) {
            if (selectedServer.siteId === '*') {
               let wildcardSite = sites[0]
               wildcardSite.site_id = '*'
               wildcardSite.server_name = '*'
               return wildcardSite
            } else {            
                return sites.find(site => site.site_id === selectedServer.siteId)
            }
        }

        return
    }

    const isAnyAppEnabled = (server: string) => {

        if (server_applications === undefined) {
            return false
        } 
                
        if (server === undefined) {
            return false
        }
        
        return server_applications.some(serv => serv.name.toLowerCase() === server.toLowerCase() && serv.applications.some(app => app.name !== '*'))

    }

    function isAppEnabled(app: string) {
        
        console.log('UC: ApplicationSelector.isAppEnabled Start', {server_applications, app, selectedServer});

        if (server_applications === undefined) {
            return false
        } 
                
        if (app === undefined) {
            return false
        }
        
        if (selectedServer === undefined) {
            return false
        }

        const server = server_applications.find(s => s.name.toLowerCase() === selectedServer.name.toLowerCase())
        
        if (server === undefined) {
            console.log('UC: ApplicationSelector.isAppEnabled - Failed to find server', {app, server});
            return false
        }

        console.log('UC: ApplicationSelector.isAppEnabled', {app, server});
        

        return server.applications.some(sa => sa.name.toLowerCase() === app.toLowerCase() || (sa.name === '*' && app === 'All'))

    }

    type RenderItem = {
        Item: any;
        props: DropdownItemProps;
        isActive: boolean
      };

    const renderItem = React.useCallback((args: RenderItem) => getCustomItem({ ...args }), []);

   

    return <>
        <div className={css.selectorCard}>
            <h5>Applications by Customer Server</h5>
            <div className={css.infoText}>Only selected applications for each selected server will be assigned to the current mailbox.</div>
            
            <Dropdown className={css.dropDown} 
                    value={selectedCustomer}
                    items={allCustomers()} 
                    placeholder="Select a customer" 
                    onChange={(e, d) => setSelectedCustomer(d.value as UcCustomer)}
                    fluid={true} 
                    
            />


            <Dropdown className={css.dropDown} 
                disabled={selectedCustomer === undefined}                
                value={selectedServer}
                items={allServersForCustomer()} 
                placeholder="Select a server" 
                onChange={(e, d) => setSelectedServer(d.value as UcServer)}
                fluid={true} 
                renderItem={
                    (Item, props) => renderItem({ Item, props, isActive:isAnyAppEnabled(props.header as string)})
                }        
            />

            {selectedServer !== undefined && 
            <table className={css.table}>
                <thead>
                    <tr>
                        <th>Selected</th>
                        <th>Application name</th>
                    </tr>
                </thead>
                <tbody>
                    {getAllApplications()?.map((app, idx) => <SubRow id={Number(idx)} value={app}
                            onSubChange={handleCheckedChange}
                            enabled={isAppEnabled(app)} />)}
                </tbody>
            </table>}
        </div>
    </>
}

interface SubRowProps {
    id: number
    enabled: boolean
    value: string
    onSubChange: (id: number, enabled: boolean) => void
}


function SubRow({ id, enabled, value, onSubChange }: SubRowProps) {
    return <tr>
        <td>
            <Checkbox checked={enabled} onChange={(_e, d) => {
                if (d && d.checked !== undefined) {
                    onSubChange(id, d.checked)
                }
            }} />
        </td>
        <td>{value}</td>
    </tr >
}

const getCustomItem = (args: {Item: any, props: DropdownItemProps, isActive: boolean }) => {

    const { Item, props, isActive } = args;
    const { header, ...rest } = props;
    
    console.log('UC:getCustomItem', args);
    
    if (!header || typeof header !== 'string') return <Item {...props} />;

    return (
      <Item
        header={<span className={isActive ? css.highlight : ''}>{header}</span>}
        {...rest}
      />
    );
  };

  
 
export default ApplicationSelector;