import { compose } from 'redux';
import { RootState } from './types';
import { useSelector as unsafeUseSelector } from "react-redux";

export const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export function useSelector<TSelected>(selector: (state: RootState) => TSelected) {
  return unsafeUseSelector(selector)
}
