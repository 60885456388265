import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { UcSite } from "../../../model";
import { generateId, ucSitesReducer } from "../../resourceActions";
import { Actions, actions } from "../../types";
import { ApiResourceState, defaultState } from "../ApiResourceState";

function sites(state: ApiResourceState<UcSite> = defaultState, action: Actions): ApiResourceState<UcSite> {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState

        case getType(actions.patchUcSiteExclusions.success):
            const key = generateId(action.payload.organisation_id, action.payload.site_id)
            return {...state, resources: {...state.resources, [key]: {...state.resources[key], exclusions: action.payload.exclusions} } }
        default:
            return ucSitesReducer(state, action);
    }
}

export default combineReducers({
    sites
})
