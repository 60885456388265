import { HTTPError } from "traverson";
import { ProblemReport } from "../model";

export function getErrorMessageFromException(error: Error | HTTPError) {
    if (instanceOfHTTPError(error)) {
        if (error.doc !== undefined) {
            const report = error.doc as ProblemReport;
            if (report !== undefined) {
                console.error('API Error:', report);
                return report.title
            }
        }
    }
    return `An unknown error occurred: ${error.message}`
}

export async function getApiError(error: Error | HTTPError | FetchError | AuthError | ApiError | ApiError2): Promise<ApiError> {

    if (instanceOfApiError(error)) {
        console.log('instanceOfApiError true')
        return error;
    }

    // V2 api errors
    if (instanceOfApiError2(error)) {
        console.log('instanceOfApiError2 true')
        return { report: error.apiError, causedBy: error, message: error.message, statusCode: error.status };
    }

    if (instanceOfHTTPError(error)) {
        console.log('instanceOfHTTPError true')
        if (error.doc !== undefined) {
            const report = error.doc as ProblemReport;
            if (report !== undefined) {
                console.error('API Error:', report);
                return { message: report.title, report, causedBy: error, statusCode: report.status }
            }
        }
    }

    if (instanceOfAuthError(error)) {
        console.log('instanceOfAuthError true')
        return { message: "STS Authorisation Error", stsAuthError: error.stsError, causedBy: error, statusCode: error.response.status }

    }

    if (instanceOfFetchError(error)) {
        console.log(`instanceOfFetchError true (url: ${error.response.url})`)
        if (error.response !== undefined) {
            const json = await getResponseJson(error.response)
            console.log("fetch error json: ", json)
            if (isProblemReport(json)) {
                console.warn('API Error:', json);
                return { message: json.title, report: json, causedBy: error, statusCode: json.status }
            }
            return { message: error.response.statusText, statusCode: error.response.status, causedBy: error }

        }
    }

    console.error('Unknown error received:', error)
    return { message: `An unknown error occurred: ${error.message}`, causedBy: error };
}


function instanceOfHTTPError(object: any): object is HTTPError {
    return (typeof object === 'object') && 'httpStatus' in object;
}

export function instanceOfApiError(object: any): object is ApiError {
    return (typeof object === 'object') && 'causedBy' in object && 'message' in object
}

export function instanceOfAuthError(object: any): object is AuthError {
    return (typeof object === 'object') && 'response' in object && 'stsError' in object
}

function instanceOfFetchError(object: any): object is FetchError {
    return (typeof object === 'object') && 'response' in object;
}

export function instanceOfApiError2(object: any): object is ApiError2 {
    return (typeof object === 'object') && 'apiError' in object && 'message' in object
}

export function isProblemReport(object: any): object is ProblemReport {
    return (typeof object === 'object') && 'status' in object && 'title' in object
}

export function isStsAuthError(object: any): object is StsAuthError {
    return (typeof object === 'object') && 'error' in object && 'error_description' in object
}

export async function getResponseJson(response: Response) {
    console.log('Response', response)
    if (response.body && !response.bodyUsed) {
        const contentLen = response.headers.get('Content-Length')
        console.log('content-length and response.body', contentLen, response.body)

        // if (response.body && contentLen && contentLen !== '0') {
        //     const reader = await response.body.getReader()
        //     console.log('response.body after getReader', response.body)
        // }
        return (await response.json())
    }
    return {}
}

export interface StsAuthError {
    error: string
    error_description: string
}
export interface ApiError {
    message: string
    report?: ProblemReport
    stsAuthError?: StsAuthError
    statusCode?: number
    causedBy: Error
}

export interface ApiError2 extends Error {
    message: string
    apiError: ProblemReport
    status?: number
}

export class FetchError extends Error {
    public response: Response;
    constructor(response: Response) {
        super(`Fetch failed: ${response.statusText}`)
        this.response = response
    }
}

export class AuthError extends Error {
    public response: Response;
    public stsError: StsAuthError;
    constructor(stsError: StsAuthError, response: Response) {
        super(`Auth failed: ${stsError.error} - ${response.statusText}`)
        this.response = response
        this.stsError = stsError
    }
}

