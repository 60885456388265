import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { OfficeLocation } from "../../model";
import { officeLocationsReducer } from "../resourceActions";
import { actions, Actions } from "../types";
import { ApiResourceState, defaultState } from "./ApiResourceState";


type OfficeLocationsState = ApiResourceState<OfficeLocation>
function locations(state: OfficeLocationsState = defaultState, action: Actions): OfficeLocationsState {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return officeLocationsReducer(state, action);
    }
}


const officeLocations = combineReducers({
    locations,
})

export default officeLocations