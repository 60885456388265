import { Operation } from "fast-json-patch";
import moment from 'moment';
import { Observable } from "rxjs";
import { mergeMap, shareReplay } from "rxjs/operators";
import traverson from "traverson";
import { BookingCount, BookingStats, BookItAttribute, HeatMap, IdType, MinMedianMax, OrganiserBookingStats, PeriodSummary } from "../model";
import { halArrayGet, halGet, getBlob, halGetUrl, halPatch, HalResource, HalResult, post } from "./Hal";
import HalApi from "./HalApi";

export class BookItApi implements HalApi {

    private serverApiRoot: string;
    private root?: Observable<HalResult<{}>>;

    constructor(serverApiRoot: string) {

        console.log("ServerApiRoot: ", serverApiRoot);
        if (serverApiRoot === null) {
            throw new Error("Config.ServerUrl is null");
        }
        this.serverApiRoot = serverApiRoot + "/bookit";
    }

    public fetchRoot() {
        if (this.root !== undefined) {
            return this.root;
        }
        this.root = halGet<{}>(
            "fetchBookItRoot",
            traverson.from(this.serverApiRoot).jsonHal()
        ).pipe(shareReplay(1));
        return this.root;
    }

    public fetchAttribute(id: IdType) {
        return this.fetchRoot().pipe(
            mergeMap(root =>
                halGet<BookItAttribute>(
                    "fetchBookItAttribute",
                    root
                        .traversal!.continue()
                        .newRequest()
                        .follow("bookit_attribute")
                        .withTemplateParameters({ id: id })
                )
            )
        );
    }

    public fetchAttributes() {
        return this.fetchRoot().pipe(
            mergeMap(root =>
                halArrayGet<BookItAttribute>(
                    "fetchBookItAttribute",
                    root
                        .traversal!.continue()
                        .newRequest()
                        .follow("bookit_attributes")
                        .follow("bookit_attribute[$all]")
                )
            )
        );
    }

  
    public createAttribute(name: string, file: File) {
        const data = new FormData();
        data.append("file", file);
        console.log("createAttribute ", name)

        return this.fetchRoot().pipe(
            mergeMap(root =>
                halGetUrl(
                    "createBookItAttribute(GETURL)",
                    root
                        .traversal!.continue()
                        .newRequest()
                        .follow("bookit_create_attribute")
                        .withTemplateParameters({ name })
                )
            ),
            mergeMap(createUrl =>
                post<HalResource<BookItAttribute>>(createUrl, data, {})
            )
        );
    }

    public patchAttribute(id: IdType, operations: Operation[]) {
        return this.fetchRoot().pipe(
            mergeMap(root =>
                halPatch<BookItAttribute>(
                    "patchBookItAttribute",
                    root
                        .traversal!.continue()
                        .newRequest()
                        .follow("bookit_attribute")
                        .withTemplateParameters({ id }),
                    operations
                )
            )
        );
    }

    public fetchHeatMapDataCsv(organisationId: IdType, startRange: Date, endRange: Date, location: string, 
        resourceType: string, bookableResource: string, excludeWeekends: boolean, workingHoursOnly: boolean, 
        isActualTime: boolean) {
        
        let utcOffset = moment(startRange).add(12, 'hour').utcOffset() //check at midday incase daylightsavings changes on this day

        return this.fetchRoot().pipe(
            mergeMap(root => 
                halGetUrl("fetchHeatMapDataCsv",
                root
                    .traversal!
                    .continue()
                    .newRequest()
                    .follow("bookit_report_room_utilisation_data_csv")
                    .withTemplateParameters({ organisationId, 
                                                StartPeriod: startRange.toISOString(), 
                                                EndPeriod: endRange.toISOString(), 
                                                Location: location, 
                                                ResourceType: resourceType, 
                                                BookableResource: bookableResource, 
                                                ExcludeWeekends: excludeWeekends, 
                                                WorkingHoursOnly: workingHoursOnly, 
                                                UTCOffset: utcOffset, 
                                                IsActualTime: isActualTime }))
            ),
            mergeMap(url => getBlob(url))
            
        ).toPromise();
          
    }

    public fetchHeatMap(organisationId: IdType, startRange: Date, endRange: Date, location: string, resourceType: string, bookableResource: string, excludeWeekends: boolean, workingHoursOnly: boolean, isActualTime: boolean) {
        let utcOffset = moment(startRange).add(12, 'hour').utcOffset() //check at midday incase daylightsavings changes on this day
        return this.fetchRoot().pipe(
            mergeMap(root =>
                halGet<HeatMap>(
                    "fetchHeatMap",
                    root
                        .traversal!.continue()
                        .newRequest()
                        .follow("bookit_report_room_utilisation")
                        .withTemplateParameters({ organisationId, StartPeriod: startRange.toISOString(), EndPeriod: endRange.toISOString(), Location: location, ResourceType: resourceType, BookableResource: bookableResource, ExcludeWeekends: excludeWeekends, WorkingHoursOnly: workingHoursOnly, UTCOffset: utcOffset, IsActualTime: isActualTime }))
            )
        );
    }

    public fetchSummary(organisationId: IdType, startRange: Date, endRange: Date, 
                        location: string, resourceType: string, bookableResource: string, 
                        excludeWeekends: boolean, workingHoursOnly: boolean) {
        let utcOffset = moment(startRange).add(12, 'hour').utcOffset() //check at midday incase daylightsavings changes on this day
        return this.fetchRoot().pipe(
            mergeMap(root =>
                halArrayGet<PeriodSummary>(
                    "fetchSummary",
                    root
                        .traversal!.continue()
                        .newRequest()
                        .follow("bookit_report_summary")
                        .withTemplateParameters({ organisationId, StartPeriod: startRange.toISOString(), EndPeriod: endRange.toISOString(), Location: location, ResourceType: resourceType, BookableResource: bookableResource, ExcludeWeekends: excludeWeekends, WorkingHoursOnly: workingHoursOnly, UTCOffset: utcOffset }))
            )
        );
    }

    public fetchSummaryDataCsv(organisationId: IdType, startRange: Date, endRange: Date, 
                                location: string, resourceType: string, bookableResource: string, 
                                excludeWeekends: boolean, workingHoursOnly: boolean) {
        
        let utcOffset = moment(startRange).add(12, 'hour').utcOffset() //check at midday incase daylightsavings changes on this day
                
        return this.fetchRoot().pipe(
            mergeMap(root => 
                halGetUrl("fetchSummaryDataCsv",
                root
                    .traversal!
                    .continue()
                    .newRequest()
                    .follow("bookit_report_summary_data_csv")
                    .withTemplateParameters({ organisationId, 
                                                StartPeriod: startRange.toISOString(), 
                                                EndPeriod: endRange.toISOString(), 
                                                Location: location, 
                                                ResourceType: resourceType, 
                                                BookableResource: bookableResource, 
                                                ExcludeWeekends: excludeWeekends, 
                                                WorkingHoursOnly: workingHoursOnly, 
                                                UTCOffset: utcOffset }))
            ),
            mergeMap(url => getBlob(url))
            
        ).toPromise();
                  
    }


    public fetchBookingCount(organisationId: IdType, startRange: Date, endRange: Date, location:string) {
        return this.fetchRoot().pipe(
            mergeMap(root =>
                halArrayGet<BookingCount>(
                    "fetchBookingCount",
                    root
                        .traversal!.continue()
                        .newRequest()
                        .follow("bookit_report_booking_count")
                        .withTemplateParameters({ organisationId, StartPeriod: startRange.toISOString(), EndPeriod: endRange.toISOString(), Location:location }))
            )
        );
    }


    public fetchMinMedianMax(organisationId: IdType, startRange: Date, endRange: Date, resourceType: string, location: string,) {
        return this.fetchRoot().pipe(
            mergeMap(root =>
                halArrayGet<MinMedianMax>(
                    "fetchMinMedianMax",
                    root
                        .traversal!.continue()
                        .newRequest()
                        .follow("bookit_report_room_min_median_max")
                        .withTemplateParameters({ organisationId, StartPeriod: startRange.toISOString(), EndPeriod: endRange.toISOString(), ResourceType: resourceType, Location: location  }))
            )
        );
    }

    public fetchBookingStats(organisationId: IdType, startRange: Date, endRange: Date, location: string, resourceType: string, bookableResource: string, cancellationBefore: number, excludeWeekends: boolean, workingHoursOnly: boolean) {
        let utcOffset = moment(startRange).add(12, 'hour').utcOffset() //check at midday incase daylightsavings changes on this day
        return this.fetchRoot().pipe(
            mergeMap(root =>
                halArrayGet<BookingStats>(
                    "fetchBookingStats",
                    root
                        .traversal!.continue()
                        .newRequest()
                        .follow("bookit_report_booking_stats")
                        .withTemplateParameters({ organisationId, 
                            StartPeriod: startRange.toISOString(), 
                            EndPeriod: endRange.toISOString(), 
                            Location: location, 
                            ResourceType: resourceType, 
                            BookableResource: bookableResource, 
                            CancellationBefore: cancellationBefore, 
                            ExcludeWeekends: excludeWeekends, 
                            WorkingHoursOnly: workingHoursOnly, 
                            UTCOffset: utcOffset }))
            )
        );
    }

    public fetchBookingStatsDataCsv(organisationId: IdType, startRange: Date, endRange: Date, location: string, resourceType: string, bookableResource: string, cancellationBefore: number, excludeWeekends: boolean, workingHoursOnly: boolean) {
        
        let utcOffset = moment(startRange).add(12, 'hour').utcOffset() //check at midday incase daylightsavings changes on this day
        
        return this.fetchRoot().pipe(
            mergeMap(root => 
                halGetUrl("fetchBookingStatsDataCsv",
                root
                    .traversal!
                    .continue()
                    .newRequest()
                    .follow("bookit_report_booking_stats_data_csv")
                    .withTemplateParameters({ organisationId, 
                        StartPeriod: startRange.toISOString(), 
                        EndPeriod: endRange.toISOString(), 
                        Location: location, 
                        ResourceType: resourceType, 
                        BookableResource: bookableResource, 
                        CancellationBefore: cancellationBefore, 
                        ExcludeWeekends: excludeWeekends, 
                        WorkingHoursOnly: workingHoursOnly, 
                        UTCOffset: utcOffset }))
            ),
            mergeMap(url => getBlob(url))
            
        ).toPromise();
    }

    public fetchOrganiserBookingStats(organisationId: IdType, startRange: Date, endRange: Date, location: string, resourceType: string, bookableResource: string, organiser: string, excludeWeekends: boolean, workingHoursOnly: boolean) {
        let utcOffset = moment(startRange).add(12, 'hour').utcOffset() //check at midday incase daylightsavings changes on this day
        return this.fetchRoot().pipe(
            mergeMap(root =>
                halArrayGet<OrganiserBookingStats>(
                    "fetchOrganiserBookingStats",
                    root
                        .traversal!.continue()
                        .newRequest()
                        .follow("bookit_report_organiser_booking_stats")
                        .withTemplateParameters({ organisationId, 
                                                    StartPeriod: startRange.toISOString(), 
                                                    EndPeriod: endRange.toISOString(), 
                                                    Location: location, 
                                                    ResourceType: resourceType, 
                                                    BookableResource: bookableResource, 
                                                    Organiser: organiser, 
                                                    ExcludeWeekends: excludeWeekends, 
                                                    WorkingHoursOnly: workingHoursOnly, 
                                                    UTCOffset: utcOffset }))
            )
        );
    }

    public fetchOrganiserBookingStatsDataCsv(organisationId: IdType, startRange: Date, endRange: Date, location: string, resourceType: string, bookableResource: string, organiser: string, excludeWeekends: boolean, workingHoursOnly: boolean) {
        let utcOffset = moment(startRange).add(12, 'hour').utcOffset() //check at midday incase daylightsavings changes on this day
        return this.fetchRoot().pipe(
            mergeMap(root => 
                halGetUrl("fetchOrganiserBookingStatsDataCsv",
                root
                    .traversal!
                    .continue()
                    .newRequest()
                    .follow("bookit_report_organiser_booking_stats_data_csv")
                    .withTemplateParameters({ organisationId, 
                                                StartPeriod: startRange.toISOString(), 
                                                EndPeriod: endRange.toISOString(), 
                                                Location: location, 
                                                ResourceType: resourceType, 
                                                BookableResource: bookableResource, 
                                                Organiser: organiser,
                                                ExcludeWeekends: excludeWeekends, 
                                                WorkingHoursOnly: workingHoursOnly, 
                                                UTCOffset: utcOffset }))
            ),
            mergeMap(url => getBlob(url))
            
        ).toPromise();
    }

}
