import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { BookableResource, BookItAttribute, BookItDevice, BookItLocation, CalendarDelegation, ConnectedDevice, 
        OnlineMeetingProvider, OrganisationSummary, Provider, ProviderTestResult, SharingGroup,  RoomoteDevice, SharingCode, SharingCodeUsage } from "../../../model";
import { bookItAttributeReducer, bookItBookableResourcesReducer, bookItDeviceReducer, bookItLocationsReducer, 
        bookItOnlineMeetingProviderReducer, bookItProviderReducer, calendarDelegationsReducer, generateId, 
        sharingGroupReducer, roomoteDeviceReducer, sharingCodeReducer, sharingCodeUsageReducer } from "../../resourceActions";
import { Actions, actions } from "../../types";
import { ApiResourceState, defaultState, fetchAllFail, fetchAllRequest, fetchAllSuccess } from "../ApiResourceState";
import metrics from "./metrics";


type BookItStateDevices = ApiResourceState<BookItDevice>
function devices(state: BookItStateDevices = defaultState, action: Actions): BookItStateDevices {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return bookItDeviceReducer(state, action);
    }
}


type BookItStateLocations = ApiResourceState<BookItLocation>
function locations(state: BookItStateLocations = defaultState, action: Actions): BookItStateLocations {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return bookItLocationsReducer(state, action);
    }
}

type BookableResourcesState = ApiResourceState<BookableResource>
function bookableResources(state: BookableResourcesState = defaultState, action: Actions): BookableResourcesState {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return bookItBookableResourcesReducer(state, action);
    }
}

type ProvidersState = ApiResourceState<Provider>
function providers(state: ProvidersState = defaultState, action: Actions): ProvidersState {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return bookItProviderReducer(state, action);
    }
}

interface ProviderTestState {
    inProgress: boolean
    result?: ProviderTestResult
}
function providerTest(state: ProviderTestState = { inProgress: false }, action: Actions): ProviderTestState {
    switch (action.type) {
        case getType(actions.testProvider.request):
            return { ...state, inProgress: true, result: undefined }
        case getType(actions.testProvider.success):
            return { ...state, inProgress: false, result: action.payload }
        case getType(actions.clearTestProviderResults):
        case getType(actions.testProvider.failure):
            return { ...state, inProgress: false, result: undefined }
        default:
            return state
    }
}

type OnlineMeetingProvidersState = ApiResourceState<OnlineMeetingProvider>
function onlineMeetingProviders(state: OnlineMeetingProvidersState = defaultState, action: Actions): OnlineMeetingProvidersState {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return bookItOnlineMeetingProviderReducer(state, action);
    }
}

type SharingGroupsState = ApiResourceState<SharingGroup>
function sharingGroups(state: SharingGroupsState = defaultState, action: Actions): SharingGroupsState {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return sharingGroupReducer(state, action);
    }
}

type SharingCodesState = ApiResourceState<SharingCode>
function sharingCodes(state: SharingCodesState = defaultState, action: Actions): SharingCodesState {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return sharingCodeReducer(state, action);
    }
}

type SharingCodeUsagesState = ApiResourceState<SharingCodeUsage>
function sharingCodeUsages(state: SharingCodeUsagesState = defaultState, action: Actions): SharingCodeUsagesState {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return sharingCodeUsageReducer(state, action);
    }
}



type CalendarDelegationsState = ApiResourceState<CalendarDelegation>
function calendarDelegations(state: CalendarDelegationsState = defaultState, action: Actions): CalendarDelegationsState {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return calendarDelegationsReducer(state, action);
    }
}

type BookItAttributesState = ApiResourceState<BookItAttribute>
function attributes(state: BookItAttributesState = defaultState, action: Actions): BookItAttributesState {
    switch (action.type) {
        default:
            return bookItAttributeReducer(state, action);
    }
}

interface OrganisationSummariesState {
    organisations: Array<OrganisationSummary>
    isLoading: boolean
    isLoaded: boolean
    isFailure: boolean
}
function organisationSummaries(state: OrganisationSummariesState = {organisations: [], isLoading: false, isLoaded: false, isFailure: false}, action: Actions): OrganisationSummariesState {
    switch (action.type) {
        case getType(actions.fetchOrganisationSummaries.request):
            return { ...state, isLoading: true, isFailure: false }
        case getType(actions.fetchOrganisationSummaries.failure):
                return { organisations: [], isLoading: false, isLoaded: false, isFailure: true }
        case getType(actions.fetchOrganisationSummaries.success):
            return { organisations: action.payload, isLoaded: true, isLoading: false, isFailure: false }
        default:
            return state
    }
}

type ConnectedDevicesState = ApiResourceState<ConnectedDevice>
function connectedDevices(state:ConnectedDevicesState = defaultState, action: Actions): ConnectedDevicesState  {
    switch (action.type) {
        case getType(actions.fetchConnectedDevices.request):
            return fetchAllRequest(state)
        case getType(actions.fetchConnectedDevices.success):
            return fetchAllSuccess<typeof state, ConnectedDevice>(state, action.payload, generateId)
        case getType(actions.fetchConnectedDevices.failure):
            return fetchAllFail(state)
    }
    return state
}


const bookIt = combineReducers({
    bookableResources,
    locations,
    onlineMeetingProviders,
    providers,
    providerTest,
    sharingGroups,
    sharingCodes,
    sharingCodeUsages,
    devices,
    attributes,
    metrics,
    organisationSummaries,
    connectedDevices,
    calendarDelegations
})

export default bookIt