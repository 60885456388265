import { connect } from "react-redux";
import RoomAttributesTable from "../components/videofx/room/RoomAttributesTable";
import { Room } from "../model";
import { HalResult } from "../services/Hal";
import { ReduxPropsType, RootState } from "../store/types";

function mapStateToProps(state: RootState, ownProps: {room: HalResult<Room> }) {
      return {
          roomAttributes: state.rooms.roomAttributes[ownProps.room.resource.name.toLowerCase()]
      };
  }

const withRedux = connect(mapStateToProps);
export type RoomAttributesTableProps = ReduxPropsType<typeof withRedux>;
export default withRedux(RoomAttributesTable);