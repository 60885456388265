import { getType } from "typesafe-actions";
import { HealthReport, HealthStatus } from "../../model";
import { flatten, toLowercaseRecord } from "../../utils/utils";
import { Actions, actions } from "../types";

export interface RoomStatus {
    status: string,
    health: HealthStatus,
}

interface RoomHealth {
    room: RoomStatus,
    admin:RoomStatus
}

interface RoomHealthChecks {
        running: RoomHealth,
        tunnel: RoomHealth
}
export interface RoomsHealth {
    [name: string]: RoomHealthChecks
}
interface Health {
    roomHealth?: RoomsHealth,
    isLoading: boolean
}
export default function health(state: Health = {isLoading: false}, action: Actions) {
    switch (action.type) {
        case getType(actions.fetchHealth.request):
            return {
                ...state,
                isLoading: true
            };
        case getType(actions.fetchHealth.failure):
            return {
                ...state,
                isLoading: false
            };

        case getType(actions.fetchHealth.success):
            return {
                ...state,
                roomHealth: roomsFromReport(action.payload),
                isLoading: false
            };
        default:
            return state;
    }
}

function roomsFromReport(report: HealthReport): RoomsHealth {
        const roomsRunning = toLowercaseRecord(report.results.room_running.data.Room)
        const adminRunning = toLowercaseRecord(report.results.room_running.data.Admin)
        const roomsTunnel = toLowercaseRecord(report.results.room_tunnel.data.Room)
        const adminTunnel = toLowercaseRecord(report.results.room_tunnel.data.Admin)

        const rooms = [ ...new Set(flatten([ roomsRunning, adminRunning, roomsTunnel, adminTunnel ].map(r => Object.keys(r)))) ]
        const data: RoomsHealth = {}
        for (const room of rooms) {
            data[room] = {
                running: {
                    room: roomsRunning[room],
                    admin: adminRunning[room],
                },
                tunnel: {
                    room: roomsTunnel[room],
                    admin: adminTunnel[room],
                },
            }
        }
        return data;
}

