import { Operation } from "fast-json-patch";
import { IpfxTokenClient } from "../auth/ipfxTokenClient";
import { ProblemReport } from "../model";

export type ApiClient = ReturnType<typeof createApiClient> extends infer R ? R : never

export function createApiClient(serverUrl: string, ipfxTokenClient: IpfxTokenClient) {
   let baseUrl= serverUrl
   if (serverUrl.endsWith("/")) {
      baseUrl = baseUrl.substr(0, baseUrl.length - 1)
   }
   console.log(`baseUrl: ${baseUrl}`)

   return {
      async get<T>(path: string) {
         const url = getUrl(path)
         return await fetchJson<T>(url, {}, (resp) => resp.json())
      },
   
      async post<T>(path: string, body: any, headers: Record<string, string> = {}) {
         const url = getUrl(path)
         return await fetchJson<T>(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...headers },
            body: JSON.stringify(body)
         }, (resp) => resp.json())
      },

      async postRaw<T>(path: string, body: any, headers: Record<string, string> = {}) {
         const url = getUrl(path)
         return await fetchJson<T>(url, {
            method: 'POST',
            headers,
            body: body,
         }, (resp) => resp.json())
      },

      async postEmpty(path: string, headers: Record<string, string> = {}) {
         const url = getUrl(path)
         await fetchJson(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...headers },
            body: null
         }, async resp => resp);
      },
   
      async del<T>(path: string) {
         const url = getUrl(path)
         return await fetchJson<T>(url, {
            method: 'DELETE'
         }, (resp) => resp.json());
      },

      async patch<T>(path: string, patch: Operation[]) {
         const url = getUrl(path)
         return await fetchJson<T>(url, {
            method: 'PATCH',
            // headers: { 'Content-Type': 'application/json-patch+json' },
            // using json-patch+json causes the serialiser that is applied to the JsonPatchDocument<> document
            // to use snake-case naming strategy
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(patch)
         }, (resp) => resp.json())
      }
   }
  

   function getUrl(path: string) {
      return `${baseUrl}${path}`
   }

   async function fetchJson<T>(url: string, { headers, ...init }: RequestInit, handleResponse: (response: Response) => Promise<T>) {

      const token = await getAccessToken()
      const response = await fetch(url, {
         credentials: 'include',
         ...init,
         headers: {
            ...headers,
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
         }
      });
      if (response.status === 204) {
         return undefined! as T;
      }
      if (response.status >= 400) {
         let body = await response.text()
         let error: ProblemReport | null = null

         try {
            const json = await JSON.parse(body)
            if ('title' in json) {
               error = json
            }
         } catch (e) {
            ;
         }

         throw new HttpError(response.status, response.statusText, error)
      }
      console.log("response", response)

      return await handleResponse(response)
   }

   async function getAccessToken() {
      const accessToken = await ipfxTokenClient.getAccessToken()
      return accessToken
   }
}


export class HttpError extends Error {
   public readonly status: number;
   public readonly statusText: string;
   public readonly apiError: ProblemReport | null;

   constructor(status: number, statusText: string, apiError: ProblemReport | null) {
      super(`${status} ${statusText}`);

      this.status = status;
      this.statusText = statusText;
      this.apiError = apiError;

      Object.setPrototypeOf(this, HttpError.prototype);
   }
}