import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Dispatch } from "redux";
import RoomControlToolbar from '../components/videofx/room/RoomControlToolbar';
import { ComponentType, Room } from '../model';
import { HalResource, HalResult } from '../services/Hal';
import { adminCollectedLogsDownloaded, adminCollectLogs, adminEmailLogs, adminReboot, adminStartRoom, adminStopRoom, deleteRoom, updateComponentToLatest, updateJavascriptPackageToLatest, updateRoomProperties } from '../store/actions';
import { getCollectedLogsForRoom } from '../store/selectors';
import { ReduxPropsType, RootState } from '../store/types';

function mapStateToProps(state: RootState, ownProps: {room: HalResource<Room>, showResetDelete?: boolean} ) {
      return {
            collectedLogs: getCollectedLogsForRoom(state, ownProps.room.name),
            userInfo: state.app.userInfo
      }
  }

  const mapDispatchToProps = (dispatch: Dispatch) => ({
      redirectRoomList: () => {
        dispatch(push('/rooms'));
      },
      onStop: (room: HalResource<Room>) => dispatch(adminStopRoom.request(room)),
      onStart: (room: HalResource<Room>) => dispatch(adminStartRoom.request(room)),
      onReboot: (room: HalResource<Room>, force: boolean) => dispatch(adminReboot.request({room, force})),
      onDownloadLogs: (room: HalResource<Room>) => dispatch(adminCollectLogs.request(room)),
      onEmailLogs: (room: HalResource<Room>, to: string[]) => dispatch(adminEmailLogs.request({room, to})),
      onDownloadComplete: (roomName: string) => dispatch(adminCollectedLogsDownloaded(roomName)),
      onDeleteRoom: (room: HalResource<Room>) => dispatch(deleteRoom.request(room)),
      onUpdateComponent: (room: HalResult<Room>, component: ComponentType) => dispatch(updateComponentToLatest.request({room, componentType: component})),
      onUpdateJavascriptPackage: (room: HalResult<Room>) => dispatch(updateJavascriptPackageToLatest.request(room)),
      updateRoomProps: (room: HalResult<Room>, updatedRoom: Room) => dispatch(updateRoomProperties.request({room, updatedRoom}))
    });


const withRedux = connect(mapStateToProps, mapDispatchToProps);
export type RoomControlToolbarProps = ReduxPropsType<typeof withRedux>;
export default withRedux(RoomControlToolbar);
