import React from 'react';
import { AllDaysOfWeek, DayOfWeek } from '../../../model';
import { useSettingsContext } from './BookItSettingsPage';
import { Setting, SettingDropdown, SettingInput, SettingsSection } from './Settings';

export interface WorkHoursSettingsEditModel {
    WorkingHoursEnabled: boolean
    WorkingHoursDaysOfWeek: DayOfWeek[]
    WorkingHoursStartTime: string,
    WorkingHoursEndTime: string,
    WorkingHoursTimeZone: TimeZoneItem,
    DeviceBackgroundUrl?: String
}

export interface TimeZoneItem {
    Name: string
    Value: string
    header: string
}

type WorkingHoursSettingsProps = {
    timezones: Array<TimeZoneItem>
}

const WorkingHoursSettings: React.FC<WorkingHoursSettingsProps> = ({ timezones }) => {
    const {values} = useSettingsContext()

    return <SettingsSection title="Working Hours">
        <Setting
            title="Use Working Hours"
            description="When enabled working hours will be taken into account when scheduling resources and people."
            name='working_hours_enabled'
            overrideName='working_hours_enabled'
        />
        <Setting title="Default Working Hours" description="The default working hours to use for a resource when not defined in Exchange"
                overrideName="default_working_hours"
                disabled={!values.working_hours_enabled}>
            <SettingDropdown
                multiple={true}
                fluid
                label="Choose working days"
                items={AllDaysOfWeek}
                disabled={!values.working_hours_enabled}
                name="working_hours_days_of_week"
                defaultValue={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']}
                placeholder="Select working days" 
                />
            <SettingInput label='Start time:' name="working_hours_start_time" disabled={!values.working_hours_enabled} defaultValue="9:00" />
            <SettingInput label='End time:' name="working_hours_end_time" disabled={!values.working_hours_enabled} defaultValue="17:00"/>
            <SettingDropdown
                multiple={false}
                checkable
                label="Timezone"
                items={timezones}
                name="working_hours_time_zone"
                disabled={!values.working_hours_enabled}
                getKey={(item:TimeZoneItem) => item.Value}
                placeholder="Select Timezone"
                defaultValue="New Zealand Standard Time"
                 />
        </Setting>
    </SettingsSection>
}

// export function toWorkingHoursEditModel(settings: Partial<BookItSettings> | undefined, defaults: BookItSettings, timeZones: TimeZoneItem[]): WorkHoursSettingsEditModel {
//     const init = settings ? { ...defaults, ...settings } : defaults
//     const tz = timeZones.find(tz => tz.Value === init.default_working_hours.time_zone) ?? timeZones.find(tz => tz.Value === 'UTC') ?? timeZones[0]
//     const editModel = {
//         WorkingHoursEnabled: init.working_hours_enabled,
//         WorkingHoursDaysOfWeek: init.default_working_hours.days_of_week,
//         WorkingHoursStartTime: init.default_working_hours.start_time,
//         WorkingHoursEndTime: init.default_working_hours.end_time,
//         WorkingHoursTimeZone: tz,
//     }
//     return editModel
// }


// export function toWorkingHoursEditModelInherited(settings: Partial<BookItSettings> | undefined, timeZones: TimeZoneItem[]): Partial<WorkHoursSettingsEditModel> {
//     const tz = settings !== undefined && settings.default_working_hours !== undefined
//         ? timeZones.find(tz => tz.Value === settings!.default_working_hours!.time_zone)
//         : timeZones.find(tz => tz.Value === 'UTC') ?? timeZones[0]
//     const editModel = {
//         WorkingHoursEnabled: settings?.working_hours_enabled,
//         WorkingHoursDaysOfWeek: settings?.default_working_hours?.days_of_week,
//         WorkingHoursStartTime: settings?.default_working_hours?.start_time,
//         WorkingHoursEndTime: settings?.default_working_hours?.end_time,
//         WorkingHoursTimeZone: settings?.default_working_hours !== undefined ? tz : undefined
//     }

//     return editModel
// }

export default WorkingHoursSettings
