import { Grid, Header, Segment } from '@fluentui/react-northstar';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { PackageFeed } from '../../../model';
import { getRoomNames } from '../../../store/selectors';
import { useSelector } from '../../../store/utils';
import ResourceAdminForm from '../../admin/ResourceAdminForm';
import { FormikNorthstarDropdown } from '../../controls/FormikNorthstarDropdown';
import { FormikNorthstarInput } from '../../controls/FormikNorthstarInput';
import "./AddRoomForm.css";


export interface CreateRoom {
    roomName?: string;
    displayName?: string;
    emailAddress?: string;
    msiPackageFeed?: string;
    windowsUsername?: string;
    windowsPassword?: string;
    exchangeUsername?: string;
    exchangePassword?: string;
    templateRoom?: string;
}

type EditModel = Omit<CreateRoom, "MsiPackageFeed"> & {
    packageFeed?: PackageFeed
}

type Props = {
    packageFeeds?: PackageFeed[]
    onSubmit: (room: CreateRoom) => void
    onCancel: () => void
}

const AddRoomForm: React.FC<Props> = ({ packageFeeds, onSubmit, onCancel }) => {
    const roomNames = useSelector(getRoomNames)

    const formSchema = useMemo(() => Yup.object().shape({
        roomName: Yup.string()
            .required('Room name is required')
            .test('no_spaces', 'Room name cannot contain spaces', value => value ? value.indexOf(' ') === -1 : false),
        emailAddress: Yup.string()
            .required("Email address is required")
            .email('Must be a valid email address'),
        displayName: Yup.string()
            .required("Display name is required"),
        exchangeUsername: Yup.string().optional().notRequired(),
        exchangePassword: Yup.string().optional().notRequired(),
        windowsUsername: Yup.string().optional().notRequired(),
        windowsPassword: Yup.string().optional().notRequired(),
        templateRoom: Yup.string().optional().notRequired()
    }), [])

    const handleSubmit = useCallback((values: EditModel) => {
        let msiPackageFeed = values.packageFeed?.feed_name
        if (msiPackageFeed === undefined && packageFeeds) {
            const def = packageFeeds!.filter(feed => feed.default && feed.type === 'Msi');
            if (def.length > 0) {
                msiPackageFeed = def[0].feed_name;
            }
        }
    
        onSubmit({...values, msiPackageFeed, templateRoom: values.templateRoom ?? "None"})
    }, [onSubmit, packageFeeds])


    const initial = useMemo<EditModel>(() => ({ roomName: '', emailAddress: '', displayName: '', exchangeUsername: '', exchangePassword: '', windowsUsername: ''
    , windowsPassword: '', packageFeed: packageFeeds ? packageFeeds.find(feed => feed.default && feed.type === 'Msi') : undefined, templateRoom: undefined })
    , [packageFeeds])

    return <ResourceAdminForm
        initial={initial}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <Grid columns={3}>
            <Segment>
                <Header as="h5" content="Room Details" />
                <FormikNorthstarInput fluid label="Room Name" name="roomName" id="roomName" />
                <FormikNorthstarInput fluid label="Email Address" name="emailAddress" id="emailAddress" />
                <FormikNorthstarInput fluid label="Display Name" name="displayName" id="displayName" />
                <FormikNorthstarDropdown
                    label="Choose package feed"
                    items={packageFeeds ?? []}
                    name="packageFeed"
                    placeholder="Select package feed"
                    getHeader={i => i.feed_name}
                />

                <FormikNorthstarDropdown
                    label="Choose template room"
                    name="templateRoom"
                    items={roomNames ?? []}
                    placeholder='Choose the template room'
                    getHeader={i => i}
                    allowNone={true}
                />
            </Segment>
            <Segment>
                <Header as="h5" content="Exchange Credentials" />
                <FormikNorthstarInput fluid label="Exchange Email Address" name="emailAddress" id="emailAddress" disabled />
                <FormikNorthstarInput fluid label="Exchange Username" name="exchangeUsername" id="exchangeUsername" />
                <FormikNorthstarInput fluid label="Exchange Password" name="exchangePassword" id="exchangePassword" type="password" />
            </Segment>
            <Segment>
                <Header as="h5" content="Windows Credentials" />
                <FormikNorthstarInput fluid label="Windows Username" name="windowsUsername" id="windowsUsername" />
                <FormikNorthstarInput fluid label="Windows Password" name="windowsPassword" id="windowsPassword" type="password" />
            </Segment>
        </Grid>
    </ResourceAdminForm>
}

export default AddRoomForm