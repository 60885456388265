import { Loader, Menu, MenuItemProps, tabListBehavior } from '@fluentui/react-northstar';
import { replace } from 'connected-react-router';
import * as React from "react";
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import RoomAttributesTable from '../../../containers/RoomAttributesTable';
import RoomLogs from '../../../containers/RoomLogs';
import RoomScreenShots from '../../../containers/RoomScreenShots';
import { fetchRoom } from '../../../store/actions';
import { ApiResult } from '../../../store/reducers/ApiResult';
import { getActiveOrganisation, getRoom } from '../../../store/selectors';
import { actions } from '../../../store/types';
import { useSelector } from '../../../store/utils';
import RoomCard from './RoomCard';
import "./RoomDetail.css";
import RoomEscalationsTable from './RoomEscalationsTable';
import WindowsUpdateTable from './WindowsUpdateTable';

interface Props {
    roomName: string;
};

const items = [
    {
        key: 'attributes',
        content: 'Attributes',
    },
    {
        key: 'logging',
        content: 'Logging',
    },
    {
        key: 'windows_updates',
        content: 'Windows Updates',
    },
    {
        key: 'escalations',
        content: 'Escalations',
    },
    {
        key: 'screenShots',
        content: 'Screen Shots',
    }
]

const RoomDetail: React.FC<Props> = ({ roomName }) => {

    const [activeTab, setActiveTab] = useState("Attributes");

    const dispatch = useDispatch();

    const { userInfo } = useSelector(s => s.app)


    const showWindowsUpdate = useMemo(() => ApiResult.isSuccess(userInfo) && userInfo.value.user.roles.includes("VideoFxWindowsUpdateAdmin"), [userInfo])

    const room = useSelector(s => getRoom(s, roomName))
    const activeOrganisation = useSelector(getActiveOrganisation)

    const roomNames = useSelector(s => s.rooms.roomNames)

    const wrongOrg = !roomNames?.includes(roomName.toLowerCase())

    useEffect(() => {
        if (wrongOrg) {
            dispatch(replace('/rooms'))
        }
    }, [dispatch, wrongOrg])

    useEffect(() => {
        if (!wrongOrg && activeOrganisation && (room === undefined || (room && room.traversal === undefined))) {
            dispatch(fetchRoom.request({organisationId: activeOrganisation.organisation_id, roomId: roomName}))
        }
    }, [room, dispatch, roomName, activeOrganisation, wrongOrg])

    const latestBackup = useSelector(s => room && s.rooms.lastBackups[room.resource.name.toLowerCase()])

    useEffect(() => {
        if (!latestBackup && room) {
            dispatch(actions.fetchLatestRoomBackup.request(room))
        }
    }, [room, dispatch, latestBackup])

    const handleMenuClicked = (event: React.SyntheticEvent<HTMLElement>, data?: MenuItemProps) => {
        setActiveTab(data!.content! as string)
    }

    const menuItems = useMemo(() => {
        let actualItems = showWindowsUpdate ? items : items.filter(item => item.key !== "windows_updates")
        return actualItems.map(i => ({
                key: i.key,
                content: i.content,
                onClick: handleMenuClicked
            }))
    }, [showWindowsUpdate])
    
    if (room === undefined) {
        return <Loader label="Loading room data..." />
    }
  
    return (
        <>
            <RoomCard room={room} />

            <Menu
                defaultActiveIndex={0}
                items={menuItems}
                underlined
                primary
                accessibility={tabListBehavior}
            />

            {activeTab === "Attributes" && <RoomAttributesTable room={room} />}
            {activeTab === "Logging" && <RoomLogs room={room.resource} />}
            {activeTab === "Escalations" && <RoomEscalationsTable roomName={room.resource.name} />}
            {activeTab === "Windows Updates" && <WindowsUpdateTable room={room} />}
            {activeTab === "Screen Shots" && <RoomScreenShots room={room.resource} />}
        </>
    )
}

export default RoomDetail