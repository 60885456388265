import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { AzureAdOrganisationTenant, Organisation } from '../../model';
import { organisationReducer, organisationTenantReducer } from '../resourceActions';
import { actions, Actions } from '../types';
import { ApiResourceState, defaultState } from './ApiResourceState';

function organisations(state: ApiResourceState<Organisation> = defaultState, action: Actions): ApiResourceState<Organisation> {
    switch (action.type) {
        default:
            return organisationReducer(state, action);
    }
}

function reloading(state: Record<string, boolean> = {}, action: Actions): Record<string, boolean> {
    switch (action.type) {
        case getType(actions.reloadOrganisation.request):
            return { ...state, [action.payload.toString()]: true }
        case getType(actions.reloadOrganisation.success):
            return { ...state, [action.payload.toString()]: false }
        case getType(actions.reloadOrganisation.failure):
            return { ...state, [action.payload.organisationId.toString()]: false }
    }
    return state
}


function organisationTenant(state: ApiResourceState<AzureAdOrganisationTenant> = defaultState, action: Actions): ApiResourceState<AzureAdOrganisationTenant> {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return organisationTenantReducer(state, action);
    }
}


export default combineReducers({
    organisations: organisations,
    organisationTenant,
    reloading
})



