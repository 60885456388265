import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { Services } from "../../services";
import { errorHandler, fetchWindowsUpdates } from "../actions";
import { RootAction, RootState } from "../types";

export const adminFetchWindowsUpdatesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    
    return action$.pipe(
        filter(isActionOf(fetchWindowsUpdates.request)),
        mergeMap(action => {
            return serverApi.fetchWindowsUpdates().pipe(
                mergeMap(hal => of(fetchWindowsUpdates.success(hal))),
                catchError(err => of(fetchWindowsUpdates.failure(err), errorHandler(err)))
        )}));
}