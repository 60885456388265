import { DropdownItemProps, DropdownSelectedItemProps, Grid, Segment } from '@fluentui/react-northstar';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { IdType } from '../../model';
import ResourceAdminForm from '../admin/ResourceAdminForm';
import { FormikNorthstarDropdown } from '../controls/FormikNorthstarDropdown';
import { FormikNorthstarInput } from '../controls/FormikNorthstarInput';
import { IRoomData } from './RoomoteDevices';


export interface CreateRoomoteDevice {
    device_name: string    
    device_pin: string
    room_id: IdType
    room?: IRoomData
}

interface Props {
    initial: CreateRoomoteDevice
    onSubmit: (device: CreateRoomoteDevice) => void
    onCancel: () => void
    existingDevices?: string[]
    allRooms: IRoomData[]
}

const AddRoomoteDevice: React.FC<Props> = (props) => {

    const { initial, onSubmit, onCancel, allRooms } = props
    const [selectedRoom, setSelectedRoom] = useState<IRoomData>()

    const formSchema = useMemo(() => Yup.object().shape({
        device_name: Yup.string().required('Device name cannot be empty'),
        device_pin: Yup.string().required('Device pin cannot be empty'),
        room_id: Yup.string().required('A Room must be selected'),
    }), [])   

    

    const handleSubmit = (values: CreateRoomoteDevice) => {
        console.log('handleSubmit', selectedRoom);
        
        if (selectedRoom?.room_id !== undefined) {
            values.room_id = selectedRoom?.room_id
            console.log('handleSubmit', values)
            onSubmit(values)
        }
    }

    type RenderItem = {
        Item: any;
        props: DropdownItemProps;
    };

    type RenderSelectedItem = {
        SelectedItem: any;
        props: DropdownSelectedItemProps;
    };

    const renderItem = React.useCallback((args: RenderItem) => getCustomItem({ ...args }), []);
    const renderSelectedItem = React.useCallback((args: RenderSelectedItem) => getCustomSelectedItem({ ...args }), []);

    useEffect(() => {
        if (initial.room_id !== undefined) {
            setSelectedRoom(allRooms.find(r => r.room_id === initial.room_id))
        }
    }, [])

    function handleSelectionChange(room: IRoomData) {
        console.log('handleSelectionChange', room[0]);
        setSelectedRoom(room[0] as IRoomData)        
    }

    // function getSelectedRoom() {
    //     if (initial.room_id !== undefined) {
    //         return allRoomItems.find(r => r.room_id === initial.room_id)            
    //     } else {
    //         return undefined
    //     }
    // }
//initial={initial} 
    return <ResourceAdminForm initial={initial} 
                            onSubmit={handleSubmit} 
                            onCancel={onCancel} 
                            formSchema={formSchema}>
            <Grid columns={2}>
                <Segment>
                    <FormikNorthstarInput fluid label="Device Name" name="device_name" id="device_name"/>
                    <FormikNorthstarInput fluid label="Pin" name="device_pin" id="device_pin"/>                            
                </Segment>
                <Segment>
                    <FormikNorthstarDropdown fluid label="Choose Room" 
                                            multiple
                                            items={allRooms} name="room" 
                                            value={selectedRoom}
                                            renderItem={(Item, props) => renderItem({ Item, props })} 
                                            renderSelectedItem={(SelectedItem, props) => renderSelectedItem({SelectedItem, props})}
                                            onChange={r => r && handleSelectionChange(r)}
                                            placeholder="Select room for this device."/>
                </Segment>
            </Grid>
        </ResourceAdminForm>



}

const getCustomItem = (args: {Item: any, props: DropdownItemProps }) => {

    const { Item, props } = args;
    const { header, ...rest } = props;
    const room = props as IRoomData
  
    return (
    
      <Item
        header={<span>{room.display_name}</span>}
        {...rest}
      />
    );
  };

  const getCustomSelectedItem = (args: {SelectedItem: any, props: DropdownSelectedItemProps }) => {

    const { SelectedItem, props } = args;
    const { header, ...rest } = props;
    const room = props as IRoomData

   
    return (
    
      <SelectedItem
        header={<span>{room.display_name}</span>}
        {...rest}
      />
    );
  };

export default AddRoomoteDevice