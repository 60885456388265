import { Menu, MenuItemProps, tabListBehavior } from '@fluentui/react-northstar';
import React, { useMemo, useState } from 'react';
import { getSite } from '../../store/selectors';
import { useSelector } from '../../store/utils';
import { SiteAttributes } from './SiteAttributes';
import { SiteExclusions } from './SiteExclusions';
import { SiteCard } from './SiteCard';
import css from './SiteDetail.module.scss';

interface SiteDetailProps {
    siteId: string
}

const items = [
    {
        key: 'attributes',
        content: 'Attributes',
    },
    {
        key: 'exclusions',
        content: 'Exclusions',
    }
]


export function SiteDetail({siteId}: SiteDetailProps) {

    const site = useSelector(s => getSite(s, siteId))

    const [activeTab, setActiveTab] = useState("Attributes");

    const handleMenuClicked = (event: React.SyntheticEvent<HTMLElement>, data?: MenuItemProps) => {
        setActiveTab(data!.content! as string)
    }

    const menuItems = useMemo(() => {
        return items.map(i => ({
                key: i.key,
                content: i.content,
                onClick: handleMenuClicked
            }))
    }, [])

    return <div className={css.siteDetail}>
        <SiteCard site={site} />

        <Menu
            defaultActiveIndex={0}
            items={menuItems}
            underlined
            primary
            accessibility={tabListBehavior}
        />

        {activeTab === "Attributes" && <SiteAttributes site={site} />}
        {activeTab === "Exclusions" && <SiteExclusions site={site} />}

    </div>
}
