import { Loader, Dropdown, Button, DownloadIcon } from "@fluentui/react-northstar";
import classNames from 'classnames';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as RoomIcon } from '../../../images/bookItIcons/room.svg';
import { ReactComponent as CarParkIcon } from '../../../images/bookItIcons/carPark.svg';
import { ReactComponent as FleetIcon } from '../../../images/bookItIcons/fleet.svg';
import { ReactComponent as HotDeskIcon } from '../../../images/bookItIcons/hotDesk.svg';
import { ReactComponent as LockerIcon } from '../../../images/bookItIcons/locker.svg';

import { HeatMap, HeatMapRoomUsageByHour } from "../../../model";
import { fetchHeatMap } from "../../../store/actions";
import { getHeatmap, getHeatmapIsLoading } from "../../../store/selectors";
import { useSelector } from "../../../store/utils";
import DoughnutIndicator from '../DoughnutIndicator';
import { avg, getTimes, ReportFilter, resourceTypes } from '../MetricsPage';
import './HeatMap.scss';
import services from "../../../services";
import { fileDownloadFromBlob } from "../../../utils/fileDownload";

const timeTypes = {
    'Scheduled': 'Scheduled',
    'Actual': 'Actual'
}



function getColor(value: number) {
    const heatMapInt = biColorInterpolator(0x056707, 0xfc8b3f, 0xb20026)
    return toHexColor(heatMapInt(value))
}

function HeatMapMetric({ filter, orgChanging }: { filter: ReportFilter, orgChanging: boolean }) {

    const data = useSelector(getHeatmap);
    const isLoading = useSelector(getHeatmapIsLoading)
    const dispatch = useDispatch()
    
    const [dropdownTimeSelector, setDropdownTimeSelector] = useState('Scheduled')
    const [isReportRunning, setIsReportRunning] = useState(false)

    useEffect(() => {
        if (!orgChanging) {
            dispatch(fetchHeatMap.request({
                organisationId: filter.organisationId,
                startRange: filter.startDate!,
                endRange: filter.endDate!,
                location: filter.location,
                resourceType: filter.resourceType,
                bookableResource: filter.bookableResource,
                excludeWeekends: filter.excludeWeekends,
                workingHoursOnly: filter.isWorkingHoursOnly,
                isActualTime: dropdownTimeSelector === 'Actual'
            }))
        }
    }, [filter.startDate, filter.endDate, filter.location, filter.resourceType, 
        filter.bookableResource, filter.excludeWeekends, dispatch, 
        filter.isWorkingHoursOnly, filter.organisationId, dropdownTimeSelector, orgChanging])


    if (isLoading) { return <Loader label="Loading Heatmap" /> }

    if (data === undefined)
        return <div>No data to show. Please change your filters</div>
    if (data.room_type_usage.length === 0)
        return <div>No data to show. Please change your filters</div>

    const min = 0 // Math.min(...data.filter(x => x))
    const max = 1 //Math.max(...data)
    const w = max - min
    return <div className="heatMap">
        <div className="heatMapTitle">
            <h2>Heat Mapping Stats</h2>

            <div className="timeTypeSelector">
                {/* Allow user to toggle between 'Actual' and 'Scheduled' times */}
                <Dropdown
                    items={Object.entries(timeTypes).map(([value, text]) => text)}
                    value={dropdownTimeSelector}
                    onChange={(e, d) => {
                        if (d) {
                            setDropdownTimeSelector(d.value as string)                           
                        }
                    }}
                />
            </div>

            {isReportRunning ? <Loader className='spaceLeft' /> : 
                        <Button primary content='Export as CSV' 
                            onClick={() => { doExport() }} 
                            icon={<DownloadIcon />} 
                            iconPosition='before' 
                            className={['downloadButton', 'spaceLeft', 'spaceBottom' ].join(' ')} />
                    }


            <div className="legend">
                <div className="grayLegend"><span>0%</span><div className="gray"></div></div>
                <span> {'>0%'}</span><div className="color" /><span>100%</span></div>
        </div>
        <div className='scrollContainer'>
            <div className='grid'>
                {data.room_type_usage.map(rt =>
                    <div className='group' key={rt.attendee_type}>
                        <div className="groupHeader">
                            <div className='heatMapRow'>
                                <div className='cell colHeader rowHeader room'>
                                    <span className="attendee">{resourceTypes[rt.attendee_type]}</span> <span>Usage</span>
                                </div>
                                {getTimes(filter.isWorkingHoursOnly).map(t => <div className={classNames("colHeader", filter.isWorkingHoursOnly ? 'workingHours' : 'hour')} key={t.valueOf()}>{t.format('ha')}</div>)}
                            </div>
                        </div>
                        {rt.room_usage.map((ru, i) => renderRoom(ru,i , rt.attendee_type))}
                    </div>
                )
                }
            </div>
        </div>
    </div>

    // Export the dataset as a CSV file
    async function doExport() {
        
        console.log('do HeatMap csv export');
       
        setIsReportRunning(true)

        try {
            const {data,fileName} = await services.bookItApi.fetchHeatMapDataCsv(filter.organisationId, 
                                            filter.startDate!, filter.endDate!, filter.location, 
                                            filter.resourceType, filter.bookableResource, 
                                            filter.excludeWeekends, filter.isWorkingHoursOnly, 
                                            dropdownTimeSelector === 'Actual')

            fileDownloadFromBlob(data, fileName ?? 'heatmap.csv')

        } catch (e) {
            console.error('doExport', e)
        }

        setIsReportRunning(false)
    }

    function renderRoom(st: HeatMap['room_type_usage'][0]['room_usage'][0], index: number, attendeeType: string) {

        const total = getTotalUsageForRoom(st.usage_by_hour, filter.isWorkingHoursOnly)
        console.log('attendeeType', attendeeType)
        return <div className="heatMapRow" key={index}>
            <div className="room">

                {attendeeType === 'MeetingRoom' && <RoomIcon className='icon' />}
                {attendeeType === 'Locker' && <LockerIcon className='icon' />}
                {attendeeType === 'HotDesk' && <HotDeskIcon className='icon' />}
                {attendeeType === 'ParkingSpace' && <CarParkIcon className='icon' />}
                {attendeeType === 'Fleetcar' && <FleetIcon className='icon' />}
                
                <span className="attendee">{st.room_name}</span>
                <div className='indicator'>
                    <DoughnutIndicator value={total} className='indicatorChart' color={getColor(total)} />
                    <span className='indicatorText'>{(total * 100).toFixed(1)}%</span>
                </div>
            </div>
            {getUsage(st.usage_by_hour, filter.isWorkingHoursOnly).map((d, i) => <div title={`${(d * 100).toFixed(1)}%`} className={classNames(filter.isWorkingHoursOnly ? "durationWorkingHours" : "duration")} style={d ? {
                backgroundColor: getColor((d - min) / w),
            } : undefined} key={i} />)}
        </div>
    }
}

function toHexColor(clr: number) {
    return '#' + ('000000' + clr.toString(16)).substr(-6)
}

function getUsage(data: HeatMapRoomUsageByHour, isWorkingHours: boolean) {
    let arr = new Array(24).fill(0)

    if (isWorkingHours)
        arr = new Array(10).fill(0)

    for (const hour in arr) {
        let value = data[parseInt(hour)]
        if (isWorkingHours)
            value = data[parseInt(hour) + 8]
        arr[hour] = value ? value.scaled <= 1 ? value.scaled : 1 : 0
    }
    return arr
}

function getTotalUsageForRoom(data: HeatMapRoomUsageByHour, isWorkingHours: boolean) {
    return avg(getUsage(data, isWorkingHours))
}


function interpolator(a: number, b: number) {
    return function (t: number) {
        return (1 - t) * a + t * b
    }
}

function colorInterpolator(c1: number, c2: number) {
    const r = interpolator(c1 >> 16, c2 >> 16)
    const g = interpolator((c1 >> 8) & 0xff, (c2 >> 8) & 0xff)
    const b = interpolator(c1 & 0xff, c2 & 0xff)

    return function (t: number) {
        return (((r(t) << 8) | g(t)) << 8) | b(t)
    }
}

function biColorInterpolator(c1: number, c2: number, c3: number) {
    const i1 = colorInterpolator(c1, c2)
    const i2 = colorInterpolator(c2, c3)
    return function (t: number) {
        return t <= 0.5 ? i1(t * 2) : i2((t - 0.5) * 2)
    }
}


export default HeatMapMetric