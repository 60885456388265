import { EditIcon, TrashCanIcon } from "@fluentui/react-northstar";
import { compare } from "fast-json-patch";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { CellInfo } from "react-table";
import * as Yup from 'yup';
import { CreateOfficeLocation, IdType, OfficeLocation } from "../../model";
import { createOfficeLocation, deleteOfficeLocation, fetchOfficeLocations, patchOfficeLocation } from "../../store/resourceActions";
import { getActiveOrganisation } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";
import ResourceAdminForm from "./ResourceAdminForm";
import ResouceAdminPage from './ResourceAdminPage';

interface Props { }

type OfficeLocationEditModel = Omit<CreateOfficeLocation, "book_it_location_id">

const OfficeLocationsPage: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const renderGPSPosition = useCallback((cellInfo: CellInfo) => {
        const office: OfficeLocation = cellInfo.original
        return <span>{`${office.latitude} lat, ${office.longitude} lon (${office.radius_in_meters}m)`}</span>
    }, [])


    const cols = useMemo(() => [
        { Header: "Name", id: "display_name", accessor: (row: OfficeLocation) => row.display_name, minWidth: 10 },
        { Header: "Geofence ID", id: "geofence_id", accessor: (row: OfficeLocation) => row.geofence_id, minWidth: 10 },
        { Header: "Geofence", id: "serial_number", minWidth: 10, accessor: (row: OfficeLocation) => row, Cell: renderGPSPosition },
    ], [renderGPSPosition])

    const activeOrganisation = useSelector(getActiveOrganisation)

    const parentKey = useMemo(() => {
        return { organisation_id: activeOrganisation!.organisation_id }
    }, [activeOrganisation]);

    const handleAddOrUpdate = (office: OfficeLocationEditModel, closeDialog: () => void, original: OfficeLocation | undefined) => {
        if (original) {
            const updateOfficeLocation = {
                office_location_id: original.office_location_id,
                organisation_id: original.organisation_id,
                book_it_location_id: original.book_it_location_id,
                photo_url: office.photo_url === "" ? undefined : office.photo_url,
               ...office
            }

            console.log("original:", original)
            console.log("update:", office)
            console.log("updateOfficeLocation:", updateOfficeLocation)
            let diff = compare(original, updateOfficeLocation);
            dispatch(patchOfficeLocation.request({ id: original, operations: diff }))
        } else {
            const newOfficeLocation = {
                photo_url: office.photo_url === "" ? undefined : office.photo_url,
                organisation_id: activeOrganisation.organisation_id,
                ...office
            }
            console.log("newOfficeLocation:", newOfficeLocation)

            dispatch(createOfficeLocation.request(newOfficeLocation))
        }
        closeDialog()
    }


    return <ResouceAdminPage<OfficeLocation, { organisation_id: IdType }>
        resourceName='Office Locations'
        parentKey={parentKey}
        selectState={s => s.officeLocations.locations}
        fetchAllAction={fetchOfficeLocations}
        deleteAction={deleteOfficeLocation}
        confirmMessage={office => `Are you sure you wish to delete the Office Location named ${office.display_name}?`}
        resourceForm={(original, closeDialog) =>
            <OfficeLocationForm initial={createEditModel(original)} onSubmit={edit => handleAddOrUpdate(edit, closeDialog, original)} onCancel={closeDialog} />
        }
        toolbarActionItems={(resource, onAddUpdate, onDelete) =>
            [{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: `Edit ${resource.display_name}`,
                onClick: () => onAddUpdate(resource)
            },
            {
                key: 'delete',
                icon: <TrashCanIcon outline />,
                tooltip: `Delete ${resource.display_name}`,
                onClick: () => onDelete(resource)
            }]
        }
        columns={cols}
        defaultSortOrder={[{ id: "display_name", desc: false }]}
    />
}

function createEditModel(resource?: OfficeLocation): OfficeLocationEditModel {
    if (resource === undefined) return { 
        display_name: '',
        geofence_id: '',
        latitude: 0.0,
        longitude: 0.0,
        radius_in_meters: 100.0,
        photo_url: '',
     }
    let { organisation_id, office_location_id, book_it_location_id, ...clean } = resource
    let edit = { photo_url: resource.photo_url ?? "", ...clean }

    return edit
}

interface OfficeLocationFormProps {
    initial: OfficeLocationEditModel
    onSubmit: (edit: OfficeLocationEditModel) => void
    onCancel: () => void
}

const OfficeLocationForm: React.FC<OfficeLocationFormProps> = ({ initial, onSubmit, onCancel }) => {
    console.log("initialOfficeLocation", initial)

    const formSchema = useMemo(() => Yup.object().shape({
        display_name: Yup.string()
            .required("The Display Name cannot be empty"),
        geofence_id: Yup.string()
            .required("The Geofence ID cannot be empty"),
        latitude: Yup.number()
            .required("The Latitude cannot be empty and must be a number"),
        longitude: Yup.number()
            .required("The Longitude cannot be empty and must be a number"),
        radius_in_meters: Yup.number().positive()
            .required("The radius in meters cannot be empty and must be a number"),
        photo_url: Yup.string()
            .optional(),
    }), [])

    return <ResourceAdminForm
        initial={initial}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <FormikNorthstarInput fluid label="Display Name" name="display_name" />
        <FormikNorthstarInput fluid label="Geofence ID" name="geofence_id" />
        <FormikNorthstarInput fluid label="Latitude" name="latitude" />
        <FormikNorthstarInput fluid label="Longitude" name="longitude" />
        <FormikNorthstarInput fluid label="Radius (in meters)" name="radius_in_meters" />
        <FormikNorthstarInput fluid label="Photo URL" name="photo_url" />

    </ResourceAdminForm>
}

export default OfficeLocationsPage