import { of } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { LogFileMetadata, LogFilesType, Process, RoomWindowsUpdate, WindowsUpdateInstallRequestResponse } from "../model";
import { getBlob, halArrayGet, halGet, halGetUrl, halPost, HalResult, post, postNoContent } from "./Hal";


export class RoomAdminApi {
    private rootDoc: HalResult<{}>;
    private roomSystemProcess?: HalResult<Process>;


    constructor(rootDoc: HalResult<{}>) {
        this.rootDoc = rootDoc;
    }

    public fetchProcess() {
        const root = this.rootDoc.traversal;

        if (this.roomSystemProcess !== undefined) {
            return of(this.roomSystemProcess);
        }

        return halGet<Process>("fetchProcess", root!.continue()
            .follow("process")
            .withTemplateParameters({ processType: 'RoomSystem' })).pipe(
                tap(process => {
                    this.roomSystemProcess = process;
                    return of(this.roomSystemProcess!);
                })
            );
    }

    public stop() {
        return this.fetchProcess()
            .pipe(
                mergeMap(process => halPost<Process>("stop", process.traversal!.continue().newRequest()
                    .follow("stop"), {}, 'application/hal+json'))
            );
    }

    public start() {
        return this.fetchProcess()
            .pipe(
                mergeMap(process => halPost<Process>("start", process.traversal!.continue().newRequest()
                    .follow("start"), {}, 'application/hal+json'))
            );
    }

    public restart() {
        return this.fetchProcess()
            .pipe(
                mergeMap(process => halPost<Process>("restart", process.traversal!.continue().newRequest()
                    .follow("restart"), {}, 'application/hal+json'))
            );
    }

    public fetchWindowsUpdates() {
        return halArrayGet<RoomWindowsUpdate>("fetchWindowsUpdate", this.rootDoc.traversal!.continue().newRequest()
            .follow("windows_updates")
            .follow("windows_update[$all]")
        )
    }

    public checkForWindowsUpdates() {
        return halGetUrl("checkForWindowsUpdates", this.rootDoc.traversal!.continue().newRequest()
            .follow("windows_updates"))
            .pipe(
                mergeMap(url => postNoContent(url, {}, { 'Content-Type': 'application/json' }))
            )
    }

    public requestWindowsUpdatesInstall(ids: number[]) {
        return halGetUrl("requestWindowsUpdatesInstall", this.rootDoc.traversal!.continue().newRequest()
            .follow("windows_updates_install"))
            .pipe(
                mergeMap(url => post<WindowsUpdateInstallRequestResponse>(url, { windows_update_ids: ids }, { 'Content-Type': 'application/json', 'Accept': 'application/json' }))
            )
    }


    public fetchScreenShot(index: number) {
        return halGetUrl("fetchScreenShot", this.rootDoc.traversal!.continue().newRequest()
            .follow("single_screen")
            .withTemplateParameters({ id: index }))
            .pipe(
                mergeMap(url => getBlob(url))
            );
    }

    public fetchLog(logFileType: LogFilesType) {
        return halGetUrl("fetchLog",
            this.rootDoc.traversal!.continue().newRequest()
                .follow("log")
                .withTemplateParameters({ processType: logFileType }))
            .pipe(
                mergeMap(url => getBlob(url))
            );
    }

    public fetchLogMetadata(logFileType: LogFilesType) {
        return halGet<LogFileMetadata>("fetchLogMetadata",
            this.rootDoc.traversal!.continue().newRequest()
                .follow("log_metadata")
                .withTemplateParameters({ processType: logFileType }))
    }

    public collectLogs() {
        return halGetUrl("collectLogs", this.rootDoc.traversal!.continue().newRequest()
            .follow("all_logs"))
            .pipe(
                mergeMap(url => getBlob(url))
            );
    }

    public emailLogs(to: string[]) {
        return halGetUrl("emailLogs(GETURL)", this.rootDoc.traversal!.continue().newRequest()
            .follow("all_logs"))
            .pipe(
                mergeMap(url => postNoContent(url, JSON.stringify({ send_to: to }), { 'Content-Type': 'application/json' }))
            )
    }

    public reboot(force = false) {
        return halGetUrl("reboot", this.rootDoc.traversal!.continue()
            .follow("reboot")
            .withTemplateParameters({ force }))
            .pipe(
                mergeMap(url => postNoContent(url, {}, { 'Content-Type': 'application/json' }))
            )
    }
}