import { connectRouter } from "connected-react-router";
import { History } from 'history';
import { combineReducers } from "redux";
import adminLogs from "./adminLogs";
import app from "./app";
import auth from "./auth";
import bookit from "./bookit";
import cors from "./cors";
import escalationRules from './escalationRules';
import eventEscalations from "./eventEscalations";
import health from './health';
import licenseFiles from "./licenseFiles";
import metrics from "./metrics";
import nfcTags from './nfcTags';
import officeLocations from './officeLocations';
import organisations from './organisations';
import packages from "./packages";
import reporting from './reporting';
import rooms from './rooms';
import toasts from "./toasts";
import uc from "./uc";
import userAccounts from "./userAccounts";
import windowsUpdates from './windowsUpdates';
import videofx from './videoFx'
import resources from './resources'
import conferenceClients from "./conferenceClients";

export function createRootReducer(history: History) {
    return combineReducers({
        router: connectRouter(history),
        rooms,
        eventEscalations,
        packages,
        licenseFiles,
        metrics,
        health,
        cors,
        toasts,
        adminLogs,
        bookit,
        app,
        auth,
        userAccounts,
        conferenceClients,
        windowsUpdates,
        reporting,
        organisations,
        nfcTags,
        escalationRules,
        uc,
        officeLocations,
        videofx,
        resources
    })
}
