import { all } from "async-saga";
import { createAADClient } from "../../auth/azure-ad";
import { clientConfig } from "../../auth/clientConfig";
import { createIpfxTokenClient } from "../../auth/ipfxTokenClient";
import services from "../../services";
import { createApiClient } from "../../services/api";
import Config from '../../services/Config';
import { actions } from "../types";
import { takeEvery } from "./async-saga";

const scopes = ["https://graph.microsoft.com/User.Read"]

const createAuthedAccountSaga = takeEvery(actions.createAuthedAccount.request, async function (ctx) {
    console.log("CREATE_AUTHED_ACCOUNT_REQUEST SAGA")
    try {
        const config = clientConfig();
        console.log("AAD Client Config", config)
        const client = createAADClient(config)
        services.azureAdClient = client
        services.ipfxTokenClient = createIpfxTokenClient(Config.serverApiRoot, client, scopes)
        services.apiClient = createApiClient(Config.serverApiRoot, services.ipfxTokenClient)

        client.handleRedirectCallback((authErr, response) => {
            if (!response) {
                 // silent renew failed should send this to the store and raise error to ui
                 console.error("AAD silent renew failed:", authErr)
            } else {
                // update account with new details
                ctx.dispatch(actions.createAuthedAccount.success(response!.account))
            }
        })

        const account = client.getAccount()!
        if (account) {
            ctx.dispatch(actions.createAuthedAccount.success(account))
        } else {
            client.acquireRedirect([], scopes)
        }
    } catch (e) {
        console.error("Error in createAuthedAccount()", e)
        ctx.dispatch(actions.createAuthedAccount.failure(e))
    }
})

const logoutSaga = takeEvery(actions.signOutAuthedAccount, async function (ctx) {
    if (services.azureAdClient === null) return
    try {
        services.azureAdClient.logout()
    } catch (e) {
        console.error("logout failed", e)
    }
})

export default all(
    createAuthedAccountSaga,
    logoutSaga,
)