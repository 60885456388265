import { Button } from "@fluentui/react-northstar";
import bind from 'bind-decorator';
import classNames from 'classnames';
import moment from 'moment';
import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ButtonProps, FormGroup, Label } from 'reactstrap';
import { Radio } from './Radio';
import './RangeSelector.css';

interface RangeSelectorProps {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    selectedRange: string
    onApply: (startDate: moment.Moment, endDate: moment.Moment, range: string, description: string, toggle: boolean) => void;
    onCancel: () => void;
}

interface RangeSelectorState {
    startDate: Date | undefined;
    endDate: Date | undefined;
    selectedRange: string;
    lastSelectRanged: string;
}
export default class RangeSelector extends React.PureComponent<RangeSelectorProps, RangeSelectorState> {
    public state: RangeSelectorState = { startDate: new Date(), endDate: new Date(), selectedRange: "last7d", lastSelectRanged: "last7d" }

    public async componentDidMount() {
        if (this.props.startDate !== undefined) {
            this.setState({ startDate: this.props.startDate.toDate() });
        }
        if (this.props.endDate !== undefined) {
            this.setState({ endDate: this.props.endDate.toDate() });
        }

        this.updateRange(this.props.selectedRange);
        this.setState({ selectedRange: this.props.selectedRange });
    }

    public render() {
        const isCustom = this.state.selectedRange === 'custom'
        return <>
            <div className="range-edit-dialog">
                <div className="range-selector">
                    <div className='range-selector-columns'>
                        <div className="range-selector-column">
                            <FormGroup check>
                                <Label check>
                                    <Radio name="range_selection" onChange={this.onRadioChange} value="today" selected={this.state.selectedRange} />
                                    Today
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Radio name="range_selection" onChange={this.onRadioChange} value="last2d" selected={this.state.selectedRange} />
                                    Last 2 days
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Radio name="range_selection" onChange={this.onRadioChange} value="last3d" selected={this.state.selectedRange} />
                                    Last 3 days
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Radio name="range_selection" onChange={this.onRadioChange} value="last7d" selected={this.state.selectedRange} />
                                    Last 7 days
                                </Label>
                            </FormGroup>
                        </div>
                        <div className="range-selector-column">

                            <FormGroup check>
                                <Label check>
                                    <Radio name="range_selection" onChange={this.onRadioChange} value="last30d" selected={this.state.selectedRange} />
                                    Last 30 days
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Radio name="range_selection" onChange={this.onRadioChange} value="last90d" selected={this.state.selectedRange} />
                                    Last 90 days
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Radio name="range_selection" onChange={this.onRadioChange} value="all" selected={this.state.selectedRange} />
                                    All
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Radio name="range_selection" onChange={this.onRadioChange} value="custom" selected={this.state.selectedRange} />
                                    Custom
                                </Label>
                            </FormGroup>
                        </div>
                        <div className={classNames("range-selector-column", { "hide-column": this.state.selectedRange === "all" })}>
                            <div>Start Time</div>
                            <DatePicker
                                disabled={!isCustom}
                                selected={this.state.startDate}
                                filterDate={this.excludeFuture}
                                locale="en-GB"
                                onChange={this.handleStartChange}
                                dateFormat="E, dd MMMM yyyy"
                            />
                            <div>End Time</div>
                            <DatePicker
                                disabled={!isCustom}
                                selected={this.state.endDate}
                                locale="en-GB"
                                filterDate={this.excludeFuture}
                                onChange={this.handleEndChange}
                                dateFormat="E, dd MMMM yyyy"
                            />
                        </div>
                    </div>
                </div>
                <div className='range-selector-buttons'>
                    <Button size="small" primary className='mr-2' onClick={this.onApply}>Apply</Button>
                    <Button size="small" onClick={this.onCancel}>Cancel</Button>
                </div>
            </div>
        </>
    }

    @bind
    private excludeFuture(date: Date) {
        return new Date() > date;
    }

    @bind
    private onApply(event: React.SyntheticEvent<HTMLElement>, data?: ButtonProps) {
        this.props.onApply(moment(this.state.startDate), moment(this.state.endDate), this.state.selectedRange, getRangeToDescripton(this.state.selectedRange), true);
        this.setState({ lastSelectRanged: this.state.selectedRange });
    }

    @bind
    private onCancel(event: React.SyntheticEvent<HTMLElement>, data?: ButtonProps) {
        this.setState({ selectedRange: this.state.lastSelectRanged! });
        this.props.onCancel();
    }

    @bind
    private onRadioChange(evt: React.ChangeEvent<HTMLInputElement>) {
        const { value } = evt.currentTarget;
        this.setState({ selectedRange: value });
        this.updateRange(value);
    }

    private updateRange(value: string) {
        if (value !== 'custom') {
            this.setState(getRange(value))
        }
    }


    @bind
    private handleStartChange(date: Date, event: React.SyntheticEvent<any>) {
        this.setState({ startDate: date });
    }
    @bind
    private handleEndChange(date: Date, event: React.SyntheticEvent<any>) {
        this.setState({ endDate: date });
    }
}

export function getRange(value: string) {
    if (value === "today") {
        return { startDate: moment().startOf('day').toDate(), endDate: moment().endOf('day').toDate() };
    } else if (value === "yesterday") {
        const startDate = moment().startOf('day').subtract(1, 'day')
        return { startDate: startDate.toDate(), endDate: startDate.clone().endOf('day').toDate() };
    } else if (value === "last2d") {
        return { startDate: moment().startOf('day').subtract(1, 'day').toDate(), endDate: moment().endOf('day').toDate() };
    } else if (value === "last3d") {
        return { startDate: moment().startOf('day').subtract(2, 'days').toDate(), endDate: moment().endOf('day').toDate() };
    } else if (value === "last7d") {
        return { startDate: moment().startOf('day').subtract(6, 'days').toDate(), endDate: moment().endOf('day').toDate() }
    } else if (value === "lastw") {
        const startDate = moment().startOf('isoWeek').subtract(1, 'week')
        return { startDate: startDate.toDate(), endDate: startDate.clone().endOf('isoWeek').toDate() };
    } else if (value === "last30d") {
        return { startDate: moment().startOf('day').subtract(29, 'day').toDate(), endDate: moment().endOf('day').toDate() };
    } else if (value === "lastm") {
        const startDate = moment().startOf('day').subtract(1, 'month').startOf('month')
        return { startDate: startDate.toDate(), endDate: startDate.clone().endOf('month').toDate() };
    } else if (value === "lastq") {
        const startDate = moment().startOf('day').subtract(1, 'quarter').startOf('quarter')
        return { startDate: startDate.toDate(), endDate: startDate.clone().endOf('quarter').toDate() };
    } else if (value === "last3m") {
        const startDate = moment().startOf('day').subtract(3, 'month').startOf('month')
        return { startDate: startDate.toDate(), endDate: startDate.clone().add(2, 'months').endOf('month').toDate() };
    } else if (value === "last90d") {
        return { startDate: moment().startOf('day').subtract(89, 'day').toDate(), endDate: moment().endOf('day').toDate() };
    } else if (value === "all") {
        return { startDate: undefined, endDate: undefined };
    } else if (value === "custom") {
        return { startDate: undefined, endDate: undefined };
    } else {
        throw new Error(`Unsupported value ${value}`)
    }
}

export function getRangeToDescripton(value: string) {
    if (value === "today") {
        return "Today";
    } else if (value === "yesterday") {
        return "Yesterday";
    } else if (value === "last2d") {
        return "Last 2 days";
    } else if (value === "last3d") {
        return "Last 3 days";
    } else if (value === "last7d") {
        return "Last 7 days";
    } else if (value === "lastw") {
        return "Last week";
    } else if (value === "last30d") {
        return "Last 30 days";
    } else if (value === "lastm") {
        return "Last month";
    } else if (value === "last3m") {
        return "Last 3 months";
    } else if (value === "lastq") {
        return "Last quarter";
    } else if (value === "last90d") {
        return "Last 90 days";
    } else if (value === "all") {
        return "All";
    }
    return "Custom";
}