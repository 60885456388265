import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { Resource } from '../../model';
import { resourcesReducer } from '../resourceActions';
import { actions, Actions } from '../types';
import { ApiResourceState, defaultState } from './ApiResourceState';


type ResourcesState = ApiResourceState<Resource>


function resources(state: ResourcesState = defaultState, action: Actions): ResourcesState {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return resourcesReducer(state, action);
    }
}

export default combineReducers({
    resources
})
