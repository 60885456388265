import { AddIcon, Button, Checkbox, CloseIcon, Dialog, EditIcon, Flex, Loader, SettingsIcon, TrashCanIcon } from '@fluentui/react-northstar';
import { compare } from 'fast-json-patch';
import React, { useEffect, useMemo, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import ReactTable, { CellInfo } from 'react-table';
import { BookableResource, BookItSettingsOverride, IdType } from '../../model';
import { deleteBookableResource, fetchBookableResources, fetchProviders, patchBookableResource } from '../../store/resourceActions';
import { getActiveOrganisation, getBookableResources, getProviders } from '../../store/selectors';
import { useSelector } from '../../store/utils';
import ConfirmationDialog from '../controls/ConfirmationDialog';
import { ToolbarWithTooltips } from '../controls/ToolbarWithTooltips';
import AddBookItRoom from './AddBookItRoom';
import './BookItRoomPage.scss';
import BookableResourceSettings from './settings/BookableResourceSettings';

const BookItRoomPage: React.FC<{}> = (props) => {
    const [isAddFormOpen, setIsAddFormOpen] = useState(false)
    const [isSettingsFormOpen, setIsSettingsFormOpen] = useState(false)

    const { isCreating: isCreatingRoom, isLoading } = useSelector(s => s.bookit.bookableResources)
    const activeOrganisation = useSelector(getActiveOrganisation)

    const dispatch = useDispatch()
    useEffect(() => {
        if (activeOrganisation) {
            dispatch(fetchBookableResources.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch])
    const rooms = useSelector(getBookableResources)

    const [editRoomId, setEditRoomId] = useState<IdType>()
    const handleBookItRoomEdit = (bookItRoom: BookableResource) => {
        setEditRoomId(bookItRoom.bookable_resource_id)
        setIsAddFormOpen(true)
    }

    const [settingsBookableResourceId, setSettingsBookableResourceId] = useState<IdType>()
    const handleBookItResourceSettings = (bookableResource: BookableResource) => {
        setSettingsBookableResourceId(bookableResource.bookable_resource_id)
        setIsSettingsFormOpen(true)
    }


    const { isLoaded: isProvidersLoaded, isLoading: isProvidersLoading, failed: providersFailed } = useSelector(s => s.bookit.providers)
    useEffect(() => {
        if (activeOrganisation && !isProvidersLoaded && !isProvidersLoading && !providersFailed) {
            dispatch(fetchProviders.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch, isProvidersLoaded, isProvidersLoading, providersFailed])
    const providers = useSelector(getProviders)
    const allowAddRoom = useMemo(() => isProvidersLoaded && providers.length > 0, [isProvidersLoaded, providers])

    const [bookItRoomToDelete, setBookItRoomToDelete] = useState<BookableResource>()
    const handleConfirmDelete = (room: BookableResource) => {
        setBookItRoomToDelete(room)
    }

    const handleBookItRoomDelete = () => {
        dispatch(deleteBookableResource.request(bookItRoomToDelete!))
        setBookItRoomToDelete(undefined)
    }
    function renderStatus(active: boolean) {
        return active ? <FaCheckCircle color="green" size="2em" /> : <FaTimesCircle color="red" size="2em" />
    }

    function renderOverrides(cellInfo: CellInfo) {
        const room = cellInfo.original

        return hasSettingsOverride(room) ? <FaCheckCircle color="green" size="2em" /> : null
    }

    function onActiveChanged(room: BookableResource) {
        const updatedRoom = { ...room, is_active: !room.is_active }
        const diff = compare(room, updatedRoom)
        dispatch(patchBookableResource.request({ id: updatedRoom, operations: diff }))
    }

    function renderToggle(cellInfo: CellInfo, column: any) {
        const room = cellInfo.original
        return <Checkbox checked={cellInfo.value} toggle onChange={() => onActiveChanged(room)} />
    }

    const renderActions = (cellInfo: CellInfo) => {
        return (<ToolbarWithTooltips className='right-align'
            items={[{
                key: 'settings',
                icon: <SettingsIcon outline />,
                tooltip: 'Settings for Resource',
                onClick: () => handleBookItResourceSettings(cellInfo.original)
            }, {
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: 'Edit Resource',
                onClick: () => handleBookItRoomEdit(cellInfo.original)
            }, {
                key: 'delete',
                icon: <TrashCanIcon outline />,
                tooltip: 'Delete this Resource',
                onClick: () => handleConfirmDelete(cellInfo.original)
            }]}
        />)
    }

    const roomData = useMemo(() => rooms.map(room => ({ ...room, active: !room.is_closed, attribute_ids: room.attribute_ids }))
        , [rooms])

    const addBookItButton = <Button primary content='Add Resource' icon={<AddIcon />} iconPosition='before' loading={isCreatingRoom} styles={{ marginBottom: '0.5rem' }} />

    const addBookItContent = isCreatingRoom
        ? <Flex hAlign='end'>{addBookItButton}</Flex>
        : (<Flex hAlign='end'>
            {allowAddRoom
                ? <Dialog
                    content={<AddBookItRoom
                        bookable_resource_id={editRoomId}
                        onSubmit={() => setIsAddFormOpen(false)}
                        onCancel={() => {
                            setEditRoomId(undefined)
                            setIsAddFormOpen(false)
                        }}
                        existingBookableResources={rooms}
                        providers={providers}
                    />}
                    header="Add/Edit Resource"
                    open={isAddFormOpen}
                    onOpen={() => setIsAddFormOpen(true)}
                    trigger={addBookItButton}
                />
                : <Dialog
                    confirmButton="OK"
                    content='Must define a valid provider first'
                    header="Can't add resources"
                    trigger={addBookItButton}
                />}
        </Flex>)

    const bookItSettingsContent = <Dialog
        content={<BookableResourceSettings bookable_resource_id={settingsBookableResourceId!}
            onSubmit={() => {
                setIsSettingsFormOpen(false)
                setSettingsBookableResourceId(undefined)
            }} />}
        headerAction={{
            icon: <CloseIcon />,
            title: 'Close',
            onClick: () => {
                setSettingsBookableResourceId(undefined)
                setIsSettingsFormOpen(false)
            }
        }}
        header="Bookable Resource Settings"
        open={isSettingsFormOpen}
        onOpen={() => setIsSettingsFormOpen(true)}
    />

    return <Flex column className='BookItRoomPage'>
        <h5>Resources</h5>
        {addBookItContent}
        {bookItSettingsContent}
        <ReactTable
            className="-highlight MeetingRoomList"
            showPagination={false}
            key={roomData.length === 0 ? "nodata" : "havedata"}
            defaultPageSize={999}
            minRows={roomData.length === 0 ? 15 : roomData.length}
            columns={[
                // { Header: "#", accessor: "seq", maxWidth: 30, Cell: (cell: CellInfo) => <p className='font-weight-bold'>{cell.viewIndex + 1}</p> },
                { Header: "Resource Name", accessor: "name" },
                { Header: "Resource Type", accessor: "sub_type" },
                { Header: "Status", accessor: "is_active", Cell: (data) => renderStatus(data.value), minWidth: 20 },
                { Header: "Setting Overrides", id: "has_overrides", Cell: renderOverrides, minWidth: 35 },
                { Header: "", accessor: "is_active", className: 'right-align', Cell: renderToggle },
                { Header: "", id: "edit_delete", Cell: renderActions }
            ]}
            defaultSorted={([{ id: "id", desc: false }])}
            data={roomData}
            loadingText={<Loader label='Loading...' />}
            loading={isLoading}
        />
        <ConfirmationDialog onConfirm={handleBookItRoomDelete}
            onCancel={() => setIsAddFormOpen(false)}
            message={`Are you sure you wish to delete the resource ${(bookItRoomToDelete ? bookItRoomToDelete!.name : "")}?`}
            heading='Delete Resource'
            confirmButtonText='Delete'
            isOpen={bookItRoomToDelete !== undefined} />
    </Flex>
}

type OverrideKey = keyof BookItSettingsOverride

function hasSettingsOverride(room: BookableResource) {
    if (room.settings.overrides === undefined) return false

    const props: OverrideKey[] = [
        'ad_hoc_enabled',
        'max_early_start_minutes',
        'auto_end_meeting_minutes',
        'meeting_summary_enabled',
        'replace_meeting_subject_enabled',
        'room_sizes_filter',
        'auto_end_started_meeting_override',
        'device_background_url',
        'allow_attendee_editing',
        'allow_secondary_login_auto_complete',
        'invite_external_attendees_enabled',
        'working_hours_enabled',
        'default_working_hours',
        'health_check_report',
        'recurrence_auto_end'
    ]

    for (const prop of props) {
        if (room.settings.overrides[prop] !== undefined && room.settings.overrides[prop]) {
            return true
        }
    }

    return false
}
export default BookItRoomPage;