import { AddIcon, Button, Dialog, EditIcon, Flex, Loader, Toolbar, TrashCanIcon } from "@fluentui/react-northstar";
import { compare } from "fast-json-patch";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTable, { CellInfo } from "react-table";
import { IdType, RoomoteDevice } from "../../model";
import { createRoomoteDevice, deleteRoomoteDevice, fetchRoomoteDevices, patchRoomoteDevice } from "../../store/resourceActions";
import { getActiveOrganisation, getRoomoteDevices } from "../../store/selectors";
import { actions } from '../../store/types';
import { useSelector } from '../../store/utils';
import { Guid } from "../../utils/guid";
import { roomsFilter } from '../../utils/roomFilters';
import ConfirmationDialog from "../controls/ConfirmationDialog";
import AddDevice, { CreateRoomoteDevice } from "./AddRoomoteDevice";
import './RoomoteDevices.scss';

export interface IRoomData {
    seq: number
    name: string
    room_id?: IdType
    display_name: string
    email_address: string
}

function RoomoteDevices() {

    const isCreating = useSelector(s => s.videofx.roomoteDevices.isCreating)
    const isDeviceLoading = useSelector(s => s.videofx.roomoteDevices.isLoading)
    const activeOrganisation = useSelector(getActiveOrganisation)

    const dispatch = useDispatch()
    useEffect(() => {
        if (activeOrganisation) {
            console.log('fetchRoomoteDevices org', activeOrganisation.organisation_id)
            dispatch(fetchRoomoteDevices.request({ organisation_id: activeOrganisation.organisation_id }))
            
        }
    }, [activeOrganisation, dispatch])

    const { isLoaded, isLoading } = useSelector(s => s.rooms)

    useEffect(() => {

        if (!isLoaded && !isLoading) {
            dispatch(actions.fetchRooms.request(activeOrganisation.organisation_id))
        }
    }, [activeOrganisation, dispatch, isLoaded, isLoading])
    
    const devices = useSelector(getRoomoteDevices)

    const rooms = useSelector(s => s.rooms)

    const allRooms: IRoomData[] | undefined = useMemo(() => {
        if (rooms.isLoading || rooms.rooms === undefined) {
            return undefined
        }
        return (Object.keys(rooms.rooms)
            .map(key => rooms.rooms[key]))
            .filter(room => roomsFilter(rooms.roomFilter)(room.resource))
            .map((room, index) => ({
                seq: index,
                name: room.resource.name,
                room_id: room.resource.room_id,
                display_name: room.resource.display_name,
                email_address: room.resource.email_address
            }))
    }, [rooms])

    const [addFormOpen, setAddFormOpen] = useState(false)

    const [deviceToEdit, setDeviceToEdit] = useState<RoomoteDevice>()
    const handleDeviceEdit = (device: RoomoteDevice) => {
        setDeviceToEdit(device)
        setAddFormOpen(true)
    }

    const handleAddUpdateDevice = useCallback((device: CreateRoomoteDevice) => {
        
        console.log('handleAddUpdateDevice', device)
        setAddFormOpen(false)
        
        if (activeOrganisation === undefined) {
            console.error("No active organisation!")
            return
        }
        const { ...newDevice } = device
        //   const ids = rooms && rooms.map(r => r.bookable_resource_id)
        const updatedDevice = {
            ...newDevice,
            organisation_id: activeOrganisation.organisation_id
        }
        if (deviceToEdit) {
            // take out original roomIds so we always treat them as an add operation
            const original = { device_name: deviceToEdit.device_name, 
                                device_pin: deviceToEdit.device_pin, 
                                organisation_id: deviceToEdit.organisation_id,
                                room_id: deviceToEdit.room_id }

            const diff = compare(original, updatedDevice);
            console.log("original", original)
            console.log("updatedDevice", updatedDevice)
            dispatch(patchRoomoteDevice.request({ id: { organisation_id:deviceToEdit.organisation_id, roomote_device_id: deviceToEdit.roomote_device_id}, operations: diff }))

        } else {
            dispatch(createRoomoteDevice.request(updatedDevice))
        }
        setDeviceToEdit(undefined)
    }, [activeOrganisation, deviceToEdit, dispatch])

    const existingDevices = useMemo(() => {
        return deviceToEdit ? devices.filter(l => l.device_name !== deviceToEdit.device_name).map(l => l.device_name) : devices.map(l => l.device_name)
    }, [devices, deviceToEdit])

    const [deviceToDelete, setDeviceToDelete] = useState<RoomoteDevice>()
    const handleConfirmDelete = (device: RoomoteDevice) => {    
        console.log('handleConfirmDelete', device);
            
        setDeviceToDelete(device)
    }

    const handleDeviceDelete = () => {
        console.log('handleDeviceDelete', deviceToDelete);
        dispatch(deleteRoomoteDevice.request(deviceToDelete!))
        setDeviceToDelete(undefined)
    }

    const deviceData = useMemo(
        () => devices.map(device => ({
            roomote_device_id: device.roomote_device_id,
            room_id: device.room_id,
            device_name: device.device_name,
            device_pin: device.device_pin,
            organisation_id: device.organisation_id
        }))
        , [devices])

    console.log("deviceData: ", deviceData)

    const renderActions = (cellInfo: CellInfo) => {
        const device: typeof deviceData[0] = cellInfo.original

        console.log('renderActions', device);
        

        return (<Toolbar className='right-align'
            items={[{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: 'Edit Device',
                onClick: () => handleDeviceEdit(device)
            }, {
                key: 'delete',
                icon: <TrashCanIcon outline/>,
                tooltip: 'Delete this Device',
                onClick: () => handleConfirmDelete(device)
            }]}
        />)
    }

    const renderPrimaryRoom = (cellInfo: CellInfo) => {
        const device: typeof deviceData[0] = cellInfo.original

        console.log('renderPrimaryRoom device', device);
        console.log('renderPrimaryRoom allRooms', allRooms);

        if (device.room_id && allRooms) {
            const deviceRoom = allRooms.find(_ => _.room_id === device.room_id)
            if (deviceRoom) {
                return <span>{`${deviceRoom.name}`}</span>
            }
        }
        return <span></span>
    }

   

    const addDeviceButton = <Button primary content='Add Device' icon={<AddIcon />} iconPosition='before' disabled={isCreating} styles={{ marginBottom: '0.5rem' }} />
    const addDeviceContent = isCreating
        ? <Flex hAlign='end'>{addDeviceButton}</Flex>
        : (<Flex hAlign='end'>
            <Dialog
                content={<AddDevice
                    initial={getDeviceToEdit(allRooms!, deviceToEdit)}
                    allRooms={allRooms!}
                    existingDevices={existingDevices}
                    onSubmit={handleAddUpdateDevice}
                    onCancel={() => {
                        setDeviceToEdit(undefined)
                        setAddFormOpen(false)
                    }} />}
                header={deviceToEdit === undefined ? "Add a new Roomote device" : "Edit a Roomote Device"}
                open={addFormOpen}
                onOpen={() => setAddFormOpen(true)}
                trigger={addDeviceButton}
            />
        </Flex>)



    return <Flex column className='DevicesPage'>
        <h5>Roomote Devices</h5>
        {addDeviceContent}
        <ReactTable
            className="-highlight"
            showPagination={false}
            key={deviceData.length === 0 ? "nodata" : "havedata"}
            defaultPageSize={999}
            minRows={deviceData.length === 0 ? 15 : deviceData.length}
            columns={[
                { Header: "Name", accessor: "device_name" },
                { Header: "Room", Cell: renderPrimaryRoom },
                { Header: "Pin", accessor: "device_pin"},
                { Header: "", id: "edit_delete", Cell: renderActions }
            ]}
            defaultSorted={([{ id: "device_name", desc: false }])}
            data={deviceData}
            loadingText={<Loader label='Loading...' />}
            loading={isDeviceLoading}
        />

        <ConfirmationDialog onConfirm={handleDeviceDelete}
            onCancel={() => setAddFormOpen(false)}
            message={`Are you sure you wish to delete the device ${(deviceToDelete ? deviceToDelete!.device_name : "")}?`}
            heading='Delete Roomote device'
            confirmButtonText='Delete'
            isOpen={deviceToDelete !== undefined} />

    </Flex>
}

function getDeviceToEdit(all: IRoomData[], existingDevice?: RoomoteDevice): CreateRoomoteDevice {
    if (existingDevice) {
        console.log("existingDevice", existingDevice)
        var device = {
            device_name: existingDevice.device_name,            
            device_pin: existingDevice.device_pin,
            room_id: existingDevice.room_id,
        }
        return device
    }
    return { device_name: '', device_pin: '', room_id: Guid.createEmpty() }
}

export default RoomoteDevices;
