import { Operation } from "fast-json-patch";
import { AnyAction } from "redux";
import { combineEpics, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { ActionCreatorBuilder, createAction, getType, isActionOf, PayloadActionCreator } from "typesafe-actions";
import { Services } from "../services";
import { ApiClient } from "../services/api";
import { errorHandler } from "./actions";
import { actionFail, ApiResourceState, creatingFail, creatingRequest, defaultState, deleteSuccess, fetchAllFail, fetchAllRequest, fetchAllSuccess, fetchCreateOrUpdateSuccess, patchFail, patchRequest } from "./reducers/ApiResourceState";
import { RootState } from "./types";

export type TActionArg = string | number | {}

function defaultApiGetAll<TResource>
    (apiClient: ApiClient, urlPath: string) {
    return apiClient.get<Array<TResource>>(urlPath)
}

function defaultApiGet<TResource>
    (apiClient: ApiClient, urlPath: string) {
    return apiClient.get<TResource>(urlPath)
}

function defaultApiCreate<TResource, TCreateResource>
    (apiClient: ApiClient, urlPath: string, body: TCreateResource) {
    return apiClient.post<TResource>(urlPath, body)
}

function defaultApiDelete(apiClient: ApiClient, urlPath: string) {
    return apiClient.del(urlPath)
}

function defaultApiPatch<TResource>
    (apiClient: ApiClient, urlPath: string, operations: Operation[]) {
    return apiClient.patch<TResource>(urlPath, operations)
}


export interface ApiFunctions<TResource extends {}, TCreateResource extends {}> {
    apiGetAll?: typeof defaultApiGetAll
    apiGet?: typeof defaultApiGet
    apiCreate?: typeof defaultApiCreate
    apiDelete?: typeof defaultApiDelete
    apiPatch?: typeof defaultApiPatch
}


export const createApiResourceActions = <TResource extends {}, TCreateResource extends {}>() =>
    <TKeyName extends keyof TResource, TArgs extends Partial<TResource>, TPartialArgs extends TActionArg>(resourceName: string,
        fullUrlPath: (arg: TArgs) => string,
        partialUrlPath: (arg: TPartialArgs) => string,
        getKey: (res: TResource) => string,
        apiFunctions: ApiFunctions<TResource, TCreateResource> = {}) => {

        const {
            apiGetAll = defaultApiGetAll,
            apiGet = defaultApiGet,
            apiCreate = defaultApiCreate,
            apiDelete = defaultApiDelete,
            apiPatch = defaultApiPatch,
        } = apiFunctions

        const fetchAllAction = createActions<TPartialArgs, Array<TResource>>(`FETCH_ALL_${resourceName}_REQUEST`, `FETCH_ALL_${resourceName}_SUCCESS`, `FETCH_ALL_${resourceName}_FAILURE`);
        const fetchAllEpic: Epic<AnyAction, AnyAction, RootState, Services> = (action$, store, services) => {
            return action$.pipe(
                filter(isActionOf(fetchAllAction.request)),
                mergeMap(action => {
                    return from(apiGetAll<TResource>(services.apiClient!, partialUrlPath(action.payload))).pipe(
                        mergeMap(res => of(fetchAllAction.success(res))),
                        catchError(err => of(fetchAllAction.failure(err), errorHandler(err)))
                    )
                }));
        }

        const fetchAction = createActions<TArgs, TResource>(`FETCH_${resourceName}_REQUEST`, `FETCH_${resourceName}_SUCCESS`, `FETCH_${resourceName}_FAILURE`)
        const fetchEpic: Epic<AnyAction, AnyAction, RootState, Services> = (action$, store, services) => {
            return action$.pipe(
                filter(isActionOf(fetchAction.request)),
                mergeMap((action) =>
                    from(apiGet<TResource>(services.apiClient!, fullUrlPath(action.payload))).pipe(
                        mergeMap(res => of(fetchAction.success(res))),
                        catchError(err => of(fetchAction.failure(err), errorHandler(err)))
                    )));
        }

        const createAction = createActions<TCreateResource, TResource>(`CREATE_${resourceName}_REQUEST`, `CREATE_${resourceName}_SUCCESS`, `CREATE_${resourceName}_FAILURE`)
        const createEpic: Epic<AnyAction, AnyAction, RootState, Services> = (action$, store, services) => {
            return action$.pipe(
                filter(isActionOf(createAction.request)),
                mergeMap((action: AnyAction) =>
                    from(apiCreate<TResource, TCreateResource>(services.apiClient!, partialUrlPath(action.payload), action.payload)).pipe(
                        mergeMap(res => of(createAction.success(res))),
                        catchError(err => of(createAction.failure(err), errorHandler(err)))
                    )));
        }

        const deleteAction = createActions<TResource, TResource>(`DELETE_${resourceName}_REQUEST`, `DELETE_${resourceName}_SUCCESS`, `DELETE_${resourceName}_FAILURE`)
        const deleteEpic: Epic<AnyAction, AnyAction, RootState, Services> = (action$, store, services) => {
            return action$.pipe(
                filter(isActionOf(deleteAction.request)),
                mergeMap((action: AnyAction) =>
                    from(apiDelete(services.apiClient!, fullUrlPath(action.payload))).pipe(
                        mergeMap(() => of(deleteAction.success(action.payload))),
                        catchError(err => of(deleteAction.failure(err), errorHandler(err)))
                    )));
        }


        const patchAction = createActions<{ id: TArgs, operations: Operation[] }, TResource>(`PATCH_${resourceName}_REQUEST`, `PATCH_${resourceName}_SUCCESS`, `PATCH_${resourceName}_FAILURE`)
        const patchEpic: Epic<AnyAction, AnyAction, RootState, Services> = (action$, store, services) => {
            return action$.pipe(
                filter(isActionOf(patchAction.request)),
                mergeMap(action =>
                    from(apiPatch<TResource>(services.apiClient!, fullUrlPath(action.payload.id), action.payload.operations)).pipe(
                        mergeMap(res => of(patchAction.success(res))),
                        catchError(err => of(patchAction.failure(err), errorHandler(err)))
                    )));
        }

        const reducer = (state: ApiResourceState<TResource> = defaultState as any, action: AnyAction): ApiResourceState<TResource> => {
            switch (action.type) {
                case getType(fetchAllAction.request):
                    return fetchAllRequest(state)
                case getType(fetchAllAction.success):
                    return fetchAllSuccess<typeof state, TResource>(state, action.payload, getKey)
                case getType(fetchAllAction.failure):
                    return fetchAllFail(state)
                case getType(createAction.request):
                    return creatingRequest(state);
                case getType(patchAction.request):
                    return patchRequest(state);
                case getType(createAction.failure):
                    return creatingFail(state);
                case getType(fetchAction.failure):
                case getType(deleteAction.failure):
                    return actionFail(state);
                case getType(patchAction.failure):
                    return patchFail(state);
                case getType(fetchAction.success):
                case getType(createAction.success):
                case getType(patchAction.success):
                    return fetchCreateOrUpdateSuccess<TResource>(state, getKey(action.payload), action.payload);
                case getType(deleteAction.success):
                    return deleteSuccess(state, getKey(action.payload))
                default:
                    return state;
            }
        }

        const epics = combineEpics(fetchAllEpic, fetchEpic, createEpic, deleteEpic, patchEpic)

        return { fetchAllAction, fetchAction, createAction, deleteAction, patchAction, reducer, epics }
    }

export type ApiAction<TReq, TRes> = {
    request: PayloadActionCreator<string, TReq>
    success: ActionCreatorBuilder<string, TRes>
    failure: PayloadActionCreator<string, Error>
}

function createActions<TReq, TRes>(req: string, res: string, err: string): ApiAction<TReq, TRes> {
    return {
        request: createAction(req, (arg: TReq) => arg)() as any,
        success: createAction(res, (arg: TRes) => arg)() as any,
        failure: createAction(err, (arg: Error) => arg)(),
    }
}
