import { push } from "connected-react-router";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import App from "./App";
import SignedOut from "./components/auth/SignedOut";
import { FullscreenLoader } from "./components/controls/FullscreenLoader";
import { ServerUnvailable } from "./components/ServerUnavailable";
import { ApiResult } from "./store/reducers/ApiResult";
import { actions } from "./store/types";
import { useSelector } from "./store/utils";

type Props = RouteComponentProps<{}>

const RoutesModule: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch()

    const { account } = useSelector(s => s.auth)
    const { location } = useSelector(s => s.router)

    useEffect(() => {
        if (!account.isRequested && location.pathname !== '/signedOut') {
            dispatch(actions.createAuthedAccount.request())
        }
    }, [account.isRequested, dispatch, location.pathname])
    

    if (location.pathname === '/serverUnvailable') {
        const p = new URLSearchParams(props.location.search)
        const error = p.get("error")
        const title = p.get("title")
        return <ServerUnvailable title={title ?? "Server unavailable"} error={error ?? "Unknown"}
            onRetry={() => dispatch(push('/'))}
        />
    }

    if (location.pathname === '/signedOut') {
        return <SignedOut/>
    }

    const routes = <Switch>
        <Route exact path='/'>
            <App />
        </Route>
        <Route>
            <App />
        </Route>
    </Switch>

    return <>
        {account.isLoading && <FullscreenLoader message='Signing in...' />}
        {ApiResult.isSuccess(account) && routes}
    </>
}


export default RoutesModule