import { Provider as StardustProvider, teamsTheme } from '@fluentui/react-northstar';
import { ConnectedRouter } from 'connected-react-router';
import enGb from 'date-fns/locale/en-GB';
import * as React from 'react';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-table/react-table.css';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import RoutesModule from './routes';
import { history, store } from './store';

registerLocale('en-GB', enGb)


const rootElement = document.getElementById('root');

// tslint:disable-next-line:jsx-no-lambda
const routes = <Route path="*" render={(props) => <RoutesModule {...props} />} />;

ReactDOM.render(
  <StardustProvider theme={teamsTheme}>
    <Provider store={store}>
      <ConnectedRouter history={history} children={routes} />
    </Provider>
  </StardustProvider>,
  rootElement);



