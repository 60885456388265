import { Button, EditIcon, Input, Dialog, Dropdown } from '@fluentui/react-northstar';
import { compare } from 'fast-json-patch';
import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { UcSite } from '../../model';
import { patchUcSite } from '../../store/resourceActions';
import { RoomPropertyEditInput, RoomPropertyEditPopup } from '../videofx/room/RoomPropertyEditPopup';
import { RoomPropertyField } from '../videofx/room/RoomPropertyField';
import css from './SiteCard.module.scss';
import moment from "moment";
import { useSelector } from '../../store/utils';

interface SiteCardProps {
    site: UcSite
}

interface PopupState {
    display_name: boolean;
}


export function SiteCard({ site }: SiteCardProps) {

    const dispatch = useDispatch()
    const { resources } = useSelector(s => s.uc.sites)
    const sites = useMemo(() => Object.values(resources), [resources])

    // ===================================================================
    // Display name edit
    // ===================================================================

    const [popupState, setPopupState] = useState<PopupState>(
        {
            display_name: false,
        })

    const onChangeClicked = useCallback((id: string) => {
        setPopupState({
            ...popupState,
            [id]: !popupState[id]
        })
    }, [popupState])

    const closePopup = useCallback((id: string) => {
        setPopupState({
            ...popupState,
            [id]: false
        })
    }, [popupState])

    // ===================================================================
    // Generic save
    // ===================================================================
    const saveChanges = useCallback((propertyName: string, value?: string) => {
        const updated = {...site, [propertyName]: value}
        const diff = compare(site, updated)
        console.log("Updating UcSite", diff)
        dispatch(patchUcSite.request({id: site, operations: diff}))        
        closePopup(propertyName)
        
    }, [closePopup, dispatch, site])

    // ===================================================================
    // Server group edit
    // ===================================================================

    const [editGroupDialogueIsOpen, setEditGroupDialogueIsOpen] = useState(false)
    const [groupToUpdate, setGroupToUpdate] = useState<string>()

    const allGroups = () => {        
        const groups: string[] = []

        sites.forEach(s => addToGroups(s.server_group, groups))
        return groups
    }

    function addToGroups(name:string, list: string[]) {
        if (name && !list.some(g => g === name)) {
            list.push(name)
        }
    }

    // =============================================================
    // Edit group
    // =============================================================

    const editGroupButton = <Button title='Update Group' text iconOnly icon={<EditIcon />} />

    const editGroupContent =  

        <div className={css.column}>
            <div>Select an existing group</div>
            <Dropdown className={css.dropDown} 
                items={allGroups()} 
                search
                placeholder="Select a group" 
                onChange={(e, d) => setGroupToUpdate(d.value as string)}
                onSearchQueryChange={(e,d) => setGroupToUpdate(d.searchQuery)}    
                fluid={true} 
            />
        </div>

    const editGroup = <Dialog header='Update Group' open={editGroupDialogueIsOpen} 
        confirmButton="Apply"
        cancelButton="Cancel"
        onConfirm={(e, p) => {            
            handleUpdateGroup()
        }}
        trigger={editGroupButton}
        onOpen={() => setEditGroupDialogueIsOpen(true)}
        onCancel={e => setEditGroupDialogueIsOpen(false)}
        content={editGroupContent}
    />

    

    const handleUpdateGroup = useCallback(() => {
        
        setEditGroupDialogueIsOpen(false)

        if (site !== undefined) {            
           saveChanges('server_group', groupToUpdate)
        }

    }, [groupToUpdate, saveChanges, site])

    // =============================================================
    // Edit customer name
    // =============================================================

    const [customerNameToUpdate, setCustomerNameToUpdate] = useState<string>()
    const [editCustomerNameDialogueIsOpen, setEditCustomerNameDialogueIsOpen] = useState(false)

    const editNameButton = <Button title='Update Name' text iconOnly icon={<EditIcon />} />

    const editName = <Dialog header='Update Customer Name' open={editCustomerNameDialogueIsOpen} 
        confirmButton="Apply"
        cancelButton="Cancel"
        onConfirm={(e, p) => {            
            handleUpdateName()
        }}
        trigger={editNameButton}
        onOpen={() => setEditCustomerNameDialogueIsOpen(true)}
        onCancel={e => setEditCustomerNameDialogueIsOpen(false)}
        content={<Input fluid onChange={(event, data) => {
            if (data && data.value) {
                setCustomerNameToUpdate(data.value as string)
            }
        }} />}
    />

    

    const handleUpdateName = useCallback(() => {
        
        setEditCustomerNameDialogueIsOpen(false)

        if (site !== undefined) {            
           saveChanges('customer_id', customerNameToUpdate)
        }

    }, [customerNameToUpdate, saveChanges, site])


    
    return <>
        <div className={css.siteCard}>
            <div className={css.header} >
                <div className={css.row1}>
                    <h6>{site.display_name}</h6>
                    <Button icon={<EditIcon />} text onClick={() => onChangeClicked('display_name')} className={css.editButton} />
                </div>
                <span className={css.row2}>{site.server_name}</span>
            </div>
            <div className={css.properties}>
                <div className={css.column}>
                    <div className={css.row}>
                        <RoomPropertyField label='Customer Name' content={site.customer_id} />
                        {editName}
                    </div>
                    <RoomPropertyField label='Site' content={site.customer_name} />
                    <RoomPropertyField label='Server' content={site.server_name} />

                </div>
                
                <div className={css.column}>
                    <RoomPropertyField label='Installation ID' content={getAttribute(site, 'installation_id')} />
                    <RoomPropertyField label='Serial Number' content={getAttribute(site, 'serial_number')} />
                    <RoomPropertyField label='Version' content={getAttribute(site, 'version')} />
                </div>
                
                <div className={css.column}>
                    <RoomPropertyField label='Last Seen' content={moment.utc(site.last_activity_utc).local().format('MMM Do YYYY, h:mm:ss a')} />
                    <RoomPropertyField label='Last Event Count' content={site.last_event_count} />
                    <div className={css.row}>
                        <RoomPropertyField label='Server Group' content={site.server_group === undefined ? '<Unassigned>' : site.server_group} />
                        {editGroup}
                    </div>
                   
                </div>

            </div>

        </div>
        <RoomPropertyEditPopup title='Edit Display Name' isOpen={popupState.display_name}
                    content={<RoomPropertyEditInput label='Display Name' defaultValue={site.display_name}
                        propertyName='display_name' onSubmit={saveChanges} onCancel={closePopup} />} />

       
    </>
}

function getAttribute(site: UcSite, key: string): string {
    return site.attributes[key]?.value.toString() ?? "Not Set"
}