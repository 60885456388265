
import { Loader } from '@fluentui/react-northstar';
import * as React from 'react';
import './FullscreenLoader.css';

interface Props {
    message: string;
}

export function FullscreenLoader(props: Props) {
    return (
        <div className="loading">
            <Loader label={props.message} size="large" />
        </div>
    );
}

