import { all } from "async-saga";
import { isFinalState, VirusScan } from "../../model";
import services from "../../services";
import { ApiError, getApiError } from "../../utils/error";
import { actions } from "../types";
import { takeEvery } from "./async-saga";

const fetchVirusScansSaga = takeEvery(actions.fetchVirusScans.request, async function (ctx) {
    const api = services.apiClient
    if (api === null) return
    
    try {
        const scans = await api.get<VirusScan[]>(`/viruscans`)
        ctx.dispatch(actions.fetchVirusScans.success(scans))
        
    } catch (e) {
        console.error("fetchVirusScansSaga: ", e)
        ctx.dispatch(actions.fetchVirusScans.failure(await getApiError(e)))
    }
})

const backgroundOperationChangeSaga = takeEvery(actions.backgroundOperationChange, async function (ctx, operation) {
    const scans = ctx.getState().packages.virus_scans
    const uploadingNewVersionForFeedName = ctx.getState().packages.uploadingNewVersionForFeedName
    
    if (scans === null) return

    const scan = scans.find(s => s.background_operation_id === operation.background_operation_id)
    if (scan) {
        if (isFinalState(operation.state) && uploadingNewVersionForFeedName === scan.package_feed_name) {
            ctx.dispatch(actions.clearUploadNewPackageVersion())
            ctx.dispatch(actions.fetchPackageFeeds.request())
        }
        ctx.dispatch(actions.fetchVirusScans.request())
    }
})

const uploadNewPackageVersionSaga = takeEvery(actions.uploadNewPackageVersion.request, async function (ctx, args) {
    const serverApi = services.serverApi

    try {
        const result = await serverApi.uploadNewPackageVersion(args.packageName, args.file).toPromise()
        console.log("Upload new package version returned", result)
        ctx.dispatch(actions.uploadNewPackageVersion.success(result))
        // should be a in progress virus scan now       
        ctx.dispatch(actions.fetchVirusScans.request())
    } catch (err) {
        console.error("uploadNewPackageVersion: ", err)
        ctx.dispatch(actions.uploadNewPackageVersion.failure(await getApiError(err)))
        ctx.dispatch(actions.customErrorHandler({err: err, errorMessage: uploadPackageErrorMessage}))
    }
})


function uploadPackageErrorMessage(apiError: ApiError) : string|null {
    if (apiError.report) {
        switch (apiError.report.api_error_code) {
            case 'version_already_exists': return 'A version of this package has already been uploaded to the server'
            case 'unsupported_package_file_type': return 'This package file type is not supported by the server'
        }
    }
    return null
}

export default all(
    fetchVirusScansSaga,
    backgroundOperationChangeSaga,
    uploadNewPackageVersionSaga
)