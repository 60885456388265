import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { Services } from "../../services";
import { createCorsAllowedOrigin, deleteCorsAllowedOrigin, errorHandler, fetchCorsAllowedOrigins, patchCorsAllowedOrigin } from "../actions";
import { RootAction, RootState } from "../types";

export const fetchCorsAllowedOriginsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchCorsAllowedOrigins.request)),
        mergeMap(action =>
            serverApi.fetchCorsAllowedOrigins().pipe(
                switchMap(hal => of(fetchCorsAllowedOrigins.success(hal))),
                catchError(err => of(fetchCorsAllowedOrigins.failure(err), errorHandler(err)))
            )));
}

export const createCorsAllowedOriginEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(createCorsAllowedOrigin.request)),
        mergeMap(action =>
            serverApi.createCorsAllowedOrigin(action.payload).pipe(
                switchMap(hal => of(createCorsAllowedOrigin.success(hal))),
                catchError(err => of(createCorsAllowedOrigin.failure(err), errorHandler(err)))
            )));
}

export const deleteCorsAllowedOriginEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(deleteCorsAllowedOrigin.request)),
        switchMap(action =>
            from(serverApi.deleteCorsAllowedOrigin(action.payload)).pipe(
                map(() => deleteCorsAllowedOrigin.success(action.payload.cors_allowed_origin_id!)),
                catchError(err => of(deleteCorsAllowedOrigin.failure(err), errorHandler(err)))
            )));
}

export const patchCorsAllowedOriginEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(patchCorsAllowedOrigin.request)),
        switchMap(action =>
            from(serverApi.patchCorsAllowedOrigin(action.payload.id, action.payload.operations)).pipe(
                map(res => patchCorsAllowedOrigin.success(res)),
                catchError(err => of(patchCorsAllowedOrigin.failure(err), errorHandler(err)))
            )));
}
