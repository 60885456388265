import { createSagaMiddleware } from 'async-saga';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import services from '../services';
import * as actions from './actions';
import rootHandler from './async-saga';
import rootEpic from './epics';
import { createRootReducer } from './reducers';
import { RootAction, RootState } from './types';
import { composeEnhancers } from './utils';
export { actions };


const loggerMiddleware = createLogger();
const sagasMiddleware = createSagaMiddleware(rootHandler)

const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState>({
  dependencies: services
});

export function configureStore(initialState: Partial<RootState>, history: History) {

  const middlewares = [sagasMiddleware, epicMiddleware, loggerMiddleware, routerMiddleware(history)];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const store = createStore(createRootReducer(history), initialState!, enhancer);

  epicMiddleware.run(rootEpic);
  sagasMiddleware.run();

  return store;
}
