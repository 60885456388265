import classNames from 'classnames';
import * as React from 'react';
import { FaRegCalendarPlus, FaSitemap, FaMouse } from 'react-icons/fa';
import { GiOrganigram } from 'react-icons/gi';
import { CgPassword } from 'react-icons/cg';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { ReactComponent as ClockIcon } from '../../images/bookItIcons/clock.svg';
import {  MdGroupAdd, MdNfc, MdPeople, MdPermContactCalendar, MdShare } from 'react-icons/md';
import { SiMicrosoftteams } from 'react-icons/si';
import { GiVideoConference } from 'react-icons/gi';
import { VscOrganization } from 'react-icons/vsc';
import { NavLink } from 'react-router-dom';
import { ReactComponent as AttributeIcon } from '../../images/attributes.svg';
import BookItLogo from '../../images/BookIT.png';
import { ReactComponent as DeviceIcon } from '../../images/device.svg';
import IPFXLogo from '../../images/IPFX.svg';
import { ReactComponent as LocationsIcon } from '../../images/locations-map.svg';
import { ReactComponent as RoomIcon } from '../../images/rooms.svg';
import { ReactComponent as GlobalSettingsIcon } from '../../images/settings-global.svg';
import { ReactComponent as SupportIcon } from '../../images/support.svg';
import { ReactComponent as PluginIcon } from '../../images/plugin.svg';
import { ReactComponent as RoomFinderIcon } from '../../images/roomfinder.svg';
import VideoFxLogo from '../../images/VideoFXBanner.svg';
import NavCollapse from './NavCollapse';
import NavItem from './NavItem';
import css from './SideNav.module.scss';


interface Props {
    roles: string[]
}

const SideNav: React.FC<Props> = ({ roles }) => {
    return (
        <nav className="bg-light sidebar">

            <ul className={classNames('nav', 'flex-column', css.topLevel)}>
                <NavItem link="/organisations" icon={<GiOrganigram className="feather material-icons" />} title="Organisations" rolesRequired={['IpfxAdmin']} />
                <NavItem link="/organisation_tenants" icon={<VscOrganization className="feather material-icons" />} title="Tenants" rolesRequired={['IpfxAdmin']} />
                <NavItem link="/user_accounts" iconName="group" title="User accounts" rolesRequired={['UserAdmin']} />
                <NavItem link="/resources" icon={<MdNfc className="feather material-icons" />} title="Resources" rolesRequired={['VideoFxAdmin', 'BookItAdmin']} />
                <NavItem link="/nfctags" icon={<MdNfc className="feather material-icons" />} title="NFC Tags" rolesRequired={['IpfxAdmin']} />                
                <NavItem link="/office_locations" icon={<HiOutlineOfficeBuilding className="feather material-icons" />} title="Office Locations" rolesRequired={['BookItAdmin']} />
                
                <NavCollapse iconName="insert_chart" title="Organisation Reports" rolesRequired={['VideoFxReporting', 'VideoFxAdmin']} >
                    <NavItem link="/admin/security_report" iconName="notification_important" title="Security Report" rolesRequired={['VideoFxAdmin']} />        
                    <NavItem link="/admin/generic_report_scheduler" icon={<ClockIcon className="feather material-icons" />} title="Generic report scheduler" rolesRequired={['VideoFxAdmin']} />        
                </NavCollapse>

                {(roles.includes("VideoFxAdmin") || roles.includes("VideoFxReporting") || roles.includes('VideoFxRooms')) && <li>
                    <NavLink to="/bookit/meeting_rooms" className="nav-link" >
                        <img src={VideoFxLogo} alt="VideoFX Logo" className={css.productLogo} />
                    </NavLink>
                </li>}

                <NavItem link="/rooms" iconName="meeting_room" title="Rooms" rolesRequired={['VideoFxAdmin', 'VideoFxRooms']} />
                <NavItem link="/packagefeeds" iconName="add_to_queue" title="Package Feeds" rolesRequired={['VideoFxAdmin']} />
                <NavItem link="/conference_clients" icon={<GiVideoConference className="feather material-icons" />} title="Conference Clients" rolesRequired={['IpfxAdmin']} />
                <NavItem link="/roomote_devices" icon={<FaMouse className="feather material-icons" />} title="Roomote devices" rolesRequired={['VideoFxAdmin']} />

                <NavCollapse iconName="new_releases" title="Escalations" rolesRequired={['VideoFxReporting', 'VideoFxAdmin']} >
                    <NavItem link="/escalations" iconName="notification_important" title="Active" rolesRequired={['VideoFxAdmin']} />
                    <NavItem link="/escalation_report" iconName="insert_chart" title="Report" rolesRequired={['VideoFxReporting']} />
                    <NavItem link="/escalation_rules" iconName="rule" title="Rules" rolesRequired={['VideoFxAdmin']} />
                </NavCollapse>

                <NavItem link="/windows_updates" iconName="update" title="Windows Updates" rolesRequired={['VideoFxWindowsUpdateAdmin']} />
                <NavCollapse iconName="insert_chart" title="Metrics" rolesRequired={['VideoFxReporting']} >
                    <NavItem link="/metrics/booking_meeting_type" iconName="bar_chart" title="By Meeting Type" />
                    <NavItem link="/metrics/duration" iconName="bar_chart" title="By Duration" />
                    <NavItem link="/metrics/start_time" iconName="bar_chart" title="By Start Time" />
                    <NavItem link="/metrics/room_by_meeting_count" iconName="pie_chart" title="By Room (Count)" />
                    <NavItem link="/metrics/room_by_usage_duration" iconName="pie_chart" title="By Room (Duration)" />
                    {/* <NavItem link="/metrics/participant_count" iconName="bar_chart" title="By Participant Count" /> */}
                </NavCollapse>

                <NavCollapse iconName="network_check" title="Health" rolesRequired={['Development']}>
                    <NavItem link="/health/rooms" iconName="meeting_room" title="Rooms" />
                </NavCollapse>

                <NavCollapse icon={<GlobalSettingsIcon className="feather material-icons" />} title="Settings" rolesRequired={['IpfxAdmin']}>
                    <NavItem link="/settings/cors" iconName="network_locked" title="CORS" rolesRequired={['IpfxAdmin']} />
                </NavCollapse>

                {(roles.includes("BookItAdmin") || roles.includes("BookItReporting")) && <li>
                    <NavLink to="/bookit/devices" className="nav-link" >
                        <img src={BookItLogo} alt="Book-It" className={css.productLogo} />
                    </NavLink>
                </li>}

                <NavItem link="/bookit/devices" title="Devices" icon={<DeviceIcon className="feather material-icons" />} rolesRequired={['BookItAdmin']} />
                <NavItem link="/bookit/bookable_resources" title="Resources" icon={<RoomIcon className="feather material-icons" />} rolesRequired={['BookItAdmin']} />

                <NavCollapse icon={<MdShare className="feather material-icons" />} title="Sharing" rolesRequired={['BookItAdmin']} >
                    <NavItem link="/bookit/sharing_groups" title="Groups" icon={<MdGroupAdd className="feather material-icons" />} rolesRequired={['IpfxAdmin']} />
                    <NavItem link="/bookit/sharing_codes" title="Codes" icon={<CgPassword className="feather material-icons" />} rolesRequired={['BookItAdmin']} />
                </NavCollapse>

                <NavItem link="/bookit/providers" title="Providers" icon={<FaRegCalendarPlus className="feather material-icons" />} rolesRequired={['BookItAdmin']} />
                <NavItem link="/bookit/online_meeting_providers" title="Online Meeting Providers" icon={<SiMicrosoftteams className="feather material-icons" />} rolesRequired={['BookItAdmin']} />
                <NavItem link="/bookit/locations" title="Locations" icon={<LocationsIcon className="feather material-icons" />} rolesRequired={['BookItAdmin']} />
                <NavItem link="/bookit/attributes" title="Attributes" icon={<AttributeIcon className="feather material-icons" />} rolesRequired={['BookItAdmin']} />
                <NavItem link="/bookit/calendar_delegation" title="Calendar Delegation" icon={<MdPermContactCalendar className="feather material-icons" />} rolesRequired={['BookItAdmin']} />
                <NavItem link="/bookit/insights" title="Insights" iconName="insert_chart" rolesRequired={['BookItReporting']} />
                <NavItem link="/bookit/organisation_settings" title="Settings" icon={<GlobalSettingsIcon className="feather material-icons" />} rolesRequired={['BookItAdmin']} />
                <NavItem link="/bookit/graph_api" title="Graph API" iconName="bar_chart" rolesRequired={['Development']} />

                <NavCollapse icon={<PluginIcon className="feather material-icons" />} title="Add-ins" rolesRequired={['BookItAdmin']} >
                    <NavItem link="/bookit/outlook_room_locator" title="Outlook: Room locator" icon={<RoomFinderIcon className="feather material-icons" />} rolesRequired={['BookItAdmin']} />                    
                </NavCollapse>

                {roles.includes("UcAdmin") && <li>
                    <NavLink to="/uc/sites" className="nav-link" >
                        <img src={IPFXLogo} alt="UC Logo" className={css.productLogo} />
                    </NavLink>
                    <NavItem link="/uc/sites" title="Sites" icon={<FaSitemap className="feather material-icons" />} rolesRequired={['UcAdmin']} />
                    <NavItem link="/uc/support_settings" title="Support" icon={<SupportIcon className="feather material-icons" />} rolesRequired={['UcAdmin']} />
                </li>}
            </ul>
        </nav>
    );
}


export default SideNav;