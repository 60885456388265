import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import timezones from '../../data/timezones.json';
import { Services } from "../../services";
import { getApiError } from "../../utils/error";
import { customErrorHandler, errorHandler, fetchFail, fetchUserInfo, loadTimezoneData, onReportApiError, onReportCustomApiError, setTimezoneData } from "../actions";
import { createOrganisation, deleteOrganisation } from "../resourceActions";
import { RootAction, RootState } from "../types";




export const updateUserInfoOnDeleteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(deleteOrganisation.success)),
        mergeMap(action => of(fetchUserInfo.request())))
}

export const updateUserInfoOnUpdateEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(createOrganisation.success)),
        mergeMap(action => of(fetchUserInfo.request())))
}




export const fetchFailEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchFail.request)),
        mergeMap(action =>
            serverApi.fetchFail().pipe(
                map(res => fetchFail.success(res)),
                catchError(err => of(fetchFail.failure(err), errorHandler(err)))
            )));
}


export const errorHandlerEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(errorHandler)),
        mergeMap(action =>
            from(getApiError(action.payload)).pipe(
                mergeMap(err => of(onReportApiError(err))
                ))))
}

export const customErrorHandlerEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(customErrorHandler)),
        mergeMap(action =>
            from(getApiError(action.payload.err)).pipe(
                mergeMap(err => of(onReportCustomApiError({ api: err, errorMessage: action.payload.errorMessage }))
                ))))
}


export const loadTimezoneDataEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { }) => {
    return action$.pipe(
        filter(isActionOf(loadTimezoneData)),
        mergeMap(action => of(setTimezoneData(timezones))
        ))
}