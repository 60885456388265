import * as React from "react";
import { Input } from "reactstrap";

export interface RadioProps {
    value: string
    name: string
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
    selected: string
}

export function Radio (props: RadioProps) {
    return (
        <Input onChange={props.onChange} type="radio" name={props.name} value={props.value} checked={props.selected === props.value} />
    );
}
