import { Flex, Loader, Tooltip } from "@fluentui/react-northstar";
import { push } from "connected-react-router";
import moment from "moment";
import * as React from "react";
import { useMemo } from "react";
import { GoSettings } from "react-icons/go";
import { MdCall, MdEvent, MdWarning } from "react-icons/md";
import { RiVolumeMuteLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import ReactTable, { CellInfo, Column, RowInfo } from "react-table";
import { compare, parse } from 'semver';
import RoomControlToolbar from "../../../containers/RoomControlToolbar";
import { Room } from "../../../model";
import { HalResult } from "../../../services/Hal";
import { useSelector } from "../../../store/utils";
import { roomsFilter } from "../../../utils/roomFilters";
import './RoomList.css';
import { RoomRunningStatusIcon, runningStatusSortWeight } from "./RoomRunningStatus";


const RoomList: React.FC<{}> = ({ }) => {

    const rooms = useSelector(s => s.rooms)

    const roomData = useMemo(() => {
        if (rooms.isLoading || rooms.rooms === undefined) {
            return undefined
        }
        return (Object.keys(rooms.rooms)
            .map(key => rooms.rooms[key]))
            .filter(room => roomsFilter(rooms.roomFilter)(room.resource))
            .map((room, index) => ({
                seq: index,
                name: room.resource.name,
                display_name: room.resource.display_name,
                provisioning_status: room.resource.provisioning_status,
                room
            }))
    }, [rooms])

    const dispatch = useDispatch()

    function getTdProps(finalState: any, rowInfo?: RowInfo, column?: Column, instance?: any): object {
        return {
            onClick: (e: React.MouseEvent<HTMLElement>, handleOriginal: () => void) => {
                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                    handleOriginal()
                }
                if (rowInfo !== undefined) {
                    console.log('rowInfo:', rowInfo)
                    const to = `/rooms/${rowInfo.row.name}`

                    dispatch(push(to))
                }
            }
        }
    }


    if (!rooms.isLoading && rooms.rooms === undefined) {
        return (<span>Failed to load rooms</span>);
    }

    return (
        <ReactTable
            className="-highlight"
            getTdProps={getTdProps}
            showPagination={false}
            key={roomData === undefined || roomData.length === 0 ? "nodata" : "havedata"}
            defaultPageSize={roomData === undefined || roomData.length === 0 ? 15 : roomData.length}
            columns={[
                // { Header: "#", accessor: "seq", maxWidth: 30, Cell: (cell: CellInfo) => <p className='font-weight-bold'>{cell.viewIndex + 1}</p> },
                { Header: "Id", accessor: "name" },
                { Header: "Name", accessor: "display_name" },
                {
                    Header: "Running Status", id: "status", accessor: "room", sortMethod: (a, b) => runningStatusSortWeight(a.resource) - runningStatusSortWeight(b.resource),
                    Cell: (data) => renderStatus(data.value.resource)
                },
                { Header: "Provisioning Status", id: "provisioning_status", accessor: "room", Cell: renderProvisioningStatus, sortMethod: provisioningStatusCompare },
                { Header: "", accessor: "room", Cell: renderRoomControl },
            ]}
            defaultSorted={([{ id: "name", desc: false }])}
            data={roomData}
            loadingText={<Loader label='Loading...' />}
            loading={rooms.isLoading}
        />)

}
function renderRoomControl(cellInfo: CellInfo, column: any) {
    const room = cellInfo.value as HalResult<Room>
    return <RoomControlToolbar room={room.resource} showExtraButtons={false} />
}

function renderProvisioningStatus(cellInfo: CellInfo, column: any) {
    const room = cellInfo.value as HalResult<Room>
    return room.resource.provisioning_status === 'Installed' && room.resource.room_component!.version
        ? `Installed (${room.resource.room_component!.version})`
        : room.resource.provisioning_status
}

function renderStatus(room: Room) {
    return (
        <Flex gap='gap.smaller'>
            {room.is_in_meeting && <Tooltip content="Room is in a call"><MdCall color='red' size="2em" /></Tooltip>}
            <RoomRunningStatusIcon room={room} />
            {!!room.has_event_escalations && <Tooltip content="Room is under escalation"><MdWarning size="2em" color='red' /></Tooltip>}
            {room.next_meeting_start &&
                <Tooltip content={`Room has meeting scheduled in ${getDuration(room.next_meeting_start)}`}><MdEvent size="2em" /></Tooltip>}
            {room.escalations_muted &&
                <Tooltip content="Room has escalations muted"><RiVolumeMuteLine size="2em" /></Tooltip>}
            {room.room_component?.stop_reason && room.room_component.running_status === "Stopped" && room.room_component.stop_reason === "Settings" &&
                <Tooltip content="Room is in the settings app"><GoSettings size="2em" /></Tooltip>}
        </Flex>)
}

function provisioningStatusCompare(a: HalResult<Room>, b: HalResult<Room>): number {
    if (a.resource.provisioning_status === b.resource.provisioning_status) {
        if (a.resource.provisioning_status === 'Installed' && a.resource.room_component!.version && b.resource.room_component!.version) {
            const aVer = parse(a.resource.room_component!.version!)
            const bVer = parse(b.resource.room_component!.version!)
            if (aVer && bVer) {
                return compare(aVer, bVer)
            }
        }
    }
    return a.resource.provisioning_status.localeCompare(b.resource.provisioning_status)
}

function getDuration(start: string) {
    const m = moment(start)
    const dur = moment.duration(m.diff(moment()))
    if (dur > moment.duration(1, "hour")) {
        return dur.hours() + ":" + dur.minutes() + " hrs"
    }
    return dur.asMinutes() + " mins"
}

export default RoomList