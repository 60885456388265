import { Flex, Loader, ProviderConsumer, RetryIcon, Text } from "@fluentui/react-northstar";
import classNames from "classnames";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MdSystemUpdateAlt } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Room } from "../../../model";
import { HalResult } from "../../../services/Hal";
import { adminCheckForWindowsUpdates, adminFetchWindowsUpdatesForRoom, adminRequestWindowsUpdatesInstall } from "../../../store/actions";
import { getRoomWindowsUpdates } from "../../../store/selectors";
import { useSelector } from "../../../store/utils";
import { ToolbarWithTooltips } from "../../controls/ToolbarWithTooltips";
import WindowsUpdateCard from "../WindowsUpdateCard";
import './WindowsUpdateTable.scss';

interface Props {
    room: HalResult<Room>
}

const WindowsUpdateTable: React.FC<Props> = (props) => {
    const { room } = props

    const dispatch = useDispatch()

    const [selectedUpdates, setSelectedUpdates] = useState<{ [id: number]: boolean | undefined }>({})

    useEffect(() => { dispatch(adminFetchWindowsUpdatesForRoom.request(room.resource)) }, [room, dispatch])
    const updates = useSelector(s => getRoomWindowsUpdates(s, room.resource.name))

    const anySelected = useMemo(() => Object.values(selectedUpdates).some(s => !!s), [selectedUpdates])

    const onItemClicked = (e: React.MouseEvent<HTMLLIElement>) => {
        const { id } = e.currentTarget;
        const existing = selectedUpdates[id]
        setSelectedUpdates({ ...selectedUpdates, [id]: !!!existing })
    }

    const checkForUpdates = useCallback(() => {
        dispatch(adminCheckForWindowsUpdates.request(room.resource))
    }, [dispatch, room])
    const requestInstall = useCallback(() => {
        var ids = Object.keys(selectedUpdates).filter(k => !!selectedUpdates[k]).map(str => parseInt(str))
        dispatch(adminRequestWindowsUpdatesInstall.request({room: room.resource, ids}))
        setSelectedUpdates({})
    }, [selectedUpdates, dispatch, room.resource])

    const toolbarItems = useMemo(() => [
        { key: 'request', icon: <MdSystemUpdateAlt/>, tooltip: 'Request install', className: 'ToolbarItem', onClick: requestInstall, disabled: !anySelected },
        { key: 'refresh', icon: <RetryIcon outline/>, tooltip: 'Check for updates', className: 'ToolbarItem', onClick: checkForUpdates },
    ], [requestInstall, checkForUpdates, anySelected])

    if (updates === undefined) {
        return <Loader label='Loading Windows Updates...' styles={{ marginTop: '1rem' }} />
    }

    return <ProviderConsumer render={theme => {
        return (<>
            <Flex className='WindowsUpdateTable' column styles={{ border: `1px solid ${theme.siteVariables.colorScheme.default.border3}` }}>
                <Flex className='WindowsUpdateTableHeader' vAlign='center'
                    styles={{
                        backgroundColor: theme.siteVariables.colorScheme.default.background,
                        borderBottom: `1px solid ${theme.siteVariables.colorScheme.default.border3}`
                    }}>
                    <Flex.Item push>
                        <ToolbarWithTooltips items={toolbarItems} />
                    </Flex.Item>
                </Flex>
                <ul className='WindowsUpdateItems'>
                    {updates.length === 0 ? <Text content='No Windows Updates found.'/> : null}
                    {updates.map(u => <li id={u.resource.windows_update_id.toString()}
                        className={classNames('WindowsUpdateItem', !!selectedUpdates[u.resource.windows_update_id.toString()] && 'WindowsUpdateItemSelected')}
                        onClick={onItemClicked}>
                        <WindowsUpdateCard key={u.resource.windows_update_id.toString()} update={u.resource} state={u.resource.state} errorMessage={u.last_error} />
                    </li>)}
                </ul>
            </Flex>
        </>)
    }} />
}

export default WindowsUpdateTable