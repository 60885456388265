import { Button, ButtonProps, CloseIcon, Dialog, Flex, Form, FormField, Input, Loader, Text, Toolbar } from "@fluentui/react-northstar";
import moment from "moment";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTable, { CellInfo } from "react-table";
import { EventEscalation, EventEscalationSelection, IdType } from "../../model";
import { HalResource } from "../../services/Hal";
import { EventEscalationsById } from "../../store/reducers/eventEscalations";
import { getActiveOrganisation } from "../../store/selectors";
import { actions } from "../../store/types";
import { useSelector } from "../../store/utils";
import './EscalationList.css';

interface Props {
    escalationSelection: EventEscalationSelection
    roomName?: string // optional room name to show escalations for
}

const EscalationList: React.FC<Props> = ({ roomName, escalationSelection }) => {
    const dispatch = useDispatch()
    const activeOrganisation = useSelector(getActiveOrganisation)
    const { eventEscalationsById: escalations, isLoading, loadingError } = useSelector(s => s.eventEscalations)

    console.log("escalations", escalations)

    useEffect(() => {
        if (roomName) {
            dispatch(actions.fetchEventEscalationsForRoom.request({ selection: escalationSelection, organisationId: activeOrganisation.organisation_id, roomName: roomName }))
        } else {
            dispatch(actions.fetchEventEscalations.request({ organisationId: activeOrganisation.organisation_id, selection: escalationSelection }))
        }
    }, [escalationSelection, roomName, dispatch, activeOrganisation])

    useEffect(() => {
        if (loadingError) {
            dispatch(actions.onReportError("Failed to load the event escalations."))
        }
    }, [loadingError, dispatch])

    const [closeEscalationId, setCloseEscalationId] = useState<IdType>()

    const renderActions = useCallback((cellInfo: CellInfo) => {
        return (<Toolbar
            items={[{
                key: 'delete',
                icon: <CloseIcon />,
                disabled: cellInfo.value.cleared_date_utc !== undefined,
                onClick: () => {
                    setCloseEscalationId(cellInfo.value.event_escalation_id)
                }
            }]}
        />)
    }, [])

    const onSubmit = (reason: string) => {
        dispatch(actions.closeEventEscalation.request({ organisationId: activeOrganisation.organisation_id, eventEscalationId: closeEscalationId!, reason }))
        setCloseEscalationId(undefined)
    }

    const data = useMemo(() => getData(roomName, escalations), [roomName, escalations])

    return (
        <>
            <ReactTable
                className="-highlight"
                showPagination={false}
                key={data === undefined || data.length === 0 ? "nodata" : "havedata"}
                defaultPageSize={data === undefined || data.length === 0 ? 15 : data.length}
                columns={getColumnDefs(roomName!, renderActions)}
                defaultSorted={([{ id: "raised", desc: true }])}
                data={data}
                noDataText={loadingError ? "Data is currently unavailable" : "No rows found"}
                loadingText={<Loader label='Loading...' />}
                loading={isLoading}
            />

            <Dialog
                content={<CloseEscalationForm
                    onSubmit={onSubmit}
                    onCancel={() => {
                        setCloseEscalationId(undefined)
                    }} />}
                header="Close Event Escalation"
                open={closeEscalationId !== undefined}
            />
        </>
    )
}

function getData(roomName: string|undefined, escalationsById: EventEscalationsById) {
    const escalations: Array<HalResource<EventEscalation>> = Object.keys(escalationsById).map(key => escalationsById[key].resource)
    if (roomName === undefined) {
        return escalations
    }
    return escalations.filter(it => it.room_name === roomName)
}

function getColumnDefs(roomName: string, renderActions: (cell: CellInfo) => JSX.Element) {
    const cols = [
        { Header: "Name", id: "name", maxWidth: 200, accessor: (row: HalResource<EventEscalation>) => row._links.room_by_org.name },
        { Header: "Description", id: "escalation_title", minWidth: 400, accessor: (row: EventEscalation) => row.cleared_date_utc ? row.escalation_cleared_title : row.escalation_title },
        { Header: "Raised", minWidth: 150, accessor: "raised_date_utc", Cell: (cell: CellInfo) => moment(cell.value).format('MMMM Do YYYY, h:mm:ss a') },
        { Header: "Duration", id: "duration", maxWidth: 100, accessor: (row: EventEscalation) => row, Cell: renderDuration },
        { Header: "Status", id: "status", maxWidth: 100, accessor: (row: EventEscalation) => row.cleared_date_utc ? "Closed" : "Active" },
        { Header: "", id: "clear", accessor: (row: HalResource<EventEscalation>) => row, Cell: renderActions },
    ]
    if (roomName) {
        // remove name column if we are if we are showing for specfic room
        cols.splice(0, 1)
    }
    return cols
}

function renderDuration(cellInfo: CellInfo) {
    if (cellInfo.original.cleared_date_utc) {
        const diff = moment(cellInfo.original.cleared_date_utc).diff(cellInfo.original.raised_date_utc)
        return moment.duration(diff).humanize()
    }
    return moment.duration(moment().diff(cellInfo.original.raised_date_utc)).humanize();
}

interface CloseEscalationFormProps {
    onSubmit: (reason: string) => void
    onCancel: () => void
}


const CloseEscalationForm: React.FC<CloseEscalationFormProps> = (props) => {
    const [reason, setReason] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>()

    const handleSubmit = () => {
        console.log("submit: ", reason)
        if (reason === undefined) {
            setErrorMessage("reason is a required field")
            return
        }
        props.onSubmit(reason!)
    }

    const handleCancel = (event: React.SyntheticEvent<HTMLElement>, data?: ButtonProps) => {
        props.onCancel()
    }

    const handleChange = (evt: any) => {
        const { value } = evt.target;
        setReason(value)
    }


    return (<Form styles={{ width: '350px' }} onSubmit={handleSubmit}>
        <Flex column gap="gap.medium" fill >
            <FormField label="Reason" name="reason" id="reason" required message={(<Text error content={errorMessage} />)}
                control={<Input onChange={handleChange} value={reason} fluid />} />
            <Flex gap="gap.smaller" hAlign="end">
                <FormField>
                    <Button
                        onClick={handleCancel}
                        secondary
                        type="button"
                        content='Cancel'
                    />
                </FormField>
                <FormField>
                    <Button
                        onClick={p => handleSubmit()}
                        primary
                        type="submit"
                        content='Submit'
                    />
                </FormField>
            </Flex>
        </Flex>
    </Form>)
}

export default EscalationList