import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { Services } from "../../services";
import { errorHandler, uploadLicenseFile } from "../actions";
import { RootAction, RootState } from "../types";


export const uploadLicenseFileEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
     filter(isActionOf(uploadLicenseFile.request)),
     mergeMap(action =>
         serverApi.uploadLicenseFile(action.payload.room, action.payload.file).pipe(
             mergeMap(res => of(uploadLicenseFile.success())),
             catchError(err => of(uploadLicenseFile.failure(err), errorHandler(err)))
         )));
}