import { Button, ButtonProps, Dialog, Flex, Form, FormField, FormProps, Input, InputProps, RadioGroup, RadioGroupItemProps, Text } from '@fluentui/react-northstar';
import React, { useEffect, useState } from 'react';

export type ViaMethodType = 'email' | 'download'

interface CollectLogsDialogProps {
    onConfirm: (via: ViaMethodType, to?: string[]) => void
    onCancel: () => void
    isOpen?: boolean
    emailAdddress: string

}
const CollectLogsDialog: React.FC<CollectLogsDialogProps> = ({ isOpen, onConfirm, onCancel, emailAdddress }) => {
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState<ViaMethodType>('email')
    const [email, setEmail] = useState(emailAdddress)

    const handleSubmit = (event: React.SyntheticEvent<HTMLElement>, data?: FormProps) => {
        const emailAddresses = email.split(/[,;]/).map(e => e.trim())
        onConfirm(selected, emailAddresses)
    }

    const handleCancel = (event: React.SyntheticEvent<HTMLElement>, data?: ButtonProps) => {
        onCancel()
    }

    useEffect(() => {
        if (isOpen !== undefined) {
            setOpen(isOpen)
        }
    }, [isOpen])

    const handleChange = (event: React.SyntheticEvent<HTMLElement>, data?: RadioGroupItemProps) => {
        if (data && data.value) {
            setSelected(data.value as ViaMethodType)
        }
    }

    const handleInputChange = (event: React.SyntheticEvent<HTMLElement>, data?: InputProps) => {
        if (data && data.value) {
            setEmail(data.value as string)
        }
    }

    const items = [
        {
            name: 'email',
            key: 'Email',
            label: (
                <Text>
                    Enter email address{' '}
                    <Input
                        inline
                        fluid
                        styles={{
                            fontSize: '14px',
                            minWidth: '20rem',
                            '& .ui-input__input': {
                                padding: '2px 8px',
                            },
                        }}
                        onChange={handleInputChange}
                        value={email}
                    />
                </Text>
            ),
            value: 'email',
        },
        {
            name: 'download',
            key: 'Download',
            label: 'Download',
            value: 'download'
        }
    ]

    const form = (
        <Form onSubmit={handleSubmit}>
            <Flex column gap="gap.medium" >
                <FormField
                    control={{
                        as: RadioGroup,
                        vertical: true,
                        defaultCheckedValue: 'email',
                        items : items,
                        checkedValueChanged: handleChange
                    }}
                />
                <Flex gap="gap.smaller" hAlign="end">
                    <FormField
                        control={{
                            as: Button,
                            onClick: handleCancel,
                            content: 'Cancel',
                        }}
                    />
                    <FormField
                        control={{
                            as: Button,
                            primary: true,
                            content: 'Collect',
                        }}
                    />
                </Flex>
            </Flex>
        </Form>)

    return (<Dialog
        open={open}
        onOpen={(e, data) => { if (data && data.open) setOpen(data!.open!) }}
        content={form}
        header='Select method for collecting the log files'
    />)
}

export default CollectLogsDialog

