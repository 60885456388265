import { Loader } from '@fluentui/react-northstar';
import { ChartLegendOptions, ChartOptions, ChartType } from 'chart.js';
import * as React from 'react';
import { useEffect } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import { AggregateColumnType, AggregateData } from '../../model';
import { getActiveOrganisation, getAggregateData } from '../../store/selectors';
import { actions } from '../../store/types';
import { useSelector } from '../../store/utils';

type Props = {
  className?: string
  aggregateFn: (data: AggregateData) => { labels: string[], data: number[] }
  startRange?: number,
  endRange?: number,
  roomName?: string
  hideLegend: boolean;
  aggregateCol: AggregateColumnType;
  type: ChartType;
  opts?: ChartOptions
};

const colours: string[] = [
  '#e23d3d',
  '#36A2EB',
  '#488440',
  '#FFCE56',
  '#e27c3c',
  '#8e3c96',
  '#c0d185',
  '#2c307c'
];


const MetricsChart: React.FC<Props> = (props) => {


  const dispatch = useDispatch()
  const activeOrganisation = useSelector(getActiveOrganisation)

  useEffect(() => {
    dispatch(actions.fetchAggregateMetrics.request({
      organisationId: activeOrganisation.organisation_id
      , aggregate: props.aggregateCol, start: props.startRange, end: props.endRange, roomName: props.roomName
    }))
  }, [props.startRange, props.endRange, props.roomName, dispatch, activeOrganisation.organisation_id, props.aggregateCol])

  const { type, className } = props;
  const opts = {
    responsive: true,
    maintainAspectRatio: false,
    ...props.opts,
  }

  const aggregateData = useSelector(s => getAggregateData(s, props.aggregateCol))

  if (aggregateData === undefined) {
    return (<Loader />);
  }

  const { labels, data } = props.aggregateFn({by:  props.aggregateCol, data: aggregateData.data});

  if (data.length === 0) {
    return <span>No data to display for selected time period</span>
  }

  const c = getColours(data.length);
  const chartData = {
    labels,
    datasets: [{
      data,
      backgroundColor: c,
      hoverBackgroundColor: c
    }]
  };

  const legend: ChartLegendOptions = {
    position: 'left',
    display: !props.hideLegend,
  }

  return (
    <div className={className}>
      {type === 'pie' && <Pie data={chartData} legend={legend} options={opts} />}
      {type === 'line' && <Line data={chartData} legend={legend} options={opts} />}
      {type === 'bar' && <Bar data={chartData} legend={legend} options={opts} />}
    </div>
  )
}

function getColours(count: number) {
  const col: string[] = [];
  for (let i: number = 0; i < count; i++) {
    col.push(colours[i % colours.length]);
  }
  return col;
}


export default MetricsChart;