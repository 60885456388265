import _ from 'lodash';
import React from 'react';
import { UcSite, UcSiteAttribute } from '../../model';
import css from './SiteAttributes.module.scss';


interface SiteAttributesProps {
    site: UcSite
}

// List of attributes displayed in main card
const filterFromAttributes = ['installation_id', 'serial_number', 'version']


export function SiteAttributes({ site }: SiteAttributesProps) {

    return <div className={css.siteAttributes}>
        <table className={css.table}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Value</th>
                    <th>DataType</th>
                    <th>Source</th>
                </tr>
            </thead>
            <tbody>
                {_.toPairsIn(site.attributes)
                    .filter(p => !filterFromAttributes.some(a => a === p[0]))
                    .sort((l, r) => l[0].localeCompare(r[0]))
                    .map((attrib, index) => <Row attrib={attrib} row={index} key={index} />)}
            </tbody>
        </table>
    </div>
}


interface RowProps {
    row: number
    attrib: [string, UcSiteAttribute]
}

function Row({ row, attrib }: RowProps) {
    return <tr>
        <th scope="row">{row + 1}</th>
        <td>{attrib[0]}</td>
        <td>{getDisplayValue(attrib[1])}</td>
        <td>{attrib[1].data_type}</td>
        <td>{attrib[1].type}</td>
    </tr >
}

function getDisplayValue(attrib: UcSiteAttribute) {
    if (attrib.data_type === 'Password') {
        return "•".repeat(attrib.value.toString().length);
    }

    return attrib.value.toString();
}

