import { EditIcon, Flex, Menu, tabListBehavior, TrashCanIcon } from "@fluentui/react-northstar";
import { compare, Operation } from "fast-json-patch";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { SiGooglehangoutsmeet, SiMicrosoftteams, SiSkypeforbusiness } from "react-icons/si";
import { useDispatch } from "react-redux";
import { CellInfo } from "react-table";
import * as Yup from 'yup';
import { ReactComponent as JitsiIcon } from '../../images/ipfx_man.svg';
import { ReactComponent as ZoomIcon } from '../../images/Zoom.svg';
import {
    AllExchangeVersions, AllOnlineMeetingProviderTypes, CreateOnlineMeetingProvider,
    GoogleMeetProviderConfiguration,

    IdType,

    JitsiProviderConfiguration, OnlineMeetingProvider, SkypeForBusinessProviderConfiguration,
    TeamsProviderConfiguration, WebExProviderConfiguration, ZoomProviderConfiguration
} from "../../model";
import { createOnlineMeetingProvider, deleteOnlineMeetingProvider, fetchOnlineMeetingProviders, patchOnlineMeetingProvider } from "../../store/resourceActions";
import { getActiveOrganisation } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { FlattenUnion } from "../../utils/utils";
import ResourceAdminForm from "../admin/ResourceAdminForm";
import ResouceAdminPage from '../admin/ResourceAdminPage';
import { FormikNorthstarCheckbox } from "../controls/FormikNorthstarCheckbox";
import { FormikNorthstarDropdown } from "../controls/FormikNorthstarDropdown";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";
import css from './OnlineMeetingProviderPage.module.scss';

interface Props { }

type P = Omit<OnlineMeetingProvider, "online_meeting_provider_id" | "organisation_id" | "domain_maps">
type OnlineMeetingProviderRest = Partial<Omit<P, "configuration_json">>
type ConfigJsonUnion = P["configuration_json"]
type ConfigJson = FlattenUnion<ConfigJsonUnion>
type OnlineMeetingProviderEditModel = OnlineMeetingProviderRest & ConfigJson & { domain_names?: string }

const OnlineMeetingProvidersPage: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const renderMeetingProviderType = useCallback((cellInfo: CellInfo) => {
        const meetingProviderType = cellInfo.original.meeting_provider_type
        return <span>
            {meetingProviderType === 'Teams' && <SiMicrosoftteams className={css.meetingProviderTypeIcon} />}
            {meetingProviderType === 'SkypeForBusiness' && <SiSkypeforbusiness className={css.meetingProviderTypeIcon} />}
            {meetingProviderType === 'GoogleMeet' && <SiGooglehangoutsmeet className={css.meetingProviderTypeIcon} />}
            {meetingProviderType === 'Jitsi' && <JitsiIcon className={css.meetingProviderTypeIcon} />}
            {meetingProviderType === 'Zoom' && <ZoomIcon className={css.meetingProviderTypeIcon} />}
            <span className={css.meetingProviderType}>{mapProviderText(meetingProviderType)}</span>
        </span>
    }, [])

    const cols = useMemo(() => [
        { Header: "Description", id: "description", minWidth: 40, accessor: (row: OnlineMeetingProvider) => row.description },
        { Header: "Meeting Provider Type", id: "meeting_provider_type", accessor: (row: OnlineMeetingProvider) => row.meeting_provider_type, minWidth: 50, Cell: renderMeetingProviderType },
    ], [renderMeetingProviderType])

    const activeOrganisation = useSelector(getActiveOrganisation)

    const handleAddOrUpdate = (provider: OnlineMeetingProviderEditModel, closeDialog: () => void, original: OnlineMeetingProvider | undefined) => {
        if (activeOrganisation === undefined) {
            console.error("Active organisation is not defined!. Cannot create online meeting provider")
            return
        }
        if (original) {
            const updatedOrganisation = {
                ...original, description: provider.description, meeting_provider_type: provider.meeting_provider_type, domain_maps: getDomainMaps(provider.domain_names!)
                , configuration_json: getConfiguration(provider)
            }
            let diff = compare(original, updatedOrganisation);
            diff = fixDiff(diff, updatedOrganisation.domain_maps, updatedOrganisation.configuration_json)
            dispatch(patchOnlineMeetingProvider.request({ id: original, operations: diff }))
        } else {
            const newProvider: CreateOnlineMeetingProvider = {
                description: provider.description!,
                meeting_provider_type: provider.meeting_provider_type!,
                domain_maps: getDomainMaps(provider.domain_names!),
                configuration_json: getConfiguration(provider),
                organisation_id: activeOrganisation.organisation_id
            }

            dispatch(createOnlineMeetingProvider.request(newProvider))
        }
        closeDialog()
    }


    return <ResouceAdminPage<OnlineMeetingProvider, { organisation_id: IdType }>
        resourceName='Online Meeting Provider'
        parentKey={{ organisation_id: activeOrganisation!.organisation_id }}
        selectState={s => s.bookit.onlineMeetingProviders}
        fetchAllAction={fetchOnlineMeetingProviders}
        deleteAction={deleteOnlineMeetingProvider}
        confirmMessage={prov => `Are you sure you wish to delete the online meeting provider '${prov.description}'?`}
        resourceForm={(original, closeDialog) =>
            <OnlineMeetingProviderForm initial={createEditModel(original)} onSubmit={edit => handleAddOrUpdate(edit, closeDialog, original)} onCancel={closeDialog} />
        }
        toolbarActionItems={(resource, onAddUpdate, onDelete) =>
            [{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: `Edit ${resource.description}`,
                onClick: () => onAddUpdate(resource)
            },
            {
                key: 'delete',
                icon: <TrashCanIcon outline />,
                tooltip: `Delete ${resource.description}`,
                onClick: () => onDelete(resource)
            }]
        }
        columns={cols}
        defaultSortOrder={[{ id: "description", desc: false }]}
    />
}

function fixDiff(diff: Operation[], domainMaps: string[], jsonConfiguration: ConfigJsonUnion): Operation[] {
    let updatedDiff = diff
    // dont use add/remove just replace whole array in domain_maps and configuration_json

    const updateDomainMaps = diff.some(op => op.path.startsWith("/domain_maps"))
    if (updateDomainMaps) {
        updatedDiff = diff.filter(op => !op.path.startsWith("/domain_maps"))
        updatedDiff = [...updatedDiff, {
            op: 'replace',
            path: '/domain_maps',
            value: domainMaps
        }]
    }

    const updateJsonConfiguration = diff.some(op => op.path.startsWith("/configuration_json"))
    if (updateJsonConfiguration) {
        updatedDiff = diff.filter(op => !op.path.startsWith("/configuration_json"))
        updatedDiff = [...updatedDiff, {
            op: 'replace',
            path: '/configuration_json',
            value: jsonConfiguration
        }]
    }

    return updatedDiff
}

function getDomainMaps(domainStr: string): string[] {
    return domainStr ? domainStr.split(',').filter(s => s && s.length > 0).map(s => s.trim()) : []
}

function getConfiguration(edit: OnlineMeetingProviderEditModel): ConfigJsonUnion {
    switch (edit.meeting_provider_type!) {
        case 'Teams': return { ClientId: edit.ClientId!, ClientSecret: edit.ClientSecret, TenantId: edit.TenantId!, UseGraphCalendarApi: edit.UseGraphCalendarApi! } as TeamsProviderConfiguration
        case 'SkypeForBusiness': return {
            UseGraphCalendarApi: edit.UseGraphCalendarApi!,
            ForgottenPinUrl: edit.ForgottenPinUrl!,
            LocalPhoneUrl: edit.LocalPhoneUrl,
            MaxMeetingSize: edit.MaxMeetingSize!,
            TollUri: edit.TollUri!,
            OnPremiseDomains: edit.OnPremiseDomains!,
            Username: edit.Username!,
            Password: edit.Password!,
            Tenant: edit.Tenant!,
            ClientID: edit.ClientID!
        } as SkypeForBusinessProviderConfiguration
        case 'GoogleMeet': return {} as GoogleMeetProviderConfiguration
        case 'Zoom': return {
            ApiKey: edit.ApiKey!,
            ApiSecret: edit.ApiSecret!,
            BaseApiUrl: edit.BaseApiUrl!,
            ProxyDomainUser: edit.ProxyDomainUser!
        } as ZoomProviderConfiguration
        case 'WebEx': return {
            ClientId: edit.ClientId!,
            ClientSecret: edit.ClientSecret!,
            RedirectUrl: edit.RedirectUrl!,
            ServiceAccount: edit.ServiceAccount!
        } as WebExProviderConfiguration
        case 'Jitsi': return {
            HostUrl: edit.HostUrl!,
            ShowPreJoin: edit.ShowPreJoin!
        } as JitsiProviderConfiguration
    }
}

function createEditModel(resource?: OnlineMeetingProvider): OnlineMeetingProviderEditModel | undefined {
    if (resource === undefined) return undefined
    const { online_meeting_provider_id, organisation_id, configuration_json, domain_maps, ...edit } = resource
    const str = domain_maps.join(", ")
    const result = { ...edit, ...configuration_json as ConfigJson, domain_names: str }
    return result
}

function mapProviderText(provider: string) : string {
    //const provider = AllOnlineMeetingProviderTypes[index]

    switch(provider) {
        case 'SkypeForBusiness':
            return 'Skype for Business'
        case 'Teams':
            return 'Teams'
        case 'Zoom':
            return 'Zoom'
        case 'WebEx':
            return 'WebEx'
        case 'GoogleMeet':
            return 'Google Meet'
        case 'Jitsi':
            return 'Ipfx'
        default:
            return 'Unknown'
    }

}

interface OnlineMeetingProviderFormProps {
    initial?: OnlineMeetingProviderEditModel
    onSubmit: (edit: OnlineMeetingProviderEditModel) => void
    onCancel: () => void,
}
const OnlineMeetingProviderForm: React.FC<OnlineMeetingProviderFormProps> = ({ initial, onSubmit, onCancel }) => {
    console.log("initial", initial)

    const initialProvider = useMemo(() => initial ?? { meeting_provider_type: 'Teams', UseGraphCalendarApi: true, domain_names: '' } as OnlineMeetingProviderEditModel, [initial])

    console.log("initialProvider", initialProvider)

    const [meetingProviderType, setMeetingProviderType] = useState<string>(initialProvider.meeting_provider_type!)

    const formSchema = useMemo(() => {
        let schema = Yup.object({
            description: Yup.string()
                .required('Description cannot be empty'),
            domain_names: Yup.string().required('Must define at least one domain')
        })
        if (meetingProviderType === 'Teams') {
            schema = schema
                .shape({
                    UseGraphCalendarApi: Yup.boolean().required(),
                    ClientId: Yup.string().when('UseGraphCalendarApi', {
                        is: true,
                        then: Yup.string().optional().notRequired(),
                        otherwise: Yup.string().required('Client ID cannot be empty')
                    }),
                    ClientSecret: Yup.string().when('UseGraphCalendarApi', {
                        is: true,
                        then: Yup.string().optional().notRequired(),
                        otherwise: Yup.string().required('Client secret cannot be empty')
                    }),
                    TenantId: Yup.string().when('UseGraphCalendarApi', {
                        is: true,
                        then: Yup.string().optional().notRequired(),
                        otherwise: Yup.string().required('Tenant ID cannot be empty')
                    })
                })
        }
        if (meetingProviderType === 'SkypeForBusiness') {
            schema.shape({
                UseGraphCalendarApi: Yup.boolean().required(),
                ForgottenPinUrl: Yup.string()
                    .required('ForgottenPinUrl cannot be empty'),
                LocalPhoneUrl: Yup.string()
                    .required('LocalPhoneUrl cannot be empty'),
                MaxMeetingSize: Yup.number()
                    .required('MaxMeetingSize cannot be empty'),
                TollUri: Yup.string()
                    .required('TollUri cannot be empty'),
                OnPremiseDomains: Yup.array(),
                Username: Yup.string().optional().notRequired(),
                Password: Yup.string().optional().notRequired(),
                Tenant: Yup.string()
                    .required('Tenant cannot be empty'),
                ClientID: Yup.string()
                    .required('Client ID cannot be empty'),
            })
        }
        
        if (meetingProviderType === 'Jitsi') {
            schema.shape({
                HostUrl: Yup.string().required('The Host Url cannot be empty'),
                ShowPreJoin: Yup.boolean().required()
            })
        }

        if (meetingProviderType === 'Zoom') {
            schema.shape({
                ApiKey: Yup.string().required('The Api Key cannot be empty'),
                ApiSecret: Yup.string().required('The Api Secret cannot be empty'),
                BaseApiUrl: Yup.string().required('The base Api Url cannot be empty'),
                ProxyDomainUser: Yup.string().required('The Proxy Domain User cannot be empty'),
            })
        }

        if (meetingProviderType === 'WebEx') {
            schema.shape({
                ClientId: Yup.string().required('The Client Id cannot be empty'),
                ClientSecret: Yup.string().required('The Client Secret cannot be empty'),
                RedirectUrl: Yup.string().required('The Redirect Url cannot be empty'),
                ServiceAccount: Yup.string().required('The Service Account Url cannot be empty'),
            })
        }

        return schema.defined()
    }, [meetingProviderType])

    console.log("initialProvider", initialProvider)

    return <ResourceAdminForm
        initial={initialProvider}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <Flex column gap="gap.medium" >
            <FormikNorthstarInput fluid label="Description" name="description" id="description" />
            <FormikNorthstarInput fluid label="Domains (comma separated)" name="domain_names" />
            <FormikNorthstarDropdown<string>
                label="Meeting Provider Type"
                name="meeting_provider_type"
                items={AllOnlineMeetingProviderTypes}
                getHeader={i => mapProviderText(i)}
                onChange={t => t && setMeetingProviderType(t)}
            />
            <Menu
                defaultActiveIndex={0}
                underlined
                primary
                accessibility={tabListBehavior}
                aria-label="Provider Configuration"
                items={[
                    { key: 'teams', content: 'Teams', disabled: meetingProviderType !== 'Teams', active: meetingProviderType === 'Teams' },
                    { key: 'skype', content: 'SkypeForBusiness', disabled: meetingProviderType !== 'SkypeForBusiness', active: meetingProviderType === 'SkypeForBusiness' },
                    { key: 'jitsi', content: 'IPFX', disabled: meetingProviderType !== 'Jitsi', active: meetingProviderType === 'Jitsi' },
                    { key: 'zoom', content: 'Zoom', disabled: meetingProviderType !== 'Zoom', active: meetingProviderType === 'Zoom' },
                    { key: 'webex', content: 'WebEx', disabled: meetingProviderType !== 'WebEx', active: meetingProviderType === 'WebEx' },
                ]}
            />
            {meetingProviderType === 'Teams' && <TeamsSettings initialUseGraph={initialProvider.UseGraphCalendarApi!} />}
            {meetingProviderType === 'SkypeForBusiness' && <SkypeForBusinessSettingsForm initialUseGraph={initialProvider.UseGraphCalendarApi!} />}
            {meetingProviderType === 'Jitsi' && <JitsiSettingsForm />}
            {meetingProviderType === 'Zoom' && <ZoomSettingsForm />}
            {meetingProviderType === 'WebEx' && <WebExSettingsForm />}
        </Flex>
    </ResourceAdminForm >
}


const TeamsSettings: React.FC<{ initialUseGraph: boolean }> = ({ initialUseGraph }) => {
    const [useGraphApi, setUseGraphApi] = useState(initialUseGraph)
    const handleOnChange = useCallback((checked: boolean) => {
        console.log(`checked=${checked}`)
        setUseGraphApi(checked)
    }, [setUseGraphApi])
    console.log(`useGraphApi=${useGraphApi}`)
    return <>
        <FormikNorthstarCheckbox toggle label="Use Graph Calendar API" name="UseGraphCalendarApi" defaultChecked={initialUseGraph} onChange={handleOnChange} />
        <FormikNorthstarInput fluid label="Tenant ID" name="TenantId" id="TenantId" disabled={useGraphApi} />
        <FormikNorthstarInput fluid label="Client ID" name="ClientId" id="ClientId" disabled={useGraphApi} />
        <FormikNorthstarInput fluid label="Client Secret" name="ClientSecret" id="ClientSecret" disabled={useGraphApi} />
    </>
}

const SkypeForBusinessSettingsForm: React.FC<{ initialUseGraph: boolean }> = ({ initialUseGraph }) => {
    const [useGraphApi, setUseGraphApi] = useState(initialUseGraph)

    return <>
        <FormikNorthstarCheckbox toggle label="Use Graph Calendar API" name="UseGraphCalendarApi" defaultChecked={initialUseGraph} onChange={checked => {
            console.log(`checked=${checked}`)
            setUseGraphApi(checked)
        }} />
        <FormikNorthstarInput fluid label="Service Account" name="ServiceAccount" id="ServiceAccount" disabled={useGraphApi} />
        <FormikNorthstarInput fluid label="Password" name="Password" id="Password" disabled={useGraphApi} />
        <FormikNorthstarDropdown<string> label="Exchange Version" name="ExchangeVersion" items={AllExchangeVersions} disabled={useGraphApi} getHeader={i => i} />
        <FormikNorthstarInput fluid label="Internal Exchange URI Hints" name="InternalExchangeUriHints" id="InternalExchangeUriHints" disabled={useGraphApi} />
        <FormikNorthstarInput fluid label="Online Exchange URI Hints" name="OnlineExchangeUriHints" id="OnlineExchangeUriHints" disabled={useGraphApi} />
        <FormikNorthstarCheckbox toggle label="Always Use Supplied URI" name="AlwaysUseSuppliedUri" disabled={useGraphApi} />
        <FormikNorthstarInput fluid label="Exchange Web Service URI" name="ExchangeWebServiceUri" id="ExchangeWebServiceUri" disabled={useGraphApi} />
        <FormikNorthstarCheckbox toggle label="Enable Tracing" name="EnableTracing" disabled={useGraphApi} />
    </>
}

const JitsiSettingsForm: React.FC = () => {

    return <>
        <FormikNorthstarInput fluid label="Host Url" name="HostUrl" id="HostUrl" /> 
        <FormikNorthstarCheckbox toggle label="Show pre-join" name="ShowPreJoin" />        
    </>
}

const ZoomSettingsForm: React.FC = () => {

    return <>
        <FormikNorthstarInput fluid label="Api Key" name="ApiKey" id="ApiKey" /> 
        <FormikNorthstarInput fluid label="Api Secret" name="ApiSecret" id="ApiSecret" /> 
        <FormikNorthstarInput fluid label="Base Url" name="BaseApiUrl" id="BaseApiUrl" />   
        <FormikNorthstarInput fluid label="Proxy Domain User" name="ProxyDomainUser" id="ProxyDomainUser" />   
    </>
}

const WebExSettingsForm: React.FC = () => {

    return <>
        <FormikNorthstarInput fluid label="Client Id" name="ClientId" id="ClientId" /> 
        <FormikNorthstarInput fluid label="Client Secret" name="ClientSecret" id="ClientSecret" /> 
        <FormikNorthstarInput fluid label="Redirect Url" name="RedirectUrl" id="RedirectUrl" />   
        <FormikNorthstarInput fluid label="Service Account" name="ServiceAccount" id="ServiceAccount" />   
    </>
}

export default OnlineMeetingProvidersPage