import { Button, Dialog, Flex, Header, Label, Text } from "@fluentui/react-northstar";
import * as moment from 'moment';
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { WindowsUpdate } from "../../model";
import Card from "../controls/Card";
import './WindowsUpdateCard.scss';


interface Props {
    update: WindowsUpdate,
    state?: string
    roomsPending?: string[]
    errorMessage?: string
}

const WindowsUpdateCard: React.FC<Props> = (props) => {
    const { update, errorMessage, state, roomsPending } = props

    const modifiedDate = moment.utc(update.modified_date_utc).local()
    const link = useMemo(() => findLink(update), [update])
    const [showRoomsPending, setShowRoomsPending] = useState(false)

    const handleRoomsPending = useCallback((e: React.SyntheticEvent) => {
        e.stopPropagation()
        setShowRoomsPending(!showRoomsPending)
    }, [showRoomsPending])

    const renderLink = useCallback(() => {
        
        console.log('Render Link', link);

        if (link.url) {
            return <Flex.Item push>
                <a href={link!.url} target="_blank">
                    <span>KB{link!.kbid}</span>
                </a>
            </Flex.Item>
        }
        return null
    }, [link])

    return <div className='WindowsUpdateCardRoot'>
        <div onClick={(e) => e.stopPropagation()}>
            <Dialog 
                open={showRoomsPending}
                header={update.title}
                cancelButton="Close"
                onCancel={e => setShowRoomsPending(false)}                
                content={

                    <Flex column className="RoomsPendingDialog">
                        <ul>
                            {roomsPending !== undefined ? roomsPending.map(r => <li><Text content={r} /></li>) : null}
                        </ul>
                    </Flex>

                }
            />
        </div>
        <Card key={`Card-${update.windows_update_id}`} className='WindowsUpdateCard'>
            <Flex.Item grow>
                <Flex key={`Content-${update.windows_update_id}`} column gap="gap.small">
                    <Flex key={`FirstRow-${update.windows_update_id}`} gap="gap.small">
                        <Header as='h5' content={update.title} />
                        {state
                            ? <Flex.Item push>
                                <Text content={state} color='brand' weight='semibold' />
                            </Flex.Item>
                            : null}
                        {roomsPending
                            ? <Flex.Item push>
                                <Button text onClick={handleRoomsPending} content={`Pending in ${roomsPending.length} room(s)`} />
                            </Flex.Item>
                            : null}

                    </Flex>
                    <Flex key={`MiddleRow-${update.windows_update_id}`} gap="gap.small">
                        <Text timestamp content={modifiedDate.format('lll')} />
                        {errorMessage ? <Flex.Item push>
                            <Text error content={errorMessage} />
                        </Flex.Item>
                            : null}
                    </Flex>

                    <Flex key={`LastRow-${update.windows_update_id}`} gap="gap.small">
                        {update.categories.map((c, idx) => <Label key={idx} as='span' circular content={c} styles={{ padding: '0.75rem' }} />)}
                        {renderLink()}
                    </Flex>
                </Flex>
            </Flex.Item>
        </Card>
    </div>
}

function findLink(update: WindowsUpdate) {
    if (update.kb_article_ids.length === 0) {
        // no KB articles take first link if any
        return update.more_info_urls.length === 0
            ? { url: undefined, kbid: undefined }
            : { url: update.more_info_urls[0], kbid: "Info" }
    }

    if (update.more_info_urls.length === 0) {
        return { url: undefined, kbid: undefined }
    }

    for (const kbid of update.kb_article_ids) {
        const match = update.more_info_urls.find(u => u.indexOf(kbid) > -1)
        if (match) return { url: match, kbid }
    }

    // fallback: the above match won't always work even though there's a valid url that's better than nothing. 
    if (update.more_info_urls.length > 0) {
        return {url: update.more_info_urls[0], kbid: "Info"}
    } 

    // Why is this here? Does it ever hold a valid url?
    return { url: update.kb_article_ids[0], kbid: "Info" }
}

export default WindowsUpdateCard