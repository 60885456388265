import { Checkbox, EditIcon, Grid, Segment, TrashCanIcon } from "@fluentui/react-northstar";
import { compare } from "fast-json-patch";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { CellInfo } from "react-table";
import * as Yup from 'yup';
import useOrganisations from "../../hooks/useOrganisations";
import { CreateUserAccount, OrganisationSummary, Role, UserAccount } from "../../model";
import { actions } from "../../store/configureStore";
import { ApiResult } from "../../store/reducers/ApiResult";
import { createUserAccount, deleteUserAccount, fetchUserAccounts, patchUserAccount } from "../../store/resourceActions";
import { getLoggedInUser } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { FormikNorthstarDropdown } from "../controls/FormikNorthstarDropdown";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";
import ResourceAdminForm from "./ResourceAdminForm";
import ResouceAdminPage from "./ResourceAdminPage";
import { privilegedRoles, RolesPicker } from "./RolesPicker";



interface UserAccountEditModel {
    email_address: string
    given_name: string,
    family_name: string,
    roles: string[]
    primary_organisation: OrganisationSummary
}

const UserAccountsPage: React.FC<{}> = (props) => {
    const dispatch = useDispatch()

    const [roles, setRoles] = useState<Role[]>([])
    const rolesResource = useSelector(s => s.userAccounts.roles)
    useEffect(() => {
        if (!rolesResource.isRequested) {
            dispatch(actions.fetchRoles.request())
        } else if (ApiResult.isSuccess(rolesResource)) {
            setRoles(rolesResource.value)
        }
    }, [dispatch, rolesResource])

    const { organisations } = useOrganisations(dispatch)

    const onAccountLockChanged = useCallback((user: UserAccount) => {
        const updatedUser = { ...user, account_locked: !user.account_locked }
        const diff = compare(user, updatedUser)
        dispatch(patchUserAccount.request({ id: user, operations: diff }))
    }, [dispatch])

    const renderLocked = useCallback((cellInfo: CellInfo) => {
        const user = cellInfo.original
        return <Checkbox checked={cellInfo.value} toggle onChange={() => onAccountLockChanged(user)} />
    }, [onAccountLockChanged])

    // const [createdUser, setCreatedUser] = useState<{ email_address: string, password: string }>()

    const handleAddUpdateUser = (user: UserAccountEditModel, closeDialog: () => void, original: UserAccount | undefined) => {

        if (original) {
            const updatedUser = { ...original,
                //  email_address: user.email_address, 
                 primary_organisation_id: user.primary_organisation.organisation_id,
                 roles: filterRoles(user.primary_organisation, user.roles),
                 given_name: user.given_name,
                 family_name: user.family_name
            }

            const initial = {...original, primary_organisation_id: original.primary_organisation.organisation_id}
            console.log("initial", initial)
            console.log("Updated", updatedUser)
            const diff = compare(initial, updatedUser);
            dispatch(patchUserAccount.request({ id: original, operations: diff }))
        } else {
            // const password = Math.random().toString(36).slice(-10);
            const newUser: CreateUserAccount = {
                ...user,
                // password: password,
                roles: filterRoles(user.primary_organisation, user.roles),
                primary_organisation_id: user.primary_organisation.organisation_id
            }

            // if (user_type !== 'External') {
            //     setCreatedUser({ email_address: cleanUser.email_address, password })
            // }

            dispatch(createUserAccount.request(newUser))
        }
        closeDialog()
    }

    const cols = useMemo(() => [
        { Header: "Email Address", id: "email_address", accessor: (row: UserAccount) => row.email_address, minWidth: 50 },
        { Header: "Given Name", id: "given_name", minWidth: 70, accessor: (row: UserAccount) => row.given_name },
        { Header: "Family Name", id: "family_name", minWidth: 70, accessor: (row: UserAccount) => row.family_name },
        { Header: "Primary Organisation", id: "primary_organisation", minWidth: 70, accessor: (row: UserAccount) => row.primary_organisation?.name ?? "Not Set"},
        { Header: "Locked", id: "account_locked", maxWidth: 60, accessor: (row: UserAccount) => row.account_locked, Cell: renderLocked },
    ], [renderLocked])


    return <>
        <ResouceAdminPage<UserAccount, {}>
            resourceName='User Account'
            parentKey={{}}
            selectState={s => s.userAccounts.users}
            fetchAllAction={fetchUserAccounts}
            deleteAction={deleteUserAccount}
            confirmMessage={user => `Are you sure you wish to delete the user account ${user.email_address}?`}
            resourceForm={(original, closeDialog) =>
                <UserAccountForm
                    initial={createEditModel(organisations[0], original)}
                    onSubmit={edit => handleAddUpdateUser(edit, closeDialog, original)}
                    onCancel={closeDialog}
                    roles={roles ?? []}
                    organisations={organisations}
                />
            }
            toolbarActionItems={(resource, onAddUpdate, onDelete) =>
                [{
                    key: 'edit',
                    icon: <EditIcon outline />,
                    tooltip: `Edit ${resource.email_address}`,
                    onClick: () => onAddUpdate(resource)
                },
                {
                    key: 'delete',
                    icon: <TrashCanIcon outline />,
                    tooltip: `Delete ${resource.email_address}`,
                    onClick: () => onDelete(resource)
                }]
            }
            columns={cols}
            defaultSortOrder={([{ id: "family_name", desc: false }])}
        />

        {/* {createdUser && <ShowPasswordDialog email={createdUser.email_address} password={createdUser.password} isOpen={createdUser !== undefined} />} */}
    </>
}

function filterRoles(org: OrganisationSummary, roles: string[]): string[] {
    if (org.trusted) return roles
    return roles.filter(r => !privilegedRoles.includes(r))
}

function createEditModel(defaultOrg: OrganisationSummary, user?: UserAccount): UserAccountEditModel {
    if (user === undefined) {
        return {
            email_address: '',
            given_name: '',
            family_name: '',
            roles: [],
            primary_organisation: defaultOrg
        }
    }
    return { ...user, primary_organisation: user.primary_organisation }
}

interface UserAccountFormProps {
    initial: UserAccountEditModel
    onSubmit: (edit: UserAccountEditModel) => void
    onCancel: () => void
    organisations: OrganisationSummary[]
    roles: Role[]
}

const UserAccountForm: React.FC<UserAccountFormProps> = ({ initial, onSubmit, onCancel, roles, organisations }) => {
    const formSchema = useMemo(() => Yup.object().shape({
        email_address: Yup.string()
            .required('Email address name cannot be empty')
            .email(),
        given_name: Yup.string()
            .required("Given name cannot be empty"),
        family_name: Yup.string()
            .required("Family name cannot be empty"),
    }), [])

    const isUpdate = initial.email_address !== ''

    const organisationItems = useMemo(() => organisations.map(o => ({ ...o, header: o.name }))
        , [organisations])

    const org = organisationItems.find(o => o.organisation_id === initial.primary_organisation.organisation_id)
    if (org) {
        initial.primary_organisation = org
    }

    type OrgType = typeof org

    const [selectedOrg, setSelectedOrg] = useState<OrgType>(org)
    const currentUser = useSelector(getLoggedInUser)
    const canChangePrivelgedRoles = useMemo(() => {
            const can = currentUser.primary_organisation.trusted && Boolean(selectedOrg?.trusted)
            return can
    }, [currentUser.primary_organisation.trusted, selectedOrg])
    const handleOrgChange = useCallback((item: OrgType) => {
            setSelectedOrg(item)
    }, [])


    // const userTypes = useMemo(() => ['Internal', 'External'].map(type => ({
    //     key: type,
    //     value: type,
    //     name: type,
    //     label: type,
    //     'aria-label': type
    // })), [])
    const primaryOrgEditable = initial.email_address === ''


    return <ResourceAdminForm
        initial={initial}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <Grid columns={2}>
            <Segment>
                <FormikNorthstarInput fluid label="Email Address" name="email_address" id="email_address" disabled={isUpdate} />
                <FormikNorthstarDropdown
                    label="Choose primary organisation"
                    items={organisationItems}
                    name="primary_organisation"
                    placeholder="Select primary organisation"
                    disabled={!primaryOrgEditable}
                    onChange={handleOrgChange}
                     />
                <FormikNorthstarInput fluid label="Given Name" name="given_name" id="given_name" />
                <FormikNorthstarInput fluid label="Family Name" name="family_name" id="family_name" /> 
                {/* <FormikRadioGroupFormField label="User Type" name="user_type" defaultCheckedValue={initial.user_type} items={userTypes} /> */}
                <b>Note that this user will be authenticated via Azure Active Directory.</b>
            </Segment>
            <Segment >


                <RolesPicker 
                    roles={roles} 
                    name="roles"
                    label="Select the required roles"
                    trustedOrganisation={canChangePrivelgedRoles}
                    />
             
            </Segment>
        </Grid>
    </ResourceAdminForm>
}



// const ShowPasswordDialog: React.FC<{ email: string, password: string, isOpen: boolean }> = ({ email, password, isOpen }) => {
//     const [open, setOpen] = useState(false)

//     const handleClose: ComponentEventHandler<DialogProps> = (event, props) => {
//         setOpen(false)
//     }

//     useEffect(() => {
//         if (isOpen !== undefined) {
//             setOpen(isOpen)
//         }
//     }, [isOpen])

//     return <Dialog
//         onConfirm={handleClose}
//         open={open}
//         onOpen={(e, data) => { if (data && data.open) setOpen(data.open!) }}
//         confirmButton="Ok"
//         content={<Flex vAlign="start">
//             <Text>{`Password: ${password}`}</Text>
//             <Tooltip
//                 trigger={<Button icon={() => (<FaRegClipboard size="1.5rem" />)} text iconOnly title="Copy to clipboard" onClick={() => navigator.clipboard.writeText(password)} />}
//                 content="Copy password to clipboard" />
//         </Flex>}
//         header={`Created user ${email}`}
//     />
// }


export default UserAccountsPage

