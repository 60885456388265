import { Button, ButtonProps, Checkbox, Flex, Input, RadioGroup, RadioGroupItemProps } from "@fluentui/react-northstar";
import { endOfDay, startOfDay, startOfMonth, subMonths } from "date-fns";
import moment from 'moment';
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import './DateSelector.scss';

interface DateSelectorProps {
    startDate?: Date
    endDate?: Date
    selectedRange: string
    excludeWeekends: boolean
    workingHoursOnly: boolean
    onApply(startDate: moment.Moment, endDate: moment.Moment, range: string, description: string, toggle: boolean, workingHoursOnly: boolean, excludeWeekends: boolean): void
    onIsWorkingHoursOnlyChanged(toggled: boolean): void
    onExcludeWeekends(toggled: boolean): void
    onCancel(): void
}

export const dateRanges = [{
    name: "dateRange",
    key: 'today',
    label: 'Today',
    value: 'today',
},
{
    name: "dateRange",
    key: 'yesterday',
    label: 'Yesterday',
    value: 'yesterday',
},
{
    name: 'dateRange',
    key: 'last7d',
    label: 'Last 7 days',
    value: 'last7d',
},
{
    name: 'dateRange',
    key: 'lastw',
    label: 'Last week',
    value: 'lastw',
},
{
    name: 'dateRange',
    key: 'last30d',
    label: 'Last 30 days',
    value: 'last30d',
},
{
    name: 'dateRange',
    key: 'lastm',
    label: 'Last month',
    value: 'lastm',
},
{
    name: 'dateRange',
    key: 'last3m',
    label: 'Last 3 months',
    value: 'last3m',
},
{
    name: 'dateRange',
    key: 'lastq',
    label: 'Last quarter',
    value: 'lastq',
},
{
    name: 'dateRange',
    key: 'last90d',
    label: 'Last 90 days',
    value: 'last90d',
},
{
    name: 'dateRange',
    key: 'custom',
    label: 'Custom',
    value: 'custom',
}]

const formatOptions: Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }

function DateSelector(props: DateSelectorProps) {
    const [startDate, setStartDate] = useState(props.startDate)
    const [endDate, setEndDate] = useState(props.endDate)
    const [selectedRange, setSelectedRange] = useState(props.selectedRange ? props.selectedRange : 'yesterday')
    const [lastSelectedRange, setLastSelectedRange] = useState('yesterday')
    const [isWorkingHoursOnly, setIsWorkingHoursOnly] = useState(props.workingHoursOnly)
    const [excludeWeekends, setExcludeWeekends] = useState(props.excludeWeekends)

    function handleChange({ value }: RadioGroupItemProps) {
        if (typeof value !== 'string') { return }
        setSelectedRange(value)
        const range = getDatesOfRange(value)
        setStartDate(range.startDate)
        setEndDate(range.endDate)
    }

    function onApply(event: React.SyntheticEvent<HTMLElement>, data?: ButtonProps) {
        props.onApply(moment(startDate), moment(endDate), selectedRange, getRangeToDescripton(selectedRange), true, isWorkingHoursOnly, excludeWeekends);
        setLastSelectedRange(selectedRange);
    }

    function onCancel(event: React.SyntheticEvent<HTMLElement>, data?: ButtonProps) {
        setSelectedRange(lastSelectedRange!);
        props.onCancel();
    }

    function resetRange() {
        setStartDate(undefined);
        setEndDate(undefined);
    }
    function onFocus() {
        resetRange();
    }

    function setRange(date: Date) {
        if(lastSelectedRange === 'custom'){
            resetRange()
        } 
        const today = new Date()
        if (startDate === undefined || endDate! < date) {
            setStartDate(startOfDay(date))
            setEndDate(undefined)
        } else if (startDate > date) {
             setEndDate(endOfDay(startDate))
             setStartDate(startOfDay(date))
        } else if (date === today){
           const range = getDatesOfRange('today')
            setStartDate(range.startDate)
            setEndDate(range.endDate)
        } else if (startDate === date) {
            setEndDate(endOfDay(date))    
        } else {   
            setEndDate(endOfDay(date))
        }
        setSelectedRange('custom');
    }

    return <Flex gap="gap.small" className="DateSelector">
        <div className="options">
            <RadioGroup
                vertical
                checkedValue={selectedRange}
                items={dateRanges}
                onCheckedValueChange={(e, d) => d && handleChange(d)}
            />
            <div className="checks">
                <Checkbox checked={isWorkingHoursOnly} onChange={() => setIsWorkingHoursOnly(!isWorkingHoursOnly)} label="Working Hours Only" />
                <Checkbox checked={excludeWeekends} onChange={() => setExcludeWeekends(!excludeWeekends)} label="Exclude Weekends" />
            </div>
        </div>
        <div>
            <div className="dates"><span>Start: <Input inline placeholder="" value={startDate ? startDate.toLocaleDateString(undefined, formatOptions) : ''} /></span>
                <span>End: <Input inline placeholder="" value={endDate ? endDate.toLocaleDateString(undefined, formatOptions) : ''} /></span>
            </div>

            <DatePicker
                selected={startDate}
                onChange={(date) => setRange(date!)}
                onFocus={() => onFocus()}
                monthsShown={2}
                selectsStart
                selectsEnd
                maxDate={new Date()}
                startDate={startDate}
                endDate={endDate}
                inline
                allowSameDay
                openToDate={startOfMonth(subMonths(new Date(), 1))}
                disabled={selectedRange !== 'custom'}
                locale='en-GB'
            />

            <div className='range-selector-buttons'>
                <Button size="small" primary className='mr-2' onClick={onApply}>Apply</Button>
                <Button size="small" onClick={onCancel}>Cancel</Button>
            </div>
        </div>
    </Flex>
}

export function getDatesOfRange(value: string) {
    if (value === "today") {
        return { startDate: moment().startOf('day').toDate(), endDate: moment().endOf('day').toDate() };
    } else if (value === "yesterday") {
        const startDate = moment().startOf('day').subtract(1, 'day')
        return { startDate: startDate.toDate(), endDate: startDate.clone().endOf('day').toDate() };
    } else if (value === "last7d") {
        return { startDate: moment().startOf('day').subtract(6, 'days').toDate(), endDate: moment().endOf('day').toDate() }
    } else if (value === "lastw") {
        const startDate = moment().startOf('isoWeek').subtract(1, 'week')
        return { startDate: startDate.toDate(), endDate: startDate.clone().endOf('isoWeek').toDate() };
    } else if (value === "last30d") {
        return { startDate: moment().startOf('day').subtract(29, 'day').toDate(), endDate: moment().endOf('day').toDate() };
    } else if (value === "lastm") {
        const startDate = moment().startOf('day').subtract(1, 'month').startOf('month')
        return { startDate: startDate.toDate(), endDate: startDate.clone().endOf('month').toDate() };
    } else if (value === "lastq") {
        const startDate = moment().startOf('day').subtract(1, 'quarter').startOf('quarter')
        return { startDate: startDate.toDate(), endDate: startDate.clone().endOf('quarter').toDate() };
    } else if (value === "last3m") {
        const startDate = moment().startOf('day').subtract(3, 'month').startOf('month')
        return { startDate: startDate.toDate(), endDate: startDate.clone().add(2, 'months').endOf('month').toDate() };
    } else if (value === "last90d") {
        return { startDate: moment().startOf('day').subtract(89, 'day').toDate(), endDate: moment().endOf('day').toDate() };
    } else if (value === "all") {
        return { startDate: undefined, endDate: undefined };
    } else if (value === "custom") {
        return { startDate: undefined, endDate: undefined };
    } else {
        throw new Error(`Unsupported value ${value}`)
    }
}

export function getRangeToDescripton(value: string) {
    if (value === "today") {
        return "Today";
    } else if (value === "yesterday") {
        return "Yesterday";
    } else if (value === "last7d") {
        return "Last 7 days";
    } else if (value === "lastw") {
        return "Last week";
    } else if (value === "last30d") {
        return "Last 30 days";
    } else if (value === "lastm") {
        return "Last month";
    } else if (value === "last3m") {
        return "Last 3 months"; 
    } else if (value === "lastq") {
        return "Last quarter";
    } else if (value === "last90d") {
        return "Last 90 days";
    } else if (value === "all") {
        return "All";
    }
    return "Custom";
}


export default DateSelector;