import { Dialog, ErrorIcon, Flex, Form, Loader } from '@fluentui/react-northstar';
import { Formik } from 'formik';
import * as React from "react";
import { useCallback, useMemo, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Provider, ProviderTestResult } from '../../model';
import { actions } from '../../store/types';
import { useSelector } from '../../store/utils';
import FormButtons from '../controls/FormButtons';
import { FormikNorthstarInput } from '../controls/FormikNorthstarInput';
import css from './TestGraphProvider.module.scss';


interface Props {
    provider: Provider
    onDismiss: () => void
}
export const TestGraphProviderForm: React.FC<Props> = ({ provider, onDismiss }) => {
    const initial = { emailAddress: '' }

    const dispatch = useDispatch()

    const formSchema = useMemo(() => {
        let schema = Yup.object({
            emailAddress: Yup.string()
                .required('Email address is required')
                .email('Valid email is required')
        })
        return schema.defined()
    }, [])

    const handleCancel = useCallback(() => {
        dispatch(actions.clearTestProviderResults())
        onDismiss()
    }, [dispatch, onDismiss])

    const { inProgress, result } = useSelector(s => s.bookit.providerTest)

    const runTest = useCallback((values) => {
        dispatch(actions.testProvider.request({ organisationId: provider.organisation_id, providerId: provider.provider_id, emailAddress: values.emailAddress }))
    }, [dispatch, provider.organisation_id, provider.provider_id])



    return <div className={css.testGraphProvider}>
        <Formik
            initialValues={initial}
            validationSchema={formSchema}
            onSubmit={(values, actions) => {
                runTest(values)
            }}>
            {({ handleSubmit }) => <Form styles={{ width: '850px' }} onSubmit={e => {
                handleSubmit(e as React.SyntheticEvent<HTMLFormElement, Event>)
            }}>
                <Flex gap='gap.medium' fill column>
                    <FormikNorthstarInput fluid label="Email address" name="emailAddress" placeholder='Provider an email address for testing graph. This calendar will be written too.' />
                    <FormButtons submitText='Test' onCancel={handleCancel} />
                    {/* <FormikDebug /> */}
                </Flex>
            </Form>
            }
        </Formik>
        {inProgress && <Loader label='Performing tests' />}
        {result && <ShowProviderTestResult result={result} />}
    </div>
}


const ShowProviderTestResult: React.FC<{ result: ProviderTestResult }> = ({ result }) => {
    const [showDetails, setShowDetail] = useState<string>()

    const handleDetailClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        const cur = event.currentTarget
        const id = parseInt(cur.id)
        console.log(`showing details for ${id}`)
        setShowDetail(result.results[id].detail)
    }, [])

    return <>
        {showDetails && <Dialog
            content={<pre>{showDetails}</pre>}
            cancelButton='Dismiss'
            onCancel={() => setShowDetail(undefined)}
            header='Failure details'
            open={showDetails !== undefined}
        />}
        <table className={css.showProviderTestResult}>
            {result.results.map((res, idx) => (
                <tr key={idx} className={css.result}>
                    <td className={css.operation}>{res.operation}</td>
                    <td className={css.message}>{res.message}</td>
                    <td className={css.icon}>{res.success ? <MdCheck color='green' /> : <ErrorIcon />}</td>
                    {!res.success && res.detail && <td className={css.detail}>
                        <button id={idx.toString()} onClick={handleDetailClick}>Details</button>
                    </td>}

                </tr>
            ))}
        </table>
    </>
}


