import { Loader } from "@fluentui/react-northstar";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactTable, { CellInfo, Column } from "react-table";
import { UncontrolledTooltip } from "reactstrap";
import { fetchEscalationsReport } from "../../store/actions";
import { getActiveOrganisation, getEscalationsReport, getEscalationsReportIsLoading } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { ReportFilter } from "../videofx/EscalationReportPage";
import './Reports.scss';

export function secondsToDhm(seconds: number) {
    const minutes = seconds / 60

    const d = Math.floor(minutes / (60*24));
    const h = Math.floor(minutes % (60*24) / 60);
    const m = Math.floor(minutes % 60);

    const dDisplay = d > 0 ? d + "d " : "";
    const hDisplay = h > 0 ? h + "hr "  : "";
    const mDisplay = m > 0 ? m + "m " : "";

    return dDisplay + hDisplay + mDisplay;
}

const columns: Column[] = [{
    headerStyle: {
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'Room',
        headerStyle: {
            textAlign: 'left',
            borderRight: '1px solid #fff',
        },
        minWidth: 100,
        accessor: 'room_name',
        Cell: (cellInfo: CellInfo) => {
            const index = cellInfo.original.room_name
            const id = cellInfo.original.room_name
            return (
                <div>
                    <span id={id}>{cellInfo.original.room_name}</span>
                    <UncontrolledTooltip placement='auto' target={id}>
                        <div key={index}>{cellInfo.original.display_name}</div>
                    </UncontrolledTooltip>
                </div>
            )
        },
    }]
}, {
    headerStyle: {
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'Unresolved',
        className: 'centerAlign',
        minWidth: 50,
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        accessor: 'unresolved',
        Cell: (cell) => cell.value ? cell.value : '',
    }]
}, {
    Header: 'All Escalations',
    headerStyle: {
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'Total',
        className: 'centerAlign',
        minWidth: 50,
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #fff',
        },
        accessor: 'total_escalations',
        Cell: (cell) => cell.value ? cell.value : '',
    }, {
        Header: 'Average Resolution',
        className: 'centerAlign',
        minWidth: 50,
        defaultSortDesc: true,
        headerStyle: {
            borderRight: '1px solid #fff',
        },
        accessor: 'average_resolution_seconds',
        Cell: (cell) => secondsToDhm(cell.value),
    }]
}, {
    Header: 'Loss of Heartbeat',
    headerStyle: {
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff'
    },
    columns: [{
        Header: 'Total',
        className: 'centerAlign',
        minWidth: 50,
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #fff'
        },
        accessor: 'total_resolved_loss_heartbeat',
        Cell: (cell) => cell.value ? cell.value : '',
    }, {
        Header: 'Average Resolution',
        className: 'centerAlign',
        minWidth: 50,
        defaultSortDesc: true,
        headerStyle: {
            borderRight: '1px solid #fff'
        },
        accessor: 'average_loss_heartbeat_resolution_seconds',
        Cell: (cell) => secondsToDhm(cell.value),
    }]
}, {
    Header: 'Loss of Critical Device',
    headerStyle: {
        borderLeft: '1px solid #fff',
    },
    columns: [{
        Header: 'Total',
        className: 'centerAlign',
        minWidth: 50,
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #fff',
        },
        accessor: 'total_resolved_loss_critical_device',
        Cell: (cell) => cell.value ? cell.value : '',
    }, {
        Header: 'Average Resolution',
        className: 'centerAlign',
        minWidth: 50,
        defaultSortDesc: true,
        accessor: "average_loss_critical_device_resolution_seconds",
        Cell: (cell) => secondsToDhm(cell.value),
    }]
}]

function EscalationsReport({ filter }: { filter: ReportFilter }) {
    const dispatch = useDispatch()
    const activeOrganisation = useSelector(getActiveOrganisation)

    useEffect(() => {
        dispatch(fetchEscalationsReport.request({ organisationId: activeOrganisation.organisation_id, startRange: filter.startDate?.toDate()!, endRange: filter.endDate?.toDate()!, tag: filter.tag }))
    }, [dispatch, filter.endDate, filter.tag, filter.startDate, activeOrganisation.organisation_id])

    const data = useSelector(getEscalationsReport);
    const isLoading = useSelector(getEscalationsReportIsLoading);

    if (isLoading) {
        return <Loader label="Loading Escalation Report" />
    } else if (data === undefined || data.length === 0) {
        return <div>No data to show. Please change your filters</div>
    } else if (data !== undefined && data.length > 0) {
        return (
            <div className="reportContainer">
                <div className="reportSection">
                    <h2>Escalations Report</h2>
                    <ReactTable
                        className="-striped -highlight"
                        showPagination={false}
                        key={data.length === 0 ? "nodata" : "havedata"}
                        defaultPageSize={data.length === 0 ? 0 : data.length}
                        columns={columns}
                        defaultSorted={([{ id: "room_name", desc: false }])}
                        data={data}
                        loadingText={<Loader label='Loading...' />}
                        loading={isLoading}
                        noDataText={<span>...</span>}
                    />
                </div>
            </div>
        )
    } else {
        return <div></div>
    }
}

export default EscalationsReport