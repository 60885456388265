import { Checkbox, CheckboxProps, FormField, FormLabel, FormMessage } from '@fluentui/react-northstar';
import classNames from 'classnames';
import { useField } from 'formik';
import React from 'react';

type FormikNorthstarCheckboxProps = Omit<CheckboxProps, 'name' | 'onChange'> & {
    name: string;
    onChange?: (checked: boolean) => void
    labelClassName?: string
};

export const FormikNorthstarCheckbox: React.FC<FormikNorthstarCheckboxProps> = ({
    label,
    name,
    onChange,
    labelClassName,
    ...props
}) => {
    const [, metadata, { setValue, setTouched }] = useField({
        name,
        type: 'checkbox',
    });

    const isError = metadata.touched && metadata.error !== undefined;

    return (
        <FormField>
            {label && <FormLabel htmlFor={name} id={`${name}-label`} className={classNames(labelClassName)}>
                {label}
            </FormLabel>}
            <Checkbox
                defaultChecked={metadata.initialValue}
                onChange={(_e, d) => {
                    if (d && d.checked !== undefined) {
                        setValue(d.checked)
                        if (onChange) onChange(d.checked)
                    }
                }}
                onBlur={() => setTouched(true)}
                {...props}
            />
            {isError && (
                <FormMessage id={`${name}-message`} role="alert" error={isError}>
                    {metadata.error}
                </FormMessage>
            )}
        </FormField>
    );
};
