import { getType } from "typesafe-actions";
import { CorsAllowedOrigin } from "../../model";
import { HalResource } from "../../services/Hal";
import { actions, Actions } from "../types";

interface Cors {
    allowedOrigins: Array<HalResource<CorsAllowedOrigin>>
}
export default function cors(state: Cors = { allowedOrigins: [] }, action: Actions) {
    switch (action.type) {
        case getType(actions.fetchCorsAllowedOrigins.success):
            return {
                ...state,
                allowedOrigins: action.payload.resources,
            };
        case getType(actions.createCorsAllowedOrigin.success):
            return {
                ...state,
                allowedOrigins: [
                    ...state.allowedOrigins,
                    action.payload.resource
                ]
            };
        case getType(actions.patchCorsAllowedOrigin.success):
            return {
                ...state,
                allowedOrigins: [
                    ...state.allowedOrigins.filter(elem => elem.cors_allowed_origin_id! !== action.payload.resource.cors_allowed_origin_id!),
                    action.payload.resource
                ]
            };
        case getType(actions.deleteCorsAllowedOrigin.success):
            return {
                ...state,
                allowedOrigins: state.allowedOrigins!.filter(elem => elem.cors_allowed_origin_id! !== action.payload)
            };
        default:
            return state;
    }
}