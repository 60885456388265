import { EditIcon, Flex, Loader, TrashCanIcon } from "@fluentui/react-northstar";
import { compare } from "fast-json-patch";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { BookableResource, CreateSharingGroup, IdType, OrganisationSummary, SharingGroup } from "../../model";
import { fetchOrganisationSummaries } from "../../store/actions";
import { createSharingGroup, deleteSharingGroup, fetchBookableResources, fetchSharingGroups, patchSharingGroup } from "../../store/resourceActions";
import { getActiveOrganisation, getBookableResources } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { Guid } from "../../utils/guid";
import ResourceAdminForm from "../admin/ResourceAdminForm";
import ResouceAdminPage from '../admin/ResourceAdminPage';
import { FormikNorthstarDropdown } from "../controls/FormikNorthstarDropdown";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";

interface Props { }


const SharingGroupsPage: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const cols = useMemo(() => [
        { Header: "Name", id: "name", minWidth: 40, accessor: (row: SharingGroup) => row.name },
        { Header: "Members", id: "members", minWidth: 40, accessor: (row: SharingGroup) => row.members.map(mem => mem.name).join(", ") },
    ], [])

    const activeOrganisation = useSelector(getActiveOrganisation)

    const { organisations, isLoading, isLoaded, isFailure } = useSelector(s => s.bookit.organisationSummaries)
    useEffect(() => {
        if (!isLoaded && !isLoading && !isFailure) {
            dispatch(fetchOrganisationSummaries.request())
        }
    }, [dispatch, isFailure, isLoaded, isLoading])

    useEffect(() => {
        if (activeOrganisation) {
            dispatch(fetchBookableResources.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch])
    const bookableResources = useSelector(getBookableResources)

    const handleAddOrUpdate = (sharingGroup: CreateSharingGroup, closeDialog: () => void, original: SharingGroup | undefined) => {
        if (activeOrganisation === undefined) {
            console.error("Active organisation is not defined!. Cannot create sharing group")
            return
        }
        if (original) {
            const orginalClean = {
                name: original.name
                // don't add member_organisation_ids or shared_bookable_resource_ids so the get add every time
            }
            const updatedEntity = {
                name: sharingGroup.name,
                member_organisation_ids: sharingGroup.member_organisation_ids,
                shared_bookable_resource_ids: sharingGroup.shared_bookable_resource_ids
            }
            let diff = compare(orginalClean, updatedEntity);
            dispatch(patchSharingGroup.request({ id: original, operations: diff }))
        } else {
            const newSharingGroup: CreateSharingGroup = {
                name: sharingGroup.name,
                member_organisation_ids: sharingGroup.member_organisation_ids,
                shared_bookable_resource_ids: sharingGroup.shared_bookable_resource_ids,
                organisation_id: activeOrganisation.organisation_id
            }

            dispatch(createSharingGroup.request(newSharingGroup))
        }
        closeDialog()
    }

    if (!activeOrganisation) {
        return <Loader label='Loading...' />
    }

    return <ResouceAdminPage<SharingGroup, { organisation_id: IdType }>
        resourceName='Sharing Group'
        parentKey={{ organisation_id: activeOrganisation!.organisation_id }}
        selectState={s => s.bookit.sharingGroups}
        fetchAllAction={fetchSharingGroups}
        deleteAction={deleteSharingGroup}
        confirmMessage={sg => `Are you sure you wish to delete the sharing group '${sg.name}'?`}
        resourceForm={(original, closeDialog) =>
            <SharingGroupForm
                initial={createEditModel(original)}
                organisations={organisations}
                bookableResources={bookableResources}
                organisationId={activeOrganisation.organisation_id}
                onSubmit={edit => handleAddOrUpdate(edit, closeDialog, original)}
                onCancel={closeDialog}
            />
        }
        toolbarActionItems={(resource, onAddUpdate, onDelete) =>
            [{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: `Edit ${resource.name}`,
                onClick: () => onAddUpdate(resource)
            },
            {
                key: 'delete',
                icon: <TrashCanIcon outline />,
                tooltip: `Delete ${resource.name}`,
                onClick: () => onDelete(resource)
            }]
        }
        columns={cols}
        defaultSortOrder={[{ id: "name", desc: false }]}
    />
}

function createEditModel(resource?: SharingGroup): CreateSharingGroup | undefined {
    if (resource === undefined) return undefined
    return {
        name: resource.name, organisation_id: resource.organisation_id, member_organisation_ids: resource.members.map(mem => mem.organisation_id),
        shared_bookable_resource_ids: resource.shared_bookable_resources.map(br => br.bookable_resource_id)
    }
}

interface SharingGroupFormProps {
    initial?: CreateSharingGroup
    organisationId: IdType
    onSubmit: (edit: CreateSharingGroup) => void
    onCancel: () => void,
    organisations: Array<OrganisationSummary>
    bookableResources: Array<BookableResource>
}
const SharingGroupForm: React.FC<SharingGroupFormProps> = ({ initial, onSubmit, onCancel, organisations, organisationId, bookableResources }) => {

    const organisationItems = useMemo(() => organisations.filter(org => org.organisation_id !== organisationId).map(org => ({ header: org.name, key: org.name, organisation_id: org.organisation_id })), [organisationId, organisations])
    const bookableResourceItems = useMemo(() => bookableResources.map(br => ({ header: br.name, key: br.name, bookable_resource_id: br.bookable_resource_id })), [bookableResources])

    const initialSharingGroup = useMemo(() =>
        initial
            ? ({
                name: initial?.name,
                organisation_id: initial.organisation_id,
                members: organisationItems.filter(oi => initial.member_organisation_ids.some(id => id === oi.organisation_id)),
                bookable_resources: bookableResourceItems.filter(bri => initial.shared_bookable_resource_ids.some(id => bri.bookable_resource_id === id))
            })
            : { name: '', organisation_id: Guid.createEmpty(), members: [], bookable_resources: [] }, [bookableResourceItems, initial, organisationItems])


    const handleSubmit = useCallback((data: typeof initialSharingGroup) => {
        const sharingGroup: CreateSharingGroup = {
            name: data.name,
            organisation_id: data.organisation_id,
            member_organisation_ids: data.members.map(org => org.organisation_id),
            shared_bookable_resource_ids: data.bookable_resources?.map(br => br.bookable_resource_id) ?? []
        }
        onSubmit(sharingGroup)
    }, [onSubmit])

    const formSchema = useMemo(() => {
        let schema = Yup.object({
            name: Yup.string()
                .required('Name cannot be empty')
        })
        return schema.defined()
    }, [])


    return <ResourceAdminForm
        initial={initialSharingGroup}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <Flex column gap="gap.medium" >
            <FormikNorthstarInput fluid label="Name" name="name" id="name" />
            <FormikNorthstarDropdown
                fluid
                label="Member Organisations"
                items={organisationItems}
                multiple
                name="members"
                placeholder='Select an organisaion'
            />
            <FormikNorthstarDropdown
                fluid
                label="Shared Bookable Resources"
                items={bookableResourceItems}
                multiple
                name="bookable_resources"
                placeholder='Select a bookable resource'
            />
        </Flex>
    </ResourceAdminForm>
}


export default SharingGroupsPage