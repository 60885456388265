import { Checkbox, CheckboxProps, Dropdown, DropdownProps, FormField, FormLabel, FormMessage, Input, InputProps } from '@fluentui/react-northstar';
import React, { PropsWithChildren, useContext } from 'react';
import { BookItSettingsOverride } from '../../../model';
import { PropsOf } from '../../../utils/utils';
import { BookItSettingsEdit, useSetting, useSettingOverrideState, useSettingsContext } from './BookItSettingsPage';
import css from './Settings.module.scss';

interface SettingsSectionProps {
    title: string
}
export const SettingsSection: React.FC<PropsWithChildren<SettingsSectionProps>> = ({ title, children }) => {
    return <div className={css.settingsSection}>
        <h4>{title}</h4>
        <div className={css.settingsSectionBody}>
            {children}
        </div>
    </div>
}

interface SettingProps {
    title: string
    description: string
    name?: PropsOf<BookItSettingsEdit, boolean>
    disabled?: boolean
    overrideName: keyof BookItSettingsOverride
    defaultValue?: boolean
}

const SettingOverrideContext = React.createContext<string | null>(null)

export function Setting({ title, description, name, disabled, children, overrideName, defaultValue }: PropsWithChildren<SettingProps>) {
    const ctx = useSettingsContext()
    const showChildren = name === undefined || Boolean(ctx.values[name])

    return <div className={css.setting}>
        <SettingOverrideContext.Provider value={ctx.overrides !== undefined ? overrideName : null}>
            <div className={css.settingHeader}>
                <div className={css.settingHeaderDesc}>
                    <strong>{title}</strong>
                    <span>{description}</span>
                    {overrideName !== null && <SettingOverride name={overrideName} />}
                </div>
                {name !== undefined && <SettingGroupToggle name={name!} disabled={disabled ?? false} defaultValue={defaultValue ?? false} />}
            </div>
            {showChildren && <div className={css.settingChildren}>
                {children}
            </div>}
        </SettingOverrideContext.Provider>
    </div>
}

export function useSettingOverride() {
    return useContext(SettingOverrideContext)
}

interface SettingOverrideProps {
    name: keyof BookItSettingsOverride
}

function SettingOverride({ name }: SettingOverrideProps) {

    const [override, onChange] = useSettingOverrideState(name)

    if (override === null) return null

    return <div className={css.settingOverride}>
        <span className={css.settingOverrideDesc}>{`${override ? "Overrides the" : "Inherits from the"} organisation setting`}</span>
        <Checkbox
            id={name}
            onChange={(_e, d) => {
                if (d && d.checked !== undefined) {
                    onChange(d.checked)
                }
            }}
            label={<span className={css.overrideCheckbox}>Override</span>}
            checked={override}
        />
    </div >
}


const SettingGroupToggle: React.FC<{ name: PropsOf<BookItSettingsEdit, boolean>, disabled: boolean, defaultValue: boolean }>
    = ({ name, disabled, defaultValue }) => {
        const { value, onChange, isDisabled } = useSetting(name, disabled, defaultValue);

        return <Checkbox
            toggle
            id={name}
            onChange={(_e, d) => {
                if (d && d.checked !== undefined) {
                    onChange(d.checked)
                }
            }}
            disabled={isDisabled}
            checked={value}
        />
    }

type SettingBaseProps<T extends keyof BookItSettingsEdit> = {
    validate?: (value: Partial<BookItSettingsEdit>[T]) => [boolean, string?]
    defaultValue: NonNullable<BookItSettingsEdit[T]>
}

type SettingInputProps<T extends PropsOf<BookItSettingsEdit, string | number>> = SettingBaseProps<T> & Omit<InputProps, 'name' | 'label' | 'defaultValue'> & {
    name: T;
    label?: string
};

export function SettingInput<T extends PropsOf<BookItSettingsEdit, string | number>>({
    label,
    name,
    validate: validateFn,
    defaultValue,
    disabled,
    ...props
}: SettingInputProps<T>) {
    const { value, onChange, validate, isDisabled } = useSetting(name, disabled ?? false, defaultValue, validateFn)
    const [isValid, errorMsg] = validate(value)
    const id = props.id || name

    return (
        <FormField className={css.settingInput}>
            {label && <FormLabel inline htmlFor={id} id={`${id}-label`}>
                {label}
            </FormLabel>}
            <div className={css.settingInputAndError}>
                <Input fluid error={!isValid} id={id} {...props} disabled={isDisabled} value={value} onChange={(event, data) => {
                    if (data) {                        
                        onChange(typeof defaultValue === 'string' ? data.value : data.value ? Number(data.value) : Number.NaN as any)
                    }
                }}
                />
                <FormMessage id={`${id}message-id`} role="alert" error={!isValid}>
                    {errorMsg}
                </FormMessage>
            </div>

        </FormField >
    );
};

type SettingToggleProps<T extends PropsOf<BookItSettingsEdit, boolean>> = SettingBaseProps<T> & Omit<CheckboxProps, 'name' | 'label'> & {
    name: T;
    label?: string
    onChange?: (checked: boolean) => void
};

export function SettingToggle<T extends PropsOf<BookItSettingsEdit, boolean>>({
    label,
    name,
    onChange,
    validate: validateFn,
    disabled,
    defaultValue,
    ...props
}: SettingToggleProps<T>) {
    const { value, onChange: onValueChange, isDisabled } = useSetting(name, disabled ?? false, defaultValue, validateFn);

    return (
        <FormField>
            {label && <FormLabel htmlFor={name} id={`${name}-label`} className={css.toggleLabel} >
                {label}
            </FormLabel>}

            <Checkbox
                toggle
                disabled={isDisabled}
                onChange={(_e, d) => {
                    if (d && d.checked !== undefined) {
                        onValueChange(d.checked)
                        if (onChange) onChange(d.checked)
                    }
                }}
                checked={value}
                {...props}
            />
        </FormField>
    );
};


type SettingsDropdownProps = ({
    multiple: true
    defaultValue: string[]
    name: PropsOf<BookItSettingsEdit, string[]>
    getKey?: (item: any) => string
} | {
    multiple?: false
    defaultValue: string
    name: PropsOf<BookItSettingsEdit, string>
    getKey?: (item: any) => string
}) & Omit<DropdownProps, 'name' | 'onChange' | 'multiple'> & {
    label?: string
}

export function SettingDropdown({
    label,
    name,
    disabled,
    defaultValue,
    getKey,
    ...props
}: SettingsDropdownProps) {
    const { value, onChange, isDisabled } = useSetting(name, disabled ?? false, defaultValue);
    const getKeyFn = getKey ?? ((item: any) => item)
    return (
        <FormField className={css.settingInput}>
            {label && <FormLabel inline htmlFor={name} id={`${name}-label`}>
                {label}
            </FormLabel>}
            <div className={css.settingInputAndError}>
                <Dropdown
                    multiple
                    onChange={(_e, d) => {
                        if (d.value) {
                            onChange(getKeyFn(d.value))
                        }
                    }}
                    value={value}
                    disabled={isDisabled}
                    {...props}
                />
            </div>

        </FormField >

    )
}

