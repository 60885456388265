import { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { AllowedOrganisation } from "../model"
import { ApiAction } from "./createApiResourceActions"
import { ApiResourceState } from "./reducers/ApiResourceState"
import { getActiveOrganisation } from "./selectors"
import { RootState } from "./types"
import { useSelector } from "./utils"



export function useOrganisationResource<TResource extends {}, TArg>(
    selectState: (s: RootState) => ApiResourceState<TResource>,
    fetchAllAction: ApiAction<TArg, Array<TResource>>,
    keySelector: (activeOrg: AllowedOrganisation) => TArg
    ) {
    const dispatch = useDispatch()

    const activeOrganisation = useSelector(getActiveOrganisation)

    const { isCreating, isLoaded, isLoading, failed, resources } = useSelector(s => selectState(s))
    useEffect(() => {
        if (!isLoaded && !isLoading && !failed) {
            dispatch(fetchAllAction.request(keySelector(activeOrganisation)))
        }
    }, [activeOrganisation, activeOrganisation.organisation_id, dispatch, failed, fetchAllAction, isLoaded, isLoading, keySelector])
    const values = useMemo(() => Object.values(resources), [resources])

    return {isCreating, isLoaded, isLoading, failed, values, activeOrganisation}
}