import React, { useState } from 'react';





const OutlookRoomLocatorPage: React.FC<{}> = () => {
    
    console.log('Loading OutlookRoomLocatorPage');
    
    
    

    return <>
        <div>Good morning campers</div>
    </>
}

export default OutlookRoomLocatorPage;