import classNames from 'classnames';
import React from 'react';
import { BookItAttribute } from '../../model';

interface Props{
    attribute: BookItAttribute
    selected?: boolean
    onClick?:(attribute: BookItAttribute) => void
}

const AttributeThumb : React.FC<Props> = (props) => {
    return <div className={classNames('attribute', props.selected && 'selected')} onClick={() => props.onClick!(props.attribute)}>
    <img src={props.attribute.url} alt='icon' />
    <span className='label'>{props.attribute.name}</span>
</div>
}

export default AttributeThumb