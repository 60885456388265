import { Button, ButtonProps, DownloadIcon, Dropdown, Flex, Loader, RetryIcon, Text } from "@fluentui/react-northstar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RoomLogsProps } from "../../../containers/RoomLogs";
import { LogFiles, LogFilesType, Room } from "../../../model";
import { HalResource } from "../../../services/Hal";
import { fileDownloadFromBlob } from "../../../utils/fileDownload";
import { formatBytes } from "../../../utils/formatBytes";
import Card from "../../controls/Card";
import { RoomPropertyField } from "./RoomPropertyField";

type Props = RoomLogsProps & {
    room: HalResource<Room>
}

const RoomLogs: React.FC<Props> = (props) => {
    const { downloadedLogFile, downloadComplete } = props
    const [selectedLog, setSelectedLog] = useState<LogFilesType>("RoomSystem")

    useEffect(() => {
        props.fetchLogMetdata({ room: props.room, log: selectedLog })
    }, [])

    const handleSelectionChange = (evt: any, data: any): void => {
        const logType = data.value
        const metadata = props.logFilesMetadata[logType]
        setSelectedLog(logType)
        console.log('selected log', logType, 'existing metdata', metadata)
        if (metadata === undefined) {
            props.fetchLogMetdata({ room: props.room, log: logType })
        }
    }

    const onDownload = (event: React.SyntheticEvent<HTMLElement>, buttonProps?: ButtonProps) => {
        props.fetchLog({ room: props.room, log: selectedLog })
    }

    useEffect(() => {
        if (downloadedLogFile !== undefined) {
            console.log('downloadLogFile available', downloadedLogFile)
            if (!downloadedLogFile.failed) {
                fileDownloadFromBlob(downloadedLogFile.data!, downloadedLogFile.fileName || `${downloadedLogFile.logName}.log`)
                downloadComplete(downloadedLogFile.logName)
            }
        }
    }, [downloadedLogFile, downloadComplete])

    const onRefresh = (event: React.SyntheticEvent<HTMLElement>, buttonProps?: ButtonProps) => {
        props.fetchLogMetdata({ room: props.room, log: selectedLog })
    }

    const currentLog = props.logFilesMetadata[selectedLog]
    const canDownload =  currentLog && currentLog.metadata

    return (
        <Card column styles={{ marginTop: '1em' }} padding='padding.medium' gap='gap.medium'>
            <Flex padding='padding.medium' gap='gap.medium'>
                <Dropdown items={LogFiles} onChange={handleSelectionChange} defaultValue={LogFiles[0]} />
                <Button primary content='Download' icon={<DownloadIcon/>} iconPosition='before' disabled={!canDownload} onClick={onDownload} />
                <Button secondary content='Refresh' icon={<RetryIcon/>} iconPosition='before' disabled={currentLog === undefined} onClick={onRefresh} />
            </Flex>
            {currentLog === undefined ? <Loader label='Fetching log data...'/> : null}
            {currentLog && currentLog.error ? <Text content={currentLog.error!.message} error />: null}
            {currentLog && currentLog.metadata ?  <RoomPropertyField label='Size' content={formatBytes(currentLog.metadata!.resource.size)} /> : null}
            {currentLog && currentLog.metadata ?  <RoomPropertyField label='Last Modified' content={`${moment.duration(moment().diff(currentLog.metadata!.resource.last_modified_date_utc)).humanize()} ago`} /> : null}

        </Card>
    )
}

export default RoomLogs