import React from "react";
import { Pie } from "react-chartjs-2";
import { HeatMap } from "../../../model";
import { avg, colors, getScaledUsage, getTimes, ReportFilter, resourceTypes, toSemiFixed } from '../MetricsPage';


function ResourceOccupancy( { filter, heatMap }: { filter: ReportFilter, heatMap: HeatMap }) {

    const values = heatMap.room_type_usage.map(x => ({
        type: resourceTypes[x.attendee_type],
        color: colors[x.attendee_type],
        subtotal: getTimes(filter.isWorkingHoursOnly).map((_, i) => avg(x.room_usage.map(r => getScaledUsage(r.usage_by_hour, i, filter.isWorkingHoursOnly)))).reduce((a, c) => c + a, 0),
    }))

    const total = values.reduce((a, c) => c.subtotal + a, 0);
    const labels = values.map(v => v.type)

    var bookingCountData = {
        labels: labels,
        datasets: [{
            backgroundColor: values.map(v => v.color),
            data: values.map(v => parseFloat(toSemiFixed((v.subtotal / total) * 100)))
        },
        ]
    };

    if (values.length > 1) {
        return <div className="pieGraph">
            <h2>Resource Occupancy </h2>
            <div className="pieGraphContainer">
                <Pie data={bookingCountData}
                    options={{
                        legend: {
                            position: "right",
                            labels: { fontColor: '#6957af' }
                        },
                        responsive: true
                    }
                    } />
            </div>
        </div>
    } else {
        return <div></div>
    }
}

export default ResourceOccupancy