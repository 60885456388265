import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { AllowedOrganisation, PortalSettings, ServerInfo, TimeZoneData, UserInfo } from '../../model';
import { actions, Actions } from '../types';
import { ApiResult } from './ApiResult';
import { createApiReducer } from './createApiReducer';

interface HubState {
    started: boolean
}
function hubReducer(state: HubState = { started: false }, action: Actions): HubState {
    switch (action.type) {
        case getType(actions.hubsStarted):
            return { ...state, started: true }
        default:
            return state
    }
}

function activeOrganisation(state: AllowedOrganisation | false = false, action: Actions) {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return action.payload
        case getType(actions.fetchUserInfo.success):
            return action.payload.allowed_organisations.find(org => org.organisation_id === action.payload.user.primary_organisation.organisation_id) ?? false
        default:
            return state
    }
}

function portalSettings(state: PortalSettings | false = false, action: Actions) {
    switch (action.type) {
        case getType(actions.setPortalSettings):
            return action.payload
        default:
            return state
    }
}

function userInfo(state: ApiResult<UserInfo> = ApiResult.default(), action: Actions): ApiResult<UserInfo> {
    switch (action.type) {
        case getType(actions.clearUserServerInfo):
            return ApiResult.default()
        default:
            return createApiReducer(actions.fetchUserInfo)(state, action)
    }
}

function serverInfo(state: ApiResult<ServerInfo> = ApiResult.default(), action: Actions): ApiResult<ServerInfo> {
    switch (action.type) {
        case getType(actions.clearUserServerInfo):
            return ApiResult.default()
        default:
            return createApiReducer(actions.fetchServerInfo)(state, action)
    }
}

function timezone(state: TimeZoneData|false = false, action: Actions): TimeZoneData|false {
    switch (action.type) {
        case getType(actions.setTimezoneData): 
            return action.payload;
        default:
            return state
    }
}


export default combineReducers({
    userInfo,
    serverInfo,
    hubs: hubReducer,
    activeOrganisation,
    timezone,
    portalSettings
})
