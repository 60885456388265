import { ComponentEventHandler, Dialog, DialogProps, RadioGroup, RadioGroupItemProps, ShorthandCollection } from '@fluentui/react-northstar';
import React, { useCallback, useEffect, useState } from 'react';
import { ComponentType } from '../../../model';

interface SelectComponentToUpdateDialogProps {
    onConfirm: (selectedValue: ComponentType) => void
    onCancel: () => void
    roomDetails?: string
    adminDetails?: string
    jsDetails?: string
    isOpen?: boolean
}

const items = [
    {
        name: 'room',
        key: 'Room',
        label: 'Room (No Updates)',
        value: 'Room',
        disabled: true
    },
    {
        name: 'admin',
        key: 'Admin',
        label: 'Admin (No Updates)',
        value: 'Admin',
        disabled: true
    },
    {
        name: 'js',
        key: 'Js',
        label: 'Javascript Package (No Updates)',
        value: 'JavascriptPackage',
        disabled: true
    }
]

const SelectComponentToUpdateDialog: React.FC<SelectComponentToUpdateDialogProps> = ({ isOpen, onConfirm, onCancel, roomDetails, adminDetails, jsDetails }) => {
    const [open, setOpen] = useState(false)
    const [selectedValues, setSelectedValues] = useState<ShorthandCollection<RadioGroupItemProps, never> | undefined>()
    const [selectedValue, setSelectedValue] = useState<string>('Room')

    const handleConfirm: ComponentEventHandler<DialogProps> = (_event, _props) => {
        setOpen(false)
        onConfirm(selectedValue as ComponentType)
    }
    const handleCancel: ComponentEventHandler<DialogProps> = (_event, _props) => {
        setOpen(false)
        onCancel()
    }

    const handleChange = useCallback((_e: React.SyntheticEvent<HTMLElement>, props?: RadioGroupItemProps) => {
        if (props !== undefined && (typeof props.value === 'string')) setSelectedValue(props.value)
    }, [])

    useEffect(() => {
        if (isOpen !== undefined) {
            setOpen(isOpen)
        }
    }, [isOpen])
    useEffect(() => {
        setSelectedValues(items.map(i => makeItem(i, roomDetails, adminDetails, jsDetails)))
        if (roomDetails) {
            setSelectedValue('Room')
        }
        else if (adminDetails) {
            setSelectedValue('Admin')
        }
        else if (jsDetails) {
            setSelectedValue('JavascriptPackage')
        }
    }, [roomDetails, adminDetails, jsDetails, open])
    
    return (<Dialog
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        open={open}
        onOpen={(e, data) => { if (data && data.open) setOpen(data!.open!)}} 
        cancelButton="Cancel"
        confirmButton='Update'
        content={<RadioGroup
            vertical
            defaultCheckedValue={selectedValue}
            items={selectedValues}
            onCheckedValueChange={handleChange}
          />}
        header='Select component to update'
        />)
}

function makeItem(item: typeof items[0], roomDetails?: string, adminDetails?: string, jsDetails?: string) {
    if (item.name === 'room' && roomDetails) {
        return {...item, label: roomDetails, disabled: false}
    }
    if (item.name === 'admin' && adminDetails) {
        return {...item, label: adminDetails, disabled: false}
    }
    if (item.name === 'js' && jsDetails) {
        return {...item, label: jsDetails, disabled: false}
    }
    return item
}

export default SelectComponentToUpdateDialog

