import { AddIcon, Button, ButtonProps, Dialog, EditIcon, Flex, Form, FormField, FormProps, Input, Loader, Toolbar, TrashCanIcon } from "@fluentui/react-northstar";
import { compare } from "fast-json-patch";
import * as React from 'react';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTable, { CellInfo } from "react-table";
import { ApiType, CorsAllowedOrigin } from "../../model";
import { actions } from "../../store/types";
import { useSelector } from "../../store/utils";
import DropdownControlled from "../controls/DropdownControlled";

const CorsSettingsPage: React.FC<{}> = () => {
    const dispatch = useDispatch()
    const allowedOrigins = useSelector( s=> s.cors.allowedOrigins)

    useEffect(() => {
        dispatch(actions.fetchCorsAllowedOrigins.request())
    }, [dispatch])

    // origin being created/edited
    const [corsAllowedOrigin, setCorsAllowedOrigin] = useState<CorsAllowedOrigin>()
    const [isUpdate, setIsUpdate] = useState(true)

    const addOrUpdate = (corsOrigin: CorsAllowedOrigin) => {
        if (isUpdate) {
           const diff = compare(corsAllowedOrigin!, corsOrigin);
           if (diff.length > 0) {
            dispatch(actions.patchCorsAllowedOrigin.request({id: corsAllowedOrigin!.cors_allowed_origin_id!, operations: diff}))
           }
        } else {
            dispatch(actions.createCorsAllowedOrigin.request(corsOrigin))
        }
        setCorsAllowedOrigin(undefined)
        setIsUpdate(true)
    }

    const handleDeleteOrigin = (origin: CorsAllowedOrigin) => {
        dispatch(actions.deleteCorsAllowedOrigin.request(origin))
    }

    const renderActions = (cellInfo: CellInfo) => {
        return (<Toolbar
            items={[{
                key: 'edit',
                icon: <EditIcon outline />,
                onClick: () => {
                    setIsUpdate(true)
                    setCorsAllowedOrigin(cellInfo.value)
                }
            }, {
                key: 'delete',
                icon: <TrashCanIcon outline />,
                onClick: () => handleDeleteOrigin(cellInfo.value)
            }]}
        />)
    }
  
    return (<>
        <h5>CORS</h5>

        <Flex column gap="gap.small">
            <Flex hAlign='end'>
                <Dialog
                    content={<CorsOriginForm
                        corsOrigin={corsAllowedOrigin!}
                        onSubmit={addOrUpdate}
                        onCancel={() => {
                            setCorsAllowedOrigin(undefined)
                            setIsUpdate(true)
                        }} />}
                    header="Add/Edit a CORS definition"
                    open={corsAllowedOrigin !== undefined}
                    onOpen={(evnt: any, data?: any) => {
                        setIsUpdate(false)
                        setCorsAllowedOrigin({ api: 'Room' })
                    }}
                    trigger={<Button primary content='Add Origin' icon={<AddIcon/>} iconPosition='before' />}
                />
            </Flex>
            <ReactTable
                className="-highlight"
                showPagination={false}
                columns={[
                    { Header: "#", accessor: "seq", maxWidth: 30, Cell: (cell: CellInfo) => <p className='font-weight-bold'>{cell.viewIndex + 1}</p> },
                    { Header: "Origin", accessor: "origin" },
                    { Header: "Description", accessor: "description" },
                    { Header: "API", accessor: "api" },
                    { Header: "", accessor: "data", Cell: renderActions }

                ]}
                defaultSorted={([{ id: "#", desc: false }])}
                data={allowedOrigins
                    ? allowedOrigins!.map((origin, index) => ({
                        seq: index,
                        origin: origin.origin,
                        description: origin.description,
                        api: origin.api as string,
                        data: origin
                    }))
                    : []
                }
                defaultPageSize={allowedOrigins.length === 0 ? 0 : allowedOrigins.length}
                loadingText={<Loader label='Loading...' />}
                loading={allowedOrigins === undefined}
            />
        </Flex>

    </>)
}

interface CorsOriginFormProps {
    corsOrigin: CorsAllowedOrigin
    onSubmit: (corsOrigin: CorsAllowedOrigin) => void
    onCancel: () => void
}

const ApiOptions: string[] = ['Server', 'Admin', 'Room'];


const CorsOriginForm: React.FC<CorsOriginFormProps> = (props) => {
    const [state, setState] = useState<CorsAllowedOrigin>({ api: 'Room' })

    useEffect(() => setState({...props.corsOrigin}), [props.corsOrigin])

    const handleSubmit = (event: React.SyntheticEvent<HTMLElement>, data?: FormProps) => {
        props.onSubmit(state)
    }

    const handleCancel = (event: React.SyntheticEvent<HTMLElement>, data?: ButtonProps) => {
        props.onCancel()
    }

    const handleChange = (evt: any) => {
        console.log('handleChange', { n:evt.target.name, v:evt.target.value} )        
        const { name, value } = evt.target;
        setState(prevState => ({ ...prevState, [name]: value }))
    }

    const handleDropdownChange = (value: string | null): void => {
        console.log('handleDropdownChange', value)
        setState(prevState => ({ ...prevState, api: value as ApiType }))

    }

    console.log('CorsOriginForm: ', props.corsOrigin)

    return (<Form styles={{ width: '350px' }} onSubmit={handleSubmit}>
        <Flex column gap="gap.medium" fill >
            <FormField label="CORS Origin"  id="origin" control={<Input name="origin" onChange={handleChange} value={state.origin} fluid required />} />
            <FormField label="Description" id="description" required control={<Input name="description" onChange={handleChange} value={state.description} fluid required />} />
            <FormField label="API Type" name="api" id="api" control={
                <DropdownControlled dataItems={ApiOptions} selectedKey={props.corsOrigin.api} onSelectionChanged={handleDropdownChange} getHeader={i => i} allowNone={false} />}
            />
            <Flex gap="gap.smaller" hAlign="end">
                <FormField
                    control={{
                        as: Button,
                        onClick: handleCancel,
                        content: 'Cancel',
                    }}
                />
                <FormField
                    control={{
                        as: Button,
                        primary: true,
                        content: 'Submit',
                    }}
                />
            </Flex>
        </Flex>
    </Form>)
}

export default CorsSettingsPage