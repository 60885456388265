import { createBrowserHistory } from 'history';
import { configureStore } from './store/configureStore';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;

export const history = createBrowserHistory({
    basename: baseUrl
  });
  
export const store = configureStore({}, history);

