import { AddIcon, Button, Dropdown, EditIcon, FormField, FormLabel, Loader, TrashCanIcon } from "@fluentui/react-northstar";
import { compare } from "fast-json-patch";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { BookableResource, CalendarDelegation, CreateCalendarDelegation, IdType } from "../../model";
import { createCalendarDelegation, deleteCalendarDelegation, fetchBookableResources, fetchCalendarDelegations, patchCalendarDelegation } from "../../store/resourceActions";
import { getActiveOrganisation, getBookableResources } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { isValidEmail } from "../../utils/utils";
import ResourceAdminForm from "../admin/ResourceAdminForm";
import ResouceAdminPage from '../admin/ResourceAdminPage';
import { FormikNorthstarDropdown } from "../controls/FormikNorthstarDropdown";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";
import { FormikNorthstarRadioGroup } from "../controls/FormikNorthstarRadioGroup";
import css from './CalendarDelegationsPage.module.scss';

interface Props { }


const CalendarDelegationsPage: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const cols = useMemo(() => [
        { Header: "Email Address", id: "email_address", minWidth: 40, accessor: (row: CalendarDelegation) => row.email_address },
        { Header: "Access", id: "access", accessor: (row: CalendarDelegation) => getAccess(row) },
    ], [])

    const activeOrganisation = useSelector(getActiveOrganisation)

    useEffect(() => {
        if (activeOrganisation) {
            dispatch(fetchBookableResources.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch])
    const bookableResources = useSelector(getBookableResources)

    const handleAddOrUpdate = (calendarDelegation: CreateCalendarDelegation, closeDialog: () => void, original: CalendarDelegation | undefined) => {
        if (activeOrganisation === undefined) {
            console.error("Active organisation is not defined!. Cannot create calendar delegation")
            return
        }
        if (original) {
            // remove the arrays because we don't support array patch
            const { people_list, room_list, organisation_id, ...orginalClean } = original

            let diff = compare(orginalClean, calendarDelegation);
            dispatch(patchCalendarDelegation.request({ id: original, operations: diff }))
        } else {
            const newCalendarDelegate = { ...calendarDelegation, organisation_id: activeOrganisation.organisation_id }
            dispatch(createCalendarDelegation.request(newCalendarDelegate))
        }
        closeDialog()
    }

    if (!activeOrganisation) {
        return <Loader label='Loading...' />
    }

    return <ResouceAdminPage<CalendarDelegation, { organisation_id: IdType }>
        resourceName='Calendar Delegation'
        parentKey={{ organisation_id: activeOrganisation!.organisation_id }}
        selectState={s => s.bookit.calendarDelegations}
        fetchAllAction={fetchCalendarDelegations}
        deleteAction={deleteCalendarDelegation}
        confirmMessage={e => `Are you sure you wish to delete the calendar delegation for '${e.email_address}'?`}
        resourceForm={(original, closeDialog) =>
            <CalendarDelegationForm
                initial={createEditModel(original)}
                bookableResources={bookableResources}
                organisationId={activeOrganisation.organisation_id}
                onSubmit={edit => handleAddOrUpdate(edit, closeDialog, original)}
                onCancel={closeDialog}
            />
        }
        toolbarActionItems={(resource, onAddUpdate, onDelete) =>
            [{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: `Edit ${resource.email_address}`,
                onClick: () => onAddUpdate(resource)
            },
            {
                key: 'delete',
                icon: <TrashCanIcon outline />,
                tooltip: `Delete ${resource.email_address}`,
                onClick: () => onDelete(resource)
            }]
        }
        columns={cols}
        defaultSortOrder={[{ id: "name", desc: false }]}
    />
}

function getAccess(delegation: CalendarDelegation): string {
    let access = ""
    function concat(txt: string, info: string): string {
        if (txt.length > 0) {
            return txt + ", " + info
        }
        return info
    }
    if (delegation.all_people) {
        access = concat(access, "All People")
    } else if (delegation.people_list.length > 0) {
        access = concat(access, `People: ${delegation.people_list.join(", ")}`)
    }
    if (delegation.all_rooms) {
        access = concat(access, "All Rooms")
    } else if (delegation.room_list.length > 0) {
        access = concat(access, `Rooms: ${delegation.room_list.join(", ")}`)
    }

    return access
}

function createEditModel(resource?: CalendarDelegation): CreateCalendarDelegation | undefined {
    if (resource === undefined) return undefined
    const { organisation_id, ...edit } = resource
    return edit
}

interface CalendarDelegationFormProps {
    initial?: CreateCalendarDelegation
    organisationId: IdType
    onSubmit: (edit: CreateCalendarDelegation) => void
    onCancel: () => void,
    bookableResources: Array<BookableResource>
}


interface Item {
    header: string
    key: string
}

type GroupState = "all" | "selected" | "none"
type CalendarDelegationEditModel = {
    email_address: string
    all_rooms: GroupState,
    all_people: GroupState,
    people_list: Item[]
    room_list: Item[]
}

function getGroupState(all: boolean, selected: string[]): GroupState {
    return all ? "all" : (selected.length === 0 ? "none" : "selected")
}
function getListFromState(state: GroupState, items: Item[]): string[] {
    return state === "all" || state === "none" ? [] : items.map(p => p.key)
}

const CalendarDelegationForm: React.FC<CalendarDelegationFormProps> = ({ initial, onSubmit, onCancel, organisationId, bookableResources }) => {

    const bookableResourceItems = useMemo(() => bookableResources.map(br => ({ header: br.email_address, key: br.email_address, bookable_resource_id: br.bookable_resource_id })), [bookableResources])

    const initialCalendarDelegation: CalendarDelegationEditModel = useMemo(() =>
        initial
            ? ({
                email_address: initial?.email_address,
                all_people: getGroupState(initial.all_people, initial.people_list),
                all_rooms: getGroupState(initial.all_rooms, initial.room_list),
                people_list: initial?.people_list.map(p => ({ header: p, key: p })) ?? [],
                room_list: initial?.room_list.map(p => ({ header: p, key: p })) ?? [],
            })
            : { email_address: '', all_people: "none", all_rooms: "none", people_list: [], room_list: [] }
        , [initial])

    const [peopleList, setPeopleList] = useState(initialCalendarDelegation.people_list)

    const handleSubmit = useCallback((data: typeof initialCalendarDelegation) => {
        const calendarDelegation: CreateCalendarDelegation = {
            email_address: data.email_address,
            all_people: data.all_people === "all",
            all_rooms: data.all_rooms === "all",
            people_list: getListFromState(data.all_people, peopleList),
            room_list: getListFromState(data.all_rooms, data.room_list),
        }
        onSubmit(calendarDelegation)
    }, [onSubmit, peopleList])

    const formSchema = useMemo(() => {
        let schema = Yup.object({
            email_address: Yup.string()
                .email()
                .required('Email address cannot be empty')
        })
        return schema.defined()
    }, [])


    const [canSelectPeople, setCanSelectPeople] = useState<boolean>(initialCalendarDelegation.all_people === "selected")
    const handleOnSelectedPeopleChange = (value: string | number) => {
        setCanSelectPeople(value === "selected")
    }
    const [canSelectRooms, setCanSelectRooms] = useState<boolean>(initialCalendarDelegation.all_rooms === "selected")
    const handleOnSelectedRoomsChange = (value: string | number) => {
        setCanSelectRooms(value === "selected")
    }

    const [searchQuery, setSearchQuery] = useState<string>()
    const [canAddEmail, setCanAddEmail] = useState<boolean>()
    const handleSearchQueryChange = (value: string | undefined) => {
        setSearchQuery(value)
        setCanAddEmail(isValidEmail(value))
    }

    const handleAddEmail = () => {
        if (searchQuery) {
            const newItem = { header: searchQuery, key: searchQuery }
            setSearchQuery("")
            setCanAddEmail(false)
            setPeopleList([...peopleList, newItem])
        }
    }

    return <ResourceAdminForm
        initial={initialCalendarDelegation}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <div className={css.calendarDelegationContent}>
            <FormikNorthstarInput fluid label="Email Address" name="email_address" id="email_address" />
            <div className={css.row}>
                <FormikNorthstarRadioGroup
                    label="People Delegation"
                    name="all_people"
                    vertical
                    className={css.radioGroup}
                    defaultCheckedValue={initialCalendarDelegation.all_people}
                    items={[{
                        name: 'none',
                        key: 'none',
                        label: 'None',
                        value: 'none'
                    }, {
                        name: 'all',
                        key: 'all',
                        label: 'All',
                        value: 'all'
                    }, {
                        name: 'selected',
                        key: 'selected',
                        label: 'Selected People',
                        value: 'selected'
                    }]}
                    onChange={handleOnSelectedPeopleChange}
                />

                <FormField>
                    <FormLabel htmlFor="people_list" id="people_list-label">Email Addresses</FormLabel>

                    <Dropdown
                        fluid
                        search
                        multiple
                        onChange={(_e, d) => {
                            if (d.value) {
                                setPeopleList(d.value as Item[])
                            }
                        }}
                        onSearchQueryChange={(_e, d) => {
                            handleSearchQueryChange(d.searchQuery)
                        }}
                        searchQuery={searchQuery}
                        value={peopleList}
                        disabled={!canSelectPeople}
                        placeholder='Select delegated calendars' />
                </FormField>

                <Button icon={<AddIcon />} iconOnly disabled={!canAddEmail} className={css.addButton} type="button" onClick={() => handleAddEmail()} />
            </div>
            <div className={css.row}>
                <FormikNorthstarRadioGroup
                    label="Room Delegation"
                    name="all_rooms"
                    vertical
                    className={css.radioGroup}
                    defaultCheckedValue={initialCalendarDelegation.all_rooms}
                    items={[{
                        name: 'none',
                        key: 'none',
                        label: 'None',
                        value: 'none'
                    }, {
                        name: 'all',
                        key: 'all',
                        label: 'All',
                        value: 'all'
                    }, {
                        name: 'selected',
                        key: 'selected',
                        label: 'Selected Rooms',
                        value: 'selected'
                    }]}
                    onChange={handleOnSelectedRoomsChange}
                />
                <FormikNorthstarDropdown
                    fluid
                    search
                    label="Email Addresses"
                    className={css.emailList}
                    items={bookableResourceItems}
                    multiple
                    name="room_list"
                    disabled={!canSelectRooms}
                    placeholder='Select delegated calendars'
                />
            </div>
        </div>
    </ResourceAdminForm>
}


export default CalendarDelegationsPage