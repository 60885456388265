import { EditIcon, Flex, TrashCanIcon } from "@fluentui/react-northstar";
import { compare, Operation } from "fast-json-patch";
import * as React from "react";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { AllNfcTagTypes, AllRoomSubTypes, CreateNfcTag, IdType, NearbyNfcTagConfiguration, NfcTag, NfcTagType, RoomSubType } from "../../model";
import { createNfcTag, deleteNfcTag, fetchNfcTags, patchNfcTag } from "../../store/resourceActions";
import { getActiveOrganisation } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { FormikNorthstarCheckbox } from "../controls/FormikNorthstarCheckbox";
import { FormikNorthstarDropdown } from "../controls/FormikNorthstarDropdown";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";
import css from './NfcTagsPage.module.scss';
import ResourceAdminForm from "./ResourceAdminForm";
import ResouceAdminPage from './ResourceAdminPage';

interface Props { }

type NfcTagEditModel = Omit<NfcTag, "nfc_tag_id" | "organisation_id" | "configuration_json"> & {
        default_resource_type?: RoomSubType 
        no_primary_device?: boolean
    }

const NfcTagsPage: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const cols = useMemo(() => [
        { Header: "Type", id: "type", minWidth: 10, accessor: (row: NfcTag) => row.tag_type },
        { Header: "Resource", id: "tag_name", accessor: (row: NfcTag) => row.tag_name, minWidth: 20 },
        { Header: "Location Code", id: "location_code", accessor: (row: NfcTag) => row.location_code, minWidth: 7 },
        { Header: "Unique Id", id: "unique_id", minWidth: 15, accessor: (row: NfcTag) => row.unique_id },
        { Header: "Default Resource Type", id: "default_resource_type", minWidth: 10, accessor: (row: NfcTag) => row.configuration_json?.DefaultResourceType ?? "" },
    ], [])

    const activeOrganisation = useSelector(getActiveOrganisation)

    const parentKey = useMemo(() => {
        console.log("Active Org changed to:", activeOrganisation)
        return { organisation_id: activeOrganisation!.organisation_id }
    }, [activeOrganisation]);

    const handleAddOrUpdate = (nfcTag: NfcTagEditModel, closeDialog: () => void, original: NfcTag | undefined) => {
        if (original) {
            const updateNfc = {
                nfc_tag_id: original.nfc_tag_id,
                organisation_id: original.organisation_id,
                unique_id: original.unique_id,
                tag_type: nfcTag.tag_type,
                tag_name: nfcTag.tag_name,
                serial_number: nfcTag.serial_number,
                location_code: nfcTag.location_code,
                configuration_json: nfcTag.tag_type === 'Nearby' ? {
                     DefaultResourceType: nfcTag.default_resource_type!,
                     NoPrimaryDevice: nfcTag.no_primary_device
                     } as NearbyNfcTagConfiguration : undefined
            }

            console.log("original:", original)
            console.log("nfcTag:", nfcTag)
            console.log("updateNfc:", updateNfc)
            let diff = compare(original, updateNfc);
            diff = fixDiff(diff, updateNfc.configuration_json)
            dispatch(patchNfcTag.request({ id: original, operations: diff }))
        } else {
            const newNfcTag: CreateNfcTag = {
                tag_type: nfcTag.tag_type,
                tag_name: nfcTag.tag_name,
                serial_number: nfcTag.serial_number,
                location_code: nfcTag.location_code,
                organisation_id: activeOrganisation.organisation_id,
                configuration_json: nfcTag.tag_type === 'Nearby' ? { 
                        DefaultResourceType: nfcTag.default_resource_type!,
                        NoPrimaryDevice: nfcTag.no_primary_device!
                    } : undefined
            }
            console.log("newNfcTag:", newNfcTag)

            dispatch(createNfcTag.request(newNfcTag))
        }
        closeDialog()
    }


    return <ResouceAdminPage<NfcTag, { organisation_id: IdType }>
        resourceName='Nfc Tag'
        parentKey={parentKey}
        selectState={s => s.nfcTags.nfcTags}
        fetchAllAction={fetchNfcTags}
        deleteAction={deleteNfcTag}
        confirmMessage={tag => `Are you sure you wish to delete the NFC Tag for Location Code/Serial No: ${tag.location_code ?? "Not Set"}/${tag.serial_number ?? "Not Set"}?`}
        resourceForm={(original, closeDialog) =>
            <NfcTagForm initial={createEditModel(original)} onSubmit={edit => handleAddOrUpdate(edit, closeDialog, original)} onCancel={closeDialog} />
        }
        toolbarActionItems={(resource, onAddUpdate, onDelete) =>
            [{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: `Edit ${resource.location_code ?? "Not Set"}/${resource.serial_number ?? "Not Set"}`,
                onClick: () => onAddUpdate(resource)
            },
            {
                key: 'delete',
                icon: <TrashCanIcon outline />,
                tooltip: `Delete ${resource.location_code ?? "Not Set"}/${resource.serial_number ?? "Not Set"}`,
                onClick: () => onDelete(resource)
            }]
        }
        columns={cols}
        defaultSortOrder={[{ id: "tag_name", desc: false }]}
    />
}

function createEditModel(resource?: NfcTag): NfcTagEditModel {
    if (resource === undefined) return { 
        tag_type: 'Nearby', 
        tag_name: '',
        serial_number: '', 
        location_code: '', 
        default_resource_type: 'HotDesk',
        no_primary_device: false
     }
    let { organisation_id, nfc_tag_id, configuration_json, ...edit } = resource
    if (edit.tag_type === 'Nearby') {
        return { ...edit,
            default_resource_type: (configuration_json?.DefaultResourceType as RoomSubType) ?? 'HotDesk',
            no_primary_device: configuration_json?.NoPrimaryDevice ?? false}
    }
    return edit
}

function fixDiff(diff: Operation[], jsonConfiguration: NearbyNfcTagConfiguration | undefined): Operation[] {
    let updatedDiff = diff
    const updateJsonConfiguration = diff.some(op => op.path.startsWith("/configuration_json"))
    if (updateJsonConfiguration) {
        updatedDiff = diff.filter(op => !op.path.startsWith("/configuration_json"))
        updatedDiff = [...updatedDiff, {
            op: 'replace',
            path: '/configuration_json',
            value: jsonConfiguration
        }]
    }

    return updatedDiff
}

interface NfcTagFormProps {
    initial: NfcTagEditModel
    onSubmit: (edit: NfcTagEditModel) => void
    onCancel: () => void
}

const NfcTagForm: React.FC<NfcTagFormProps> = ({ initial, onSubmit, onCancel }) => {
    console.log("initialNfcTag", initial)

    const [nfcTagType, setNfcTagType] = useState<NfcTagType>(initial.tag_type!)

    const formSchema = useMemo(() => Yup.object().shape({
        tag_type: Yup.string()
            .required("The Tag Type cannot be empty"),
        tag_name: Yup.string()
            .required("The Tag Name cannot be empty"),
    }), [])

    const [showRoomNoDevice, setShowRoomNoDevicee] = useState(initial.default_resource_type 
                                                && initial.default_resource_type == 'MeetingRoom')
    const handleChange = (item: string|undefined) => {
        setShowRoomNoDevicee(Boolean(item) && item === "MeetingRoom")
    }

    return <ResourceAdminForm
        initial={initial}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <FormikNorthstarDropdown<string> label="Tag Type" name="tag_type" items={AllNfcTagTypes} getHeader={i => i} onChange={t => t && setNfcTagType(t as NfcTagType)} />
        <FormikNorthstarInput fluid label="Resource" name="tag_name" />
        {/* <FormikNorthstarInput fluid label="NFC Serial Number" name="serial_number" /> */}
        <FormikNorthstarInput fluid label="Location Code" name="location_code" />
        <Flex>
            <FormikNorthstarDropdown<string>
                label="Default Resource Type"
                name="default_resource_type"
                disabled={nfcTagType !== 'Nearby'}
                items={AllRoomSubTypes}
                getHeader={i => i} 
                onChange={handleChange}
            />

            {showRoomNoDevice && <div className={css.noDevice}>
                <FormikNorthstarCheckbox
                        
                        toggle
                        label="Room Has No Primary Device" 
                        name="no_primary_device" 

                        />
            </div>}
        </Flex>
    </ResourceAdminForm>
}

export default NfcTagsPage