import { AzureAdClientOptions } from "./azure-ad";


interface ClientOptions extends AzureAdClientOptions {
    scopes: Array<string>,
}

export function clientConfig(): ClientOptions {

    return {
        authority: 'https://login.microsoftonline.com/organizations',
        clientId: '52111528-6325-488b-8a8f-33a32d870389',
        scopes: ['https://graph.microsoft.com/User.Read'],
        redirectUrl: process.env.PUBLIC_URL,
        postLogoutRedirectUri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${process.env.PUBLIC_URL}/signedOut`
    }
}