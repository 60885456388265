import { Button, RetryIcon } from '@fluentui/react-northstar';
import classNames from 'classnames';
import * as React from 'react';
import { MdCloudOff } from 'react-icons/md';
import css from './ServerUnavailable.module.scss';


export function ServerUnvailable({title, error, onRetry}: {title: string, error: string, onRetry?: () => void}) {
    return <div className={classNames(css.fullscreen)}>
      <MdCloudOff className={css.icon}/>
      <span className={css.errorTitle}>{title}</span>
      <span className={css.errorText}>{error}</span>
      <Button icon={<RetryIcon />} content="Retry" iconPosition="after" text 
            onClick={() => { if (onRetry) onRetry() }}/>
    </div>
  
  }