import { EditIcon, TrashCanIcon } from "@fluentui/react-northstar";
import { compare, Operation } from "fast-json-patch";
import * as React from "react";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { AzureAdOrganisationTenant, CreateOrganisationTenant, IdType } from "../../model";
import { createOrganisationTenant, deleteOrganisationTenant, fetchOrganisationTenants, patchOrganisationTenant } from "../../store/resourceActions";
import { getActiveOrganisation } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";
import { FormikNorthstarCheckbox } from "../controls/FormikNorthstarCheckbox";
import ResourceAdminForm from "./ResourceAdminForm";
import ResouceAdminPage from './ResourceAdminPage';

interface Props { }

type OrganisationTenantEditModel = Pick<AzureAdOrganisationTenant, "tenant_id" | "client_id" | "client_secret" | "allow_portal_login"> & { domains: string }

const OrganisationTenantsPage: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const cols = useMemo(() => [
        { Header: "Type", id: "type", minWidth: 15, accessor: (row: AzureAdOrganisationTenant) => row.type },
        { Header: "Client ID", id: "client_id", accessor: (row: AzureAdOrganisationTenant) => row.client_id, minWidth: 25 },
        { Header: "Tenant ID", id: "tenant_id", accessor: (row: AzureAdOrganisationTenant) => row.tenant_id, minWidth: 25 },
        { Header: "Domains", id: "domains", minWidth: 30, accessor: (row: AzureAdOrganisationTenant) => row.domains.join(", ") },
        { Header: "Allow portal login", id: "is_enabled", minWidth: 10, accessor: (row: AzureAdOrganisationTenant) => row.allow_portal_login ? 'Yes' : 'No' },
    ], [])

    const activeOrganisation = useSelector(getActiveOrganisation)

    const handleAddOrUpdate = (tenant: OrganisationTenantEditModel, closeDialog: () => void, original: AzureAdOrganisationTenant | undefined) => {
        if (original) {
            const updateTenant = {
                organisation_tenant_id: original.organisation_tenant_id,
                organisation_id: original.organisation_id,
                client_id: tenant.client_id,
                tenant_id: tenant.tenant_id,
                client_secret: tenant.client_secret,
                type: original.type,
                allow_portal_login: tenant.allow_portal_login,
                domains: getDomains(tenant.domains)
            }

            const {domains: _, ...intiial} = original // remove domains so we generate full replace of array in the patch

            console.log("intiial:", intiial)
            console.log("tenant:", tenant)
            console.log("updateTenant:", updateTenant)
            let diff = compare(intiial, updateTenant);
            diff = fixDiff(diff, updateTenant.domains)
            dispatch(patchOrganisationTenant.request({ id: original, operations: diff }))
        } else {
            const newTenant: CreateOrganisationTenant = {
                client_id: tenant.client_id,
                tenant_id: tenant.tenant_id,
                client_secret: tenant.client_secret,
                type: 'AzureAdToken',
                domains: getDomains(tenant.domains),
                organisation_id: activeOrganisation.organisation_id,
                allow_portal_login: tenant.allow_portal_login
            }
            console.log("newTenant:", newTenant)

            dispatch(createOrganisationTenant.request(newTenant))
        }
        closeDialog()
    }


    return <ResouceAdminPage<AzureAdOrganisationTenant, { organisation_id: IdType }>
        resourceName='OrganisationTenant'
        parentKey={{ organisation_id: activeOrganisation!.organisation_id }}
        selectState={s => s.organisations.organisationTenant}
        fetchAllAction={fetchOrganisationTenants}
        deleteAction={deleteOrganisationTenant}
        confirmMessage={org => `Are you sure you wish to delete the organisation tenant for Client ID ${org.client_id}?`}
        resourceForm={(original, closeDialog) =>
            <OrganisationTenantForm initial={createEditModel(original)} onSubmit={edit => handleAddOrUpdate(edit, closeDialog, original)} onCancel={closeDialog} />
        }
        toolbarActionItems={(resource, onAddUpdate, onDelete) =>
            [{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: `Edit ${resource.tenant_id}`,
                showButton: () => resource.type === 'AzureAdToken',
                onClick: () => onAddUpdate(resource)
            },
            {
                key: 'delete',
                icon: <TrashCanIcon outline />,
                tooltip: `Delete ${resource.tenant_id}`,
                onClick: () => onDelete(resource)
            }]
        }
        columns={cols}
        defaultSortOrder={[{ id: "is_enabled", desc: true }, { id: "name", desc: false }]}
    />
}

function fixDiff(diff: Operation[], domains: string[]): Operation[] {
    let updatedDiff = diff
    // dont use add/remove just replace whole array in domain

    const updateDomains = diff.some(op => op.path.startsWith("/domains"))
    if (updateDomains) {
        updatedDiff = diff.filter(op => !op.path.startsWith("/domains"))
        updatedDiff = [...updatedDiff, {
            op: 'replace',
            path: '/domains',
            value: domains
        }]
    }
 
    return updatedDiff
}

function createEditModel(resource?: AzureAdOrganisationTenant): OrganisationTenantEditModel | undefined {
    if (resource === undefined) return undefined
    const { organisation_id, organisation_tenant_id, type, ...edit } = resource
    return { ...edit, domains: resource.domains.join(", ") }
}

function getDomains(domainStr: string): string[] {
    return domainStr ? domainStr.split(',').filter(s => s && s.length > 0).map(s => s.trim()) : []
}

interface OrganisationTenantFormProps {
    initial?: OrganisationTenantEditModel
    onSubmit: (edit: OrganisationTenantEditModel) => void
    onCancel: () => void
}

const OrganisationTenantForm: React.FC<OrganisationTenantFormProps> = ({ initial, onSubmit, onCancel }) => {
    const initialOrganisationTenant = useMemo(() =>
        initial ?? { tenant_id: '', client_id: '', client_secret: '', domains: '' } as OrganisationTenantEditModel, [initial])

    console.log("initialOrganisationTenant", initialOrganisationTenant)

    const formSchema = useMemo(() => Yup.object().shape({
        tenant_id: Yup.string()
            .required("The Tenant ID cannot be empty"),
        client_id: Yup.string()
            .required("The Client ID cannot be empty"),
        client_secret: Yup.string()
            .required("The Client Secret cannot be empty"),
        domains: Yup.string()
            .required("The domains cannot be empty")
    }), [])

    return <ResourceAdminForm
        initial={initialOrganisationTenant}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <FormikNorthstarInput fluid label="Tenant ID" name="tenant_id" />
        <FormikNorthstarInput fluid label="Client ID" name="client_id" />
        <FormikNorthstarInput fluid label="Client Secret" name="client_secret" />
        <FormikNorthstarInput fluid label="Allowed Domains (Comma separated)" name="domains" />
        <FormikNorthstarCheckbox toggle label="Allow portal login" name="allow_portal_login" /> 

    </ResourceAdminForm>
}

export default OrganisationTenantsPage