import { Dropdown, DropdownProps, FormField, FormLabel, FormMessage } from '@fluentui/react-northstar'
import classNames from 'classnames'
import { useField } from 'formik'
import React, { useMemo } from 'react'

type FormikDropdownFieldProps<TItem = any> = Omit<DropdownProps, 'name' | 'onChange' | 'items'> & {
  label: string
  name: string
  onChange?: (item: TItem | undefined) => void
  items: TItem[]
  getHeader?: (item: TItem) => string
  labelClassName?: string
  allowNone?: boolean
  selectedItemPredicate?: (item: TItem, formikValue: any) => boolean
}

export function FormikNorthstarDropdown<TItem>({
  label,
  name,
  items,
  onChange,
  getHeader,
  labelClassName,
  allowNone,
  selectedItemPredicate,
  multiple,
  ...props
}: FormikDropdownFieldProps<TItem>) {

  const dropdownItems = useMemo<Array<FormikNorthstarDropdownItem<TItem> | TItem>>(() => {
    if (getHeader === undefined) return items;
    if (items === undefined) return []
    const dropItems = items.map(item => ({ header: getHeader(item), data: item } as FormikNorthstarDropdownItem<TItem>))
    return allowNone ?? false ? [{ header: 'None', data: undefined }, ...dropItems] : dropItems
  }, [allowNone, getHeader, items])

  const [field, metadata, { setValue, setTouched }] = useField(name)
  const isError = metadata.touched && metadata.error !== undefined

  const value = useMemo(() => getHeader === undefined ? field.value : dropdownItems.find((di: any) => {
    const res = selectedItemPredicate && di.data !== undefined ? selectedItemPredicate(di.data, field.value) : di.data === field.value
    return res
  }), [dropdownItems, field.value, getHeader, selectedItemPredicate])

  return (
    <FormField>
      <FormLabel htmlFor={name} id={`${name}-label`} className={classNames(labelClassName)}>
        {label}
      </FormLabel>

      <Dropdown
        items={dropdownItems}
        multiple={multiple}
        onChange={(_e, d) => {
          if (d.value) {
            if (getHeader) {
              const i = d.value as FormikNorthstarDropdownItem<TItem>
              setValue(i.data)
              if (onChange) onChange(i.data)
            }
            else {
              setValue(d.value)
              if (onChange) onChange(d.value as TItem)
            }
          }
        }}
        onBlur={_e => setTouched(true)}
        value={value}
        {...props}
      />

      {isError && (
        <FormMessage id={`${name}message`} role="alert" error={isError}>
          {metadata.error}
        </FormMessage>
      )}
    </FormField>
  )
}

interface FormikNorthstarDropdownItem<T> {
  header: string
  data: T | undefined
}


