
import { Button } from '@fluentui/react-northstar';
import { push } from 'connected-react-router';
import React from 'react';
import { FaSignInAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import css from './SignedOut.module.scss';


const SignedOut: React.FC<{}> = () => {
  const dispatch = useDispatch()
  return <div className={css.signedOut}>
    <span className={css.title}>You have been signed out of the MRS Admin Portal.</span>
    <Button icon={<FaSignInAlt />} content="Sign in" iconPosition="after" 
      onClick={() => { dispatch(push('/')) }} />
  </div>
}
export default SignedOut;