import { FormField, FormLabel, FormMessage, RadioGroup, RadioGroupProps } from '@fluentui/react-northstar'
import { useField } from 'formik'
import React from 'react'

type FormikRadioGroupFieldProps = Omit<RadioGroupProps, 'name'> & {
  label: string
  name: string
  onChange?: (val: string|number) => void
}

export const FormikNorthstarRadioGroup: React.FC<FormikRadioGroupFieldProps> = ({
  label,
  onChange,
  ...props
}) => {

  const [{ onBlur, ...field }, metadata, { setValue, setTouched }] = useField(
    props
  )

  const isError = metadata.touched && metadata.error !== undefined
  const id = props.name

  return (
    <FormField>
      <FormLabel htmlFor={id} id={`${id}-label`}>
        {label}
      </FormLabel>
      <RadioGroup
        id={id}
        defaultCheckedValue={metadata.initialValue}
        onCheckedValueChange={(_e, props) => {
          setValue(props?.value)
          if (onChange && props?.value) onChange(props.value)
        }}
        onBlur={() => setTouched(true)}
        {...field}
        {...props}
      />
      {isError && (
        <FormMessage id={`${id}message-id`} role="alert" error={isError}>
          {metadata.error}
        </FormMessage>
      )}
    </FormField>
  )
}