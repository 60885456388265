import { Dialog, Text } from '@fluentui/react-northstar';
import React, { useEffect, useState } from 'react';
import { ReactComponent as IPFXLogo } from '../../images/IPFX.svg';
import css from './AboutPopup.module.scss';

interface Props {
    isOpen: boolean
    serverVersion: string
}

const AboutPopup: React.FC<Props> = ({ isOpen, serverVersion }) => {
    const [open, setOpen] = useState(false)


    useEffect(() => {
        if (isOpen !== undefined) {
            setOpen(isOpen)
        }
    }, [isOpen])

    return (<Dialog
        onConfirm={() => setOpen(!open)}
        confirmButton="Ok"
        open={open}
        onOpen={(e, data) => { if (data && data.open) setOpen(data!.open!) }}
        header="IPFX Meeting Rooms Management Portal"
        content={<AboutContent serverVersion={serverVersion} />}

    />)
}

interface AboutContentProps {
    serverVersion: String
}

const AboutContent: React.FC<AboutContentProps> = ({ serverVersion }) => {

    const portalVersion = process.env.REACT_APP_APPLICATION_VERSION ? process.env.REACT_APP_APPLICATION_VERSION : "Not Set"
    
    return <div className={css.content}>
        <IPFXLogo className={css.logo} />
        <div className={css.text}>
            <Text size={'medium'} as="div">
                Copyright 2019(C) IPFX Limited.
            </Text>
            <Text as="div">
                Version {portalVersion}
            </Text>
            <Text as="div" styles={{ marginBottom: '0.5rem' }}>
                Server API Version {serverVersion}
            </Text>
        </div>

    </div>
}

export default AboutPopup