import React from 'react';
import './DoughnutIndicator.scss';
import classNames from 'classnames';

interface Props {
    className?: string
    value: number
    color: string
}

export default function DoughnutIndicator({ className, value, color }: Props) {
    const size = 16;
    const stroke = 4.5;
    const r = size - stroke / 2;
    const circ = 2 * Math.PI * r;
    return <svg viewBox={`0 0 ${size * 2} ${size * 2}`} className={classNames('DoughnutIndicator', className)}>
        <circle stroke={color} r={r} cx={size} cy={size} strokeWidth={stroke} strokeDasharray={circ} strokeDashoffset={circ * (1 - value)} />
        <circle className='background' r={r} cx={size} cy={size} strokeWidth={stroke} strokeDasharray={circ} strokeDashoffset={-circ * value} />
    </svg>
}