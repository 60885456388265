import { FormField, FormLabel } from "@fluentui/react-northstar";
import { useField } from "formik";
import * as React from "react";
import { useMemo } from "react";
import { Role } from "../../model";
import { Picker } from "./Picker";

interface RolesPickerProps {
    roles: Role[]
    name: string
    label: string
    trustedOrganisation: boolean
}

export const privilegedRoles = ['UserAdmin', 'IpfxAdmin']

export const RolesPicker: React.FC<RolesPickerProps> = ({ roles, name, label, trustedOrganisation }) => {
    const roleItems = useMemo(() => roles?.map(r => ({ key: r.name, title: r.description, disabled: !trustedOrganisation && privilegedRoles.includes(r.name) }))
        , [roles, trustedOrganisation])

    const [, metadata, { setValue, setTouched }] = useField({
        name,
        type: 'checkbox',
    });

    return <FormField>
        <FormLabel htmlFor={name} id={`${name}-label`}>{label}</FormLabel>

        <Picker values={roleItems}
            initialCheckedKeys={metadata.initialValue}
            onSelectedKeysChange={(keys) => {
                console.log("selected keys change: ", keys)
                setTouched(true)
                setValue(keys)
            }}
        />
    </FormField>
}