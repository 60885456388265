
import { all } from "async-saga";
import { ConnectedDevice } from "../../model";
import services from "../../services";
import { getApiError } from "../../utils/error";
import { actions } from "../types";
import { takeEvery } from "./async-saga";


const reloadOrganisationSaga = takeEvery(actions.reloadOrganisation.request, async function (ctx, organisationId) {
    const api = services.apiClient
    if (api === null) return
    
    try {
        await api.postEmpty(`/organisations/${organisationId}/reload`)
        ctx.dispatch(actions.reloadOrganisation.success(organisationId))
        
    } catch (e) {
        console.error("reloadOrganisationSaga: ", e)
        ctx.dispatch(actions.reloadOrganisation.failure({organisationId, error: await getApiError(e)}))
    }
})

const fetchConnectedDevicesSaga = takeEvery(actions.fetchConnectedDevices.request, async function (ctx, organisationId) {
    const api = services.apiClient
    if (api === null) return
    
    try {
        const devices = await api.get<ConnectedDevice[]>(`/organisations/${organisationId}/devicemgmt`)
        ctx.dispatch(actions.fetchConnectedDevices.success(devices))
        
    } catch (e) {
        console.error("reloadOrganisationSaga: ", e)
        ctx.dispatch(actions.fetchConnectedDevices.failure(await getApiError(e)))
    }
})

const connectedDeviceCommandSaga = takeEvery(actions.connectedDeviceControl.request, async function (ctx, {organisation_id, device_id, command}) {
    const api = services.apiClient
    if (api === null) return
    
    try {
        await api.postEmpty(`/organisations/${organisation_id}/devicemgmt/${device_id}?command=${command}`)
        ctx.dispatch(actions.connectedDeviceControl.success())
        
    } catch (e) {
        console.error("reloadOrganisationSaga: ", e)
        ctx.dispatch(actions.connectedDeviceControl.failure(await getApiError(e)))
    }
})



export default all(
    reloadOrganisationSaga,
    fetchConnectedDevicesSaga,
    connectedDeviceCommandSaga
)

