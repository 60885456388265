
import { AuthError, getResponseJson, isStsAuthError } from "../utils/error";
import { AzureAdClient } from "./azure-ad";


export type IpfxTokenClient = ReturnType<typeof createIpfxTokenClient>

export function createIpfxTokenClient(baseUrl: string, aadClient: AzureAdClient, scopes: string[]) {

    const tokenUrl = baseUrl + "/aad_token"
    let pending: Promise<Token> | null = null
    let token: Token = {access_token: "", expiry: 0}
    const azureAdClient = aadClient

    console.log(`Creating IFPX Token Client baseUrl=${baseUrl}`)

    return {
        async getAccessToken() {
            const now = new Date().valueOf()
            if (token.expiry < now) {
                if (pending === null) {
                    pending = renewToken(tokenUrl, azureAdClient, scopes)
                }
                try {
                    token = await pending
                } finally {
                    pending = null
                }
            }
            return token.access_token
        }
    }  
}

async function renewToken(tokenUrl: string, azureAdClient: AzureAdClient, scopes: string[]): Promise<Token> {
    const azureAdToken = await getAzureAccessToken(azureAdClient, scopes)

     
    let tokenRequest = {
        azure_ad_access_token: azureAdToken.token,
        user_principal_name: azureAdToken.email
    }
    let body = JSON.stringify(tokenRequest)
    
    const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),

        body
    })
    if (response.status === 200) {
        const resp = await response.json() as TokenResponse
        // renew 1 minute before actually expiry to deal with clock drift
        const expiry = new Date().valueOf() + ((resp.expires_in - 60)* 1000)
        const token = resp.access_token
        return {expiry: expiry, access_token: token}
    } else {
        const json = await  getResponseJson(response)
        if (isStsAuthError(json)) {
            console.trace("AuthError")
            throw new AuthError(json, response)
        }
        throw new AuthError({error: "unknown_error", error_description: "unknown_auth_error"}, response)
    }
}

async function getAzureAccessToken(azureAdClient: AzureAdClient, scopes: string[]) {
    const resp = await azureAdClient.acquireSilent(scopes)  
    return {token: resp.accessToken, email: resp.account.userName}
}

interface TokenResponse {
    access_token: string
    expires_in: number
}

interface Token {
    access_token: string
    expiry: number
}
