import { takeEvery } from "./async-saga";
import services from "../../services";
import { all } from "async-saga";
import { fetchConferenceClients } from "../resourceActions";
import { actions } from "../types";

const startNotifcationHubSaga = takeEvery(actions.startServerNotificationHub.success, async function (ctx) {
    const hub = services.serverNotificationHub
    console.log("Watching ConferenceClientsChanged")
    hub.connection.on("ConferenceClientsChanged", async () => {
        console.log("ConferenceClientsChanged event received")
        ctx.dispatch(fetchConferenceClients.request({}))
    })
})

export default all(
    startNotifcationHubSaga
)