import { Loader, Dropdown, Button, DownloadIcon } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { fetchHeatMap } from "../../../store/actions";
import { getHeatmap, getHeatmapIsLoading } from "../../../store/selectors";
import { avg, colors, getScaledUsage, getTimes, ReportFilter, resourceTypes } from "../MetricsPage";
import ResourceOccupancy from "./ResourceOccupancy";
import services from "../../../services";
import { fileDownloadFromBlob } from "../../../utils/fileDownload";


const timeTypes = {
    'Scheduled': 'Scheduled',
    'Actual': 'Actual'
}

function Occupancy({ filter, orgChanging }: { filter: ReportFilter, orgChanging: boolean }) {

    const heatMap = useSelector(getHeatmap);
    const isLoading = useSelector(getHeatmapIsLoading)

    const dispatch = useDispatch()

    const [dropdownTimeSelector, setDropdownTimeSelector] = useState('Scheduled')
    const [isReportRunning, setIsReportRunning] = useState(false)
    
    useEffect(() => {
        if (!orgChanging) {
            dispatch(fetchHeatMap.request({
                organisationId: filter.organisationId,
                startRange: filter.startDate!,
                endRange: filter.endDate!,
                location: filter.location,
                resourceType: filter.resourceType,
                bookableResource: filter.bookableResource,
                excludeWeekends: filter.excludeWeekends,
                workingHoursOnly: filter.isWorkingHoursOnly,
                isActualTime: dropdownTimeSelector === 'Actual'
            }))
        }
    }, [filter.startDate, filter.endDate, filter.location, filter.resourceType, filter.bookableResource, 
        filter.excludeWeekends, dispatch, filter.isWorkingHoursOnly, filter.organisationId, dropdownTimeSelector, orgChanging])

    if (isLoading) { return <Loader label="Loading data" /> }
    if (heatMap === undefined)
        return <div>No data to show. Please change your filters</div>
    if (heatMap.room_type_usage.length === 0)
        return <div>No data to show. Please change your filters</div>
    
    if (!heatMap) { return null }
    
    if (heatMap.room_type_usage.length === 0) { return null }
    
     // Export the dataset as a CSV file
     async function doExport() {
        
        console.log('do Occupancy csv export');
        
        setIsReportRunning(true)

        try {
            const {data,fileName} = await services.bookItApi.fetchHeatMapDataCsv(
                filter.organisationId,
                filter.startDate!,
                filter.endDate!,
                filter.location,
                filter.resourceType,
                filter.bookableResource,
                filter.excludeWeekends,
                filter.isWorkingHoursOnly,
                dropdownTimeSelector === 'Actual')

            fileDownloadFromBlob(data, fileName ?? 'bookit_occupancy_report.csv')

        } catch (e) {
            console.error('doExport', e)
        }

        setIsReportRunning(false)
    }

    const values = heatMap.room_type_usage.map(x => ({
        type: x.attendee_type,
        data: getTimes(filter.isWorkingHoursOnly).map((_, i) => avg(x.room_usage.map(r => getScaledUsage(r.usage_by_hour, i, filter.isWorkingHoursOnly) * 100))),
    }))

    var data = {
        labels: getTimes(filter.isWorkingHoursOnly).map(m => m.format('ha')),
        datasets: values.map(v => ({
            label: resourceTypes[v.type],
            backgroundColor: colors[v.type],
            data: v.data,
        }))
    };

    return (

    <div className="graphs">
        <div className="barGraph">

            <div className='allInARow'>
                <h2>Occupancy</h2>

                <div className="timeTypeSelector">
                {/* Allow user to toggle between 'Actual' and 'Scheduled' times */}
                <Dropdown
                    items={Object.entries(timeTypes).map(([value, text]) => text)}
                    value={dropdownTimeSelector}
                    onChange={(e, d) => {
                        if (d) {
                            setDropdownTimeSelector(d.value as string)                           
                        }
                    }}
                />
            </div>

            {isReportRunning ? <Loader className='spaceLeft' /> : 
                        <Button primary content='Export as CSV' 
                            onClick={() => { doExport() }} 
                            icon={<DownloadIcon />} 
                            iconPosition='before' 
                            className={['downloadButton', 'spaceLeft', 'spaceBottom' ].join(' ')} />
                    }
            </div>

           
            <div className="graphContainer">
                <Bar data={data} options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: function(value, index, values) {
                                    return value + '%';
                                }
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                return data.datasets![tooltipItem.datasetIndex!].label + ': ' + Math.round(parseInt(tooltipItem.value!)) + '%' || ''
                            },
                        }
                    },
                }} />
            </div>
        </div>
        <ResourceOccupancy filter={filter} heatMap={heatMap}/>
    </div>

    );
}

export default Occupancy