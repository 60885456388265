import { Checkbox, Toolbar } from "@fluentui/react-northstar";
import * as React from "react";
import { useCallback, useState } from "react";
import { BiSelectMultiple } from 'react-icons/bi';
import { MdFilterNone } from 'react-icons/md';
import css from './Picker.module.scss';

export interface PickerItem {
    key: string
    title: string
    disabled?: boolean
}

interface PickerProps {
    values: PickerItem[]
    initialCheckedKeys: string[]
    onSelectedKeysChange: (keys: string[]) => void
}

export const Picker: React.FC<PickerProps> = ({ values, initialCheckedKeys, onSelectedKeysChange }) => {

    const [selectedKeys, setSelectedKeys] = useState<Record<string, boolean>>(initialCheckedKeys.reduce((acc, cur) => ({ ...acc, [cur]: true }), {}))

    const handleOnChange = useCallback((key: string, selected: boolean) => {
        const newSelected = { ...selectedKeys, [key]: selected }
        setSelectedKeys(newSelected)
        onSelectedKeysChange(Object.keys(newSelected).filter(k => newSelected[k]))
    }, [selectedKeys, onSelectedKeysChange])

    const handleSelectAll = useCallback((event: React.SyntheticEvent<HTMLElement, Event>) => {
        event.preventDefault();
        const all = values.map(item => item.key)
                            .reduce((acc, cur) => ({ ...acc, [cur]: true }), {})
        setSelectedKeys(all)
        onSelectedKeysChange(Object.keys(all))

    }, [onSelectedKeysChange, values])
    const handleSelectNone = useCallback((event: React.SyntheticEvent<HTMLElement, Event>) => {
        event.preventDefault();
        setSelectedKeys({})
        onSelectedKeysChange([])
    }, [onSelectedKeysChange])

    return <div className={css.Picker}>
        <div className={css.Header}>
            <Toolbar aria-label="Selection">
                <Toolbar.Item
                    icon={<BiSelectMultiple />}
                    title="Select All"
                    onClick={(e) => handleSelectAll(e)}
                />
                <Toolbar.Item
                    icon={<MdFilterNone />}
                    title="Select None"
                    onClick={(e) => handleSelectNone(e)}
                />
            </Toolbar>
        </div>
        <ul className={css.PickerList}>
            {values.map((item: PickerItem) => <li key={item.key} >
                <Checkbox
                    checked={Boolean(selectedKeys[item.key])}
                    labelPosition="end"
                    label={item.title} id={item.key}
                    disabled={item.disabled}
                    onChange={(e, d) => {
                        if (d && d.checked !== undefined) {
                            handleOnChange((d as any).id, d.checked)
                        }
                    }}
                />
            </li>)}
        </ul>
    </div >
}