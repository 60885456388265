import { ShorthandCollection, Toolbar, ToolbarDividerProps, ToolbarItemProps, ToolbarItemShorthandKinds, ToolbarProps, Tooltip, tooltipAsLabelBehavior } from "@fluentui/react-northstar";
import * as React from "react";
import { useMemo } from "react";


export type IntermediateToolbarItem = ToolbarItemProps & ToolbarDividerProps & {
    showButton?: () => boolean
    key: string
    kind?: keyof ToolbarItemShorthandKinds
    tooltip?: string
}

type Props = Omit<ToolbarProps, "items"> & {
    items: IntermediateToolbarItem[]
}

export const ToolbarWithTooltips: React.FC<Props> = ({ items, ...rest }) => { 
    const newItems = useMemo<ShorthandCollection<ToolbarItemProps, ToolbarItemShorthandKinds>>(() => items
        .filter(item => !item.showButton || item.showButton())
        .map(item => {
            const {showButton: _, ...newItem} = item
            return newItem
        })
        .map(
            (item): ToolbarItemProps => ({
                ...item,
                // rendering Tooltip for the Toolbar Item
                children: (ItemComponent: any, props: any) => {
                    const { tooltip, key, iconElement, ...rest } = props
                    const item = <ItemComponent key={`${key}_item`} {...rest} />
                    return rest.disabled || tooltip === undefined
                        ? item
                        : <Tooltip key={key} content={tooltip} accessibility={tooltipAsLabelBehavior} trigger={item}/>
                }
        })
        ), [items])

    return <Toolbar items={newItems} {...rest} />
}

export const SingleItemToolbar: React.FC<IntermediateToolbarItem> = ({...rest}) => {
    return (<ToolbarWithTooltips
        items={[{...rest}]}
    />)
}


