import { ApiError } from "../../utils/error"

type ApiResultBase = Readonly<{ isLoading: boolean, isRequested: boolean }>

type ApiSuccessResult<T> = ApiResultBase &  Readonly<{
    hasResponse: true
    error?: undefined
    value: T
}>

type ApiFailureResult = ApiResultBase & Readonly<{
    hasResponse: true
    error: ApiError
    value?: undefined
}>

type ApiNoDataResult =  ApiResultBase & Readonly<{ hasResponse: false }>

export type ApiResult<T> = ApiNoDataResult
        | ApiSuccessResult<T>
        | ApiFailureResult

export const ApiResult = {
    default<T>(): ApiResult<T> {
        return {
            isRequested: false,
            isLoading: false,
            hasResponse: false
        }
    },
    loading<T>(state: ApiResult<T>): ApiResult<T> {
        return {
            ...state,
            isRequested: true,
            isLoading: true
        }
    },
    success<T>(value: T): ApiResult<T> {
        return {
            isLoading: false,
            isRequested: true,
            hasResponse: true,
            value
        }
    },
    failure<T>(error: ApiError): ApiResult<T> {
        return {
            isLoading: false,
            isRequested: true,
            hasResponse: true,
            error: error
        }
    },
    getValueOrThrow<T>(result: ApiResult<T>): T {
        if (this.isSuccess(result)) {
            return result.value
        }
        throw Error("Cannot access value for failed result")
    },
    getValueOrDefault<T>(result: ApiResult<T>, defValue: T): T {
        if (this.isSuccess(result)) {
            return result.value
        }
        return defValue
    },
    getError<T>(result: ApiResult<T>): ApiError {
        if (this.isFailure(result)) {
            return result.error;
        }
        throw Error("Cannot access error for successfull result")
    },
    isSuccess<T>(result: ApiResult<T>): result is ApiSuccessResult<T> {
        return result.hasResponse && !result.error
    },
    isFailure(result: ApiResult<any>): result is ApiFailureResult {
        return result.hasResponse && Boolean(result.error)
    },
}