import React, { useState } from 'react';
import css from './SupportSettingsPage.module.scss';
import { UcServerGroup, UcSite } from '../../../model';
import { Dropdown, Checkbox, DropdownItemProps } from "@fluentui/react-northstar";

type ApplicationSelectorProps = ({
    group_assignments: UcServerGroup[]
    all_applications?: string[]
    sites: UcSite[]
    onChange: (server: string, application: string, enabled: boolean) => void
})

interface Group {
    header: string
    isWild: boolean
}

function GroupAssignments({group_assignments, all_applications, sites, onChange}: ApplicationSelectorProps) {

    const [selectedGroup, setSelectedGroup] = useState<Group>()
    const [allApplications, setAllApplications] = useState<string[]>(getAllApplications())

    const allGroups = () => {        
        const groups: Group[] = [{header:'All', isWild: true}]

        sites.forEach(s => addToGroups(s.server_group, groups))
        return groups
    }

    function addToGroups(name:string, list: Group[]) {
        if (name && !list.some(g => g.header === name)) {
            list.push({header:name, isWild:false})
        }
    }

    function getAllApplications() {     
        if (all_applications !== undefined && all_applications.length > 1) {
            all_applications.sort((a,b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
        }   
        return ["All", ...all_applications ?? []]
    }

    const handleSelectedGroup = (group: Group) => {
        setSelectedGroup(group)
        setAllApplications(getAllApplications())
    } 

    const handleCheckedChange = (id: number, enabled: boolean) => {

        
        if (getAllApplications !== undefined && selectedGroup !== undefined) {
            
            let selectedApp = getAllApplications()[id]
            if (id === 0) {
                selectedApp = '*'
                if (enabled) {
                    setAllApplications(['All'])
                } else {
                    setAllApplications(getAllApplications())
                }
            }
            console.log('uc: Server handleSubChange', {id, enabled, selectedGroup});  
            
            const groupName = selectedGroup.isWild ? '*' : selectedGroup.header

            if (selectedGroup !== undefined) {
                onChange(groupName, selectedApp, enabled)
            }
        }

    }

    const isAnyAppEnabled = (groupName: string) => {

        if (group_assignments === undefined) {
            return false
        }                 
        
        return group_assignments.some(group => group.name.toLowerCase() === groupName.toLowerCase() && 
                                            group.applications.some(app => app.name.toLowerCase() !== '*'))

    }

    function isAppEnabled(appName: string) {
                                
        if (appName === undefined) {
            return false
        }
        
        if (selectedGroup === undefined) {
            return false
        }
        
        console.log('UC:isAppEnabled', {selectedGroup, appName, group_assignments});
        
        return group_assignments.some(g => 
            (g.name === selectedGroup.header || 
                (g.name === '*' && selectedGroup.header === 'All')
            ) && 
            (g.applications.some(app => app.name === appName || 
                (app.name === '*' && appName === 'All'))
                ))

    }

    type RenderItem = {
        Item: any;
        props: DropdownItemProps;
        isActive: boolean
      };

    const renderItem = React.useCallback((args: RenderItem) => getCustomItem({ ...args }), []);

    return <>
        <div className={css.selectorCard}>
            <h5>Applications by Server Group</h5>
            <div className={css.infoText}>Only selected applications for each selected Server Group will be assigned to the current mailbox.</div>
            
            <Dropdown className={css.dropDown} 

                    items={allGroups()} 
                    placeholder="Select a group" 
                    onChange={(e, d) => handleSelectedGroup(d.value as Group)}
                    fluid={true} 
                    renderItem={
                         (Item, props) => renderItem({ Item, props, isActive:isAnyAppEnabled(props.header as string)})
                    }        
            />

            {selectedGroup !== undefined && 
            <table className={css.ucTable}>
                <thead>
                    <tr>
                        <th>Selected</th>
                        <th>Application</th>
                    </tr>
                </thead>
                <tbody>
                    {allApplications?.map((app, idx) => <SubRow id={Number(idx)} value={app}
                            onSubChange={handleCheckedChange}
                            enabled={isAppEnabled(app)} />)}
                </tbody>
            </table>}
        </div>
    </>
}

interface SubRowProps {
    id: number
    enabled: boolean
    value: string
    onSubChange: (id: number, enabled: boolean) => void
}


function SubRow({ id, enabled, value, onSubChange }: SubRowProps) {
    return <tr>
        <td>
            <Checkbox checked={enabled} onChange={(_e, d) => {
                if (d && d.checked !== undefined) {
                    onSubChange(id, d.checked)
                }
            }} />
        </td>
        <td>{value}</td>
    </tr >
}

const getCustomItem = (args: {Item: any, props: DropdownItemProps, isActive: boolean }) => {

    const { Item, props, isActive } = args;
    const { header, ...rest } = props;
    
    console.log('UC:getCustomItem', args);
    
    if (!header || typeof header !== 'string') return <Item {...props} />;

    return (
    
      <Item
        header={<span className={isActive ? css.highlight : ''}>{header}</span>}
        {...rest}
      />
    );
  };

 
export default GroupAssignments;