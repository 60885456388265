
import { all } from "async-saga";
import { AnyAction, Dispatch } from "redux";
import { IdType, PortalSettings } from "../../model";
import services from "../../services";
import { getApiError } from "../../utils/error";
import { actions } from "../types";
import { takeEvery } from "./async-saga";


const fetchUserInfoSaga = takeEvery(actions.fetchUserInfo.request, async function (ctx) {
    try {
        const result = await services.serverApi.fetchUserInfo().toPromise()
        ctx.dispatch(actions.fetchUserInfo.success(result))
        
    } catch (e) {
        ctx.dispatch(actions.fetchUserInfo.failure(await getApiError(e)))
    }
})

const fetchServerInfoSaga = takeEvery(actions.fetchServerInfo.request, async function (ctx) {
    try {
        const result = await services.serverApi.fetchServerInfo().toPromise()
        ctx.dispatch(actions.fetchServerInfo.success(result))
        
    } catch (e) {
        ctx.dispatch(actions.fetchServerInfo.failure(await getApiError(e)))
    }
})

const fetchPortalSettingsSaga = takeEvery(actions.setActiveOrganisation, async function (ctx, org) {
    fetchPortalSettings(org.organisation_id, ctx.dispatch)
})

const fetchPortalSettingsOnLoginSaga = takeEvery(actions.fetchUserInfo.success, async function (ctx, userInfo) {
    fetchPortalSettings(userInfo.user.primary_organisation.organisation_id, ctx.dispatch)
})

async function fetchPortalSettings(organisationId: IdType, dispatch: Dispatch<AnyAction>) {

    if (!services.apiClient) return
    try {
        const result = await services.apiClient.get<PortalSettings>(`/organisations/${organisationId}/portal_settings`)
        dispatch(actions.setPortalSettings(result))
        
    } catch (e) {
        console.error('Faield to fetch portal settings', e)
    }
}

export default all(
    fetchUserInfoSaga,
    fetchServerInfoSaga,
    fetchPortalSettingsSaga,
    fetchPortalSettingsOnLoginSaga
)

