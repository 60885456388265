
import css from './SiteExclusions.module.scss';
import { useDispatch } from 'react-redux';
import { UcSite } from '../../model';
import { Button, Checkbox, AddIcon, TrashCanIcon, Input, Dialog } from "@fluentui/react-northstar";
import { ToolbarWithTooltips } from '../controls/ToolbarWithTooltips';
import React, { useState } from "react";
import { compare } from 'fast-json-patch';
import { getActiveOrganisation } from '../../store/selectors';
import { useSelector } from "../../store/utils";
import { actions } from '../../store/types';


interface SiteExclusionsProps {
    site: UcSite
}

export function SiteExclusions({ site }: SiteExclusionsProps) {

    const dispatch = useDispatch()

    const activeOrganisation = useSelector(getActiveOrganisation)

    const [exclusionToAdd, setExclusionToAdd] = useState<string>()
    const [addExclusionIsOpen, setAddExclusionIsOpen] = useState(false)

    const handlAddAddition = () => {
        setAddExclusionIsOpen(false)
        const { base, additions, ...original } = site.exclusions
        const update = { ...original, additions: [...site.exclusions.additions, exclusionToAdd] }
        const diff = compare(original, update)
        console.log('UC: Exclusions add', diff);
        
        dispatch(actions.patchUcSiteExclusions.request({ organisation_id: activeOrganisation.organisation_id, site_id: site.site_id, diff }))
    }

    const handleRemoveAddition = (exclusionText: string) => {
        const { base, additions, ...original } = site.exclusions
        const update = { ...original, additions: site.exclusions.additions.filter(e => e !== exclusionText) }
        const diff = compare(original, update)
        dispatch(actions.patchUcSiteExclusions.request({ organisation_id: activeOrganisation.organisation_id, site_id: site.site_id, diff }))
    }

    const handleSubChange = (id: number, enabled: boolean) => {
        const { base, subtractions, ...original } = site.exclusions

        const updated = enabled ?
            [...site.exclusions.subtractions, id]
            : site.exclusions.subtractions.filter(e => e !== id)

        const update = { ...original, subtractions: updated }
        const diff = compare(original, update)
        dispatch(actions.patchUcSiteExclusions.request({ organisation_id: activeOrganisation.organisation_id, site_id: site.site_id, diff }))
    }

    const addExclusionButton = <Button primary content='Add exclusion' icon={<AddIcon />} iconPosition='before' />

    const addExclusionContent = <Dialog header='Add Exclusion' open={addExclusionIsOpen} 
        confirmButton="Add"
        cancelButton="Cancel"
        onConfirm={(e, p) => {
            handlAddAddition()
        }}
        trigger={addExclusionButton}
        onOpen={() => setAddExclusionIsOpen(true)}
        onCancel={e => setAddExclusionIsOpen(false)}
        content={<Input fluid onChange={(event, data) => {
            if (data && data.value) {
                setExclusionToAdd(data.value as string)
            }
        }} />}
    />


    return <>
        <div className={css.siteExclusions}>
            <div>
                <h3>Additions</h3>
                {addExclusionContent}
            </div>

            <table className={css.table}>
                <thead><tr><th>Additional exclusion text</th></tr></thead>
                <tbody>
                    {site.exclusions.additions
                        .map(value => <AddRow value={value} onRemove={handleRemoveAddition} />)}
                </tbody>
            </table>
        </div>


        <div className={css.siteExclusions}>
            <h3>Subtractions</h3>

            <table className={css.table}>
                <thead>
                    <tr>
                        <th>Excluded</th>
                        <th>Exclusion text</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(site.exclusions.base)
                        .map(([id, value]) => <SubRow id={Number(id)} value={value}
                            onSubChange={handleSubChange}
                            enabled={site.exclusions.subtractions.some(idx => idx === Number(id))} />)}
                </tbody>
            </table>
        </div>


    </>



}

interface AddRowProps {
    value: string
    onRemove: (value: string) => void
}

interface SubRowProps {
    id: number
    enabled: boolean
    value: string
    onSubChange: (id: number, enabled: boolean) => void
}


function SubRow({ id, enabled, value, onSubChange }: SubRowProps) {
    return <tr>
        <td>
            <Checkbox checked={enabled} onChange={(_e, d) => {
                if (d && d.checked !== undefined) {
                    onSubChange(id, d.checked)
                }
            }} />
        </td>
        <td>{value}</td>
    </tr >
}

function AddRow({ value, onRemove }: AddRowProps) {
    return <tr>
        <td>{value}</td>
        <td>
            <ToolbarWithTooltips className='right-align'
                items={[{
                    key: 'delete',
                    icon: <TrashCanIcon outline />,
                    tooltip: 'Delete the exclusion',
                    onClick: () => onRemove(value)
                }]}
            />
        </td>
    </tr >
}




