import { Loader } from "@fluentui/react-northstar";
import * as _ from "lodash";
import * as React from "react";
import Table from "reactstrap/lib/Table";
import { RoomAttributesTableProps } from "../../../containers/RoomAttributesTable";
import { Room, RoomAttribute } from "../../../model";
import { HalResult } from "../../../services/Hal";

type Props = RoomAttributesTableProps & {
    room: HalResult<Room>
}

export default class RoomAttributesTable extends React.PureComponent<Props> {
    public render() {
        if (this.props.roomAttributes === undefined) {
            return <Loader style={{marginTop: '1em'}} label="Loading room attributes..." />
        }

        const { _links, ...attribs } = this.props.roomAttributes!.resource;
        return <Table borderless striped size="sm">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Value</th>
                    <th>DataType</th>
                    <th>Source</th>
                </tr>
            </thead>
            <tbody>
                {_.toPairsIn(attribs)
                    .sort((l, r) => l[0].localeCompare(r[0]))
                    .map((attrib, index) => this.renderRow(index, attrib))}
            </tbody>
        </Table>
    }

    private renderRow(row: number, attrib: [string, RoomAttribute]) {
        return (
            <tr key={row.toString()}>
                <th scope="row">{row + 1}</th>
                <td>{attrib[0]}</td>
                <td>{this.getDisplayValue(attrib[1])}</td>
                <td>{attrib[1].data_type}</td>
                <td>{attrib[1].type}</td>
            </tr>
        );
    }

    private getDisplayValue(attrib: RoomAttribute) {
        if (attrib.data_type === 'Password') {
            return "•".repeat(attrib.value.toString().length);
        }

        return attrib.value.toString();
    }
}