import { Flex, Form, Grid, Segment } from "@fluentui/react-northstar";
import { Formik } from 'formik';
import { FormikDebug } from '../controls/FormikDebug';
import FormButtons from '../controls/FormButtons';
import { useState, useMemo, useEffect } from "react";
import { useSelector } from '../../store/utils';
import { BookableResource, GenericReportSchedule } from "../../model";
import { FormikNorthstarCheckbox } from "../controls/FormikNorthstarCheckbox";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";
import { FormikNorthstarDropdown } from '../controls/FormikNorthstarDropdown';
import css from './OrganisationsPage.module.scss';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { getLocations, getBookableResources, getActiveOrganisation } from '../../store/selectors';
import { fetchBookableResources, fetchLocations } from '../../store/resourceActions';


interface EditorProps {
    report: GenericReportSchedule;
    default_mailbox?: string;
    onCancel: () => void;
    onSubmit: (editedRecord: GenericReportSchedule) => void; 
}

//const AddBookItRoom: React.FC<Props> = (props) => {
const GenericReportSchedulerEditor: React.FC<EditorProps> = ({report, default_mailbox, onCancel, onSubmit}) => {

    const formSchema = useMemo(() => Yup.object().shape({
        
        due_time: Yup.string().required('Report due time cannot be empty'),
        
        schedule_name: Yup.string().required('Schedule name cannot be empty'),

        mailboxes: Yup.string().required('At least one email address must be supplied'),

        timezone: Yup.string().required('Timezone cannot be empty (e.g. "New Zealand Standard Time")'),

        due_day_of_month: Yup.number()
            .min(1, 'If present, day of month must be between 1 and 28')
            .max(28, 'If present, day of month must be between 1 and 28')
            .test('notBothAtTheSameTime', 
                'You cannnot provide both a day of week and month', 
                function(due_day_of_month){
                    const {due_day_of_week} = this.parent;
                    if (due_day_of_week && due_day_of_month) {
                        return false;
                    }
                    return true;
                }),
                
        due_day_of_week: Yup.number()
            .min(1, 'If present, day of week must be between 1 and 7')
            .max(7, 'If present, day of month must be between 1 and 7')
            .test('notBothAtTheSameTime', 
                'You cannnot provide both a day of week and month', 
                function(due_day_of_week){
                    const {due_day_of_month} = this.parent;
                    if (due_day_of_month && due_day_of_week) {
                        return false;
                    }
                    return true;
                }),

    }), [])

    const [enabled, setEnabled] = useState(report?.is_enabled ? report?.is_enabled : false) 

    const handleEnabledChange = (enabled: boolean) => {
        setEnabled(enabled)
    }

    const handleResourceSelectionChange = (resources: any) => {
        console.log('handleResourceSelectionChange', resources);
        while (resources.length > 1) {
            resources.shift();
        }
    }

    const handleSubmit = (editedRecord: GenericReportSchedule) => {
        console.log('handleSubmit', editedRecord);
        onSubmit(editedRecord)
    }

    const handleCancel = () => {   
        console.log('handleCancel')     
        onCancel()
    }

    const periodItems = ["Today", "Yesterday", "Last7Days", "LastWeek", "Last30Days", "LastMonth", "Last3Months", "LastQuarter", "Last90Days"];
    const resourceTypes = ["All", "MeetingRoom", "HotDesk", "ParkingSpace", "Locker"];
    const reportTypes = ["Occupancy", "Statistics"];

    const { isLoaded: isLocationsLoaded, isLoading: isLocationsLoading } = useSelector(s => s.bookit.locations)
    const activeOrganisation = useSelector(getActiveOrganisation)
    const dispatch = useDispatch();

    useEffect(() => {
        if (activeOrganisation && !isLocationsLoaded && !isLocationsLoading) {
            dispatch(fetchLocations.request({ organisation_id: activeOrganisation.organisation_id }))
        }      
    })

    const locations = useSelector(getLocations)
    const locationItems = useMemo(() => locations.map(l => l.name), [locations])

    const { isLoaded: isRoomsLoaded, isLoading: isRoomsLoading } = useSelector(s => s.bookit.bookableResources)
    useEffect(() => {
        if (!isRoomsLoaded && !isRoomsLoading && activeOrganisation) {
            dispatch(fetchBookableResources.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch, isRoomsLoaded, isRoomsLoading])
    const bookableResources = useSelector(getBookableResources)

    const roomItems = useMemo(() => 
        bookableResources && bookableResources.map(r => ({ header: r.name, ...r }))
    , [bookableResources])

    return <Flex column gap="gap.medium" fill>

        <Formik
            initialValues={{...report}}
            validationSchema={formSchema}
            onSubmit={handleSubmit}>
            {formik => <Form styles={{ width: '700px' }} onSubmit={e => formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)}>
                <Flex gap='gap.medium' fill column>
                    <FormikNorthstarCheckbox toggle label="Is enabled" name="is_enabled" onChange={handleEnabledChange} />                        
                    
                    <FormikNorthstarInput fluid label="Name" name="schedule_name" id="schedule_name" disabled={!enabled} /> 
                    
                    <Flex gap="gap.large">
                        <FormikNorthstarDropdown fluid label="Report" items={reportTypes} name="report_name" placeholder='Select a report' disabled={!enabled} />                          
                        <FormikNorthstarDropdown fluid label="Location" items={locationItems} name="location" placeholder='Select a location' disabled={!enabled}  /> 
                    </Flex>

                    <FormikNorthstarDropdown label="Resource Type" items={resourceTypes} name="resource_type" placeholder='Select a resource type' getHeader={item => item} />
                    <FormikNorthstarDropdown fluid multiple label="Choose resource" items={roomItems} 
                                                    name="account" placeholder="Select resource or leave empty for 'All'" disabled={!enabled} onChange={handleResourceSelectionChange}/>
                    
                    <FormikNorthstarDropdown label="Reporting period" items={periodItems} name="reporting_period" placeholder="Select a reporting period" disabled={!enabled} />   
            
                    <Flex gap="gap.large">
                        <FormikNorthstarInput label="Due day of month" name="due_day_of_month" id="due_day_of_month" disabled={!enabled} />  
                        <div>OR</div> 
                        <FormikNorthstarInput label="Due day of week" name="due_day_of_week" id="due_day_of_week" disabled={!enabled} />  
                        <FormikNorthstarInput label="Due time" name="due_time" id="due_time" disabled={!enabled} />   
                    </Flex>  
            
                    <FormikNorthstarInput fluid label="Timezone" name="timezone" id="timezone" disabled={!enabled} /> 
                    
                    <FormikNorthstarInput fluid label="Mailboxes (comma-separated)" name="mailboxes" id="mailboxes" disabled={!enabled} /> 

                    <Flex gap="gap.large">
                        <FormikNorthstarCheckbox toggle label="Working hours only" name="working_hours_only" disabled={!enabled} />      
                        <FormikNorthstarCheckbox toggle label="Exclude weekends" name="exclude_weekends" disabled={!enabled} />      
                    </Flex>
                                 
                    <FormButtons onCancel={handleCancel} />
                 {/* <FormikDebug /> */}
                </Flex>
            </Form>
            }
        </Formik>
    </Flex>

   
}

export default GenericReportSchedulerEditor