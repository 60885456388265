import { Dropdown, DropdownProps } from '@fluentui/react-northstar';
import React, { useEffect, useMemo, useState } from 'react';

type Props<T> = DropdownProps & {
    dataItems: T[]
    onSelectionChanged: (selected: T | null) => void
    selectedKey?: string
    getHeader: (item: T) => string
    allowNone: boolean
}

const DropdownControlled = <T extends any>(props: Props<T>) => {
    const { dataItems, onSelectionChanged, selectedKey, getHeader, allowNone, placeholder, defaultValue, value, ...rest } = props

    const items = useMemo(() => getItems(allowNone, getHeader, dataItems), [dataItems, getHeader, allowNone])
    const [selected, setSelected] = useState<DropdownControlledItem<T>>()

    useEffect(() => {
        if (selected !== undefined) {
            return
        }
        const defaultVal = items.find(item => item.header === selectedKey)
        if (defaultVal) {
            setSelected(defaultVal)
            const data = defaultVal.data
            onSelectionChanged(data)
        }
    }, [items, onSelectionChanged, selected, selectedKey])

    
    const handleSelectionChange = (event: any, data: any): void => {
        const item = data.value
        onSelectionChanged(item.data)
        setSelected(item)
    }

    return <Dropdown
        items={items}
        loadingMessage='Loading...'
        loading={items === undefined}
        onChange={handleSelectionChange}
        placeholder={placeholder}
        value={selected}
        {...rest}
    />
}

export interface DropdownControlledItem<T> {
    header: string
    data: T | null
}
function getItems<T>(allowNone: boolean, getHeader: (item: T) => string, dataItems?: T[]) {
    if (!dataItems) {
        return []
    }
    const items = dataItems.map<DropdownControlledItem<T>>(item => ({ header: getHeader(item), data: item }))
    if (allowNone) {
        items.unshift({ header: 'None', data: null })
    }

    return items;
}


export default DropdownControlled