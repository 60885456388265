import { combineReducers } from 'redux';
import { UserAccount } from '../../model';
import { userAccountReducer } from '../resourceActions';
import { Actions, actions } from '../types';
import { ApiResourceState, defaultState } from './ApiResourceState';
import { createApiReducer } from './createApiReducer';


type UserAccountsState = ApiResourceState<UserAccount>


function userAccounts(state: UserAccountsState = defaultState, action: Actions): UserAccountsState {
    switch (action.type) {
        default:
            return userAccountReducer(state, action);
    }
}

export default combineReducers({
     roles: createApiReducer(actions.fetchRoles),
     users: userAccounts
})



