import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { Services } from "../../services";
import { errorHandler, fetchAggregateMetrics } from "../actions";
import { RootAction, RootState } from "../types";

export const fetchAggregateMetricsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchAggregateMetrics.request)),
        mergeMap(c =>
            from(serverApi.fetchAggregateMetrics(c.payload.organisationId, c.payload.aggregate, c.payload.start, c.payload.end, c.payload.roomName)).pipe(
                map(hal => fetchAggregateMetrics.success(hal)),
                catchError(err => of(fetchAggregateMetrics.failure(err), errorHandler(err)))
            )));
}
