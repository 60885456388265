import _ from 'lodash';
import { from, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { Builder, InAction, Traversal } from "traverson";
import services from '.';
import { FetchError } from "../utils/error";


export interface HalResult<TModel> {
     traversal?: Traversal;
     resource: HalResource<TModel>;
}

export interface HalArrayResult<TModel> {
     traversal?: Traversal;
     resources: Array<HalResource<TModel>>;
}

export type HalResource<TModel> = HalLinks & HalEmbedded & TModel;

export interface HalLinks {
    // tslint:disable-next-line:variable-name
    _links: {
        [name: string]: Link
    }
}

export interface HalEmbedded {
    // tslint:disable-next-line:variable-name
    _embedded: {
        [name: string]: any
    }
}

export interface Link {
    href: string;
    title: string;
    name: string;
}


export async function getAccessToken() {
    const client = services.ipfxTokenClient
    if (client === null) throw Error("IPFX token client not configured")
    return await client.getAccessToken()
}

async function fetchData<TResult>(token: string, url: string, resultConverter: (response: Response) => Promise<TResult>) {
    const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({ 'Authorization': 'Bearer ' + token, 'Access-Control-Expose-Headers': 'Content-Disposition' })
    });

    if (response.status === 200) {
        return await resultConverter(response);
    }
    throw new FetchError(response)
}


async function postData<TResult>(token: string, url: string, additionalHeaders: Record<string, string>, data: any, resultConverter: (response: Response) => Promise<TResult>) {
    let body = _.isObject(data) && !(data instanceof FormData) ? JSON.stringify(data) : data
    const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({...additionalHeaders, 'Authorization': 'Bearer ' + token, 'Access-Control-Expose-Headers': 'Content-Disposition' }),
        body
    });

    if (response.status === 200 || response.status ===  201 || response.status ===  202 || response.status === 204) {
        return await resultConverter(response);
    }
    throw new FetchError(response)
}

async function patchData<TResult>(token: string, url: string, data: any, resultConverter: (response: Response) => Promise<TResult>) {
    const response = await fetch(url, {
        method: 'PATCH',
        headers: new Headers({ 'Authorization': 'Bearer ' + token, 'Access-Control-Expose-Headers': 'Content-Disposition', 'Content-Type': 'application/json-patch+json' }),
        body: data
    });

    if (response.status === 200) {
        return await resultConverter(response);
    }
    throw new FetchError(response)
}

async function deleteImpl(token: string, url: string) {
    const response = await fetch(url, {
        method: 'DELETE',
        headers: new Headers({ 'Authorization': 'Bearer ' + token, 'Access-Control-Expose-Headers': 'Content-Disposition' }),
    });

    if (response.status !== 204) {
        throw new FetchError(response)
    }
}

export function deleteUrl(url: string) {
    return from(getAccessToken())
        .pipe(
            mergeMap(token => {
                return new Observable(observer => {
                    deleteImpl(token, url)
                        .then(result => {
                            observer.next();
                            observer.complete();
                        })
                        .catch(err => observer.error(err));
                })
            })
        );
}

export function post<TResult>(url: string, data: any, additionalHeaders: Record<string, string>) {
    return from(getAccessToken())
        .pipe(
            mergeMap(token => {
                return new Observable<TResult>(observer => {
                    postData(token, url, additionalHeaders, data, (resp: Response) => resp.json() )
                        .then(result => {
                            observer.next(result);
                            observer.complete();
                        })
                        .catch(err => observer.error(err));
                })
            })
        );
}

export function postNoContent(url: string, data: any, additionalHeaders: Record<string, string>) {
    return from(getAccessToken())
        .pipe(
            mergeMap(token => {
                return new Observable(observer => {
                    postData<boolean>(token, url, additionalHeaders, data, (resp: Response) => Promise.resolve(true) )
                        .then(result => {
                            observer.next();
                            observer.complete();
                        })
                        .catch(err => observer.error(err));
                })
            })
        );
}

export function get<TResult>(url: string, resultConverter: (response: Response) => Promise<TResult>) {
    return from(getAccessToken())
        .pipe(
            mergeMap(token => {
                return new Observable<TResult>(observer => {
                    fetchData(token, url, resultConverter)
                        .then(data => {
                            observer.next(data);
                            observer.complete();
                        })
                        .catch(err => observer.error(err));
                })
            })
        );
}


export function patch<TResult>(url: string, patchDoc: any, resultConverter: (response: Response) => Promise<TResult>) {
    return from(getAccessToken())
        .pipe(
            mergeMap(token => {
                return new Observable<TResult>(observer => {
                    patchData(token, url, patchDoc, resultConverter)
                        .then(data => {
                            observer.next(data);
                            observer.complete();
                        })
                        .catch(err => observer.error(err));
                })
            })
        );
        }
        

export function getText(url: string) {
    return get<string>(url, resp => resp.text());
}

export function getBlob(url: string) {
    return get<{ fileName?: string, data: Blob }>(url, resp => {
        const header = resp.headers.get('Content-Disposition');
        let filename: string | null = null;
        if (header !== null) {
            const expr = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const match = (header.match(expr) as RegExpMatchArray);
            filename = match[1];
            const trim = /^"+|"+$/g;
            filename = filename.replace(trim, '');
        }
        return resp.blob().then(blob => ({ fileName: filename === null ? undefined : filename, data: blob }));
    });
}

export function halGetUrl(diagnosticTag: string, builder: Builder) {
    return traversonExec<string>(diagnosticTag, builder, (b, c) => b.getUrl(c), (doc, traversal?) => (doc as string));
}

export function halGet<TModel>(diagnosticTag: string, builder: Builder) {
    return traversonExec<HalResult<TModel>>(diagnosticTag, builder, (b, c) => b.getResource(c), (doc, traversal?) => ({ traversal, resource: doc }));
}

export function halArrayGet<TModel>(diagnosticTag: string, builder: Builder) {
    return traversonExec<HalArrayResult<TModel>>(diagnosticTag, builder, (b, c) => b.getResource(c), (doc, traversal?) => ({ traversal, resources: doc }));
}

export function halPost<TModel>(diagnosticTag: string, builder: Builder, data: any, contentType?: string) {
    return traversonExec<HalResult<TModel>>(diagnosticTag, builder, (b, c) => b.post(data, c)
        , (doc, traversal?) => ({ traversal, resource: doc })
        , contentType ? { 'Content-Type': contentType } : {});
}

export function halPut<TModel>(diagnosticTag: string, builder: Builder, data: any, contentType?: string) {
    return traversonExec<HalResult<TModel>>(diagnosticTag, builder, (b, c) => b.put(data, c)
        , (doc, traversal?) => ({ traversal, resource: doc })
        , contentType ? { 'Content-Type': contentType } : {});
}

export function halDelete<TModel>(diagnosticTag: string, builder: Builder) {
    return traversonExec<HalResult<TModel>>(diagnosticTag, builder, (b, c) => b.delete(c)
        , (doc, traversal?) => ({ traversal, resource: doc }));
}

export function halPatch<TModel>(diagnosticTag: string, builder: Builder, data: any) {
    return traversonExec<HalResult<TModel>>(diagnosticTag, builder, (b, c) => b.patch(data, c)
        , (doc, traversal?) => ({ traversal, resource: doc })
        ,  { 'Accept': 'application/hal+json' });
}

function traversonExec<TResult>(
    diagnosticTag: string,
    builder: Builder,
    action: (builder: Builder, resultCallback: (err: any, document: any, traversal?: Traversal) => void) => InAction,
    result: (doc: any, traversal?: Traversal) => TResult,
    additionalHeaders: { [U: string]: any } = {}) {
        return from(getAccessToken())
        .pipe(
            mergeMap(token => {
            return new Observable<TResult>((observer) => {
                const header = builder
                    .withRequestOptions({
                        headers: { ...additionalHeaders, 'Authorization': 'Bearer ' + token },
                    });
                    action(header, (err: any, doc: any, traversal?: Traversal) => {
                    if (err) {
                        observer.error(err);
                    } else {
                        // console.log(`traversonExec(${diagnosticTag}) resource: `, doc);
                        // console.log(`traversonExec(${diagnosticTag}) continue: `, traversal);
                        observer.next(result(doc, traversal));
                    }
                    observer.complete();
                })
            })
        }));
}
