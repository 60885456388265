import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, map, mergeMap, tap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { AllowedOrganisation, ArchivedEvent, BackgroundOperation, IdType } from "../../model";
import { Services } from "../../services";
import { backgroundOperationChange, errorHandler, fetchRoom, onEventEscalation, onEventEscalationCleared, onNfcTagScanned, onReportInformation, onRoomChange, onRoomWindowsUpdatesChanged, startServerNotificationHub, updateComponentsInPackageFeedNotification } from "../actions";
import { fetchConferenceClients } from "../resourceActions";
import { RootAction, RootState } from "../types";

export const startServerNotificationHubEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverNotificationHub }) => {
    return action$.pipe(
        filter(isActionOf(startServerNotificationHub.request)),
        mergeMap(action =>
            from(serverNotificationHub.start()).pipe(
                tap(() => console.log('Server Notification Hub Started')),
                map(() => startServerNotificationHub.success()),
                catchError(err => of(startServerNotificationHub.failure(err), errorHandler(err)))
            )));
}


export const listenToNfcTagsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverNotificationHub }) => {
    return action$.pipe(
        filter(isActionOf(startServerNotificationHub.success)),
        tap(() => console.log('Listening for NfcTagScanned notifications')),
        mergeMap(act =>
            serverNotificationHub.on("NfcTagScanned", (notificationId: String, tagName: String, tagType: String, emailAddress: String) => ({ notificationId, tagName, tagType, emailAddress })).pipe(
                tap(evt => console.log(`NfcTagScanned ${evt.tagName}: notifId=${evt.notificationId}, tagType=${evt.tagType}, email=${evt.emailAddress}`)),
                tap(evt => console.log(`Evt`, evt)),
                tap(evt => serverNotificationHub.invoke("NfcTagScannedResponse", evt.notificationId, "Navigate", [ 'MobileApp', `/logged-in-status/${evt.tagName}` ])),
                mergeMap(evt => of(onNfcTagScanned(evt))
                ))));
}


export const listenToRoomChangesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverNotificationHub }) => {

    function notHeartbeat(props: string[]) {
        if (props.length > 1) {
            return true
        }
        if (props.length === 1 && props[0] === "last_heartbeat") {
            return false;
        }
        // if (props.length === 2 && props.some(p => p === "last_heartbeat" && props.some(p => p === "version"))) {
        //     return false
        // }
        return true
    }

    function isActiveOrganisation(activeOrganisation: false | AllowedOrganisation, organisationId: IdType) {
        if (!activeOrganisation) return false
        return activeOrganisation.organisation_id === organisationId
    }

    return action$.pipe(
        filter(isActionOf(startServerNotificationHub.success)),
        tap(() => console.log('Listening for RoomChanged notifications')),
        mergeMap(act =>
            serverNotificationHub.on("RoomChanged", (organisationId: IdType, roomName: string, rel: string, properties: string[]) => ({ organisationId, roomName, rel, properties })).pipe(
                filter(notif => notHeartbeat(notif.properties)),
                filter(notif => isActiveOrganisation(store.value.app.activeOrganisation, notif.organisationId)),
                mergeMap(notif => of(
                    onRoomChange(notif),
                    fetchRoom.request({organisationId: notif.organisationId, roomId: notif.roomName})
                )
                ))));
}

export const listenToEventEscalationsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverNotificationHub }) => {
    return action$.pipe(
        filter(isActionOf(startServerNotificationHub.success)),
        tap(() => console.log('Listening for EventEscalated notifications')),
        mergeMap(act =>
            serverNotificationHub.on("EventEscalated", (event: ArchivedEvent, escalation: boolean) => ({ event, escalation })).pipe(
                tap(notif => console.log(`Event escalated ${notif.event.id}: isEscalation=${notif.escalation}`)),
                mergeMap(notif => of(
                    notif.escalation ? onEventEscalation(notif.event) : onEventEscalationCleared(notif.event),
                )
                ))));
}

export const listenToRoomWindowsUpdatesChangedEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverNotificationHub }) => {
    return action$.pipe(
        filter(isActionOf(startServerNotificationHub.success)),
        tap(() => console.log('Listening for RoomWindowsUpdatesChanged notifications')),
        mergeMap(act =>
            serverNotificationHub.on("RoomWindowsUpdatesChanged", (roomName: String) => ({ roomName })).pipe(
                tap(notif => console.log(`onRoomWindowsUpdatesChanged received ${notif.roomName}`)),
                mergeMap(change => of(
                    onRoomWindowsUpdatesChanged(change.roomName),
                    onReportInformation({title: "Windows Updates", message: `Room ${change.roomName} has refreshed it Windows Updates list.`})
                )
                ))));
}

export const listenToBackgroundOperationChangeEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverNotificationHub }) => {
    return action$.pipe(
        filter(isActionOf(startServerNotificationHub.success)),
        tap(() => console.log('Listening for BackgroundOperationChange notifications')),
        mergeMap(act =>
            serverNotificationHub.on("BackgroundOperationChange", (operation: BackgroundOperation) => ({ operation })).pipe(
                tap(notif => console.log(`BackgroundOperationChange `, notif.operation)),
                mergeMap(notif => of(
                    updateComponentsInPackageFeedNotification(notif.operation),
                    backgroundOperationChange(notif.operation)
                )
                ))));
}
