import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RoomoteDevice } from "../../../model";
import { roomoteDeviceReducer } from "../../resourceActions";
import { Actions, actions } from "../../types";
import { ApiResourceState, defaultState } from "../ApiResourceState";


type RoomoteStateDevices = ApiResourceState<RoomoteDevice>
function roomoteDevices(state: RoomoteStateDevices = defaultState, action: Actions): RoomoteStateDevices {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return roomoteDeviceReducer(state, action);
    }
}

const videofx = combineReducers({
    roomoteDevices
})

export default videofx