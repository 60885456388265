import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { Services } from "../../services";
import { backupRoom, createRoom, deleteRoom, errorHandler, fetchLatestRoomBackup, fetchRoom, fetchRoomAttributes, fetchRoomNames, fetchRooms, refreshRoomPin, updateComponentProperties, updateComponentToLatest, updateJavascriptPackageToLatest, updateRoomProperties } from "../actions";
import { RootAction, RootState } from "../types";

export const updateRoomPropertiesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(updateRoomProperties.request)),
        switchMap(action => 
            from(serverApi.updateRoom(action.payload.room, action.payload.updatedRoom)).pipe(
                map(hal => updateRoomProperties.success(hal)),
                catchError(err => of(updateRoomProperties.failure(err), errorHandler(err)))
            )));
}

export const updateComponentPropertiesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(updateComponentProperties.request)),
        switchMap(action => 
            from(serverApi.updateComponent(action.payload.room, action.payload.component, action.payload.updatedComponent)).pipe(
                map(hal => updateComponentProperties.success(hal)),
                catchError(err => of(updateComponentProperties.failure(err), errorHandler(err)))
            )));
}

export const fetchRoomEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchRoom.request)),
        switchMap(action =>
            from(serverApi.fetchRoom(action.payload.organisationId, action.payload.roomId)).pipe(
                mergeMap(hal => of(
                    fetchRoom.success(hal),
                    fetchRoomAttributes.request(hal)
                )),
                catchError(err => of(fetchRoom.failure(err), errorHandler(err)))
            )));
}

export const createRoomEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(createRoom.request)),
        switchMap(action =>
            from(serverApi.createRoom(action.payload.room, action.payload.attribs)).pipe(
                map(hal => createRoom.success(hal)),
                catchError(err => of(createRoom.failure(err), errorHandler(err)))
            )));
}

export const updateRoomToLatestEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(updateComponentToLatest.request)),
        switchMap(action =>
            from(serverApi.updateComponentToLatest(action.payload.room, action.payload.componentType)).pipe(
                map(_ => updateComponentToLatest.success()),
                catchError(err => of(updateComponentToLatest.failure(err), errorHandler(err)))
            )));
}

export const updateJavascriptPackageToLatestEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(updateJavascriptPackageToLatest.request)),
        switchMap(action =>
            from(serverApi.updateJavascriptPackageToLatest(action.payload)).pipe(
                map(_ => updateJavascriptPackageToLatest.success()),
                catchError(err => of(updateJavascriptPackageToLatest.failure(err), errorHandler(err)))
            )));
}

export const deleteRoomEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(deleteRoom.request)),
        switchMap(action =>
            from(serverApi.deleteRoom(action.payload)).pipe(
                map(() => deleteRoom.success(action.payload.name)),
                catchError(err => of(deleteRoom.failure(err), errorHandler(err)))
            )));
}

// export const provisionRoomEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
//     return action$.pipe(
//         filter(isActionOf(provisionRoom.request)),
//         switchMap(action =>
//             from(serverApi.provisionRoom(action.payload)).pipe(
//                 map(res => provisionRoom.success(res)),
//                 catchError(err => of(provisionRoom.failure(err), errorHandler(err)))
//             )));
// }

export const refreshRoomPinEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(refreshRoomPin.request)),
        mergeMap(action =>
            serverApi.refreshRoomPin(action.payload).pipe(
                map(hal => refreshRoomPin.success(hal)),
                catchError(err => of(refreshRoomPin.failure(err), errorHandler(err)))
            )));
}

export const fetchRoomAttributesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchRoomAttributes.request)),
        mergeMap(action =>
            serverApi.fetchRoomAttributes(action.payload).pipe(
                map(hal => fetchRoomAttributes.success({roomName: action.payload.resource!.name, attribs: hal})),
                catchError(err => of(fetchRoomAttributes.failure(err), errorHandler(err)))
            )));
}

export const fetchLatestRoomBackupEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchLatestRoomBackup.request)),
        mergeMap(action =>
            serverApi.fetchLatestRoomBackup(action.payload).pipe(
                map(hal => fetchLatestRoomBackup.success({roomName: action.payload.resource!.name, configuration: hal})),
                catchError(err => of(fetchLatestRoomBackup.failure(err)))
            )));
}

export const backupRoomEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(backupRoom.request)),
        mergeMap(action =>
            serverApi.backupRoom(action.payload).pipe(
                map(hal => backupRoom.success({roomName: action.payload.resource!.name, configuration: hal})),
                catchError(err => of(backupRoom.failure(err), errorHandler(err)))
            )));
}

export const fetchRoomsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchRooms.request)),
        mergeMap(action =>
            serverApi.fetchRooms(action.payload).pipe(
                mergeMap(hal => of(fetchRooms.success(hal))),
                catchError(err => of(fetchRooms.failure(err), errorHandler(err)))
            )));
}


export const fetchRoomNamesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(fetchRoomNames.request)),
        mergeMap(action =>
            serverApi.fetchRoomNames(action.payload).pipe(
                mergeMap(names => of(fetchRoomNames.success(names.resource))),
                catchError(err => of(fetchRoomNames.failure(err), errorHandler(err)))
            )));
}