import { Button, Dialog, Flex, Form, FormField, Input, Text } from "@fluentui/react-northstar";
import * as React from "react";
import { useEffect, useState } from "react";
import DropdownControlled from "../../controls/DropdownControlled";

interface Props {
    title: string;
    isOpen: boolean;
    content: React.ReactNode
}
const RoomPropertyEditPopup: React.FC<Props> = ({ title, isOpen, content }) => {
    const [open, setOpen] = useState(false)
 
    useEffect(() => {
        if (isOpen !== undefined) {
            setOpen(isOpen)
        }
    }, [isOpen])

    return (<Dialog
        open={open}
        onOpen={(e, data) => { if (data && data.open) setOpen(data!.open!) }}
        header={title}
        content={content}

    />)
}

interface RoomPropertyEditInputProps {
    label: string
    propertyName: string
    defaultValue?: string
    clearable?: boolean
    onCancel: (propertyName: string) => void
    onSubmit: (propertyName: string, value?: string) => void
    // validate the input and return error message if there is a problem
    validate?: (propertyName: string, value?: string) => string|undefined
}
const RoomPropertyEditInput: React.FC<RoomPropertyEditInputProps> = ({label, propertyName, defaultValue, clearable, onCancel, onSubmit, validate}) => {
    const [validationFailure, setValidationFailure] = useState<string>();
    const [currentValue, setCurrentValue] = useState<string|undefined>(defaultValue)

    const handleChange = (evt: any) => {
        const { value } = evt.target;
        setCurrentValue(value)
    }

    const handleSubmit = () => {
        if (validate) {
            let message = validate(propertyName, currentValue)
            if (message !== undefined) {
                setValidationFailure(message)
                return
            }
        }
        onSubmit(propertyName, currentValue)
    }

    return (
        <Form styles={{width: '350px'}}>
            <Flex column gap="gap.medium" fill >
                <FormField 
                        label={label}
                        name="propertyName" 
                        id="propertyName"
                        control={<Input onChange={handleChange} value={currentValue} fluid clearable={!!clearable}/>}
                        message={() => (<Text error content={validationFailure} />)}
                    />
                <RoomPropertyEditButtons onCancel={() => onCancel(propertyName)} onSubmit={handleSubmit}/>
            </Flex>
        </Form>
    )
}

interface RoomPropertyEditDropdownProps<T> {
    label: string
    propertyName: string
    dataItems: T[]
    getHeader: (item: T) => string
    selectedKey?: string
    clearable?: boolean
    placeholder: string
    allowNone: boolean
    onCancel: (propertyName: string) => void
    onSubmit: (propertyName: string, value?: string) => void
}

const RoomPropertyEditDropdown = <T extends any>(props: RoomPropertyEditDropdownProps<T>) => {
    const {label, propertyName, dataItems, placeholder, allowNone, selectedKey, getHeader, onCancel, onSubmit} = props
    const [selected, setSelected] = useState<string|undefined>()


    const handleChange = (value: T | null): void => {
        const name = value === null ? 'None' : getHeader(value)
        setSelected(name)
    }

    return (
        <Form styles={{width: '350px'}}>
            <Flex column gap="gap.medium" fill >
                <FormField label={label} name="propertyName" id="propertyName" control={
                    <DropdownControlled dataItems={dataItems} onSelectionChanged={handleChange} getHeader={getHeader} placeholder={placeholder} selectedKey={selectedKey} allowNone={allowNone}/>}
                 />
                <RoomPropertyEditButtons onCancel={() => onCancel(propertyName)} onSubmit={() => onSubmit(propertyName, selected)}/>
            </Flex>
        </Form>
    )
}

interface RoomPropertyEditButtonsProps {
    onCancel: () => void
    onSubmit: () => void
}
const RoomPropertyEditButtons: React.FC<RoomPropertyEditButtonsProps> = (props) => {
    return (
        <Flex gap="gap.smaller" hAlign="end">
            <FormField
                control={{
                    as: Button,
                    content: 'Cancel',
                    onClick: props.onCancel
                }}
            />
            <FormField
                control={{
                    as: Button,
                    primary: true,
                    content: 'Save Changes',
                    onClick: props.onSubmit
                }}
            />
        </Flex>
    )
}

export { RoomPropertyEditPopup, RoomPropertyEditInput, RoomPropertyEditDropdown };

