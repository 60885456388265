import { DownloadIcon, PlayIcon, TrashCanIcon, UndoIcon } from '@fluentui/react-northstar';
import * as React from "react";
import { useCallback, useEffect, useState } from 'react';
import { FaPowerOff, FaStop } from 'react-icons/fa';
import { MdRestorePage, MdUpdate } from 'react-icons/md';
import { RoomControlToolbarProps } from '../../../containers/RoomControlToolbar';
import { ComponentType, Room, RoomConfiguration } from "../../../model";
import { HalResource, HalResult } from "../../../services/Hal";
import { ApiResult } from '../../../store/reducers/ApiResult';
import { fileDownloadFromBlob } from '../../../utils/fileDownload';
import ConfirmationDialog from '../../controls/ConfirmationDialog';
import { IntermediateToolbarItem, ToolbarWithTooltips } from '../../controls/ToolbarWithTooltips';
import CollectLogsDialog, { ViaMethodType } from './CollectLogsDialog';
import "./RoomControlToolbar.css";
import SelectComponentToUpdateDialog from './SelectComponentToUpdateDialog';
import { ReactComponent as TeamViewerIcon } from './teamviewer.svg';

type Props = RoomControlToolbarProps & React.HTMLAttributes<HTMLDivElement> & {
    room: HalResource<Room>
    halRoom?: HalResult<Room>
    latestBackup?: HalResult<RoomConfiguration>
    showExtraButtons: boolean
}

const RoomControlToolbar: React.FC<Props> = (props) => {
    //https://github.com/stardust-ui/react/blob/9d7740800022b471218cbea650ef4758f88ab12e/docs/src/examples/components/Menu/Usages/MenuExampleWithTooltip.shorthand.tsx
    const { room, halRoom, latestBackup, showExtraButtons, userInfo
        , onStart, onStop, onDownloadLogs: onCollectLogs, onEmailLogs, onReboot, updateRoomProps, onDeleteRoom, redirectRoomList
        , onDownloadComplete, onUpdateComponent, collectedLogs, className } = props

    useEffect(() => {
        if (collectedLogs && collectedLogs.isLoading && collectedLogs.data) {
            fileDownloadFromBlob(collectedLogs.data!, `${room.name}_${collectedLogs.name!}`);
            onDownloadComplete(room.name);
        }
    }, [collectedLogs, onDownloadComplete, room.name])

    const onClick = (event: React.SyntheticEvent<HTMLElement>, data?: any) => {
        const { id } = data
        event.stopPropagation()
        if (id === "start") {
            onStart(room)
        } else if (id === "stop") {
            onStop(room);
        } else if (id === "reboot") {
            onReboot(room, false)
        } else if (id === "teamviewer") {
            window.open(`https://start.teamviewer.com/${room.team_viewer_id!.replace(/ /g, '')}`);
        }
    }

    const isStopped = room.room_component!.running_status! !== "Running"
    const noAdminService = false // (!room.admin_api_url || !(room.admin_component!.running_status === "Running"));
    const teamviewNotAvailable = room.team_viewer_id === undefined
    const canReset = room.provisioning_status !== 'AwaitingProvisioning'
    const canReinstall = room.provisioning_status !== 'AwaitingProvisioning' && latestBackup !== undefined
    const canUpdateRoom = (room.room_component?.update_available ?? false) && !noAdminService
    const canUpdateAdmin = (room.admin_component?.update_available ?? false) && !noAdminService
    const canUpdateJs = (room.javascript_package_component?.update_available ?? false) && !noAdminService
    const canUpdate = canUpdateRoom || canUpdateAdmin || canUpdateJs

    const [collectOpen, setCollectOpen] = useState(false)
    const chooseCollect = (event: React.SyntheticEvent<HTMLElement>, data?: any) => {
        event.preventDefault()
        event.stopPropagation()
        setCollectOpen(true)
    }
    const handleCollect = (via: ViaMethodType, to?: string[]) => {
        console.log(`Collecting logs: via=${via}, to=`, to)
        setCollectOpen(false)
        if (via === 'download') {
            onCollectLogs(room)
        } else {
            onEmailLogs(room, to!)
        }
    }

    const [resetOpen, setResetOpen] = useState<boolean>()
    const confirmReset = (event: React.SyntheticEvent<HTMLElement>, data?: any) => {
        event.stopPropagation()
        setResetOpen(true)
    }
    const handleReset = () => {
        setResetOpen(undefined)
        updateRoomProps(halRoom!, { ...halRoom!.resource, provisioning_status: 'AwaitingProvisioning' });
    }

    const [reinstallOpen, setReinstallOpen] = useState<boolean>()
    const confirmReinstall = (event: React.SyntheticEvent<HTMLElement>, data?: any) => {
        event.stopPropagation()
        setReinstallOpen(true)
    }
    const handleReinstall = () => {
        setReinstallOpen(undefined)
        updateRoomProps(halRoom!, { ...halRoom!.resource, provisioning_status: 'AwaitingReinstall' });
    }

    const [deleteOpen, setDeleteOpen] = useState<boolean>()
    const confirmDelete = (event: React.SyntheticEvent<HTMLElement>, data?: any) => {
        event.stopPropagation()
        setDeleteOpen(true)
    }
    const handleDelete = () => {
        setDeleteOpen(undefined)
        onDeleteRoom(room)
        redirectRoomList()
    }

    const [selectComponentOpen, setSelectComponentOpen] = useState<boolean>()
    const handleSelectComponent = (event: React.SyntheticEvent<HTMLElement>, data?: any) => {
        event.stopPropagation()
        setSelectComponentOpen(true)
    }
    const [componentToUpdate, setComponentToUpdate] = useState<ComponentType>()
    const [versionToUpdateTo, setVersionToUpdateToo] = useState<string>()
    const handleComponentSelected = useCallback((component: ComponentType) => {
        setSelectComponentOpen(undefined)
        setComponentToUpdate(component)
        if (component === 'Room') {
            setVersionToUpdateToo(room.room_component?.update_version!)
        }
        if (component === 'Admin') {
            setVersionToUpdateToo(room.admin_component?.update_version!)
        }
        if (component === 'JavascriptPackage') {
            setVersionToUpdateToo(room.javascript_package_component?.update_version!)
        }
    }, [room.admin_component, room.javascript_package_component, room.room_component])

    const handleUpdateComponent = useCallback(() => {
        setComponentToUpdate(undefined)
        setVersionToUpdateToo(undefined)
        onUpdateComponent(halRoom!, componentToUpdate!)
    }, [onUpdateComponent, halRoom, componentToUpdate])

    const items: Array<IntermediateToolbarItem> = [
        { key: 'reset', icon: <UndoIcon outline/>, tooltip: 'Reset room', onClick: confirmReset, showButton: () => !!showExtraButtons, disabled: !canReset },
        { key: 'reinstall', icon: <MdRestorePage/>, tooltip: 'Prepare room for reinstall', onClick: confirmReinstall, showButton: () => !!showExtraButtons, disabled: !canReinstall },
        { key: 'delete', icon: <TrashCanIcon outline/>, tooltip: 'Delete room', onClick: confirmDelete, showButton: () => !!showExtraButtons, disabled: canReset },
        { key: 'divider1', kind: 'divider', showButton: () => !!showExtraButtons },
        { key: 'update', icon: <MdUpdate/>, tooltip: 'Update room', onClick: handleSelectComponent, showButton: () => !!showExtraButtons, disabled: !canUpdate },
        { key: 'start', icon: <PlayIcon outline/>, tooltip: 'Start room', onClick, showButton: () => isStopped, disabled: noAdminService },
        { key: 'stop', icon: <FaStop/>, tooltip: 'Stop room', onClick, showButton: () => !isStopped, disabled: noAdminService },
        { key: 'reboot', icon: <FaPowerOff/>, tooltip: 'Reboot room', onClick, disabled: noAdminService },
        { key: 'collect', icon: <DownloadIcon outline />, tooltip: 'Collect & Download logs', onClick: chooseCollect, showButton: () => !!showExtraButtons, disabled: noAdminService },
        { key: 'teamviewer', icon: <TeamViewerIcon/>, tooltip: 'Launch TeamViewer', onClick, disabled: teamviewNotAvailable }
    ]

    return (
        <>
            <ToolbarWithTooltips
                className={className}
                items={items}
            />

            <CollectLogsDialog
                onConfirm={handleCollect}
                onCancel={() => setCollectOpen(false)}
                isOpen={collectOpen}
                emailAdddress={ApiResult.getValueOrThrow(userInfo).user.email_address}
            />

            <SelectComponentToUpdateDialog onConfirm={handleComponentSelected}
                onCancel={() => setSelectComponentOpen(undefined)}
                roomDetails={canUpdateRoom ? `Room (${room.room_component!.update_version!})` : undefined}
                adminDetails={canUpdateAdmin ? `Admin (${room.admin_component!.update_version!})` : undefined}
                jsDetails={canUpdateJs ? `Javascript Package (${room.javascript_package_component!.update_version!})` : undefined}
                isOpen={selectComponentOpen}
            />

            <ConfirmationDialog onConfirm={handleUpdateComponent}
                onCancel={() => setComponentToUpdate(undefined)}
                message={`Are you sure you want to install version ${versionToUpdateTo} of the ${componentToUpdate} component to room ${room.name}?`}
                heading={`Update ${componentToUpdate} to Latest Version`}
                confirmButtonText='Update'
                isOpen={componentToUpdate !== undefined} />


            <ConfirmationDialog onConfirm={handleReset}
                onCancel={() => setResetOpen(undefined)}
                message={`Are you sure you wish to set the provisioning status for ${room.name} back to the intitial state?`}
                heading='Reset Room Provisioning Status'
                confirmButtonText='Reset'
                isOpen={resetOpen} />

            <ConfirmationDialog onConfirm={handleReinstall}
                onCancel={() => setReinstallOpen(undefined)}
                message={`Are you sure you wish to reset the room status for ${room.name} so it can be re-installed?`}
                heading='Set Room Status to Reinstall'
                confirmButtonText='Reinstall'
                isOpen={reinstallOpen} />

            <ConfirmationDialog onConfirm={handleDelete}
                onCancel={() => setDeleteOpen(undefined)}
                message={`Are you sure you wish to delete room ${room.name}? This operation cannot be un-done`}
                heading='Delete Room'
                confirmButtonText='Delete'
                isOpen={deleteOpen} />

        </>)
}

export default RoomControlToolbar