import { getType } from "typesafe-actions";
import { AggregateColumnType, AggregateData } from "../../model";
import { HalResource } from "../../services/Hal";
import { Actions, actions } from "../types";

interface Metrics {
    metrics?: {
        [key in AggregateColumnType]?: HalResource<AggregateData>;
    }
}
export default function metrics(state: Metrics = {}, action: Actions): Metrics {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return {
                ...state,
                metrics: {}
            }
        case getType(actions.fetchAggregateMetrics.success):
            return {
                ...state,
                metrics: { ...state.metrics, [action.payload.resource!.by]: action.payload.resource! }
            };
        default:
            return state;
    }
}