import { compare, Operation } from 'fast-json-patch';
import moment from 'moment';
import { identity, Observable, of, throwError } from 'rxjs';
import { map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import traverson from 'traverson';
import * as JsonHalAdapter from 'traverson-hal';
import { AggregateColumnType, AggregateData, BackgroundOperation, BackgroundOperationResult, Component, ComponentType, CorsAllowedOrigin, 
    EscalationReport, SecurityReport, SecurityUserReport, EventEscalation, EventEscalationSelection, HealthReport, IdType, PackageFeed, 
    PackageFeedType, PackageVersion, Room, RoomAttribute, RoomAttributes, RoomConfiguration, RoomNames, ServerInfo, SettingsCategory, 
    Tag, UserAccount, UserInfo, WindowsUpdate } from '../model';
import { RoomAdminApi } from '../services/RoomAdminApi';
import { ApiError } from '../utils/error';
import { deleteUrl, get, getBlob, halArrayGet, halGet, halGetUrl, halPatch, halPost, HalResource, HalResult, patch, post, postNoContent } from './Hal';
import HalApi from './HalApi';

export class ServerApi implements HalApi {
    private serverApiRoot: string;
    private root?: Observable<HalResult<{}>>;
    private metricsRoot?: HalResult<{}>;
    constructor(serverApiRoot: string) {
        console.log("ServerApiRoot: ", serverApiRoot)
        this.serverApiRoot = serverApiRoot;

        traverson.registerMediaType(JsonHalAdapter.mediaType, JsonHalAdapter);
    }
    public fetchRoot() {

        if (this.serverApiRoot === null) {
            throw new Error('Config.ServerUrl is null');
        }

        if (this.root !== undefined) {
            return this.root;
        }

        // this.root = from(new Promise(resolve => {
        //     halGet<{}>("fetchRoot", traverson.from(this.serverApiRoot).jsonHal()).subscribe(r => resolve(r))
        // }));
        this.root = halGet<{}>("fetchRoot", traverson.from(this.serverApiRoot)
            .jsonHal())
            .pipe(shareReplay(1));
        return this.root;
    }
    
    public fetchServerInfo() {
        if (this.serverApiRoot === null) {
            throw new Error('Config.ServerUrl is null');
        }
        const url = `${this.serverApiRoot}/.well-known/videofx`
        return get<ServerInfo>(url, r => r.json())
    }

    public fetchRooms(organisationId: IdType) {
        this.fetchRoot();
        return this.fetchRoot().pipe(
            mergeMap(root => halArrayGet<Room>("fetchRooms",
                root.traversal!.continue().newRequest()
                    .follow("rooms_by_org")
                    .withTemplateParameters({ organisationId })
                    .follow("room_by_org[$all]")
            ))
        );
    }

    public fetchRoomNames(organisationId: IdType) {
        this.fetchRoot();
        return this.fetchRoot().pipe(
            mergeMap(root => halGet<RoomNames>("fetchRoomNames",
                root.traversal!.continue().newRequest()
                    .follow("room_names")
                    .withTemplateParameters({ organisationId })
            ))
        );
    }

    public fetchRoom(organisationId: IdType, roomName: string) {
        return this.fetchRoot().pipe(
            mergeMap(root => halGet<Room>("fetchRoom",
                root.traversal!.continue().newRequest()
                    .follow("room_by_org")
                    .withTemplateParameters({ organisationId, id: roomName })
            )));
    }

    public createRoom(room: Room, attributes?: Array<{ name: string, value: RoomAttribute }>) {
        const patchDoc = attributes
            ? attributes!.map(a => ({ path: `/${a.name}`, op: 'add', value: a.value }))
            : undefined;
        return this.fetchRoot().pipe(
            mergeMap(root => halPost<Room>("createRoom(POST)", root.traversal!.continue().newRequest().follow("room_by_org").withTemplateParameters({ organisationId: room.organisation_id }), room, 'application/json')),
            mergeMap(hal => halGetUrl("createRoom(GETURL)", hal.traversal!.continue().newRequest().follow("attributes")).pipe(
                mergeMap(url => patch<RoomAttributes>(url, JSON.stringify(patchDoc), r => r.json()).pipe(
                    map(attrib => hal)
                )))));
    }

    public deleteRoom(room: Room) {
        return this.fetchRoot().pipe(
            mergeMap(root => halGetUrl("deleteRoom",
                root.traversal!.continue().newRequest()
                    .follow("room_by_org")
                    .withTemplateParameters({ organisationId: room.organisation_id, id: room.name })
            )),
            mergeMap(url => deleteUrl(url)));
    }

    public refreshRoomPin(room: HalResult<Room>) {
        return this.fetchRoot().pipe(
            mergeMap(root => halPost<Room>("refreshRoomPin",
                room.traversal!.continue().newRequest()
                    .follow("update_pin").withTemplateParameters({ organisationId: room.resource.organisation_id }), {}
            )));
    }

    public updateComponentToLatest(room: HalResult<Room>, componentType: ComponentType) {
        return halGetUrl("updateComponentToLatest",
            room.traversal!.continue().newRequest()
                .follow(`install_latest[name:${componentType}]`)).pipe(
                    mergeMap(url => postNoContent(url, JSON.stringify({}), {'Content-Type': 'application/json'}))
                )
    }

    public updateJavascriptPackageToLatest(room: HalResult<Room>) {
        return halGetUrl("updateJavascriptPackageToLatest",
            room.traversal!.continue().newRequest()
                .follow("deploy_latest_javascript")).pipe(
                    mergeMap(url => postNoContent(url, JSON.stringify({}), {'Content-Type': 'application/json'}))
                )
    }


    public fetchRoomAttributes(room: HalResult<Room>) {
        return this.fetchRoot().pipe(
            mergeMap(root => halGet<RoomAttributes>("fetchRoomAttributes",
                room.traversal!.continue().newRequest()
                    .preferEmbeddedResources()
                    .follow("attributes")
            )));
    }

    public fetchLatestRoomBackup(room: HalResult<Room>) {
        return this.fetchRoot().pipe(
            mergeMap(root => halGet<RoomConfiguration>("fetchLatestRoomBackup",
                room.traversal!.continue()
                    .newRequest()
                    .follow("configurations")
                    .follow("latest")
            )));
    }

    public backupRoom(room: HalResult<Room>) {
        return this.fetchRoot().pipe(
            mergeMap(root => halPost<RoomConfiguration>("backupRoom",
                room.traversal!.continue()
                    .newRequest()
                    .follow("configurations")
                , {})));
    }

    public fetchCorsAllowedOrigins() {
        return this.fetchRoot().pipe(
            mergeMap(root => halArrayGet<CorsAllowedOrigin>("fetchCorsAllowedOrigins",
                root.traversal!.continue().newRequest()
                    .follow("cors_allowed_origins")
                    .follow("cors_allowed_origin[$all]")
            )));
    }

    public fetchEventEscalations(selection: EventEscalationSelection, organisationId: IdType) {
        return this.fetchRoot().pipe(
            mergeMap(root => halArrayGet<EventEscalation>("fetchEventEscalations",
                root.traversal!.continue().newRequest()
                    .follow("event_escalations").withTemplateParameters({ organisationId, selection })
                    .follow("event_escalation[$all]")
            )));
    }

    public fetchEventEscalationsForRoom(selection: EventEscalationSelection, organisationId: IdType, roomName: string) {
        return this.fetchRoot().pipe(
            mergeMap(root => halArrayGet<EventEscalation>("fetchEventEscalations",
                root.traversal!.continue().newRequest()
                    .follow("room_event_escalations").withTemplateParameters({ organisationId, id: roomName, selection })
                    .follow("event_escalation[$all]")
            )));
    }

    public closeEventEscalation(eventEscalationId: IdType, organisationId: IdType, reason: string) {
        return this.fetchRoot().pipe(
            mergeMap(root => halPost<EventEscalation>("closeEventEscalation",
                root.traversal!.continue().newRequest()
                    .follow("event_escalations_close").withTemplateParameters({ organisationId, id: eventEscalationId })
                , { reason }
            )));
    }

    public createCorsAllowedOrigin(origin: CorsAllowedOrigin) {
        return this.fetchRoot().pipe(
            mergeMap(root => halPost<CorsAllowedOrigin>("createCorsAllowedOrigin",
                root.traversal!.continue().newRequest()
                    .follow("cors_allowed_origins"), origin
            )));
    }

    public deleteCorsAllowedOrigin(origin: CorsAllowedOrigin) {
        return this.fetchRoot().pipe(
            mergeMap(root => halGetUrl("deleteCorsAllowedOrigin",
                root.traversal!.continue().newRequest()
                    .follow("cors_allowed_origin")
                    .withTemplateParameters({ id: origin.cors_allowed_origin_id! })
            )),
            mergeMap(url => deleteUrl(url)));
    }

    public patchCorsAllowedOrigin(id: IdType, operations: Operation[]) {
        return this.fetchRoot().pipe(
            mergeMap(root => halPatch<CorsAllowedOrigin>("patchCorsAllowedOrigin",
                root.traversal!.continue().newRequest()
                    .follow("cors_allowed_origin")
                    .withTemplateParameters({ id }), operations)
            ))
    }

    public fetchWindowsUpdates() {
        return this.fetchRoot().pipe(
            mergeMap(root => halArrayGet<WindowsUpdate>("fetchWindowsUpdates",
                root.traversal!.continue().newRequest()
                    .follow("windows_updates")
                    .follow("windows_update[$all]"))),
            identity
        );
    }

    public fetchPackageFeeds() {
        return this.fetchRoot().pipe(
            mergeMap(root => halGet<{}>("fetchPackageFeeds",
                root.traversal!.continue().newRequest()
                    .withTemplateParameters({ latest: true })
                    .follow("package_feeds"))),
            identity
        );
    }

    public fetchAllPackageFeeds() {
        return this.fetchPackageFeeds().pipe(
            mergeMap(pkgs => halArrayGet<PackageFeed>("fetchAllPackageFeeds",
                pkgs.traversal!.continue().newRequest()
                    .follow("package_feed[$all]"))
            ));
    }

    public createPackageFeed(feedName: string, feedType: PackageFeedType, componentType: ComponentType, description: string, automatic_update_time_utc?: string) {
        return this.fetchRoot().pipe(
            mergeMap(root => halPost<PackageFeed>("createPackageFeed",
                root.traversal!.continue().newRequest()
                    .follow("package_feeds"), { feed_name: feedName, type: feedType, component_type: componentType, description, automatic_update_time_utc }
            )));
    }

    public patchPackageFeed(feed_name: string, operations: Operation[]) {
        return this.fetchRoot().pipe(
            mergeMap(root => halPatch<PackageFeed>("patchPackageFeed",
                root.traversal!.continue().newRequest()
                    .follow("package_feeds")
                    .withTemplateParameters({ feed_name }), operations)
            ))
    }

    public deletePackageFeed(feedName: string) {
        return this.fetchRoot().pipe(
            mergeMap(root => halGetUrl("deletePackageFeed",
                root.traversal!.continue().newRequest()
                    .follow("package_feed")
                    .withTemplateParameters({ feedName })
            )),
            mergeMap(url => deleteUrl(url)));
    }

    public updateComponentsInPackageFeed(feedName: string) {
        return this.fetchRoot().pipe(
            mergeMap(root => halGetUrl("updateComponentsInPackageFeed",
                root.traversal!.continue().newRequest()
                    .follow("package_feed")
                    .withTemplateParameters({ feedName })
                    .follow("install_latest")
            )),
            mergeMap(url => post<HalResource<BackgroundOperation>>(url, {}, {})));
    }

    public fetchLatestSoftwarePackageVersion(feedName: string) {
        return this.fetchRoot().pipe(
            mergeMap(root => halGet<PackageVersion>("fetchLatestSoftwarePackageVersion",
                root.traversal!.continue().newRequest()
                    .follow("package_feed")
                    .withTemplateParameters({ feedName })
                    .follow("latest_version"))),
        );
    }

    public fetchPackageVersion(version: PackageVersion) {
        return this.fetchRoot().pipe(
            mergeMap(root => halGet<PackageFeed>("fetchPackageVersion(GETFEED)",
                root.traversal!.continue().newRequest()
                    .follow("package_feed")
                    .withTemplateParameters({ feedName: version.feed_name }))),
            mergeMap(pkg => halGet<PackageVersion>("fetchPackageVersion(GETVERSION)",
                pkg.traversal!.continue().newRequest()
                    .follow("package_version")
                    .withTemplateParameters({ version: version.version })
            )))
    }

    public fetchAdminUsers() {
        return this.fetchRoot().pipe(
            mergeMap(root => halArrayGet<UserAccount>("fetchAdminUsers",
            root.traversal!.continue().newRequest()
                .follow("admin_users")
                .follow("admin_user[$all]"))
        ));
    }

    public downloadPackageVersion(version: HalResource<PackageVersion>) {

        return this.fetchPackageVersion(version).pipe(
            mergeMap(pkg => halGetUrl("downloadPackageVersion(GETURL)",
                pkg.traversal!.continue().newRequest()
                    .follow("image"))),
            mergeMap(url => getBlob(url))
        );
    }

    public uploadNewPackageVersion(feedName: string, file: File) {
        const data = new FormData();
        data.append('file', file);

        return this.fetchRoot().pipe(
            mergeMap(root => halGet<PackageFeed>("getPackageFeed(GET)",
                root.traversal!.continue().newRequest()
                    .follow("package_feed")
                    .withTemplateParameters({ feedName }))),
            mergeMap(pkg => halGetUrl("uploadNewPackageVersion(GETURL)",
                pkg.traversal!.continue().newRequest()
                    .follow("new_version"))),
            mergeMap(uploadUrl => post<HalResource<PackageVersion>|BackgroundOperationResult>(uploadUrl, data, {}))
        );
    }

    public uploadLicenseFile(room: HalResult<Room>, file: File) {
        const data = new FormData();
        data.append('file', file);

        return this.fetchRoot().pipe(
            mergeMap(pkg => halGetUrl("uploadLicenseFile(GETURL)",
                room.traversal!.continue().newRequest()
                    .follow("license_file"))),
            mergeMap(uploadUrl => postNoContent(uploadUrl, data, {}))
        );
    }

    public fetchMetricsRoot() {
        if (this.metricsRoot !== undefined) {
            return of(this.metricsRoot);
        }
        return this.fetchRoot().pipe(
            mergeMap(root => halGet<{}>("fetchMetricsRoot",
                root.traversal!.continue().newRequest().follow("metrics"))),
            tap(hal => {
                this.metricsRoot = hal;
                return of(hal);
            }))
    }

    public fetchAggregateMetrics(organisationId: IdType, column: AggregateColumnType, start?: number, end?: number, roomName?: string) {
        const param: any = { organisationId, by: column };
        if (start !== undefined) {
            param.start = moment(start).toISOString();
        }
        if (end !== undefined) {
            param.end = moment(end).toISOString();
        }
        if (roomName !== undefined) {
            param.room_name = roomName!;
        }
        return this.fetchMetricsRoot().pipe(
            mergeMap(root => halGet<AggregateData>("fetchAggregateMetrics",
                root.traversal!.continue().newRequest()
                    .follow("meetings_aggregate")
                    .withTemplateParameters(param)))
        );
    }

    public adminApiForRoom(room: HalResource<Room>): Observable<RoomAdminApi> {
        if (room.admin_api_url && room.admin_api_url.length > 0) {

            return halGet<HalResult<{}>>("adminApiForRoom", traverson.from(room.admin_api_url).jsonHal())
                .pipe(
                    mergeMap(root => of(new RoomAdminApi(root)))
                );
        }
        const message = 'No Admin API Available to fetch logs'
        const err: ApiError = {
            message: message,
            report: {
                api_error_code: 'no_admin_api',
                title: message,
                detail: `The Administration API for room ${room.name} is not available. Check the Admininstration Agent is configured and running`,
                status: 409,
                type: 'ipfx.com',
                instance: room._links.self.href,
            },
            causedBy: new Error(message)
        }
        return throwError(err)
    }

    public updateRoom(prevRoom: HalResult<Room>, updatedRoom: Room) {

        const diff = compare(prevRoom.resource, updatedRoom);

        return this.fetchRoot().pipe(
            mergeMap(root => halGetUrl("updateRoom", prevRoom.traversal!.continue().newRequest().follow("self"))),
            mergeMap(url => patch<Room>(url, JSON.stringify(diff), r => r.json()))
        );
    }

    public updateComponent(room: HalResult<Room>, component: HalResource<Component>, updateComponent: Component) {

        const diff = compare(component, updateComponent);
        const targetHref = component._links.self.href
        const componentLink = Object.keys(room.resource._links).map(rel => ({...room.resource._links[rel], rel})).find(link => link.href === targetHref)

        console.log('diff', diff)
        console.log('targetHref', targetHref)
        console.log('componentLink', componentLink)

        return this.fetchRoot().pipe(
            mergeMap(root => halGetUrl("updateComponent", room.traversal!.continue().newRequest().follow(componentLink!.rel))),
            mergeMap(url => patch(url, JSON.stringify(diff), r => r.json()))
        );
    }

    public fetchSettingsCategory(application: string, category: string) {
        return this.fetchRoot().pipe(
            mergeMap(root => halGet<SettingsCategory>("fetchSettingsCategory",
                root.traversal!.continue().newRequest()
                    .follow("settings")
                    .withTemplateParameters({ application, category })
            )));
    }

    public addSetting(application: string, category: string, name: string, value: any) {
        return this.patchSetting(application, category, name, value, "add");
    }

    public updateSetting(application: string, category: string, name: string, value: any) {
        return this.patchSetting(application, category, name, value, "replace");
    }

    public fetchUserInfo() {
        const url = `${this.serverApiRoot}/user_info`;
        return get<UserInfo>(url, resp => resp.json()).pipe(
            switchMap(a => of(a))
        );
    }

    public fetchFail() {
        const url = `${this.serverApiRoot}/fail`;
        return get<any>(url, resp => resp.json())
    }

    public fetchHealth() {
        const url = `${this.serverApiRoot}/health`;
        return get<HealthReport>(url, resp => resp.json()).pipe()
    }

    public patchSetting(application: string, category: string, name: string, value: any, op: string) {

        const operation = {
            op,
            path: `/${name}`,
            value
        };

        return this.fetchRoot().pipe(
            mergeMap(root => halPatch<SettingsCategory>("patchSetting",
                root.traversal!.continue().newRequest()
                    .follow("settings")
                    .withTemplateParameters({ application, category })
                , operation)));
    }

    public fetchEscalationsReport(organisationId: IdType, startRange: Date, endRange: Date, tag: string) {
        return this.fetchRoot().pipe(
            mergeMap(root => halArrayGet<EscalationReport>(
                "fetchEscalationsReport",
                root
                    .traversal!.continue().newRequest()
                    .follow("escalations_report")
                    .withTemplateParameters({ organisationId, StartPeriod: startRange.toISOString(), EndPeriod: endRange.toISOString(), Tag: tag })
            ))
        );
    }

    public fetchSecurityReport(startRange: Date, endRange: Date) {
        return this.fetchRoot().pipe(
            mergeMap(root => halArrayGet<SecurityReport>(
                "fetchSecurityReport",
                root
                    .traversal!.continue().newRequest()
                    .follow("security_report")
                    .withTemplateParameters({ StartPeriod: startRange.toISOString(), EndPeriod: endRange.toISOString() })
            ))
        );
    }

    public fetchSecurityReportCsv(startRange: Date, endRange: Date) {
        return this.fetchRoot().pipe(
            mergeMap(root => 
                halGetUrl("fetchSecurityReportCsv",
                root
                    .traversal!
                    .continue()
                    .newRequest()
                    .follow("security_report_csv")
                    .withTemplateParameters({ StartPeriod: startRange.toISOString(), EndPeriod: endRange.toISOString() }))
            ),
            mergeMap(url => getBlob(url))
            
        ).toPromise();
    }

    public fetchSecurityUserReport(startRange: Date, endRange: Date, tag: string) {
        return this.fetchRoot().pipe(
            mergeMap(root => halArrayGet<SecurityUserReport>(
                "fetchSecurityUserReport",
                root
                    .traversal!.continue().newRequest()
                    .follow("security_report_for_user")
                    .withTemplateParameters({ StartPeriod: startRange.toISOString(), EndPeriod: endRange.toISOString(), Tag: tag })
            ))
        );
    }

    public fetchSecurityUserReportCsv(startRange: Date, endRange: Date, tag: string) {
        return this.fetchRoot().pipe(
            mergeMap(root => 
                halGetUrl("fetchSecurityUserReportCsv",
                root
                    .traversal!
                    .continue()
                    .newRequest()
                    .follow("security_user_report_csv")
                    .withTemplateParameters({ StartPeriod: startRange.toISOString(), EndPeriod: endRange.toISOString(), Tag: tag }))
            ),
            mergeMap(url => getBlob(url))
            
        ).toPromise();
    }

    public fetchReportingRoomGroups(organisationId: IdType) {
        return this.fetchRoot().pipe(
            mergeMap(root => halArrayGet<Tag>("fetchReportingRoomGroups",
                root.traversal!.continue().newRequest()
                    .follow("reporting_room_groups")
                    .withTemplateParameters({ organisationId })
            ))
        );
    }
}
function postData(token: string, url: string, additionalHeaders: any, data: FormData, arg4: (resp: Response) => Promise<any>) {
    throw new Error('Function not implemented.');
}

