import { Button, Flex, Form, FormLabel } from '@fluentui/react-northstar';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { BookItAttribute } from '../../model';
import FormButtons from '../controls/FormButtons';
import { FormikNorthstarInput } from '../controls/FormikNorthstarInput';
import './AddAttribute.scss';

export interface CreateAttribute {
    name: string;
    file?: File;
}

interface Props {
    initial?: BookItAttribute
    onSubmit: (attribute: CreateAttribute) => void
    onCancel: () => void
    onDelete: () => void
    existingAttributes: string[]
}

const AddAttribute: React.FC<Props> = (props) => {
    const { initial, onSubmit, onCancel, onDelete, existingAttributes } = props

    const formSchema = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .required('Must provide a name')
            .notOneOf(existingAttributes, 'Attribute already exists'),
        }), [existingAttributes])

    const handleSubmit = (values: CreateAttribute) => {
        onSubmit(values)
    }

    const handleDelete = () => {
        onDelete();
    }

    const handleCancel = () => {
        onCancel()
    }

    return <Flex column gap="gap.medium" fill className="AddAttribute">
        <Formik
            initialValues={initial || { name: '', file: undefined }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}>
            {formik => <Form styles={{ width: '350px' }} onSubmit={e => formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)}>
                <Flex gap='gap.medium' fill column>
                    <FormikNorthstarInput fluid label="Attribute Name" name="name" />
                    
                    <FormLabel htmlFor="file" >Upload Icon</FormLabel>
                    <input
                        id="file"
                        type="file"
                        name="file"
                        onChange={e => {
                            const file = e.target.files![0];
                            const name = e.target.name
                            formik.setFieldValue(name, file, false);
                            formik.setFieldTouched(name, true, false);
                        }}
                    />
                    <Flex gap="gap.large">
                        <Button className="deleteButton" content="Delete" onClick={handleDelete} />
                        <FormButtons onCancel={handleCancel} />
                        {/* <FormikDebug/> */}
                    </Flex>
                </Flex>
            </Form>
            }
        </Formik>

    </Flex>
}


export default AddAttribute;