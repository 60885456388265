import { Loader, Button, DownloadIcon } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTable, { Column } from "react-table";
import moment from "moment";
import services from "../../services";
import { fetchSecurityUserReport } from "../../store/actions";
import { getSecurityUserReport, getSecurityUserReportIsLoading } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { ReportFilter } from "../admin/SecurityReportPage";
import { fileDownloadFromBlob } from "../../utils/fileDownload";

import './Reports.scss';

function localeDay(dateToDisplay: Date) {
    return moment(dateToDisplay).add(moment(dateToDisplay).utcOffset(), 'minutes').format('Do MMM')
}

function localeTime(dateToDisplay: Date) {
    return moment(dateToDisplay).add(moment(dateToDisplay).utcOffset(), 'minutes').format('h:mm a')
}

export function secondsToDhm(seconds: number) {
    const minutes = seconds / 60

    const d = Math.floor(minutes / (60*24));
    const h = Math.floor(minutes % (60*24) / 60);
    const m = Math.floor(minutes % 60);

    const dDisplay = d > 0 ? d + "d " : "";
    const hDisplay = h > 0 ? h + "hr "  : "";
    const mDisplay = m > 0 ? m + "m " : "";

    return dDisplay + hDisplay + mDisplay;
}

const columns: Column[] = [{
    Header: 'Date',
    headerStyle: {
        textAlign: 'left',
    },
    columns: [{
        minWidth: 200,
        accessor: "action_time",
        headerClassName: 'organiserHeader',
        Cell: (cell) => {
            return (
                <div>
                    <div>{localeDay(cell.value)} {localeTime(cell.value)}</div>
                </div>
            )
        },
    }]
},   
    
{
    Header: "Action",
    minWidth: 100,    
    headerStyle: {
        borderLeft: '1px solid #aaa'
    },
    columns: [{
        minWidth: 100,
        accessor: "action_type",       
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #aaa'
        },
    }
]},
{
    Header: "Additional Information",
    minWidth: 300,    
    headerStyle: {
        borderLeft: '1px solid #aaa'
    },
    columns: [{
        minWidth: 300,
        accessor: "additional_info",       
        defaultSortDesc: true,
        headerStyle: {
            borderLeft: '1px solid #aaa'
        }
    }
]}
]

 // Export the dataset as a CSV file
 async function doExport(startDate: Date, endDate: Date, tag: string) {
                
    //setIsReportRunning(true)

    try {

        const {data,fileName} = await services.serverApi.fetchSecurityUserReportCsv(startDate!, endDate!, tag)

        fileDownloadFromBlob(data, fileName ?? 'organisations_security_user_report.csv')

    } catch (e) {
        console.error('doExport', e)
    }

    //setIsReportRunning(false)
}

export interface SecurityUserReportProps {
    filter: ReportFilter
    userAccount: string
}

function SecurityUserReport(props: SecurityUserReportProps) {
    const dispatch = useDispatch()
    //const activeOrganisation = useSelector(getActiveOrganisation)

    useEffect(() => {
        dispatch(fetchSecurityUserReport.request({ startRange: props.filter.startDate?.toDate()!, endRange: props.filter.endDate?.toDate()!, tag: props.userAccount}))
    }, [dispatch, props.filter.endDate, props.filter.startDate, props.userAccount])

    const data = useSelector(getSecurityUserReport);
    const isLoading = useSelector(getSecurityUserReportIsLoading);

    const [expandedList, setExpandedList] = useState({})

    if (isLoading) {
        return <Loader label="Loading Security Report" />
    } else if (data === undefined || data.length === 0) {
        return <div>No data to show. Please change your filters</div>
    } else if (data !== undefined && data.length > 0) {

        console.log('Security report: ', data);
        return (
            <div className="reportContainer">
                <div className="reportSection">
                    <h2>Security Report for {props.userAccount}</h2>
                    {<Button primary content='Export as CSV' 
                            onClick={() => { doExport(props.filter.startDate?.toDate()!, props.filter.endDate?.toDate()!, props.userAccount) }} 
                            icon={<DownloadIcon />} 
                            iconPosition='before' 
                        />
                    }
                    <ReactTable
                        className="-striped -highlight"
                        showPagination={false}
                        key={data.length === 0 ? "nodata" : "havedata"}
                        defaultPageSize={data.length === 0 ? 0 : data.length}
                        columns={columns}
                        //defaultSorted={([{ id: "room_name", desc: false }])}
                        data={data}
                        loadingText={<Loader label='Loading...' />}
                        loading={isLoading}
                        noDataText={<span>...</span>}
                        collapseOnDataChange={false}
                        
                    />
                </div>
            </div>
        )
    } else {
        return <div></div>
    }
}

export default SecurityUserReport