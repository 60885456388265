import { Flex, ShorthandValue, SvgIconProps, Text, ToolbarItemIconProps } from "@fluentui/react-northstar";
import * as React from "react";
import { SingleItemToolbar } from "../../controls/ToolbarWithTooltips";

type ActionIconType =  React.FC<React.HTMLAttributes<HTMLSpanElement> & SvgIconProps> & {
    handledProps: ("size" | "disabled" | "aria-label" | "className" | "rotate" | "outline" | "alt" | "bordered" | "circular" | "design" | "styles" | "variables" | "xSpacing")[];
}

interface Props {
    label: string
    content?: React.ReactNode
    actionIcon?:  ShorthandValue<ToolbarItemIconProps>
    actionOnClick?: () => void
    canEdit?: boolean
}

export const RoomPropertyField: React.FC<Props> = (props) => {
    const { label, content, actionIcon, actionOnClick, canEdit } = props
     const disabled = canEdit !== undefined && !canEdit
    return (
        <Flex column>
            <Flex vAlign='center'>
                <Text size={'medium'} weight={'bold'} as="div">
                    {label}
                </Text>
                {actionIcon && actionOnClick && <SingleItemToolbar icon={actionIcon} onClick={actionOnClick!} disabled={disabled} key={label}/>}
            </Flex>
            <Text as="div" styles={{marginBottom: '0.5rem'}}>
                {content || 'N/A'}
            </Text>
        </Flex>)
}



