
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { getLoggedInUserRoles } from '../../store/selectors';
import { useSelector } from '../../store/utils';

interface Props {
  link: string
  iconName?: string
  icon?: ReactNode
  title: string,
  rolesRequired?: string[]
}

const NavItem: React.FC<Props> = ({ link, iconName, icon, title, rolesRequired }) => {
  const roles = useSelector(getLoggedInUserRoles)
  if (rolesRequired && !rolesRequired.some(reqRole => roles.includes(reqRole))) {
    return null;
  }
  return (
    <li className="nav-item">
      <NavLink to={link} replace className="nav-link">
        {iconName && <i className="feather material-icons">{iconName}</i>}
        {icon === undefined ? null : icon}
        <span>{title}</span>
      </NavLink>
    </li>
  )
}

export default NavItem;