import { EditIcon, Flex, Loader, Toolbar, TrashCanIcon,  Checkbox, Button, AddIcon, Dialog } from "@fluentui/react-northstar";
import { Organisation, GenericReportSchedule } from "../../model";
import { compare } from "fast-json-patch";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTable, { CellInfo } from "react-table";
import { getActiveOrganisation, getOrganisation } from "../../store/selectors";
import { useSelector } from '../../store/utils';
import { fetchOrganisation, patchOrganisation } from '../../store/resourceActions';
import './GenericReportScheduler.scss';
import GenericReportSchedulerEditor from "./GenericReportSchedulerEditor";
import ConfirmationDialog from "../controls/ConfirmationDialog";
import { ApiResult } from '../../store/reducers/ApiResult';

function GenericReportScheduler() {

    type OrganisationEditModel = Partial<Pick<Organisation, "settings">>

    const dispatch = useDispatch()
    const activeOrganisation = useSelector(getActiveOrganisation)
    const organisation = useSelector(s => getOrganisation(s, activeOrganisation.organisation_id))
    const { userInfo } = useSelector(s => s.app)
    const currentUser = ApiResult.getValueOrThrow(userInfo)


    useEffect(() => {
        if (organisation === undefined) {
            dispatch(fetchOrganisation.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation.organisation_id, dispatch, organisation])

    
   
    const [addFormOpen, setAddFormOpen] = useState(false)
    const [reportToDelete, setReportToDelete] = useState<GenericReportSchedule>()
    
    const handleConfirmDelete = (cellInfo: CellInfo) => {    
        const report: GenericReportSchedule = cellInfo.original;   
        setReportToDelete(report)
        setCurrentReportIndex(cellInfo.index);
    }

    const [reportToEdit, setReportToEdit] = useState<GenericReportSchedule>()
    const [currentReportIndex, setCurrentReportIndex] = useState(-1);
    
    const handleReportEdit = (cellInfo: CellInfo) => {
        const report: GenericReportSchedule = cellInfo.original;
        setReportToEdit(report);
        setCurrentReportIndex(cellInfo.index);
        setAddFormOpen(true);
    }
    
    const handleAddUpdateDeleteReport = useCallback((report: GenericReportSchedule) => {
      
        let updatedOrganisation = {
            ...organisation
        };

        if (currentReportIndex >= 0) { // will be -1 if no current report selected 
            if (reportToDelete) {
                // It's a delete
                updatedOrganisation.settings!.scheduled_generic_report_settings.splice(currentReportIndex, 1);
            } else {
                // It's an update
                updatedOrganisation.settings!.scheduled_generic_report_settings[currentReportIndex] = report;
            }
        } else {
            // It's an add (First time?)
            if (!updatedOrganisation.settings!.scheduled_generic_report_settings) {
                updatedOrganisation.settings!.scheduled_generic_report_settings = [];                
            }

            report.owner_mailbox = currentUser.user.email_address;
            updatedOrganisation.settings!.scheduled_generic_report_settings.push(report);
        }

        const { ...initial } = organisation 
        
        // A dummy comparison to generate a diff object
        let diff = compare(organisation!, organisation!);
        
        // Regardles of what we are doing, it's always a replace
        diff = [...diff, {
            op: 'replace',
            path: '/settings',
            value: {...updatedOrganisation.settings}
        }]        

        dispatch(patchOrganisation.request({ id: initial, operations: diff }))
        setAddFormOpen(false)
        setReportToDelete(undefined);
        setReportToEdit(undefined)
        setCurrentReportIndex(-1);
        
    }, [currentReportIndex, currentUser.user.email_address, dispatch, organisation, reportToDelete])


    const renderActions = (cellInfo: CellInfo) => {
                
        return (<Toolbar className='right-align'
            items={[{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: 'Edit report schedule',
                onClick: () => handleReportEdit(cellInfo)
            }, {
                key: 'delete',
                icon: <TrashCanIcon outline/>,
                tooltip: 'Delete this report schedule',
                onClick: () => handleConfirmDelete(cellInfo)
            }]}
        />)
    }  

    function renderToggle(cellInfo: CellInfo, column: any) {
        return <Checkbox checked={cellInfo.value} toggle disabled={true} />
    }

    const isCreating = false;
    const addReportButton = <Button primary content='Add Report' icon={<AddIcon />} iconPosition='before' disabled={isCreating} styles={{ marginBottom: '0.5rem' }} />
    
    function fetchOrCreateReportInstance() : GenericReportSchedule {
        
        if (!reportToEdit) {           
        
            setReportToEdit({due_time: '8:00', 
                            exclude_weekends: false, 
                            is_enabled: false, 
                            working_hours_only: false,  
                            location: 'All',
                        report_name: 'Occupancy',
                    });
            setCurrentReportIndex(-1);
        }

        return reportToEdit!;
    }

    const addUpdateReportContent = isCreating
        ? <Flex hAlign='end'>{addReportButton}</Flex>
        : <Flex hAlign='end'>
            <Dialog
                content={<GenericReportSchedulerEditor
                    report={fetchOrCreateReportInstance()}
                    default_mailbox={currentUser.user.email_address}
                    onSubmit={handleAddUpdateDeleteReport}
                    onCancel={() => {
                        setReportToEdit(undefined)
                        setCurrentReportIndex(-1)
                        setAddFormOpen(false)
                    }} />}
                header={reportToEdit === undefined ? "Add a new Report Schedule" : "Edit a Report Schedule"}
                open={addFormOpen}
                onOpen={() => setAddFormOpen(true)}
                trigger={addReportButton}
            />
        </Flex>

    return <Flex column>
    

        <h5>Report Scheduler</h5>
        {addUpdateReportContent}
        <ReactTable<GenericReportSchedule>
            className="-highlight"
            //getTdProps={getTdProps}
            showPagination={false}
            columns={[
                { Header: "Name", accessor: "schedule_name", minWidth:120 },
                { Header: "Owner", accessor: "owner_mailbox", minWidth:120 },
                { Header: "Report", accessor: "report_name", minWidth:120 },
                { Header: "Period", accessor: "reporting_period" },
                { Header: "Location", accessor: "location" },                        
                { Header: "Resource type", accessor: "resource_type"},
                { Header: "", accessor: "is_enabled", className: 'right-align', Cell: renderToggle, maxWidth:60 },                    
                { Header: "", id: "edit_delete", Cell: renderActions, maxWidth:80 }
            ]}
            defaultSorted={([{ id: "report_name", desc: false }])}
            data={organisation!.settings!.scheduled_generic_report_settings}
            //noDataText={failed ? <FailedRetry onRetry={handleRetry} /> : "No data available"}
            loadingText={<Loader label='Loading...' />}
            //loading={isLoading}
        />

        <ConfirmationDialog onConfirm={() => handleAddUpdateDeleteReport(reportToDelete!)}
            onCancel={() => setAddFormOpen(false)}
            message={`Are you sure you wish to delete the report schedule for ${(reportToDelete ? `${reportToDelete!.report_name} due ${reportToDelete.reporting_period} at ${reportToDelete.due_time}` : "")}?`}
            heading='Delete Report Schedule'
            confirmButtonText='Delete'
            isOpen={reportToDelete !== undefined} />

        </Flex>

    
}

export default GenericReportScheduler;
