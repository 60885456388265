
import { all } from "async-saga";
import { Exclusions } from "../../model";
import services from "../../services";
import { getApiError } from "../../utils/error";
import { actions } from "../types";
import { takeEvery } from "./async-saga";


const patchUcSiteExclusionsSage = takeEvery(actions.patchUcSiteExclusions.request, async function (ctx, {organisation_id, site_id, diff}) {
    const api = services.apiClient
    if (api === null) return
    
    try {
        const res = await api.patch<Exclusions>(`/organisations/${organisation_id}/ucsites/${site_id}/exclusions`, diff)
        ctx.dispatch(actions.patchUcSiteExclusions.success({organisation_id, site_id, exclusions: res}))
        
    } catch (e) {
        console.error("reloadOrganisationSaga: ", e)
        ctx.dispatch(actions.patchUcSiteExclusions.failure(await getApiError(e)))
    }
})


export default all(
    patchUcSiteExclusionsSage
)