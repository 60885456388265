import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import services from '.';


export class SignalRHub {
    public connection: signalR.HubConnection
    private subjects: { [name: string]: Subject<any> };
    private error$: Subject<Error>;
    private reconnecting: boolean;
    private retries: number;

    constructor(url: string, protocol?: signalR.IHubProtocol) {
        this.subjects = {};
        this.error$ = new Subject<Error>();
        this.retries = 0;
        this.reconnecting = false;
        const prot = protocol ?? new signalR.JsonHubProtocol()

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(url, {
                accessTokenFactory: async () => {
                    const client = services.ipfxTokenClient
                    if (client === null) throw Error("IPFX token client not configured") 
                    const token = await client.getAccessToken()
                    return token
                }
            })
            .withHubProtocol(prot)
            .configureLogging(process.env.NODE_ENV === 'development' ? signalR.LogLevel.Information : signalR.LogLevel.Error)
            .build();


        this.connection.onclose(err => {
            this.log('error', 'connection closed', err);
            this.error$.next(err);
            this.reconnect();
        });
    }

    public get error() {
        return this.error$.asObservable();
    }

    public start(checkSubs: boolean = true) {
        if (checkSubs && !this.hasSubscriptions()) {
            this.log('warn', 'No listeners have been setup. You need to setup a listener before starting the connection or you will not receive data.');
        }
        return this.connection.start();
    }

    public invoke(methodName: string, arg1: any, arg2: any, arg3: any) {
        return this.connection.invoke(methodName, arg1, arg2, arg3)
    }

    public on<T, T1, T2, T3>(event: string, resolve: (...args:any[])=> T): Observable<T> {
        const subject = this.getOrCreateSubject<T>(event);
        this.connection.on(event, (...args: any[]) => {
            const data = resolve(...args);
            const logRoomChangeEvents = (window as any).logRoomChangeEvents
            if (logRoomChangeEvents) {
                console.log('roomChange event: ', data);
            }
            subject.next(data);
        })
        return subject.asObservable();
    }


    public hasSubscriptions(): boolean {
        for (const key in this.subjects) {
            if (this.subjects.hasOwnProperty(key)) {
                return true;
            }
        }

        return false;
    }

    private getOrCreateSubject<T>(event: string): Subject<T> {
        return this.subjects[event] || (this.subjects[event] = new Subject<T>());
    }

    private async reconnect() {
        if (this.reconnecting) { return }
        this.reconnecting = true;

        await this.connection.stop().catch(() => undefined);

        try {
            this.log('info', 'connecting...');
            await this.delay(this.backoff(this.retries++));
            await this.connection.start().catch(err => this.error$.next(err))
        } catch (e) {
            this.log('error', 'error connecting', e);
            this.reconnect();
            return;
        }

        this.reconnecting = false;
        this.retries = 0;

        this.log('info', 'reconnected');
    }

    private delay(ms: number) {
        return new Promise<void>(res => {
            window.setTimeout(() => res(), ms);
        })
    }

    private backoff(retries: number) {
        const s = 1000;
        const base = 1 * s;
        const exp = 1.5;
        const max = 30 * s;

        return Math.min(max, base * (Math.pow(exp, retries) - 1));
    }

    private log(type: 'info' | 'warn' | 'error', ...args: any[]) {
        if (process.env.NODE_ENV === 'development') {
            console[type](...args);
        }
    }

}