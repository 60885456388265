import { Button, Dropdown, Flex, Menu, MenuItemProps, Popup } from "@fluentui/react-northstar";
import { endOfDay, startOfDay } from "date-fns";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import useToggle from "../../hooks/useToggle";
import { HeatMapRoomUsageByHour, IdType } from "../../model";
import { fetchBookableResources, fetchLocations } from "../../store/resourceActions";
import { getActiveOrganisation, getBookableResources, getLocations } from "../../store/selectors";
import { useSelector } from '../../store/utils';
import DateSelector, { getDatesOfRange, getRangeToDescripton } from "./DateSelector";
import BookingStatsReport from "./insights/BookingStatsReport";
//import ComparativeReport from "./insights/ComparativeReport";
import HeatMapMetric from './insights/HeatMap';
import Occupancy from "./insights/Occupancy";
import { Summary } from "./insights/Summary";
import './MetricsPage.scss';

const defaultRange = "last7d"

const resourceTypesDictionary = {
    'All': 'All',
    'Meeting Rooms': 'MeetingRoom',
    'Lockers': 'Locker',
    'Car Parks': 'ParkingSpace',
    'Fleet Cars': 'FleetCar',
    'Hot Desks': 'HotDesk',
}

export const resourceTypes = {
    'All': 'All',
    'MeetingRoom': 'Meeting Rooms',
    'Locker': 'Lockers',
    'ParkingSpace': 'Car Parks',
    'FleetCar': 'Fleet Cars',
    'HotDesk': 'Hot Desks',
}

export const colors = {
    'MeetingRoom': '#00b879',
    'Locker': '#dd3e56',
    'ParkingSpace': '#e9a217',
    'FleetCar': '#604ba9',
    'HotDesk': '#9181bd',
}

export interface ReportFilter {
    organisationId: IdType,
    startDate?: Date,
    endDate?: Date,
    resourceType: string,
    location: string,
    isWorkingHoursOnly: boolean,
    excludeWeekends: boolean,
    bookableResource: string,
}

function MetricsPage() {
    const [rangeOpen, toggleRangeOpen] = useToggle(false)

    const initialRange = getDatesOfRange(defaultRange)

    const [startDate, setStartDate] = useState(initialRange.startDate)
    const [endDate, setEndDate] = useState(initialRange.endDate)
    const [selectedRange, setSelectedRange] = useState(defaultRange)
    const [rangeDescription, setRangeDescription] = useState(getRangeToDescripton(defaultRange))
    const [resourceType, setResourceType] = useState("All")
    const [dropdownResourceType, setDropdownResourceType] = useState('All')
    const [location, setLocation] = useState("All")
    const [bookableResource, setBookableResource] = useState("All")
    const [isWorkingHoursOnly, setIsWorkingHoursOnly] = useState(false);
    const [excludeWeekends, setExcludeWeekends] = useState(true);

    const dispatch = useDispatch()
    const isLoadingLocations = useSelector(s => s.bookit.locations.isLoading)
    const apiLocations = useSelector(getLocations)

    const activeOrganisation = useSelector(getActiveOrganisation)
    const [organisationId, setOrganisationId] = useState(activeOrganisation.organisation_id)
    const [orgChanging, setOrgChanging] = useState(false)

    //keep track of organisation changes
    useEffect(() => {
        setOrgChanging(true)
    }, [activeOrganisation.organisation_id])

    //when organisation changes, stall the other reloads triggered by resettin the dropdowns
    useEffect(() => {
        if (orgChanging) {
            if (location !== 'All') {setLocation('All')}
            if (resourceType !== 'All') {setResourceType('All')}
            if (dropdownResourceType !== 'All') {setDropdownResourceType('All')}
            if (bookableResource !== 'All') {setBookableResource('All')}
            
            if (location === 'All' && resourceType === 'All' && dropdownResourceType === 'All' && bookableResource === 'All') {
                setOrgChanging(false)
                setOrganisationId(activeOrganisation.organisation_id)
            }
        }
    }, [activeOrganisation.organisation_id, dropdownResourceType, location, orgChanging, resourceType, bookableResource])

    const locations = useMemo(() => {
        if (isLoadingLocations) return ["All"]
        const locs = apiLocations.map(loc => loc.name).sort()
        return ["All", ...locs]
    }, [apiLocations, isLoadingLocations])

    //only get the locations once per organisation change/dropdown reset
    useEffect(() => { 
        if (!orgChanging && organisationId) {
            dispatch(fetchLocations.request({organisation_id: organisationId})) 
        }
    }, [dispatch, organisationId, orgChanging])

    const locationDictionary = useMemo(() => {
        if (isLoadingLocations) return {}
        const locationDictionary = apiLocations.reduce((acc, currentData) => {
            acc[currentData.name] = currentData.location_id
            return acc
        }, {})
        return locationDictionary
    }, [apiLocations, isLoadingLocations])

    const isLoadingBookableResources = useSelector(s => s.bookit.bookableResources.isLoading)
    const apiBookableResources = useSelector(getBookableResources)
    const bookableResources = useMemo(() => {
        if (isLoadingBookableResources) return ["All"]
        if (location === 'All') {
            if (resourceType === 'All') {
                //return all
                const bookableResourceNames = apiBookableResources.map(a => a.name).sort()
                return ["All", ...bookableResourceNames]
            } else {
                //return if the resource is the same
                const bookableResourceNames = apiBookableResources.flatMap(a => {
                    if (a.sub_type === resourceType) {
                        return a.name
                    } else {
                        return []
                    }
                }).sort()
                return ["All", ...bookableResourceNames]
            }
        } else {
            if (resourceType === 'All') {
                //return all for the location
                if (locationDictionary) {
                    const bookableResourceNames = apiBookableResources.flatMap(a => {
                        if (a.location_id && a.location_id === locationDictionary[location]) {
                            return a.name
                        } else {
                            return []
                        }
                    }).sort()
                    return ["All", ...bookableResourceNames]
                } else {
                    //else the dictionary hasn't been created by useMemo yet
                    return ['All']
                }
            } else {
                //return only the ones for the set location and set resourcetype
                if (locationDictionary) {
                    const bookableResourceNames = apiBookableResources.flatMap(a => {
                        if (a.location_id && a.location_id === locationDictionary[location] && (a.sub_type === resourceType)) {
                            return a.name
                        } else {
                            return []
                        }
                    }).sort()
                    return ["All", ...bookableResourceNames]
                } else {
                    //else the dictionary hasn't been created by useMemo yet
                    return ["All"]
                }
            }
        }
    }, [apiBookableResources, isLoadingBookableResources, location, locationDictionary, resourceType])

    //only get the bookable resources once per organisation change/dropdown reset
    useEffect(() => { 
        if (!orgChanging && organisationId) {
            dispatch(fetchBookableResources.request({organisation_id: organisationId})) 
        }
    }, [dispatch, organisationId, orgChanging])

    const [activeIndex, setActiveIndex] = useState(0)

    const onApplyRange = (sDate: moment.Moment, eDate: moment.Moment, range: string, description: string, toggle: boolean, workingHoursOnly: boolean, excludeWeekends: boolean) => {
        setRangeDescription(description)
        setSelectedRange(range)
        setStartDate(startOfDay(sDate.toDate()))
        setEndDate(endOfDay(eDate.toDate()))
        setIsWorkingHoursOnly(workingHoursOnly)
        setExcludeWeekends(excludeWeekends)
        if (toggle) {
            toggleRangeOpen();
        }
    }

    const filter: ReportFilter = useMemo(() => ({
        organisationId,
        startDate,
        endDate,
        selectedRange,
        resourceType,
        location,
        bookableResource,
        isWorkingHoursOnly,
        excludeWeekends
        }),
        [organisationId, endDate, excludeWeekends, isWorkingHoursOnly, location, resourceType, bookableResource, selectedRange, startDate]
    )

    const openSelector = () => {
        setSelectedRange('yesterday')
        toggleRangeOpen()
        console.log(selectedRange)
    }

    const rangeContent = (
        <Flex column>
            <Flex.Item align="end">
                <Button text iconOnly icon="close" onClick={() => openSelector()} title="Close" />
            </Flex.Item>
            <DateSelector startDate={startDate} endDate={endDate} onApply={onApplyRange} onCancel={toggleRangeOpen}
                selectedRange={selectedRange} onIsWorkingHoursOnlyChanged={() => setIsWorkingHoursOnly(!isWorkingHoursOnly)}
                onExcludeWeekends={() => setExcludeWeekends(!excludeWeekends)} excludeWeekends={excludeWeekends} workingHoursOnly={isWorkingHoursOnly} />
        </Flex>
    )

    const handleMenuItemClick = useCallback((event: React.SyntheticEvent<HTMLElement>, data?: MenuItemProps) => {
        console.log("handleMenuItemClick", event)
        console.log("handleMenuItemClick", data)
        if (data) {
            setActiveIndex(data.index!)
        }
    }
    , [])
    const tabItems = [
        {
            "key": "summary",
            "content": "Summary"
        },
        {
            "key": "heatmap",
            "content": "Heatmap"
        },
        {
            "key": "occupancy",
            "content": "Occupancy"
        },
        /*{
            "key": "comparative",
            "content": "Comparative"
        },*/
        {
            "key": "Statistics",
            "content": "Statistics"
        },
    ]

    //{activeIndex === 3 && <ComparativeReport filter={filter}/> }
    return (
        <Flex className="MetricsPage">
            <h5>Book-It Insights</h5>
            <div className="filters">
                <Popup
                    trapFocus
                    trigger={<Button className="date" primary onClick={() => toggleRangeOpen()}>{rangeDescription}</Button>}
                    content={rangeContent}
                    open={rangeOpen}
                    onOpenChange={() => toggleRangeOpen()}
                />
                <Flex className="buttons">
                    <div>
                        <label>Location</label>
                        <Dropdown
                            items={locations}
                            placeholder="Select a location"
                            value={location}
                            onChange={(e, d) => {
                                if (d) {
                                    setLocation(d.value as string)
                                    if (d.value !== 'All') {
                                        setDropdownResourceType('All')
                                        setResourceType('All')
                                        setBookableResource('All')
                                    }
                                }
                            }}
                        />
                    </div>
                    <div>
                        <label>Resource Type</label>
                        <Dropdown
                            items={Object.entries(resourceTypes).map(([value, text]) => text)}
                            placeholder={"Select a resource type"}
                            value={dropdownResourceType}
                            onChange={(e, d) => {
                                if (d) {
                                    setDropdownResourceType(d.value as string)
                                    setResourceType(resourceTypesDictionary[d.value as string])
                                    setBookableResource('All')
                                }
                            }}
                        />
                    </div>
                    <div>
                        <label>Resource</label>
                        <Dropdown
                            items={bookableResources}
                            placeholder={"Select a resource"}
                            value={bookableResource}
                            onChange={(e, d) => d && setBookableResource(d.value as string)}
                        />
                    </div>

                </Flex>
            </div>

            <Menu defaultActiveIndex={0} 
                activeIndex={activeIndex} 
                onItemClick={handleMenuItemClick}
                items={tabItems} 
                underlined primary
            />

            {activeIndex === 0 && <Summary resourceTypes={resourceTypes} filter={filter} orgChanging={orgChanging} />}
            {activeIndex === 1 && <HeatMapMetric filter={filter} orgChanging={orgChanging} /> }
            {activeIndex === 2 && <Occupancy filter={filter} orgChanging={orgChanging} /> }
            {activeIndex === 3 && <BookingStatsReport filter={filter} orgChanging={orgChanging} /> }
        </Flex>
    )
}

export function toSemiFixed(n: number, maxPlaces: number = 2) {
    const str = n.toString()
    const index = str.indexOf('.')
    if (index > -1 && index + 3 <= str.length) {
        return n.toFixed(maxPlaces)
    } else {
        return str
    }
}

export function minutesToDhm(minutes: number) {
    //minutes = Number(minutes);
    const d = Math.floor(minutes / (60*24));
    const h = Math.floor(minutes % (60*24) / 60);
    const m = Math.floor(minutes % 60);

    const dDisplay = d > 0 ? d + "d " : "";
    const hDisplay = h > 0 ? h + "hr "  : "";
    const mDisplay = m > 0 ? m + "m " : "";

    return dDisplay + hDisplay + mDisplay;
}

export function getTimes(isWorkingHours: boolean) {
    if (isWorkingHours) return Array.from(new Array(10)).map((_, h) => moment.utc(8).add(h + 8, 'h'));
    return Array.from(new Array(24)).map((_, h) => moment.utc(0).add(h, 'h'));
}

export function avg(data: number[]) {
    return data.reduce((a, x) => a + x, 0) / data.length
}

export function getScaledUsage(usage: HeatMapRoomUsageByHour, hour: number, isWorkingHours: boolean): number {
    let data = usage[hour]
    if (isWorkingHours)
        data = usage[hour + 8]

    return data ? data.scaled <= 1 ? data.scaled : 1 : 0
}

export default MetricsPage;