import { Tag, EscalationReport, SecurityReport, SecurityUserReport } from "../../model";
import { getType } from "typesafe-actions";
import { Actions, actions } from "../types";
import { combineReducers } from "redux";

interface ReportingRoomGroups {
    data?: Tag[],
}

interface EscalationsReportMetric {
    data?: EscalationReport[],
    isLoading: boolean,
}

interface SecurityReportMetric {
    data?: SecurityReport[],
    isLoading: boolean,
}

interface SecurityUserReportMetric {
    data?: SecurityUserReport[],
    isLoading: boolean,
}

export function reportingRoomGroups(state: ReportingRoomGroups = { data: [] }, action: Actions) {
    switch (action.type) {
        case getType(actions.fetchReportingRoomGroups.failure):
            return {
                ...state,
                data: undefined,
            }
        case getType(actions.fetchReportingRoomGroups.success):
            return {
                ...state,
                data: action.payload.resources,
            };
        default:
            return state;
    }
}

export function securityReport(state: SecurityReportMetric = { isLoading: false }, action: Actions) {
    switch (action.type) {
        case getType(actions.fetchSecurityReport.request):
            return {
                ...state,
                isLoading: true,
            }
        case getType(actions.fetchSecurityReport.failure):
            return {
                ...state,
                data: undefined,
                isLoading: false,
            }
        case getType(actions.fetchSecurityReport.success):
            return {
                ...state,
                data: action.payload.resources,
                isLoading: false,
            };
        default:
            return state;
    }
}

export function securityUserReport(state: SecurityUserReportMetric = { isLoading: false }, action: Actions) {
    switch (action.type) {
        case getType(actions.fetchSecurityUserReport.request):
            return {
                ...state,
                isLoading: true,
            }
        case getType(actions.fetchSecurityUserReport.failure):
            return {
                ...state,
                data: undefined,
                isLoading: false,
            }
        case getType(actions.fetchSecurityUserReport.success):
            return {
                ...state,
                data: action.payload.resources,
                isLoading: false,
            };
        default:
            return state;
    }
}

export function escalationsReport(state: EscalationsReportMetric = { isLoading: false }, action: Actions) {
    switch (action.type) {
        case getType(actions.fetchEscalationsReport.request):
            return {
                ...state,
                isLoading: true,
            }
        case getType(actions.fetchEscalationsReport.failure):
            return {
                ...state,
                data: undefined,
                isLoading: false,
            }
        case getType(actions.fetchEscalationsReport.success):
            return {
                ...state,
                data: action.payload.resources,
                isLoading: false,
            };
        default:
            return state;
    }
}


export default combineReducers({
    reportingRoomGroups,
    escalationsReport,
    securityReport,
    securityUserReport
})
