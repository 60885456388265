import { ComponentEventHandler, Dialog, DialogProps, Flex, Loader, Text } from '@fluentui/react-northstar';
import React, { useEffect, useState } from 'react';
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { BackgroundOperation, OperationState } from '../../model';
import './BackgroundOperationProgressDialog.scss';

interface BackgroundOperationProgressDialogProps {
    onDismiss: (done: boolean) => void
    onCancel?: () => void
    operation: BackgroundOperation
    renderStatus?: (op: BackgroundOperation) => JSX.Element
    heading: string
    isOpen?: boolean
}
const BackgroundOperationProgressDialog: React.FC<BackgroundOperationProgressDialogProps> 
        = ({ isOpen, onDismiss, onCancel, operation, heading, renderStatus }) => {
    const [open, setOpen] = useState(false)

    const handleDismiss: ComponentEventHandler<DialogProps> = (event, props) => {
        setOpen(false)
        onDismiss(finalState(operation.state))
    }
    const handleCancel: ComponentEventHandler<DialogProps> = (event, props) => {
        setOpen(false)
        if (onCancel) {
            onCancel()
        }
    }

    useEffect(() => {
        if (isOpen !== undefined) {
            setOpen(isOpen)
        }
    }, [isOpen])

    return (
        <Dialog
            styles={{width: '600px !important'}}
            className='BackgroundOperationProgressDialog'
            onConfirm={handleDismiss}
            onCancel={handleCancel}
            open={open}
            onOpen={(e, data) => { if (data && data.open) setOpen(data!.open!) }}
            cancelButton={onCancel ? "Cancel" : undefined}
            confirmButton={finalState(operation.state) ? "Done" : "Dismiss"}
            content={<OperationProgress operation={operation} renderStatus={renderStatus}/>}
            header={heading}
        />
    )
}

const OperationProgress: React.FC<{ operation: BackgroundOperation, renderStatus?: (op: BackgroundOperation) => JSX.Element}> 
        = ({ operation, renderStatus }) => {
    return (<Flex className='OperationProgressContent' column hAlign='center' gap="gap.medium" fill>
        {!finalState(operation.state) && <Loader />}
        {operation.state === 'Complete' && <FaRegCheckCircle color="green" size='3rem'/>}
        {operation.state === 'Failed' && <FaRegTimesCircle  color="red" size='3rem'/>}
        {renderStatus && renderStatus(operation)}
        {renderStatus === undefined && <Text>{operation.status}</Text>}
    </Flex>)
}

function finalState(state: OperationState) {
    switch (state) {
        case 'Cancelled':
        case 'Failed':
        case 'Timeout':
        case 'Complete':
            return true
        default:
            return false
    }
}


export default BackgroundOperationProgressDialog