import { Flex, Form } from "@fluentui/react-northstar";
import { Formik } from "formik";
import * as React from "react";
import { PropsWithChildren } from "react";
import { Schema } from "yup";
import FormButtons from "../controls/FormButtons";
import './ResourceAdminForm.scss';

interface ResourceAdminFormProps<TEditModel> {
    initial: TEditModel
    onSubmit: (edit: TEditModel) => void
    onCancel: () => void
    formSchema: Schema<any>
    width?: string
}

const ResourceAdminForm = <TEditModel extends {}>
    ({ initial, onSubmit, onCancel, formSchema, width, children }: PropsWithChildren<ResourceAdminFormProps<TEditModel>>) => {

    return <Flex className="ResourceAdminForm" column gap="gap.medium" fill>
        <Formik
            initialValues={initial}
            validationSchema={formSchema}
            
            onSubmit={(values, actions) => {
                onSubmit(values)
            }
                }>
            {({ handleSubmit }) => <Form styles={{ width: width ?? '850px' }} onSubmit={e => {
                handleSubmit(e as React.SyntheticEvent<HTMLFormElement, Event>)
            }}>
                <Flex gap='gap.large' fill column>
                    {children}
                    <FormButtons onCancel={onCancel} />
                    {/* <FormikDebug /> */}
                </Flex>
            </Form>
            }
        </Formik>
    </Flex>
}

export default ResourceAdminForm;