import { Loader } from '@fluentui/react-northstar';
import { Operation } from 'fast-json-patch';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bookItSettingsDefaults } from '../../../model';
import { fetchOrganisation, patchOrganisation } from '../../../store/resourceActions';
import { getActiveOrganisation, getOrganisation } from '../../../store/selectors';
import { useSelector } from '../../../store/utils';
import BookItSettingsPage from './BookItSettingsPage';

const OrganisationSettingsPage: React.FC<{}> = () => {
    const dispatch = useDispatch()
    const activeOrganisation = useSelector(getActiveOrganisation)
    const organisation = useSelector(s => getOrganisation(s, activeOrganisation.organisation_id))

    useEffect(() => {
        if (organisation === undefined) {
            dispatch(fetchOrganisation.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation.organisation_id, dispatch, organisation])

    const handleSubmit = useCallback((values) => {
        const patch: Operation[] = [{
            op: 'replace',
            value: values,
            path: '/book_it_settings'
        }]
        dispatch(patchOrganisation.request({ id: { organisation_id: activeOrganisation.organisation_id }, operations: patch }))
    }, [activeOrganisation.organisation_id, dispatch])

    return <>
        <h5>Organisation Settings</h5>
        {organisation === undefined && <Loader/>}
        {organisation !== undefined && <BookItSettingsPage onSave={handleSubmit} source={organisation.book_it_settings ?? bookItSettingsDefaults}/>}
    </>
}



export default OrganisationSettingsPage;
