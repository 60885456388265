import { Checkbox, Flex, Loader, TrashCanIcon, EditIcon } from '@fluentui/react-northstar';
import { push } from 'connected-react-router';
import { compare } from 'fast-json-patch';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTable, { CellInfo, Column, RowInfo } from 'react-table';
import { UcSite } from '../../model';
import { deleteUcSite, fetchUcSites, patchUcSite } from '../../store/resourceActions';
import { getActiveOrganisation } from '../../store/selectors';
import { useSelector } from '../../store/utils';
import { FailedRetry } from '../admin/ResourceAdminPage';
import ConfirmationDialog from '../controls/ConfirmationDialog';
import { ToolbarWithTooltips } from '../controls/ToolbarWithTooltips';
import css from './SitesList.module.scss';
import moment from "moment";

export function SitesList() {
    
    const activeOrganisation = useSelector(getActiveOrganisation)

    const dispatch = useDispatch()
    useEffect(() => {
        if (activeOrganisation) {
            dispatch(fetchUcSites.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch])

    const { isLoading, failed, resources } = useSelector(s => s.uc.sites)
    const sites = useMemo(() => Object.values(resources), [resources])

    const handleRetry = useCallback(() => {
        if (activeOrganisation) {
            dispatch(fetchUcSites.request({ organisation_id: activeOrganisation.organisation_id }))
        }
    }, [activeOrganisation, dispatch])


    const [ucSiteToDelete, setUcSiteToDelete] = useState<UcSite>()
    const handleConfirmDelete = (site: UcSite) => {
        setUcSiteToDelete(site)
        console.log('handleConfirmDelete', site)
    }

    const handleUcSiteDelete = () => {
        dispatch(deleteUcSite.request(ucSiteToDelete!))
        setUcSiteToDelete(undefined)
    }

    function onActiveChanged(site: UcSite) {
        const updatedSite = { ...site, is_enabled: !site.is_enabled }
        const diff = compare(site, updatedSite)
        dispatch(patchUcSite.request({ id: site, operations: diff }))
    }

    function renderToggle(cellInfo: CellInfo, column: any) {
        const room = cellInfo.original
        return <Checkbox checked={cellInfo.value} toggle onChange={() => onActiveChanged(room)} />
    }

    const renderLastSeen = (cellInfo: CellInfo) => {
        if (cellInfo.value) {
            const time = moment.utc(cellInfo.value).local()
            return <span>{time.format('MMM Do YYYY, h:mm:ss a')}</span>
        }
        return null
    }

    function getTdProps(finalState: any, rowInfo?: RowInfo, column?: Column, instance?: any): object {
        return {
            onClick: (e: React.MouseEvent<HTMLElement>, handleOriginal: () => void) => {
                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                    handleOriginal()
                }
                if (rowInfo !== undefined) {
                    const to = `/uc/sites/${rowInfo.row._original.site_id}`
                    dispatch(push(to))
                }
            }
        }
    }
    const handleEdit = (rowInfo?: RowInfo) => {
        if (rowInfo !== undefined) {
            const to = `/uc/sites/${rowInfo.row._original.site_id}`
            dispatch(push(to))
        }
    }

    const renderDeleteCell = (cellInfo: CellInfo) => {
        return (<ToolbarWithTooltips className='right-align'
            items={[{
                key: 'delete',
                icon: <TrashCanIcon outline />,
                tooltip: 'Delete the site',
                onClick: () => handleConfirmDelete(cellInfo.original)
            }]}
        />)
    }

    const renderEditCell = (cellInfo: CellInfo) => {
        return (<ToolbarWithTooltips className='right-align'
            items={[{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: 'Edit the site',
                onClick: () => handleEdit(cellInfo)
            }]}
        />)
    }

    // taken from ReactTable props to go to edit page when clicked anywhere in line, but obscured delete getTdProps={getTdProps} 
    return <Flex column className={css.siteList}>
        <ReactTable<UcSite>
            className="-highlight"
            showPagination={false}
            defaultPageSize={sites.length === 0 ? 0 : sites.length}
            columns={[
                { Header: "Name", accessor: "display_name", minWidth:120 },
                { Header: "Server", accessor: "server_name", minWidth:60 },
                { Header: "Group", accessor: "server_group", minWidth:60 },
                { Header: "Customer Name", accessor: "customer_id", minWidth:60 },
                { Header: "Site", accessor: "customer_name", minWidth:120 },
                { Header: "Site Id", accessor: "site_id" },
                { Header: "Last seen", accessor: "last_activity_utc", Cell: renderLastSeen},
                { Header: "Events #", accessor: "last_event_count", minWidth:40},
                // { Header: "", accessor: "is_enabled", className: 'right-align', Cell: renderToggle, maxWidth:60 },
                { Header: "", id: "edit", Cell: renderEditCell, maxWidth:40 },
                { Header: "", id: "delete", Cell: renderDeleteCell, maxWidth:40 }
            ]}
            defaultSorted={([{ id: "server_name", desc: false }])}
            data={sites}
            noDataText={failed ? <FailedRetry onRetry={handleRetry} /> : "No data available"}
            loadingText={<Loader label='Loading...' />}
            loading={isLoading}
        />

        {ucSiteToDelete && <ConfirmationDialog onConfirm={handleUcSiteDelete}
            onCancel={() => setUcSiteToDelete(undefined)}
            message={`Are you sure you wish to delete the UC Site ${ucSiteToDelete!.display_name}?`}
            heading='Delete UC Site'
            confirmButtonText='Delete'
            isOpen={ucSiteToDelete !== undefined} />}
    </Flex>
}

