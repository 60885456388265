import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { EscalationRule } from "../../model";
import { escalationRulesReducer } from "../resourceActions";
import { actions, Actions } from "../types";
import { ApiResourceState, defaultState } from "./ApiResourceState";

function escalationRules(state: ApiResourceState<EscalationRule> = defaultState, action: Actions): ApiResourceState<EscalationRule> {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return escalationRulesReducer(state, action);
    }
}

export default combineReducers({
    escalationRules
})
