import { Loader } from "@fluentui/react-northstar";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { FaCheckCircle, FaStopCircle, FaTimesCircle } from 'react-icons/fa';
import { useDispatch } from "react-redux";
import ReactTable, { CellInfo } from "react-table";
import { UncontrolledTooltip } from "reactstrap";
import { fetchHealth } from "../../store/actions";
import { RoomsHealth, RoomStatus } from "../../store/reducers/health";
import { useSelector } from "../../store/utils";
import './RoomsHealthPage.css';

const RunningStatusIcon: React.FC<{ name: string, data: RoomStatus }> = (props) => {
    const id = `running-${props.name}`
    return (
        <div>
            {(props.data.health === "Unhealthy")
                ? <FaTimesCircle color="red" size="2em" id={id} />
                : props.data.status === "Running" ? <FaCheckCircle color="green" size="2em" id={id} /> : <FaStopCircle color="red" size="2em" id={id} />}
            <UncontrolledTooltip placement="auto-end" target={id}>{props.data.status}</UncontrolledTooltip>
        </div>
    )
}
const TunnelStatusIcon: React.FC<{ name: string, data?: RoomStatus }> = (props) => {
    const id = `tunnel-${props.name}`

    if (!props.data) {
        return null
    }
    return (<div>
        {(props.data.health === "Healthy")
            ? <FaCheckCircle color="green" size="2em" id={id} />
            : <FaTimesCircle color="red" size="2em" id={id} />}
        <UncontrolledTooltip placement="auto-end" target={id}>{props.data.status}</UncontrolledTooltip>
    </div>)
}

export const RoomsHealthPage: React.FC<{}> = (props) => {

    const dispatch = useDispatch()

    const health = useSelector(s => s.health.roomHealth)
    const isLoading = useSelector(s => s.health.isLoading)

    useEffect(() => {
        if (health === undefined) {
            dispatch(fetchHealth.request())
        }
    })

    const cols = React.useMemo(() => [
        { Header: "Name", accessor: "name", maxWidth: 200 },
        { Header: "Room", id: "roomRunning", maxWidth: 140, Cell: (cell: CellInfo) => { console.log("cell:", cell); return <RunningStatusIcon name={`${cell.original.name}-room`} data={cell.original.data.running.room} /> } },
        { Header: "Admin Agent", id: "adminRunning", maxWidth: 140, Cell: (cell: CellInfo) => <RunningStatusIcon name={`${cell.original.name}-admin`} data={cell.original.data.running.admin} /> },
        { Header: "Room Tunnel", id: "roomTunnel", maxWidth: 140, Cell: (cell: CellInfo) => <TunnelStatusIcon name={`${cell.original.name}-room`} data={cell.original.data.tunnel ? cell.original.data.tunnel.room : undefined} /> },
        { Header: "Admin Tunnel", id: "adminTunnel", maxWidth: 140, Cell: (cell: CellInfo) => <TunnelStatusIcon name={`${cell.original.name}-admin`} data={cell.original.data.tunnel ? cell.original.data.tunnel.admin : undefined} /> },
    ], [])

    const data = useMemo(() => getData(health), [health])
    console.log("data", data)

    const pageSize = useMemo(() => data === undefined || data.length === 0 ? 15 : data.length, [data])
    console.log("pageSize", pageSize)

    return <>
        <h5>Rooms Health</h5>
        <ReactTable
            className="-highlight"
            key={data === undefined || data.length === 0 ? "nodata" : "havedata"}
            showPagination={false}
            defaultPageSize={pageSize}
            columns={cols}
            data={data}
            noDataText="No data found"
            loadingText={<Loader label='Performing Room Tests...'/>}
            loading={isLoading}
        />
    </>;
}

function getData(health?: RoomsHealth) {
    if (!health) {
        return []
    }

    return Object.keys(health)
        .map(name => ({ name, data: health[name] }))
}


