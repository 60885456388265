import { Flex } from '@fluentui/react-northstar';
import React from 'react';
import GraphApiApp from './GraphApiApp.js';
import './GraphApiPage.scss';

const GraphApiPage: React.FC<{}> = (props) => {
    
    return <Flex column gap="gap.medium" className='GraphApiPage' >
        <h5>Graph API</h5>
        <GraphApiApp/>
    </Flex>
}
export default GraphApiPage;