import { AzureAdClient } from '../auth/azure-ad';
import { IpfxTokenClient } from '../auth/ipfxTokenClient';
import { ApiClient } from './api';
import { BookItApi } from './BookItApi';
import Config from './Config';
import { ServerApi } from './ServerApi';
import { SignalRHub } from './SignalRHub';

// tslint:disable-next-line:no-console
const logger = { log: console.log };
export interface Services {
  logger: any,
  serverApi: ServerApi,
  bookItApi: BookItApi,
  serverNotificationHub: SignalRHub,
  pingHub?: SignalRHub,

  // following all have complex dependencies so creation is defered until
  // app root action is deployed.
  apiClient: ApiClient|null,
  azureAdClient: AzureAdClient|null,
  ipfxTokenClient: IpfxTokenClient|null

  
}
const services: Services = {
  logger,
  serverApi: new ServerApi(Config.serverApiRoot),
  bookItApi: new BookItApi(Config.serverApiRoot),
  serverNotificationHub: new SignalRHub(`${Config.serverApiRoot}/notifications`),
  apiClient: null,
  azureAdClient: null,
  ipfxTokenClient: null
}


 export default services;