import { Button, ButtonProps, Dropdown, RadioGroup } from "@fluentui/react-northstar";
import classNames from 'classnames';
import * as React from "react";
import { useState } from "react";
import { FormGroup, Label } from 'reactstrap';
import './RoomSelector.css';

interface Props {
    selectedRoomName?: string
    onApply: (selectedRoomName?: string) => void;
    onCancel: () => void;
    roomNames: string[]
}

type SelectionTypes = "all_rooms" | "single_room"

const RoomSelector: React.FC<Props> = (props: Props) => {
    const [selectionType, setSelectionType] = useState<SelectionTypes>(props.selectedRoomName === undefined ? "all_rooms" : "single_room")
    const [lastSelectionType, setLastSelectionType] = useState<SelectionTypes>()
    const [selectedRoomName, setSelectedRoomName] = useState<string|undefined>(props.selectedRoomName)
    const [lastSelectedRoomName, setLastSelectedRoomName] = useState<string>()

    

    const onApply = (event: React.SyntheticEvent<HTMLElement>, data?: ButtonProps) => {
        props.onApply(selectionType === "all_rooms" ? undefined : selectedRoomName)
        setLastSelectionType(selectionType)
        setLastSelectedRoomName(selectedRoomName)
    };

    const onRadioChange = (value: string | number | undefined) => {
        if (value === undefined) return
        setSelectionType(value as SelectionTypes)
        if (!selectedRoomName) {
            setSelectedRoomName(props.roomNames[0])
        }
    }

    const onCancel = (event: React.SyntheticEvent<HTMLElement>, data?: ButtonProps) => {
        setSelectionType(lastSelectionType ? lastSelectionType : "all_rooms")
        setSelectedRoomName(lastSelectedRoomName)
        props.onCancel()
    }

    const items = [{name: 'selection_type', label: 'All', value: 'all_rooms', key: 'all_rooms'},{name: 'selection_type', label: 'Room', value: 'single_room', key: 'single_room'}];

    return <>
        <div className="room-select-dialog">
            <div className="room-selector">
                <div className='room-selector-columns'>
                    <div className="room-selector-column">
                        <RadioGroup
                            items={items}
                            defaultCheckedValue={selectionType as string}
                            vertical
                            onCheckedValueChange={(_, d) => onRadioChange(d?.value)}
                        />
                    </div>
                    <div className={classNames("room-selector-column", { "hide-column": selectionType === "all_rooms" })}>
                        <FormGroup>
                            <Label for="roomName" >Room</Label>
                            <Dropdown items={props.roomNames} 
                                        defaultValue={selectedRoomName || props.roomNames[0]}
                                        onChange={(_e, p) => {setSelectedRoomName(p && p.value as string)}}
                                        id="roomName"
                                        />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className='room-selector-buttons'>
                <Button size="small" primary className='mr-2' onClick={onApply}>Apply</Button>
                <Button size="small" onClick={onCancel}>Cancel</Button>
            </div>
        </div>
    </>
}

export default RoomSelector





