import { Button, ButtonProps, Flex, Form, FormField, FormProps, Input, InputProps } from "@fluentui/react-northstar";
import React, { useState } from "react";

interface FileUploadFormProps {
    onSubmit: (file: File) => void
    onCancel: () => void
    accept?: string
}

const FileUploadForm: React.FC<FileUploadFormProps> = (props) => {
    const [uploadingFile, setUploadingFile] = useState<File>()

    const handleFileChange = (e: React.SyntheticEvent<HTMLElement>, data?: InputProps) => {
        const inputElement = e.currentTarget! as HTMLInputElement
        setUploadingFile(inputElement.files![0])
    }

    const handleSubmit = (event: React.SyntheticEvent<HTMLElement>, data?: FormProps) => {
        props.onSubmit(uploadingFile!)
    }

    const handleCancel = (event: React.SyntheticEvent<HTMLElement>, data?: ButtonProps) => {
        props.onCancel()
    }

    console.log("Accept:", props.accept)
    return (
        <Form onSubmit={handleSubmit}>
            <Flex column gap="gap.medium" >
                <Flex>
                    <FormField
                        id='upload'
                        name="upload"
                        control={<Input type="file" onChange={handleFileChange} name="file" accept={props.accept} id="uploadFile" />} />
                </Flex>
                <Flex gap="gap.smaller" hAlign="end">
                    <FormField
                        control={{
                            as: Button,
                            onClick: handleCancel,
                            content: 'Cancel',
                        }}
                    />
                    <FormField
                        control={{
                            as: Button,
                            disabled: uploadingFile === undefined,
                            primary: true,
                            content: 'Submit',
                        }}
                    />
                </Flex>
            </Flex>
        </Form>)
}



export default FileUploadForm