import { getType } from "typesafe-actions";
import { EventEscalation } from "../../model";
import { HalResource } from "../../services/Hal";
import { actions, Actions } from "../types";


export interface EventEscalationsById {
    [key: number]: { resource: HalResource<EventEscalation>, closing: boolean }
}
interface EventEscalations {
    eventEscalationsById: EventEscalationsById
    isLoading?: boolean
    loadingError: boolean
}
export default function eventEscalations(state: EventEscalations = { eventEscalationsById: {}, loadingError: false }, action: Actions): EventEscalations {
    switch (action.type) {
        case getType(actions.fetchEventEscalations.request):
        case getType(actions.fetchEventEscalationsForRoom.request):
            return { ...state, isLoading: true, loadingError: false }
        case getType(actions.fetchEventEscalations.failure):
        case getType(actions.fetchEventEscalationsForRoom.failure):
            return { ...state, isLoading: false, loadingError: true }
        case getType(actions.fetchEventEscalations.success):
            return { ...state, isLoading: false, eventEscalationsById: escalationsById(action.payload.resources!) }
        case getType(actions.fetchEventEscalationsForRoom.success):
            return { ...state, isLoading: false, eventEscalationsById: escalationsById(action.payload.resources!)}
        case getType(actions.closeEventEscalation.request):
            return {
                ...state,
                eventEscalationsById: {
                    ...state.eventEscalationsById,
                     [action.payload.eventEscalationId.toString()]: { ...state.eventEscalationsById[action.payload.eventEscalationId.toString()], closing: true }
                }
            }
        case getType(actions.closeEventEscalation.success):
            return {
                ...state,
                eventEscalationsById: { ...state.eventEscalationsById, [action.payload.resource.event_escalation_id.toString()]: { resource: action.payload.resource, closing: false } }
            }
        case getType(actions.closeEventEscalation.failure):
            return {
                ...state,
                eventEscalationsById: { ...state.eventEscalationsById, [action.payload.eventEscalationId.toString()]: {...state.eventEscalationsById[action.payload.eventEscalationId.toString()], closing: false } }
            }
        default:
            return state;
    }
}

function escalationsById(escalations: Array<HalResource<EventEscalation>>): EventEscalationsById {
    return escalations.reduce(
        (acc: EventEscalationsById, cur: HalResource<EventEscalation>) => ({ ...acc, [cur.event_escalation_id.toString()]: { resource: {...cur, room_name: cur._links.room_by_org.name}, closing: false } }), {});
}