import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { NfcTag } from "../../model";
import { nfcTagsReducer } from "../resourceActions";
import { actions, Actions } from "../types";
import { ApiResourceState, defaultState } from "./ApiResourceState";

function nfcTags(state: ApiResourceState<NfcTag> = defaultState, action: Actions): ApiResourceState<NfcTag> {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return defaultState
        default:
            return nfcTagsReducer(state, action);
    }
}

export default combineReducers({
     nfcTags
})
