
import { Flex, Text, Tooltip, TooltipContent } from '@fluentui/react-northstar';
import * as React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { MdWarning } from 'react-icons/md';
import { Component, Room, StopReason } from '../../../model';

interface RoomRunningStatusProps {
    room: Room
}

const RoomRunningStatus: React.FunctionComponent<RoomRunningStatusProps> = (props) => {
    const { room } = props

    let roomStatus = runningStatusText(room.room_component!, room.is_in_meeting)
    let adminStatus = runningStatusText(room.admin_component!)

    return (<>
        <Flex column>
            <Flex gap="gap.small">
                <Text weight='semibold' content='Room:' />
                <Text>{roomStatus}{room.has_event_escalations && <MdWarning color='red' />}</Text>
            </Flex>
            < Flex gap="gap.small">
                <Text weight='semibold' content='Admin Agent:' />
                <Text>{adminStatus}</Text>
            </Flex>
        </Flex>
    </>);
};

function runningStatusText(component: Component, inMeeting?: boolean) {
    let status = component.running_status as string
    if (component.running_status === "Stopped") {
        status = `Stopped (${component.stop_reason || "Never Run"})`;
    } else if (component.running_status === "Running") {
        status = !!inMeeting ? "Running (In Meeting)" : "Running";
    }
    return status
}

const RoomRunningStatusIcon: React.FunctionComponent<RoomRunningStatusProps> = ({ room }) => {
    const adminHealthy = good(room.admin_component!)
    const roomHealthy = good(room.room_component!)

    const healthy = roomHealthy && adminHealthy
    const unhealthyColour = !roomHealthy && !adminHealthy ? "red" : "orange"
    const id = `${room.name}_unhealth`

    const children = []

    if (!adminHealthy) {
        children.unshift(<div key='admin-agent'><Text weight='semibold'>Admin Agent: </Text><Text >{failureStatusText(room.admin_component!)}</Text></div>)
    }
    if (!roomHealthy) {
        if (children.length > 0) {
            children.unshift(<br key='br1' />)
        }
        children.unshift(<div key='room'><Text weight='semibold'>Room: </Text><Text >{failureStatusText(room.room_component!)}</Text></div>)
    }
    const unhealthyContent = <TooltipContent key={`${room.name}_ttc`} children={children} />

    return healthy
        ? (<FaCheckCircle color="green" size="2em" />)
        : (<Tooltip content={unhealthyContent}><FaTimesCircle color={unhealthyColour} size="2em" id={id} /></Tooltip>)
};

export function runningStatusSortWeight(room: Room): number {
    const adminHealthy = good(room.admin_component!)
    const roomHealthy = good(room.room_component!)

    if (adminHealthy && roomHealthy) {
        return 0
    }
    if (!adminHealthy && !roomHealthy) {
        return 2
    }
    return 1
}

function failureStatusText(component: Component) {
    return component.stop_reason || "Never Run"
}

function good(component: Component) {
    return component.running_status === 'Running' || (component.running_status === "Stopped" && validStopReason(component.stop_reason))
}

function validStopReason(stopReason?: StopReason) {
    if (stopReason === undefined) {
        return false
    }
    return stopReason === 'ByUser' || stopReason === "ByApi" || stopReason === "Settings" || stopReason === "Reboot" || stopReason === "System"
}

export { RoomRunningStatus, RoomRunningStatusIcon };

