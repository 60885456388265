import { ComponentEventHandler, Dialog, DialogProps } from '@fluentui/react-northstar';
import React, { useEffect, useState } from 'react';

interface ConfirmationDialogPopupProps {
    onConfirm: () => void
    onCancel: () => void
    message: string
    heading: string
    confirmButtonText: string
    isOpen?: boolean
}
const ConfirmationDialog: React.FC<ConfirmationDialogPopupProps> = ({ isOpen, onConfirm, onCancel, heading, message, confirmButtonText }) => {
    const [open, setOpen] = useState(false)

    const handleConfirm: ComponentEventHandler<DialogProps> = (event, props) => {
        setOpen(false)
        onConfirm()
    }
    const handleCancel: ComponentEventHandler<DialogProps> = (event, props) => {
        setOpen(false)
        onCancel()
    }

    useEffect(() => {
        if (isOpen !== undefined) {
            setOpen(isOpen)
        }
    }, [isOpen])
    
    return (<Dialog
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        open={open}
        onOpen={(e, data) => { if (data && data.open) setOpen(data!.open!)}} 
        cancelButton="Cancel"
        confirmButton={confirmButtonText}
        content={message}
        header={heading}
        />)
}

export default ConfirmationDialog

