import { Loader, Button, DownloadIcon } from "@fluentui/react-northstar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTable, { CellInfo, Column } from "react-table";
import { UncontrolledTooltip } from "reactstrap";
import { ReactComponent as CarParkIcon } from '../../../images/bookItIcons/carPark.svg';
import { ReactComponent as FleetIcon } from '../../../images/bookItIcons/fleet.svg';
import { ReactComponent as HotDeskIcon } from '../../../images/bookItIcons/hotDesk.svg';
import { ReactComponent as LockerIcon } from '../../../images/bookItIcons/locker.svg';
import { ReactComponent as RoomIcon } from '../../../images/bookItIcons/room.svg';
import { fetchOrganiserBookingStats } from "../../../store/actions";
import { getActiveOrganisation, getOrganiserBookingStats, getOrganiserBookingStatsFilter, getOrganiserBookingStatsIsLoading } from "../../../store/selectors";
import { useSelector } from '../../../store/utils';
import { Guid } from "../../../utils/guid";
import { minutesToDhm, ReportFilter } from "../MetricsPage";
import services from "../../../services";
import { fileDownloadFromBlob } from "../../../utils/fileDownload";

function localeDay(dateToDisplay: Date) {
    return moment(dateToDisplay).add(moment(dateToDisplay).utcOffset(), 'minutes').format('ddd Do MMM')
}

function localeTime(dateToDisplay: Date) {
    return moment(dateToDisplay).add(moment(dateToDisplay).utcOffset(), 'minutes').format('h:mm a')
}

function dateDifferent(date1?: Date, date2?: Date): boolean {
    if (date1 && date2) {
        const d1 = new Date(date1.toISOString())
        const d2 = new Date(date2.toISOString())
        return d1.getTime() !== d2.getTime()
    } else {
        return true //undefined is different
    }
}

function dayDifferent(date1?: string, date2?: string): boolean {
    if (date1 && date2) {
        const d1 = moment(date1).add(moment(date1).utcOffset(), 'minutes')
        const d2 = moment(date2).add(moment(date2).utcOffset(), 'minutes')
        return !d1.isSame(d2, 'day')
    } else {
        return true //undefined is different
    }
}



const columns: Column[] = [{
    Header: 'Scheduled',
    headerStyle: {
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'From',        
        minWidth: 60,
        className: 'centerAlign',
        accessor: "booked_start",
        Cell: (cell) => {
            return (
                <div>
                    <div>{localeDay(cell.value)}</div>
                    <div>{localeTime(cell.value)}</div>
                </div>
            )
        },
    }, {
        Header: 'To',
        headerStyle: {
            borderRight: '1px solid #fff',
        },
        minWidth: 60,
        className: 'centerAlign',
        Cell: (cellInfo: CellInfo) => {
            return (
                <div>
                    <div>{dayDifferent(cellInfo.original.booked_start, cellInfo.original.booked_end) ? localeDay(cellInfo.original.booked_end) : <br></br>}</div>
                    <div>{localeTime(cellInfo.original.booked_end)}</div>
                </div>
            )
        },
    }]
}, {
    Header: 'Actual',
    headerStyle: {
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'From',
        headerStyle: {
            borderLeft: '1px solid #fff',            
        },
        minWidth: 60,
        className: 'centerAlign',
        Cell: (cellInfo: CellInfo) => {
            if (cellInfo.original.cancelled) {
                return (
                    <div>
                        <br></br>
                        <div>n/a</div>
                    </div>
                )
            } else if (moment.utc(cellInfo.original.actual_start) < moment()) {
                return (
                    <div>
                        <div>{localeDay(cellInfo.original.actual_start)}</div>
                        <div>{localeTime(cellInfo.original.actual_start)}</div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <br></br>
                        <div>-</div>
                    </div>
                )
            }
        },
    }, {
        Header: 'To',
        headerStyle: {
            borderRight: '1px solid #fff',
        },
        minWidth: 60,
        className: 'centerAlign',
        Cell: (cellInfo: CellInfo) => {
            if (cellInfo.original.cancelled) {
                return (
                    <div>
                        <br></br>
                        <div>n/a</div>
                    </div>
                )
            } else if (moment.utc(cellInfo.original.actual_end) < moment()) {
                return (
                    <div>
                        <div>{dayDifferent(cellInfo.original.actual_start, cellInfo.original.actual_end) ? localeDay(cellInfo.original.actual_end) : <br></br>}</div>
                        <div>{localeTime(cellInfo.original.actual_end)}</div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <br></br>
                        <div>-</div>
                    </div>
                )
            }
        },
    }]
}, {
    Header: '',
    headerStyle: {
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'Duration',
        headerStyle: {
            textAlign: 'center',
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        className: 'centerAlign',
        minWidth: 50,
        accessor: "duration",
        Cell: (cell) => {
            return (
                <div>
                    <br></br>
                    {minutesToDhm(cell.value)}
                </div>
            )
        },
        defaultSortDesc: true,
    }]
}, {
    Header: '',
    headerStyle: {
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'Actual Duration',
        headerStyle: {
            textAlign: 'center',
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff'
        },
        className: 'centerAlign',
        minWidth: 50,
        Cell: (cellInfo: CellInfo) => {
            if (cellInfo.original.cancelled) {
                return (
                    <div>
                        <br></br>
                        <div>n/a</div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <br></br>
                        {moment.utc(cellInfo.original.actual_end) < moment() ? minutesToDhm(cellInfo.original.actual_duration) : '-'}
                    </div>
                )
            }
        },
        defaultSortDesc: true,
    }]
}, {
    Header: '',
    headerStyle: {
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'Attendees',
        headerStyle: {
            textAlign: 'center',
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        className: 'centerAlign',
        minWidth: 50,
        accessor: "attendees",
        Cell: (cell) => (<div><br></br>{cell.value}</div>),
        defaultSortDesc: true,
    }]
}, {
    Header: '',
    headerStyle: {
        borderLeft: '1px solid #fff',
        borderRight: '1px solid #fff',
    },
    columns: [{
        Header: 'Info',
        headerStyle: {
            textAlign: 'center',
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
        },
        className: 'centerAlign',
        minWidth: 100,
        Cell: (cellInfo: CellInfo) => {
            var info: string[] = []
            if (cellInfo.original.recurring) {
                info.push('Recurring')
            }
            if (cellInfo.original.no_show_leave) {
                info.push('No Show')
            }
            if (cellInfo.original.next_meeting_start_leave) {
                info.push('Ended by other')
            }
            if (cellInfo.original.cancelled) {
                info.push('Cancelled')
            }
            if (cellInfo.original.is_all_day_event) {
                info.push('All day')
            }
            
            return <div><br></br>{info.join(', ')}</div>
        }
    }]
}, {
    Header: '',
    headerStyle: {
        borderLeft: '1px solid #fff',
    },
    columns: [{
        Header: 'Resources',
        headerStyle: {
            textAlign: 'center',
            borderLeft: '1px solid #fff',        
        },
        className: 'centerAlign',
        minWidth: 100,
        accessor: "resources",
        Cell: (cell) => {
            if (!cell.value) {
                return null
            }
            const resourcesObj = JSON.parse(cell.value)
            if (resourcesObj && resourcesObj.hasOwnProperty('resources')) {
                var resourceGroups = {}
                resourcesObj.resources.forEach((resource: any) => {
                    if (!resourceGroups.hasOwnProperty(resource.type)) {
                        resourceGroups[resource.type] = []
                    }
                    resourceGroups[resource.type].push(resource.name)
                })

                return (
                    <div>
                        <br></br>
                        {Object.keys(resourceGroups).map(function(key) {
                            return renderResourceGroup(cell.index, key, resourceGroups[key])
                        })}
                    </div>
                )
            } else {
                return null
            }
        }
    }]
}]

function renderResourceGroup(index: number, key: string, resourceArray: string[]) {
    const divKey = 'div'+index.toString()+key
    const id = 'uncontrolledTooltip'+index.toString()+key
    return (
        <div key={divKey} className='reportIconWrapper'>
            <span id={id}><div className='reportIcons'>{renderIcon(key)}</div></span>
            <UncontrolledTooltip placement='auto' target={id}>
                {resourceArray.map((name: string, index: number) => {
                    return <div key={index}>{name + ((index + 1) < resourceArray.length ? ', ' : '')}</div>
                })}
            </UncontrolledTooltip>
        </div>
    )
}

function renderIcon(type: string) {
    switch(type) {
        case 'MeetingRoom':
            return <RoomIcon />
        case 'Locker':
            return <LockerIcon />
        case 'ParkingSpace':
            return <CarParkIcon />
        case 'FleetCar':
            return <FleetIcon />
        case 'HotDesk':
            return <HotDeskIcon />
        default:
            return null
    }
}

function isSameGuid(guidA?: Guid, guidB?: Guid) {
    
    if (guidA === undefined || guidB === undefined) {
        return false
    }

    if (!Guid.isGuid(guidA) || !Guid.isGuid(guidB)) {
        return false;
    }

    return guidA.toString() === guidB.toString()

}

export interface OrganiserSummaryProps {
    filter: ReportFilter
    organiser: string
}

function OrganiserSummary(props: OrganiserSummaryProps) {
    const dispatch = useDispatch()
    const data = useSelector(s => getOrganiserBookingStats(s, props.organiser));
    const isLoading = useSelector(s => getOrganiserBookingStatsIsLoading(s, props.organiser))
    const previousFilter = useSelector(s => getOrganiserBookingStatsFilter(s, props.organiser))
    
    //get new data if last fetch was more than 5 minutes ago and end of range is today (or filters have changed)
    const getNewData = 
        data === undefined
        || dateDifferent(props.filter.startDate, previousFilter.startRange)
        || dateDifferent(props.filter.endDate, previousFilter.endRange)
        || props.filter.location !== previousFilter.location
        || props.filter.resourceType !== previousFilter.resourceType
        || props.filter.bookableResource !== previousFilter.bookableResource
        || props.filter.excludeWeekends !== previousFilter.excludeWeekends
        || props.filter.isWorkingHoursOnly !== previousFilter.workingHoursOnly
        || !isSameGuid(props.filter.organisationId, previousFilter.organisationId)
        || (moment().diff(moment(previousFilter.lastFetch), 'minutes') >= 5 && moment().endOf('day').diff(moment(props.filter.endDate), 'days') === 0)

    const activeOrganisation = useSelector(getActiveOrganisation)
    const [isReportRunning, setIsReportRunning] = useState(false)

    // Export the dataset as a CSV file
    async function doExport() {
            
        console.log('do Occupancy csv export');

        setIsReportRunning(true)

        try {

            const {data,fileName} = await services.bookItApi.fetchOrganiserBookingStatsDataCsv(
                                                    activeOrganisation.organisation_id, 
                                                    props.filter.startDate!, 
                                                    props.filter.endDate!, 
                                                    props.filter.location!, 
                                                    props.filter.resourceType, 
                                                    props.filter.bookableResource!, 
                                                    props.organiser!, 
                                                    props.filter.excludeWeekends, 
                                                    props.filter.isWorkingHoursOnly)

            fileDownloadFromBlob(data, fileName ?? 'bookit_organiser_booking_stats_report.csv')

        } catch (e) {
            console.error('doExport', e)
        }

        setIsReportRunning(false)

    }

    useEffect(() => {
        if (!isLoading && getNewData) {
            dispatch(fetchOrganiserBookingStats.request({ organisationId: activeOrganisation.organisation_id, startRange: props.filter.startDate!, endRange: props.filter.endDate!, location: props.filter.location!, resourceType: props.filter.resourceType, bookableResource: props.filter.bookableResource!, organiser: props.organiser!, excludeWeekends: props.filter.excludeWeekends, workingHoursOnly: props.filter.isWorkingHoursOnly }))
        }
    }, [activeOrganisation.organisation_id, dispatch, getNewData, isLoading, props.filter.endDate, props.filter.location, props.filter.resourceType, props.filter.bookableResource, props.filter.startDate, props.organiser, props.filter.excludeWeekends, props.filter.isWorkingHoursOnly])

    if (!props.organiser) {
        return <div></div>
    } else if (isLoading) {
        return <Loader label="Loading Organiser Booking Statistics" />
    } else if (!getNewData && data !== undefined && data.length > 0) {
        return (
            <div className="organiserStatsReport">

                {isReportRunning ? <Loader className='spaceLeft' /> : 
                        <Button primary content='Export as CSV' 
                            onClick={() => { doExport() }} 
                            icon={<DownloadIcon />} 
                            iconPosition='before' 
                            className={['downloadButton', 'spaceLeft', 'spaceBottom' ].join(' ')} />
                    }

                <ReactTable
                    className="-striped -highlight"
                    showPagination={false}
                    key={data.length === 0 ? "nodata" : "havedata"}
                    defaultPageSize={data.length === 0 ? 0 : data.length}
                    columns={columns}
                    defaultSorted={([{ id: "booked_start", desc: false }])}
                    data={data}
                    loadingText={<Loader label='Loading...' />}
                    loading={isLoading}
                    noDataText={<span>...</span>}
                />
            </div>
        )
    } else {
        return <div></div>
    }
}

export default OrganiserSummary