import { AddIcon, Button, Dialog, Flex, Loader, RetryIcon, Text } from "@fluentui/react-northstar";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTable, { CellInfo, Column, SortingRule } from "react-table";
import { ApiAction } from "../../store/createApiResourceActions";
import { ApiResourceState } from "../../store/reducers/ApiResourceState";
import { RootState } from "../../store/types";
import { useSelector } from "../../store/utils";
import ConfirmationDialog from "../controls/ConfirmationDialog";
import { IntermediateToolbarItem, ToolbarWithTooltips } from "../controls/ToolbarWithTooltips";
import css from './ResourceAdminPage.module.scss';

interface ResourceAdminPageProps<TResource extends {}, TArg extends Partial<TResource>> {
    resourceName: string
    selectState: (s: RootState) => ApiResourceState<TResource>
    fetchAllAction: ApiAction<TArg, Array<TResource>>
    deleteAction: ApiAction<TResource, TResource>
    confirmMessage: (res: TResource) => string
    resourceForm: (edit: TResource | undefined, closeDialog: () => void) => JSX.Element
    toolbarActionItems: (resource: TResource, onAddUpdate: (edit: TResource) => void, onDelete: (edit: TResource) => void) => IntermediateToolbarItem[]
    columns: Array<Column<TResource>>
    defaultSortOrder: SortingRule[] | undefined
    parentKey: TArg
    disableAdd?: boolean
}

const ResourceAdminPage = <TResource extends {}, TArg extends Partial<TResource>>
    ({ resourceName, selectState, fetchAllAction, deleteAction, confirmMessage, resourceForm, toolbarActionItems, columns, defaultSortOrder, parentKey, disableAdd }:
        ResourceAdminPageProps<TResource, TArg>) => {

    const dispatch = useDispatch()

    const [formOpen, setFormOpen] = useState(false)

    const { isCreating, isLoaded, isLoading, failed, resources } = useSelector(s => selectState(s))
    useEffect(() => { 
        if (!isLoaded && !isLoading && !failed) {
            dispatch(fetchAllAction.request(parentKey)) 
        }
    }, [fetchAllAction, dispatch, isLoaded, isLoading, parentKey, failed])
    const data = useMemo(() => Object.values(resources), [resources])

    const handleRetry = useCallback(() => {
        dispatch(fetchAllAction.request(parentKey)) 
    }, [dispatch, fetchAllAction, parentKey])

    const [resourceToEdit, setResourceToEdit] = useState<TResource>()
    const handleResourceEdit = useCallback((resource: TResource) => {
        setResourceToEdit(resource)
        setFormOpen(true)
    }, [])

    const [resourceToDelete, setResourceToDelete] = useState<TResource>()
    const handleConfirmDelete = useCallback((resource: TResource) => {
        setResourceToDelete(resource)
    }, [])

    const handleResourceDelete = () => {
        console.log("About to delete:", resourceToDelete)
        dispatch(deleteAction.request(resourceToDelete!))
        setResourceToDelete(undefined)
    }

    const renderActions = useCallback((cellInfo: CellInfo) => {
        return (<ToolbarWithTooltips
            items={toolbarActionItems(cellInfo.original, handleResourceEdit, handleConfirmDelete)}
        />)
    }, [toolbarActionItems, handleConfirmDelete, handleResourceEdit])

    const cols = [...columns, { Header: "", id: "action_bar", maxWidth: 110, Cell: renderActions }]

    const addResourceButton = <Button primary content={`Add ${resourceName}`} icon={<AddIcon />} iconPosition='before' loading={isCreating} disabled={disableAdd} styles={{ marginBottom: '0.5rem' }} />
    const addResourceContent = isCreating
        ? <Flex hAlign='end'>{addResourceButton}</Flex>
        : (<Flex hAlign='end'>
            <Dialog
                content={resourceForm(resourceToEdit, () => {
                    setResourceToEdit(undefined)
                    setFormOpen(false)
                })}
                header={`Add/Edit ${resourceName}`}
                open={formOpen}
                onOpen={() => setFormOpen(true)}
                trigger={addResourceButton}
            />
        </Flex>)

    return <Flex column className={css.ResourceAdminPage}>
        <h5>{resourceName}s</h5>
        {addResourceContent}

        <ReactTable<TResource>
            showPagination={false}
            minRows={5}
            pageSize={999}
            columns={cols}
            defaultSorted={defaultSortOrder}
            data={data}
            noDataText={failed ? <FailedRetry onRetry={handleRetry} /> : "No data available"}
            loadingText={<Loader label='Loading...' />}
            loading={isLoading}
        />

        {resourceToDelete && <ConfirmationDialog onConfirm={handleResourceDelete}
            onCancel={() => setResourceToDelete(undefined)}
            message={confirmMessage(resourceToDelete!)}
            heading={`Delete ${resourceName}`}
            confirmButtonText='Delete'
            isOpen={resourceToDelete !== undefined} />
        }
    </Flex>
}

export const FailedRetry: React.FC<{onRetry: () => void}> = ({onRetry}) => {
    return <Flex column>
        <Text error content="Failed to load data"/>
        <Button icon={<RetryIcon />} content="Retry" iconPosition="after" text 
            onClick={() => { onRetry() }}/>
    </Flex>
}

export default ResourceAdminPage

