import * as _ from 'lodash';
import { Traversal } from "traverson";
import { getType } from "typesafe-actions";
import { Room, RoomAttributes, RoomConfiguration, RoomsFilters, RoomsFilterType, RoomWindowsUpdate } from "../../model";
import { HalResource, HalResult } from "../../services/Hal";
import { Actions, actions } from "../types";

interface Rooms {
    rooms: {
        [name: string]: HalResult<Room>
    },
    lastBackups: {
        [name: string]: HalResult<RoomConfiguration> | undefined
    }
    roomAttributes: {
        [name: string]: HalResult<RoomAttributes> | undefined
    }
    windowsUpdates: {
        [name: string]: {
            [updateId: number]: { resource: RoomWindowsUpdate, last_error?: string }
        }
    },
    roomNames: string[] | undefined,
    roomFilter: RoomsFilterType,
    roomsTraveral?: Traversal;
    isLoading: boolean;
    isLoaded: boolean;
    isCreatingRoom: boolean;
}

const intitialState = {
    rooms: {},
    lastBackups: {},
    roomAttributes: {},
    windowsUpdates: {},
    roomNames: undefined,
    isLoading: false,
    isLoaded: false,
    isCreatingRoom: false,
    roomFilter: RoomsFilters[0]
}

export default function rooms(state: Rooms = intitialState, action: Actions): Rooms {
    switch (action.type) {
        case getType(actions.setActiveOrganisation):
            return intitialState
        case getType(actions.fetchRooms.success):
            const res = _.keyBy(action.payload.resources, r => r.name.toLowerCase());
            const res1 = _.mapValues(res, obj => ({ resource: denormaliseRoom(obj) } as HalResult<Room>));
            return {
                ...state,
                rooms: res1,
                roomNames: Object.keys(res),
                isLoaded: true,
                roomsTraveral: action.payload.traversal,
                isLoading: false
            }
        case getType(actions.fetchRoomNames.success):
            return {
                ...state,
                roomNames: action.payload.names
            }
        case getType(actions.createRoom.request):
            return {
                ...state,
                isCreatingRoom: true
            }
        case getType(actions.createRoom.failure):
            return {
                ...state,
                isCreatingRoom: false,
            }
        case getType(actions.fetchRoom.success):
        case getType(actions.createRoom.success):
            return {
                ...state,
                isCreatingRoom: false,
                rooms: { ...state.rooms, [action.payload.resource.name.toLowerCase()]: { ...action.payload, resource: denormaliseRoom(action.payload.resource) } }
            }
        case getType(actions.deleteRoom.success):
            const { [action.payload.toLowerCase()]: deleted, ...newState } = state.rooms;
            return {
                ...state,
                rooms: newState
            };
        case getType(actions.refreshRoomPin.success):
            return {
                ...state,
                rooms: { ...state.rooms, [action.payload.resource.name.toLowerCase()]: { ...action.payload, resource: denormaliseRoom(action.payload.resource) } }
            }
        case getType(actions.fetchRoomAttributes.success):
            return {
                ...state,
                roomAttributes: {
                    ...state.roomAttributes,
                    [action.payload.roomName.toLowerCase()]: action.payload.attribs
                }
            }
        case getType(actions.backupRoom.success):
        case getType(actions.fetchLatestRoomBackup.success):
            return {
                ...state,
                lastBackups: {
                    ...state.lastBackups,
                    [action.payload.roomName.toLowerCase()]: action.payload.configuration
                }
            }
        case getType(actions.fetchRooms.failure):
            return {
                ...state,
                rooms: {}, roomsTraveral: undefined, isLoading: false
            };
        case getType(actions.fetchRooms.request):
            return { ...state, isLoading: true };
        case getType(actions.setRoomsFilter):
            return {
                ...state,
                roomFilter: action.payload
            };
        case getType(actions.adminFetchWindowsUpdatesForRoom.success):
            const updatesById = _.keyBy(action.payload.updates.resources, "windows_update_id");
            const updatesById1 = _.mapValues(updatesById, obj => ({ resource: obj, last_error: undefined }));
            return {
                ...state,
                windowsUpdates: {
                    ...state.windowsUpdates,
                    [action.payload.roomName]: updatesById1
                }
            }
        case getType(actions.adminRequestWindowsUpdatesInstall.success): {
            const resultsById = _.keyBy(action.payload.result.data, "resource.windows_update_id");
            console.log('resultsById', resultsById)

            const newUpdates = _.mapValues(state.windowsUpdates[action.payload.roomName], v =>
                resultsById[v.resource.windows_update_id.toString()]
                    ? { resource: resultsById[v.resource.windows_update_id.toString()].resource, last_error: errorCodeToMessage(resultsById[v.resource.windows_update_id.toString()].error_code) }
                    : v)


            return {
                ...state,
                windowsUpdates: {
                    ...state.windowsUpdates,
                    [action.payload.roomName]: newUpdates
                }
            }
        }
        default:
            return state;
    }
}

function errorCodeToMessage(errorCode?: string): string | undefined {
    if (errorCode === undefined) return undefined
    if (errorCode === 'windows_update_not_found') {
        return "This update is no longer available"
    }
    if (errorCode === 'windows_update_already_requested') {
        return "An install is already requested for this update"
    }
    return "An unknown error occurred trying to request an install"
}

function denormaliseRoom(room: HalResource<Room>) {
    return {
        ...room,
        room_component: room._embedded.room_component,
        admin_component: room._embedded.admin_component,
        javascript_package_component: room._embedded.javascript_package_component
    }
}
