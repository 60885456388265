import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { BookingCount, BookingStats, HeatMap, MinMedianMax, OrganiserBookingStats, PeriodSummary } from "../../../model";
import { Guid } from "../../../utils/guid";
import { actions, Actions } from "../../types";

interface HeatMapMetric {
  heatMap?: HeatMap,
  isLoading: boolean
}

interface SummaryMetric {
  summary?: PeriodSummary[],
  isLoading: boolean
}


interface BookingCountMetric {
  bookingCount?: BookingCount[],
  isLoading: boolean
}

interface MinMedianMaxMetric {
  minMedianMax?: MinMedianMax[],
  isLoading: boolean
}

interface BookingStatsMetric {
  bookingStats?: BookingStats[],
  isLoading: boolean
}

type OrganiserBookingStatsMetric = Record<string, { lastFetch?: Date, isLoading?: boolean, data?: OrganiserBookingStats[], startRange?: Date, endRange?: Date, location?: string, resourceType?: string, bookableResource?: string, excludeWeekends?: boolean, workingHoursOnly?: boolean, organisationId?: Guid }>

export function heatmap(state: HeatMapMetric = { isLoading: false }, action: Actions) {
  switch (action.type) {
    case getType(actions.fetchHeatMap.request):
      return {
        ...state,
        isLoading: true
      };
    case getType(actions.fetchHeatMap.failure):
      return {
        ...state,
        heatMap: undefined,
        isLoading: false
      };

    case getType(actions.fetchHeatMap.success):
      return {
        ...state,
        heatMap: action.payload.resource,
        isLoading: false
      };
    default:
      return state;
  }
}

export function summary(state: SummaryMetric = { isLoading: false }, action: Actions) {
  switch (action.type) {
    case getType(actions.fetchSummary.request):
      return {
        ...state,
        isLoading: true
      };
    case getType(actions.fetchSummary.failure):
      return {
        ...state,
        summary: undefined,
        isLoading: false
      };
    case getType(actions.fetchSummary.success):
      return {
        ...state,
        summary: action.payload.resources,
        isLoading: false
      };
    default:
      return state;
  }
}

export function bookingcount(state: BookingCountMetric = { isLoading: false }, action: Actions) {
  switch (action.type) {
    case getType(actions.fetchBookingCount.request):
      return {
        ...state,
        isLoading: true
      };
    case getType(actions.fetchBookingCount.failure):
      return {
        ...state,
        bookingCount: undefined,
        isLoading: false
      };
    case getType(actions.fetchBookingCount.success):
      return {
        ...state,
        bookingCount: action.payload.resources,
        isLoading: false
      };
    default:
      return state;
  }
}


export function minmedianmax(state: MinMedianMaxMetric = { isLoading: false }, action: Actions) {
  switch (action.type) {
    case getType(actions.fetchMinMedianMax.request):
      return {
        ...state,
        isLoading: true
      };
    case getType(actions.fetchMinMedianMax.failure):
      return {
        ...state,
        minMedianMax: undefined,
        isLoading: false
      };
    case getType(actions.fetchMinMedianMax.success):
      return {
        ...state,
        minMedianMax: action.payload.resources,
        isLoading: false
      };
    default:
      return state;
  }
}

export function bookingstats(state: BookingStatsMetric = { isLoading: false }, action: Actions) {
  switch (action.type) {
    case getType(actions.fetchBookingStats.request):
      return {
        ...state,
        isLoading: true
      };
    case getType(actions.fetchBookingStats.failure):
      return {
        ...state,
        bookingStats: undefined,
        isLoading: false
      };
    case getType(actions.fetchBookingStats.success):
      return {
        ...state,
        bookingStats: action.payload.resources,
        isLoading: false
      };
    default:
      return state;
  }
}

export function organiserbookingstats(state: OrganiserBookingStatsMetric = {}, action: Actions): OrganiserBookingStatsMetric {
  switch (action.type) {
    case getType(actions.fetchOrganiserBookingStats.request):
      return {
        ...state,
        [action.payload.organiser.toLowerCase()]: { isLoading: true }
      };
    case getType(actions.fetchOrganiserBookingStats.failure):
      return {
        ...state,
        [action.payload.organiser.toLowerCase()]: { data: undefined, isLoading: false }
      };
    case getType(actions.fetchOrganiserBookingStats.success):
      return {
        ...state,
        [action.payload.organiser.toLowerCase()]: { lastFetch: action.payload.lastFetch, data: action.payload.data.resources, isLoading: false, startRange: action.payload.startRange, endRange: action.payload.endRange, location: action.payload.location, resourceType: action.payload.resourceType, bookableResource: action.payload.bookableResource, excludeWeekends: action.payload.excludeWeekends, workingHoursOnly: action.payload.workingHoursOnly, organisationId: action.payload.organisationId }
      };
    default:
      return state
  }
}

export default combineReducers({
  heatmap,
  summary,
  bookingcount,
  minmedianmax,
  bookingstats,
  organiserbookingstats,
})
