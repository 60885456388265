import { AcceptIcon, CloseIcon, TrashCanIcon } from "@fluentui/react-icons-northstar"
import { Button, Dropdown, DropdownItemProps, Loader, ShorthandCollection } from "@fluentui/react-northstar"
import { Operation } from "fast-json-patch"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import ReactTable, { CellInfo, Column } from "react-table"
import { ConferenceClient, ConferenceClientStatus, IdType } from "../../model"
import { deleteConferenceClient, fetchConferenceClients, patchConferenceClient } from "../../store/resourceActions"
import { useSelector } from "../../store/utils"
import { FailedRetry } from "../admin/ResourceAdminPage"
import ConfirmationDialog from "../controls/ConfirmationDialog"
import { ToolbarWithTooltips } from "../controls/ToolbarWithTooltips"
import css from './ConferenceClientsPage.module.scss';


const statusItems = [
    {
        header: 'Unknown',
        data: 'Unknown'
    },
    {
        header: 'Approved',
        data: 'Approved'
    },
    {
        header: 'Known Issues',
        data: 'KnownIssues'
    }
]
export default function ConferenceClientsPage() {
    const dispatch = useDispatch()
    const [resourceToDelete, setResourceToDelete] = useState<ConferenceClient>()

    const { isLoaded, isLoading, resources, failed } = useSelector(s => s.conferenceClients.conferenceClients)
    useEffect(() => {
        if (!isLoaded && !isLoading && !failed) {
            dispatch(fetchConferenceClients.request({}))
        }
    }, [dispatch, failed, isLoaded, isLoading])
    const values = useMemo(() => Object.values(resources), [resources])

    const [status, setStatus] = useState<typeof statusItems[0]>()
    const [statusUnderEdit, setStatusUnderEdit] = useState<IdType>()
    const [originalStatus, setOriginalStatus] = useState<typeof statusItems[0]>()
    const onEdit = (client: ConferenceClient) => {
        const item = statusItems.find(i => i.data === client.status)
        setStatusUnderEdit(client.video_conference_client_id)
        setStatus(item)
        setOriginalStatus(item)
    }
    const onCancelEdit = () => {
        setStatus(undefined)
        setOriginalStatus(undefined)
        setStatusUnderEdit(undefined)

    }
    const onSaveStatus = useCallback((client: ConferenceClient) => {
        const patch: Operation[] = [{
            op: 'replace',
            value: status!.data,
            path: '/status'
        }]
        dispatch(patchConferenceClient.request({id: client, operations: patch}))
        onCancelEdit()
    }, [dispatch, status])

    const handleRetry = () => {
        dispatch(fetchConferenceClients.request({}))
    }
    const handleConfirmDelete = (resource: ConferenceClient) => {
        setResourceToDelete(resource)
    }
    const handleDelete = () => {
        dispatch(deleteConferenceClient.request(resourceToDelete!))
        setResourceToDelete(undefined)
    }

    const renderActions = useCallback((cellInfo: CellInfo) => {
        return <ToolbarWithTooltips items={[
            {
                key: 'delete',
                icon: <TrashCanIcon outline />,
                tooltip: 'Delete conference client version',
                onClick: () => handleConfirmDelete(cellInfo.original)
            }]}
        />
    }, [])

    const renderStatus = useCallback((cellInfo: CellInfo) => {
        if (statusUnderEdit && statusUnderEdit === cellInfo.original.video_conference_client_id) {
            return <div className={css.statusEdit}>
                <Dropdown
                    items={statusItems}
                    position='below'
                    positionFixed
                    onChange={(_e, d) => {
                        console.log("Status change: ", d)
                        if (d.value) {
                            setStatus((d.value as any))
                        }
                    }}
                    value={status}
                />
                {status !== originalStatus && <div className={css.okcancel}>
                    <Button circular icon={<AcceptIcon size='small'/>} className={css.ok} onClick={() => onSaveStatus(cellInfo.original)} />
                    <Button circular icon={<CloseIcon size='small' />} className={css.cancel} onClick={() => onCancelEdit()} />
                </div>}
            </div>
        }
        return <div onClick={() => onEdit(cellInfo.original)}>
            {formatStatus(cellInfo.original.status)}
        </div>
    }, [onSaveStatus, originalStatus, status, statusUnderEdit])
    const cols = useMemo<Column<ConferenceClient>[]>(() => [
        { Header: "Type", id: "type", accessor: "type", minWidth: 8, Cell: (cell) => `${cell.original.type} (${cell.original.implementation})` },
        { Header: "Version", id: "version", accessor: "version", minWidth: 8 },
        { Header: "Status", id: "status", accessor: "status", minWidth: 20, Cell: renderStatus, className: css.status },
        { Header: "First Seen", id: "created_date_utc", accessor: "created_date_utc", minWidth: 10, Cell: (cell) => {
            console.log("First cell: ", cell.value)
            return moment(cell.value).format('MMMM Do YYYY, h:mm:ss a')
         } },
        { Header: "", id: "action_bar", maxWidth: 110, Cell: renderActions }
    ], [renderActions, renderStatus])

    return <div className={css.conferenceClientsPage}>
        <h5>My Codes</h5>
        <ReactTable<ConferenceClient>
            showPagination={false}
            minRows={5}
            pageSize={999}
            columns={cols}
            defaultSorted={[{ id: "type", desc: false }, { id: "version", desc: false }]}
            data={values}
            noDataText={failed ? <FailedRetry onRetry={handleRetry} /> : "No data available"}
            loadingText={<Loader label='Loading...' />}
            loading={isLoading}
        />

        {resourceToDelete && <ConfirmationDialog onConfirm={handleDelete}
            onCancel={() => setResourceToDelete(undefined)}
            message='Are you sure you want to delete this conference client version information?'
            heading='Delete conference client version'
            confirmButtonText='Delete'
            isOpen={resourceToDelete !== undefined} />
        }
    </div>

}

function formatStatus(status: string) {
    if (status === 'KnownIssues') {
        return 'Known Issues'
    }

    return status
}
