import { AddIcon, Button, Dialog, Flex, Form, FormField, FormLabel, FormMessage, Input, Loader, RadioGroup, RetryIcon, TrashCanIcon } from "@fluentui/react-northstar";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTable, { CellInfo } from "react-table";
import { SharingCode, SharingCodeUsage, UsageType } from "../../model";
import { fetchOrganisationSummaries } from "../../store/actions";
import { createSharingCode, deleteSharingCode, deleteSharingCodeUsage, fetchSharingCodes, fetchSharingCodeUsages } from "../../store/resourceActions";
import { getActiveOrganisation } from "../../store/selectors";
import { useOrganisationResource } from "../../store/useOrganisationResource";
import { useSelector } from "../../store/utils";
import { FailedRetry } from "../admin/ResourceAdminPage";
import ConfirmationDialog from "../controls/ConfirmationDialog";
import { ToolbarWithTooltips } from "../controls/ToolbarWithTooltips";
import services from "../../services";

import css from './SharingCodesPage.module.scss';
import { HttpError } from "../../services/api";
import FormButtons from "../controls/FormButtons";

function SharingGroupsPage() {

    const dispatch = useDispatch()

    const activeOrganisation = useSelector(getActiveOrganisation)

    const { isLoading: isLoadingOrgs, isLoaded: isLoadedOrgs, isFailure: isFailureOrgs } = useSelector(s => s.bookit.organisationSummaries)
    useEffect(() => {
        if (!isLoadedOrgs && !isLoadedOrgs && !isFailureOrgs) {
            dispatch(fetchOrganisationSummaries.request())
        }
    }, [dispatch, isFailureOrgs, isLoadingOrgs, isLoadedOrgs])

    if (!activeOrganisation) {
        return <Loader label='Loading...' />
    }

    return <Flex column className={css.sharingCodesPage}>
        <h4>Sharing Codes</h4>

        <SharingCodeTable />

        <SharingCodeUsageTable />
    </Flex>
}

function SharingCodeTable() {

    const dispatch = useDispatch()

    const [addFormOpen, setAddFormOpen] = useState(false)

    const { isCreating, isLoading, failed, values, activeOrganisation } =
        useOrganisationResource(s => s.bookit.sharingCodes, fetchSharingCodes, activeOrg => ({ organisation_id: activeOrg.organisation_id }))

    const [codeToDelete, setCodeToDelete] = React.useState<SharingCode>()

    const handleRetry = () => {
        dispatch(fetchSharingCodes.request({ organisation_id: activeOrganisation.organisation_id }))
    }
    const handleConfirmDelete = (resource: SharingCode) => {
        setCodeToDelete(resource)
    }
    const handleDelete = () => {
        dispatch(deleteSharingCode.request(codeToDelete!))
        setCodeToDelete(undefined)
    }

    const handleAdd = () => {
        if (activeOrganisation === undefined) {
            console.error("Active organisation is not defined!. Cannot create sharing group")
            return
        }
        dispatch(createSharingCode.request({ organisation_id: activeOrganisation.organisation_id }))
        setAddFormOpen(false)
    }

    const renderActions = useCallback((cellInfo: CellInfo) => {
        return <ToolbarWithTooltips items={[
            {
                key: 'revoke',
                icon: <RetryIcon outline />,
                tooltip: 'Revoke sharing code',
                onClick: () => handleConfirmDelete(cellInfo.original)
            }]}
        />
    }, [])
    const codeCols = useMemo(() => [
        { Header: "Code", id: "code", minWidth: 5, accessor: (row: SharingCode) => formatCode(row.code) },
        { Header: "Used By", id: "used_by", minWidth: 40, accessor: (row: SharingCode) => row.usages.length === 0 ? "Not Used" : row.usages.map(mem => `${mem.shared_to.name} (${formatUsageType(mem.usage_type)})`).join(", ") },
        { Header: "", id: "action_bar", maxWidth: 110, Cell: renderActions }
    ], [renderActions])

    if (!activeOrganisation) {
        return <Loader label='Loading...' />
    }

    const addButton = <Button primary content={`Add Sharing Code`} icon={<AddIcon />} iconPosition='before' loading={isCreating} styles={{ marginBottom: '0.5rem' }} />
    const addContent = isCreating
        ? <Flex hAlign='end'>{addButton}</Flex>
        : (<Flex hAlign='end'>

            <Dialog
                cancelButton="Cancel"
                confirmButton="Add"
                open={addFormOpen}
                onOpen={() => setAddFormOpen(true)}
                onCancel={() => setAddFormOpen(false)}
                onConfirm={handleAdd}
                content="Are you sure you want to add a new Sharing Code?"
                header="Add Sharing Code"
                trigger={addButton}
            />
        </Flex>)


    return <div>
        <h5>My Codes</h5>
        {addContent}
        <ReactTable<SharingCode>
            showPagination={false}
            minRows={3}
            pageSize={999}
            columns={codeCols}
            defaultSorted={[{ id: "code", desc: false }]}
            data={values}
            noDataText={failed ? <FailedRetry onRetry={handleRetry} /> : "No data available"}
            loadingText={<Loader label='Loading...' />}
            loading={isLoading}
        />

        {codeToDelete && <ConfirmationDialog onConfirm={handleDelete}
            onCancel={() => setCodeToDelete(undefined)}
            message={codeToDelete.usages.length > 0
                ? 'This sharing code is used by other organisations to share resources to your tenant. If you delete it those shared resources will no longer be available. Are you sure?'
                : 'Are you sure you want to delete this sharing code?'}
            heading='Revoke Sharing Code'
            confirmButtonText='Delete'
            isOpen={codeToDelete !== undefined} />
        }
    </div>

}

function SharingCodeUsageTable() {

    const dispatch = useDispatch()

    const [addFormOpen, setAddFormOpen] = useState(false)

    const { isCreating, isLoading, failed, values, activeOrganisation } =
        useOrganisationResource(s => s.bookit.sharingCodeUsages, fetchSharingCodeUsages, activeOrg => ({ organisation_id: activeOrg.organisation_id }))

    const [usageToDelete, setUsageToDelete] = React.useState<SharingCodeUsage>()

    const handleRetry = () => {
        dispatch(fetchSharingCodeUsages.request({ organisation_id: activeOrganisation.organisation_id }))
    }
    const handleConfirmDelete = (resource: SharingCodeUsage) => {
        setUsageToDelete(resource)
    }
    const handleDelete = () => {
        dispatch(deleteSharingCodeUsage.request(usageToDelete!))
        setUsageToDelete(undefined)
    }

    const handleCreated = (usage: SharingCodeUsage) => {
        console.log("Sharing code usage created", usage)
        setAddFormOpen(false)
        dispatch(fetchSharingCodeUsages.request({organisation_id: activeOrganisation.organisation_id }))
    }

    const renderActions = useCallback((cellInfo: CellInfo) => {
        return <ToolbarWithTooltips items={[
            {
                key: 'revoke',
                icon: <TrashCanIcon outline />,
                tooltip: 'Remove share',
                onClick: () => handleConfirmDelete(cellInfo.original)
            }]}
        />
    }, [])
    const cols = useMemo(() => [
        { Header: "Code", id: "code", minWidth: 15, accessor: (row: SharingCodeUsage) => formatCode(row.code) },
        { Header: "Shared To", id: "shared_to", minWidth: 15, accessor: (row: SharingCodeUsage) => row.owner_organisation.name },
        { Header: "Usage Type", id: "usage_type", minWidth: 15, accessor: (row: SharingCodeUsage) => formatUsageType(row.usage_type) },
        { Header: "", id: "action_bar", maxWidth: 110, Cell: renderActions }
    ], [renderActions])

    if (!activeOrganisation) {
        return <Loader label='Loading...' />
    }

    const addButton = <Button primary content={`Add Usage`} icon={<AddIcon />} iconPosition='before' loading={isCreating} styles={{ marginBottom: '0.5rem' }} />
    const addContent = isCreating
        ? <Flex hAlign='end'>{addButton}</Flex>
        : (<Flex hAlign='end'>

            <Dialog
                open={addFormOpen}
                onOpen={() => setAddFormOpen(true)}
                content={<SharingCodeUsageForm onCreated={handleCreated} onCancel={() => setAddFormOpen(false)} />}
                header="Add Sharing Code"
                trigger={addButton}
            />
        </Flex>)


    return <div>
        <h5>My Usages</h5>
        {addContent}
        <ReactTable<SharingCodeUsage>
            showPagination={false}
            minRows={3}
            pageSize={999}
            columns={cols}
            defaultSorted={[{ id: "code", desc: false }]}
            data={values}
            noDataText={failed ? <FailedRetry onRetry={handleRetry} /> : "No data available"}
            loadingText={<Loader label='Loading...' />}
            loading={isLoading}
        />

        {usageToDelete && <ConfirmationDialog onConfirm={handleDelete}
            onCancel={() => setUsageToDelete(undefined)}
            message={`Remove this share to to ${usageToDelete.owner_organisation.name}. That organisation will no longer be able to see the resources shared to it. Are you sure?`}
            heading='Revoke Sharing Code'
            confirmButtonText='Delete'
            isOpen={usageToDelete !== undefined} />
        }
    </div>

}

interface SharingCodeUsageFormProps {
    onCreated: (usage: SharingCodeUsage) => void
    onCancel: () => void
}

function SharingCodeUsageForm({ onCreated, onCancel }: SharingCodeUsageFormProps) {

    const [code, setCode] = useState<string | undefined>(undefined)
    const [error, setError] = useState<string | undefined>(undefined)
    const [sharingCode, setSharingCode] = useState<SharingCode | undefined>(undefined)
    const [usageType, setusageType] = useState<UsageType>('LoginCompletionSharedDevices')
    const [checking, setChecking] = useState(false)
    const [confirmCreate, setConfirmCreate] = useState(false)

    const activeOrganisation = useSelector(getActiveOrganisation)

    const onChange = async (v: string) => {
        setSharingCode(undefined)
        setError(undefined)
        setCode(v)
        v = v.replace('-', '')
        if (isNaN(v as any)) {
            setError('Sharing code must be a number')
        }
        else if (v.length === 8) {
            const api = services.apiClient
            if (!api) return
            setChecking(true)
            try {
                const code = await api.get<SharingCode>(`/sharing_codes/${v}`)
                if (code.organisation_id === activeOrganisation.organisation_id) {
                    setError('Cannot share to the same organisation')
                } else {
                    setSharingCode(code)
                }
            } catch (e) {
                if (e instanceof HttpError && e.apiError?.api_error_code === 'sharing_code_not_found') {
                    setError('Sharing code was not found')
                } else {
                    setError('A unknown error occured searching for the sharing code')
                }
            }
            setChecking(false)
        }
    }

    const createUsage = async () => {
        try {
            setConfirmCreate(false)
            const api = services.apiClient!
            const usage = await api.post<SharingCodeUsage>(`/organisations/${activeOrganisation.organisation_id}/sharing_codes/${sharingCode!.sharing_code_id}/usages`, { usage_type: usageType })
            onCreated(usage)
        } catch (e) {
            console.log('create usage failed', e, typeof e)   
            if (e instanceof HttpError && e.apiError?.api_error_code === 'sharing_code_usage_already_exists') {
                setError(`${activeOrganisation.name} is already using this Sharing Code.`)
                setSharingCode(undefined)
                return
            }
            setError('An unknown error occured creating the Sharing Code Usage')
            setSharingCode(undefined)
        }

    }

    return <>
        <Form className={css.sharingCodeUsageForm}>
            <FormField>
                <FormLabel htmlFor='sharingCode' id='sharingCodeLabel'>
                    Sharing Code
                </FormLabel>
                <div className={css.sharingCodeInput}>
                    <Input
                        error={error !== undefined}
                        id='sharingCode'
                        fluid
                        disabled={checking}
                        onChange={(_e, p) => {
                            if (p) {
                                onChange(p.value)
                            }
                        }}
                        value={code}
                    />
                    {checking && <Loader size="smaller" />}
                </div>
                {error && (
                    <FormMessage id='error' role="alert" error={error !== undefined}>
                        {error}
                    </FormMessage>
                )}
            </FormField>
            <FormField>
                <FormLabel htmlFor='usageType' id='usageTypeLabel'>
                    Usage Type
                </FormLabel>
                <RadioGroup
                    id='usageType'
                    defaultCheckedValue='LoginCompletionSharedDevices'
                    items={[
                        {
                            key: 'LoginCompletionSharedDevices',
                            label: formatUsageType('LoginCompletionSharedDevices'),
                            value: 'LoginCompletionSharedDevices'
                        },
                        {
                            key: 'FederatedContacts',
                            label: formatUsageType('FederatedContacts'),
                            value: 'FederatedContacts'
                        }                       
                    ]}
                    onCheckedValueChange={(_e, props) => {
                        if (props?.value) {
                            setusageType((props.value as UsageType))
                        }
                    }}
                />
            </FormField>
            <FormButtons
                submitText="Share"
                submitEnabled={sharingCode !== undefined}
                onCancel={onCancel}
                onSubmit={() => setConfirmCreate(true)}

            />
        </Form>
        {confirmCreate &&  <ConfirmationDialog onConfirm={() => createUsage()}
            onCancel={() => setConfirmCreate(false)}
            message={usageType === 'FederatedContacts' 
                    ? `${sharingCode!.owner.name} will have full access to the contacts of your organisation. Are you sure?` 
                    : `All ${sharingCode!.owner.name} tablets shared to your organisation will have access to the contacts of your organisation to complete login email addresses. Are you sure?`}
            heading='Share'
            confirmButtonText='Share'
            isOpen={confirmCreate} />}
    </>
}

function formatCode(code: string) {
    if (code.length === 8) {
        return code.substring(0, 4) + "-" + code.substring(4, 8)
    }
    return code
}

function formatUsageType(usageType: UsageType): string {
    if (usageType === 'FederatedContacts') {
        return 'Full Contact Federation'
    }
    if (usageType === 'LoginCompletionSharedDevices') {
        return 'Login Completion on Shared Devices'
    }
    return usageType
}


export default SharingGroupsPage