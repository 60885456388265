import { Loader, Button, DownloadIcon } from "@fluentui/react-northstar";
import { faChevronDown, faChevronUp, faEquals } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CalendarIcon } from '../../../images/bookItIcons/Calendar.svg';
import { ReactComponent as CarParkIcon } from '../../../images/bookItIcons/carPark.svg';
import { ReactComponent as ClockIcon } from '../../../images/bookItIcons/clock.svg';
import { ReactComponent as FleetIcon } from '../../../images/bookItIcons/fleet.svg';
import { ReactComponent as HotDeskIcon } from '../../../images/bookItIcons/hotDesk.svg';
import { ReactComponent as LockerIcon } from '../../../images/bookItIcons/locker.svg';
import { ReactComponent as RoomIcon } from '../../../images/bookItIcons/room.svg';
import { fetchSummary } from "../../../store/actions";
import { getSummary, getSummaryIsLoading } from "../../../store/selectors";
import { minutesToDhm, ReportFilter, toSemiFixed } from "../MetricsPage";
import services from "../../../services";
import { fileDownloadFromBlob } from "../../../utils/fileDownload";

interface SummaryProp {
    resourceTypes: Record<string, string>
    filter: ReportFilter
    orgChanging: boolean
}

export const Summary: React.FC<SummaryProp> = ({ resourceTypes: resources, filter, orgChanging }) => {

    const dispatch = useDispatch()

    useEffect(() => {
        if (!orgChanging) {
            dispatch(fetchSummary.request({ 
                organisationId: filter.organisationId,
                startRange: filter.startDate!,
                endRange: filter.endDate!,
                location: filter.location,
                resourceType: filter.resourceType,
                bookableResource: filter.bookableResource,
                excludeWeekends: filter.excludeWeekends,
                workingHoursOnly: filter.isWorkingHoursOnly,
                isActualTime: false 
            })) 
        }
    }, [dispatch, filter, orgChanging])

    const summary = useSelector(getSummary)
    const summaryIsLoading = useSelector(getSummaryIsLoading)

    const data = useMemo(() => {
        if (summaryIsLoading || summary === undefined) return []
        return filter.resourceType === 'All' ? summary : summary.filter(s => s.attendee_type === filter.resourceType)
    } , [filter.resourceType, summary, summaryIsLoading])

    // Export the dataset as a CSV file
    async function doExport() {
        
        console.log('do Summary csv export');
   
        try {
            const {data,fileName} = await services.bookItApi.fetchSummaryDataCsv(
                filter.organisationId,
                filter.startDate!,
                filter.endDate!,
                filter.location,
                filter.resourceType,
                filter.bookableResource,
                filter.excludeWeekends,
                filter.isWorkingHoursOnly)

            fileDownloadFromBlob(data, fileName ?? 'summary.csv')

        } catch (e) {
            console.error('doExport', e)
        }
    }

    if (summaryIsLoading) return <Loader />
    if (summary === undefined)
        return <div>No data to show. Please change your filters</div>
    if (summary.length === 0)
        return <div>No data to show. Please change your filters</div>


    return <div className={['summary', 'allInAColumn'].join(' ')}>

        <Button primary content='Export as CSV' 
                onClick={() => { doExport() }} 
                icon={<DownloadIcon />} 
                iconPosition='before' 
                className={['downloadButton', 'spaceTop'].join(' ')} />

        <div className="allInARow">
        {data.map((s, i) => <div className="inner" key={i}>
            {filter.resourceType !== 'All' && <SummaryCard title="Bookings" value={s.period.bookings_count} previousValue={s.previous_period.bookings_count}><CalendarIcon className="icon" /></SummaryCard>}
            {filter.resourceType !== 'All' && <SummaryCard title="Hours Booked" value={s.period.hours_booked} previousValue={s.previous_period.hours_booked}><ClockIcon className="icon" /></SummaryCard>}
            {<SummaryCard title={resources[s.attendee_type]} value={s.period.resource_count} previousValue={s.previous_period.resource_count} >
                {s.attendee_type === 'Locker' && <LockerIcon className="icon" />}
                {s.attendee_type === 'MeetingRoom' && <RoomIcon className="icon" />}
                {s.attendee_type === 'ParkingSpace' && <CarParkIcon className="icon" />}
                {s.attendee_type === 'HotDesk' && <HotDeskIcon className="icon" />}
                {s.attendee_type === 'FleetCar' && <FleetIcon className="icon" />}
            </SummaryCard>}
        </div>
        )}
        </div>

       
    </div>
}

interface SummaryCardProp {
    title: string
    value: number
    previousValue: number
    children: React.ReactNode
}
const SummaryCard: React.FC<SummaryCardProp> = (props: SummaryCardProp) => {

    const per = Math.round(props.previousValue === 0 ? 0 : ((props.value - props.previousValue) / props.previousValue) * 100);  
    
    let perString = '(No change)'

    if (props.previousValue === 0) {
        perString = '(No prev. data)'
    } else if (Math.abs(Math.round(per)) > 0) {
        perString = per < 1 ? toSemiFixed(Math.abs(per), 0) + '%' : (Math.abs( per)).toFixed(0) + '%'
    }
    

    //get summary information array
    return <div className="summaryCard">
        <div className="firstLine">
            {props.children}
            {props.title === 'Hours Booked' ?
                <div className="valueDhm">{minutesToDhm(props.value)}</div>
            :
                <div className="value">{toSemiFixed(props.value)}</div>
            }

            <div className={per === 0 ? "gray" : per > 0 ? "green" : "red"}>
                {per !== 0 && 
                    <FontAwesomeIcon className="arrow" 
                                     size="2x" 
                                     icon={per === 0 ? faEquals : per > 0 ? faChevronUp : faChevronDown} 
                                     color={per === 0 ? "gray" : per > 0 ? "green" : "red"} />}
                <div className="percentage">{perString}</div>
            </div>
            
        </div>
        <span className="title">{props.title}</span>
    </div >
}