import { Room, RoomsFilterType } from "../model";



export function roomsFilter(filterName: RoomsFilterType) {
    switch (filterName) {
        case 'All Rooms':
            return all;
        case 'Stopped Rooms': 
            return stoppedRooms;
        case 'Rooms Requiring Install': 
            return roomsRequiringInstall;
        case 'Rooms Requiring Upgrade':
            return roomsRequiringUpgrade;
        case 'Unresponsive Rooms':
            return unresponsiveRooms;
        default:
            return all;
    }
}

function all(room: Room) {
    return room;
}

function stoppedRooms(room: Room) {
    return room.room_component!.running_status && room.room_component!.running_status === "Stopped";
}

function roomsRequiringInstall(room: Room) {
    return room.provisioning_status && room.provisioning_status === "AwaitingInstall";
}

function roomsRequiringUpgrade(room: Room) {
    return (room.room_component?.update_available ?? false) || (room.admin_component?.update_available ?? false) 
}

function unresponsiveRooms(room: Room) {
    return room.room_component!.running_status && room.room_component!.running_status === "Stopped" && room.room_component!.stop_reason && room.room_component!.stop_reason === "Unresponsive";;
}
