var graph = require('@microsoft/microsoft-graph-client');

function getAuthenticatedClient(accessToken) {
  const client = graph.Client.init({
    authProvider: (done) => {
      done(null, accessToken.accessToken);
    }
  });

  return client;
}

export async function getUserDetails(accessToken) {
  const client = getAuthenticatedClient(accessToken);
  const user = await client.api('/me').get();
  return user;
}

export async function getCharts(accessToken, sessionId) {
  const client = getAuthenticatedClient(accessToken);

  const chartsList = await client
    .api('/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/worksheets/sheet2/charts')
    .header("workbook-session-id", sessionId)
    .select('id,name')
    .get();

  return chartsList;
}

export async function getChartString(accessToken, sessionId, chartName) {
  const client = getAuthenticatedClient(accessToken);
  const uri = "/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/worksheets('sheet2')/charts('"+chartName+"')/Image(width=1100,height=600,fittingMode='fill')";

  const chartString = await client
    .api(uri)
    .header("workbook-session-id", sessionId)
    .get();

  return chartString;
}

export async function getTableRowCount(accessToken, sessionId) {
  const client = getAuthenticatedClient(accessToken);
  const tableRange = await client
    .api("/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/worksheets/sheet1/tables/table1/range")
    .header("workbook-session-id", sessionId)
    .get();

  if (tableRange.rowCount) {
    return tableRange.rowCount;
  } else {
    return 0; //row 1 should be column titles
  }
}

export async function patchTableRows(accessToken, sessionId, rows) {
  const client = getAuthenticatedClient(accessToken);

  var rowData = {};
  rowData["values"] = [];
  for (let i = 0; i < rows; i++) {
    const min = 1;
    const max = 100;
    var rand = 0;

    var rowDataArray = [];
    var rowId = String.fromCharCode(65+Math.floor(Math.random() * 26))
    rowDataArray.push(rowId);

    for (let j = 0; j < 10; j++) {
      rand = min + Math.random() * (max - min);
      rand = Math.floor(rand);
      rowDataArray.push(rand);
    }
    rowData["values"].push(rowDataArray);
  }

  rows = parseFloat(rows) + 1; //zero index for the for loop, +1 for range, since top row is headers

  await client
    .api("/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/worksheets/sheet1/range(address='A2:K" + rows + "')")
    .header("workbook-session-id", sessionId)
    .patch(rowData);
}

export async function postDeleteTableRows(accessToken, sessionId, currentRows, rowsToDelete) {
  const client = getAuthenticatedClient(accessToken);

  var param = {};
  param["shift"] = "up";

  const fromRow = (currentRows + 1) - (rowsToDelete - 1);
  const toRow = currentRows + 1; //first row is headers

  await client
    .api("/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/worksheets/sheet1/range(address='A" + fromRow + ":K" + toRow + "')/delete")
    .header("workbook-session-id", sessionId)
    .post(param);
}

export async function postChartRows(accessToken, sessionId, rows) {
  const client = getAuthenticatedClient(accessToken);

  var rowData = {};
  rowData["values"] = [];
  for (let i = 0; i < rows; i++)
  {
    const min = 1;
    const max = 100;
    var rand = 0;
    
    var rowDataArray = [];
    var rowId = String.fromCharCode(65+Math.floor(Math.random() * 26))
    rowDataArray.push(rowId);
    for (let j = 0; j < 10; j++)
    {
      rand = min + Math.random() * (max - min);
      rand = Math.floor(rand);
      rowDataArray.push(rand);
    }
    rowData["values"].push(rowDataArray);
  }

  await client
    .api("/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/worksheets/sheet1/tables/Table1/rows/add")
    .header("workbook-session-id", sessionId)
    .post(rowData);
}

export async function postApplyValuesFilter(accessToken, sessionId, filter) {
  if (filter !== " " && filter.length > 0) {
    var param = {};
    param["values"] = filter.split(" ");

    const client = getAuthenticatedClient(accessToken);
    await client
      .api("/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/worksheets/sheet1/tables/Table1/columns/1/filter/applyValuesFilter")
      .header("workbook-session-id", sessionId)
      .post(param);
  }
}

export async function postClearFilter(accessToken, sessionId) {
  var param = {};
  param["values"] = [];

  const client = getAuthenticatedClient(accessToken);
  //.api("/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/worksheets/sheet1/tables/Table1/clearFilters")
  await client
    .api("/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/worksheets/sheet1/tables/Table1/columns/1/filter/clear")
    .header("workbook-session-id", sessionId)
    .post(param);
}

export async function postCreateSession(accessToken) {
  const client = getAuthenticatedClient(accessToken);

  var param = {};
  param["persistChanges"] = true;

  var sessionId = await client
    .api("/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/createSession")
    .post(param);

  return sessionId.id;
}

export async function postRefreshSession(accessToken, sessionId) {
  const client = getAuthenticatedClient(accessToken);

  await client
    .api("/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/refreshSession")
    .header("workbook-session-id", sessionId)
    .post();
}

export async function postCloseSession(accessToken, sessionId) {
  const client = getAuthenticatedClient(accessToken);

  await client
    .api("/users/e9424c2d-5d0d-4091-b92d-b9a4610b958e/drive/root:/demo.xlsx:/workbook/closeSession")
    .header("workbook-session-id", sessionId)
    .post();
}
