import { Button, Dropdown, Menu, menuAsToolbarBehavior, Popup } from '@fluentui/react-northstar';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { ReactComponent as IPFXLogo } from '../../images/IPFX.svg';
import { AllowedOrganisation, ServerInfo, UserInfo } from '../../model';
import { actions } from '../../store/types';
import AboutPopup from './AboutPopup';
import css from './NavBar.module.scss';

interface Props {
    userInfo: UserInfo
    serverInfo: ServerInfo | null | undefined
}

const NavBar: React.FC<Props> = ({ userInfo, serverInfo }) => {
    const [aboutOpen, setAboutOpen] = useState(false)

    const dispatch = useDispatch()

    const onSignOut = () => {
        console.log("Processing onSignOut")
        dispatch(actions.signOutAuthedAccount())
    };

    const onAbout = () => {
        setAboutOpen(!aboutOpen)
    }
    const initials = `${userInfo.user.given_name![0]}${userInfo.user.family_name![0]}`

    const organisations = useMemo(() => userInfo.allowed_organisations.map(org => ({ ...org, header: org.name }))
        , [userInfo])

    const defaultOrg = useMemo(() => organisations.find(org => org.organisation_id === userInfo.user.primary_organisation.organisation_id)
        , [organisations, userInfo.user.primary_organisation.organisation_id])


    const handleChange = useCallback((org: AllowedOrganisation) => {
        dispatch(actions.setActiveOrganisation(org))
    }, [dispatch])

    const popupContent = (<Menu
        className={css.popupMenu}
        vertical
        underlined={false}

        items={[
            {
                key: 'about',
                content: 'About',
                onClick: onAbout
            },
            { kind: 'divider', key: 'divider1' },
            {
                className: 'icon-margin',
                icon: <FaSignOutAlt size='1em' />,
                key: 'sign_out',
                content: 'Sign out',
                onClick: onSignOut
            }
        ]} />)

    return (
        <>
            <header className={css.header}>
                <div className={css.title} >
                    <IPFXLogo />
                    <span>Management Portal</span>
                </div>

                <Dropdown
                    disabled={organisations.length < 2}
                    className={css.orgSelector}
                    items={organisations}
                    defaultValue={defaultOrg}
                    placeholder="Select your organisation"
                    onChange={(e, d) => {
                        if (d.value) handleChange(d.value as AllowedOrganisation)
                    }}
                    checkable
                    getA11ySelectionMessage={{
                        onAdd: item => `${item} has been selected.`,
                    }}
                />

                <div className={css.login}>

                    <Popup
                        className={css.popup}
                        accessibility={menuAsToolbarBehavior}
                        content={{
                            className: css.popupContent,
                            content: popupContent
                        }}
                        trigger={<Button circular styles={{ background: 'white', border: 'none', ':focus': { outline: 'none' } }} content={initials} />}
                    />
                </div>
            </header>
            <AboutPopup isOpen={aboutOpen} serverVersion={serverInfo?.version ?? ""} />
        </>
    );
};

export default NavBar;