import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { LogFilesType, Room } from "../../model";
import { Services } from "../../services";
import { HalResource } from "../../services/Hal";
import { getApiError } from "../../utils/error";
import { adminCheckForWindowsUpdates, adminCollectLogs, adminEmailLogs, adminFetchLog, adminFetchLogMetadata, adminFetchLogMetadataErrorHandler, adminFetchScreenShot, adminFetchWindowsUpdatesForRoom, adminReboot, adminRequestWindowsUpdatesInstall, adminRestartRoom, adminStartRoom, adminStopRoom, errorHandler, onReportInformation } from "../actions";
import { RootAction, RootState } from "../types";

export const adminStopRoomEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const stopRoom = (payload: HalResource<Room>) => {
        return serverApi.adminApiForRoom(payload).pipe(
            mergeMap(adminApi => adminApi.stop()))
    };

    return action$.pipe(
        filter(isActionOf(adminStopRoom.request)),
        mergeMap(action =>
            stopRoom(action.payload).pipe(
                mergeMap(hal => of(adminStopRoom.success(hal))),
                catchError(err => of(adminStopRoom.failure(err), errorHandler(err)))
            )));

}

export const adminStartRoomEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const startRoom = (payload: HalResource<Room>) => {
        return serverApi.adminApiForRoom(payload).pipe(
            mergeMap(adminApi => adminApi.start()))
    };

    return action$.pipe(
        filter(isActionOf(adminStartRoom.request)),
        mergeMap(action =>
            startRoom(action.payload).pipe(
                mergeMap(hal => of(adminStartRoom.success(hal))),
                catchError(err => of(adminStartRoom.failure(err), errorHandler(err)))
            )));
}

export const adminRestartRoomEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const restartRoom = (payload: HalResource<Room>) => {
        return serverApi.adminApiForRoom(payload).pipe(
            mergeMap(adminApi => adminApi.restart()))
    };

    return action$.pipe(
        filter(isActionOf(adminRestartRoom.request)),
        mergeMap(action =>
            restartRoom(action.payload).pipe(
                mergeMap(hal => of(adminRestartRoom.success(hal))),
                catchError(err => of(adminRestartRoom.failure(err), errorHandler(err)))
            )));
}

export const adminRebootEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const rebootRoom = (room: HalResource<Room>, force?: boolean) => {
        return serverApi.adminApiForRoom(room).pipe(
            mergeMap(adminApi => adminApi.reboot(force || false)))
    };

    return action$.pipe(
        filter(isActionOf(adminReboot.request)),
        mergeMap(action =>
            rebootRoom(action.payload.room, action.payload.force).pipe(
                mergeMap(hal => of(adminReboot.success())),
                catchError(err => of(adminReboot.failure(err), errorHandler(err)))
            )));
}

export const adminFetchWindowsUpdatesForRoomEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const fetchWindowsUpdates = (room: HalResource<Room>) => {
        return serverApi.adminApiForRoom(room).pipe(
            mergeMap(adminApi => adminApi.fetchWindowsUpdates()))
    };

    return action$.pipe(
        filter(isActionOf(adminFetchWindowsUpdatesForRoom.request)),
        mergeMap(action => {
            console.log('adminFetchWindowsUpdates', action.payload)
            return fetchWindowsUpdates(action.payload).pipe(
                mergeMap(hal => of(adminFetchWindowsUpdatesForRoom.success({roomName: action.payload.name, updates: hal}))),
                catchError(err => of(adminFetchWindowsUpdatesForRoom.failure(err), errorHandler(err)))
        )}));
}


export const adminCheckForWindowsUpdatesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const checkForWindowsUpdates = (room: HalResource<Room>) => {
        return serverApi.adminApiForRoom(room).pipe(
            mergeMap(adminApi => adminApi.checkForWindowsUpdates()))
    };

    return action$.pipe(
        filter(isActionOf(adminCheckForWindowsUpdates.request)),
        mergeMap(action => {
            console.log('adminCheckForWindowsUpdates', action.payload)
            return checkForWindowsUpdates(action.payload).pipe(
                mergeMap(hal => 
                    of (
                        adminCheckForWindowsUpdates.success(),
                        onReportInformation({title: "Windows Updates", message: "A refresh was successfully requested. The update may take several minutes."})
                    )
                ),
                catchError(err => of(adminCheckForWindowsUpdates.failure(err), errorHandler(err)))
        )}));
}

export const adminRequestWindowsUpdatesInstallEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const requestWindowsUpdatesInstall = (room: HalResource<Room>, ids: number[]) => {
        return serverApi.adminApiForRoom(room).pipe(
            mergeMap(adminApi => adminApi.requestWindowsUpdatesInstall(ids)))
    };

    return action$.pipe(
        filter(isActionOf(adminRequestWindowsUpdatesInstall.request)),
        mergeMap(action => {
            console.log('adminRequestWindowsUpdatesInstall', action.payload)
            return requestWindowsUpdatesInstall(action.payload.room, action.payload.ids).pipe(
                mergeMap(result => of(adminRequestWindowsUpdatesInstall.success({roomName: action.payload.room.name, result}))),
                catchError(err => of(adminRequestWindowsUpdatesInstall.failure(err), errorHandler(err)))
        )}));
}

export const adminFetchLogEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const fetchLog = (room: HalResource<Room>, log: LogFilesType) => {
        return serverApi.adminApiForRoom(room).pipe(
            mergeMap(adminApi => adminApi.fetchLog(log)))
    };

    return action$.pipe(
        filter(isActionOf(adminFetchLog.request)),
        mergeMap(action => {
            console.log('adminFetchLog', action.payload)
            return fetchLog(action.payload.room, action.payload.log).pipe(
                mergeMap(log => of(adminFetchLog.success({logName: action.payload.log, fileName: log.fileName, data: log.data}))),
                catchError(err => of(adminFetchLog.failure({logName: action.payload.log, error: err}), errorHandler(err)))
        )}));
}

export const adminFetchLogMetadataEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const fetchLogMetadata = (room: HalResource<Room>, log: LogFilesType) => {
        return serverApi.adminApiForRoom(room).pipe(
            mergeMap(adminApi => adminApi.fetchLogMetadata(log)))
    };

    return action$.pipe(
        filter(isActionOf(adminFetchLogMetadata.request)),
        mergeMap(action => {
            return fetchLogMetadata(action.payload.room, action.payload.log).pipe(
                mergeMap(meta => of(adminFetchLogMetadata.success({roomName: action.payload.room.name, log: action.payload.log, result: meta}))),
                catchError(error => of(adminFetchLogMetadataErrorHandler({err: error, log: action.payload.log, roomName: action.payload.room.name})))
        )}));
}

export const adminFetchLogMetadataErrorHandlerEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    return action$.pipe(
        filter(isActionOf(adminFetchLogMetadataErrorHandler)),
        mergeMap(action => 
            from(getApiError(action.payload.err)).pipe(
                mergeMap(err =>  of(adminFetchLogMetadata.failure({error: err, roomName: action.payload.roomName, log: action.payload.log}))
                ))))
}

export const adminCollectLogsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const collectLogs = (room: HalResource<Room>) => {
        return serverApi.adminApiForRoom(room).pipe(
            mergeMap(adminApi => adminApi.collectLogs()))
    };

    return action$.pipe(
        filter(isActionOf(adminCollectLogs.request)),
        mergeMap(action =>
            collectLogs(action.payload).pipe(
                mergeMap(res => of(adminCollectLogs.success({...res, roomName: action.payload.name}))),
                catchError(err => of(adminCollectLogs.failure({error: err, roomName: action.payload.name}), errorHandler(err)))
            )));
}

export const adminEmailLogsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const emailLogs = (room: HalResource<Room>, to: string[]) => {
        return serverApi.adminApiForRoom(room).pipe(
            mergeMap(adminApi => adminApi.emailLogs(to)))
    };

    return action$.pipe(
        filter(isActionOf(adminEmailLogs.request)),
        mergeMap(action =>
            emailLogs(action.payload.room, action.payload.to).pipe(
                mergeMap(res => of(adminEmailLogs.success(action.payload.room.name))),
                catchError(err => of(adminEmailLogs.failure({error: err, roomName: action.payload.room.name}), errorHandler(err)))
            )));
}

export const adminFetchScreenShotEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store, { serverApi }) => {
    const fetchScreenShot = (room: HalResource<Room>, index: number) => {
        return serverApi.adminApiForRoom(room).pipe(
            mergeMap(adminApi => adminApi.fetchScreenShot(index)))
    };

    return action$.pipe(
        filter(isActionOf(adminFetchScreenShot.request)),
        mergeMap(action =>
            fetchScreenShot(action.payload.room, action.payload.index).pipe(
                mergeMap(res => of(adminFetchScreenShot.success({data: res.data, roomName: action.payload.room.name, index: action.payload.index}))),
                catchError(err => of(adminFetchScreenShot.failure({error: err, roomName: action.payload.room.name, index: action.payload.index}), errorHandler(err)))
            )));
}