import { Flex, Loader, ProviderConsumer, Text, Toolbar } from "@fluentui/react-northstar";
import classNames from "classnames";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MdSystemUpdateAlt } from "react-icons/md";
import { useDispatch } from "react-redux";
import { fetchWindowsUpdates } from "../../store/actions";
import { ApiResult } from "../../store/reducers/ApiResult";
import { useSelector } from "../../store/utils";
import WindowsUpdateCard from "./WindowsUpdateCard";
import './WindowsUpdatesPage.scss';

const WindowsUpdatesPage: React.FC<{}> = () => {
    const dispatch = useDispatch()

    const [selectedUpdates, setSelectedUpdates] = useState<{ [id: string]: boolean | undefined }>({})

    const updatesResult = useSelector(s => s.windowsUpdates.windowsUpdates)

    const updates = useMemo(() => ApiResult.isSuccess(updatesResult) ? updatesResult.value.resources : [], [updatesResult])

    useEffect(() => { 
        if (!updatesResult.isRequested) {
            dispatch(fetchWindowsUpdates.request())
        }
     }, [dispatch, updatesResult.isRequested])

    const anySelected = useMemo(() => Object.values(selectedUpdates).some(s => !!s), [selectedUpdates])

    const onItemClicked = (e: React.MouseEvent<HTMLLIElement>) => {
        const { id } = e.currentTarget;
        const existing = selectedUpdates[id]
        setSelectedUpdates({ ...selectedUpdates, [id]: !!!existing })
        console.log("selectedUpdates:", selectedUpdates)
    }

    const requestInstall = useCallback(() => {
        // var ids = Object.keys(selectedUpdates).filter(k => !!selectedUpdates[k]).map(str => parseInt(str))
        // dispatch(adminRequestWindowsUpdatesInstall.request({room: room.resource, ids}))
        setSelectedUpdates({})
    }, [])

    const toolbarItems = useMemo(() => [
        { key: 'request', icon: MdSystemUpdateAlt, tooltip: 'Request install', className: 'ToolbarItem', onClick: requestInstall, disabled: !anySelected },
    ], [requestInstall, anySelected])

    if (ApiResult.isFailure(updatesResult)) {
        return <Text error content="Failed to load the Windows Updates from the server" />
    }
    

    if (updatesResult.isLoading) {
        return <Loader label='Loading Windows Updates...' styles={{ marginTop: '1rem' }} />
    }

    return <ProviderConsumer render={theme => {
        return (<>
            <Flex className='WindowsUpdatesPage' column styles={{ border: `1px solid ${theme.siteVariables.colorScheme.default.border3}` }}>
                <Flex className='WindowsUpdatesPageHeader' vAlign='center'
                    styles={{
                        backgroundColor: theme.siteVariables.colorScheme.default.background,
                        borderBottom: `1px solid ${theme.siteVariables.colorScheme.default.border3}`
                    }}>
                    <Flex.Item push>
                        <Toolbar items={toolbarItems} />
                    </Flex.Item>
                </Flex>
                <ul className='WindowsUpdateItems'>
                    {updates.length === 0 ? <Text content='No Windows Updates found.'/> : null}
                    {updates.map(u => (<li id={u.windows_update_id.toString()}
                        className={classNames('WindowsUpdateItem', !!selectedUpdates[u.windows_update_id.toString()] && 'WindowsUpdateItemSelected')}
                        onClick={onItemClicked}>
                        <WindowsUpdateCard key={u.windows_update_id.toString()} update={u} roomsPending={u.pending_rooms}/>
                    </li>))}
                </ul>
            </Flex>
        </>)
    }} />
}

export default WindowsUpdatesPage