import { Setting, SettingsSection, SettingInput } from "./Settings"
import { useSettingsContext } from './BookItSettingsPage';

export default function HealthCheckReport() {

    const {values} = useSettingsContext()

    return <SettingsSection title="Health check report">
         <Setting
            title="Use Health Check reports"
            description="Allows Device Health Check reports to be emailed up to twice daily to a nominated mailbox."
            name="enabled"
            overrideName='health_check_report'
        >
            <SettingInput label='Run time am:' name="report_am_time" disabled={!values.health_check_report} defaultValue="8:00" />
            <SettingInput label='Run time pm:' name="report_pm_time" disabled={!values.health_check_report} defaultValue="16:00"/>
            <SettingInput label='Timezone:' name="timezone" disabled={!values.health_check_report} defaultValue="New Zealand Standard Time"/>
            <SettingInput label='Send to mailbox:' name="report_mailbox" disabled={!values.health_check_report} defaultValue=""/>
        </Setting>

    </SettingsSection>
}
