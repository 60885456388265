import { EditIcon } from "@fluentui/react-northstar";
import { compare, Operation } from "fast-json-patch";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { CreateResource, IdType, ManagedEnginSettings, Resource } from "../../model";
import { createResource, deleteResource, fetchResources, patchResource } from "../../store/resourceActions";
import { getActiveOrganisation } from "../../store/selectors";
import { useSelector } from "../../store/utils";
import { FormikNorthstarCheckbox } from "../controls/FormikNorthstarCheckbox";
import { FormikNorthstarInput } from "../controls/FormikNorthstarInput";
import { defaultOrganisationSettings } from "./OrganisationsPage";
import ResourceAdminForm from "./ResourceAdminForm";
import ResouceAdminPage from './ResourceAdminPage';

interface Props { }

type ResourceEditModel = CreateResource

const ResourcesPage: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const cols = useMemo(() => [
        { Header: "Id", id: "identifier", minWidth: 10, accessor: (row: Resource) => row.identifier },
        { Header: "DisplayName", id: "display_name", minWidth: 10, accessor: (row: Resource) => row.display_name },
        { Header: "EmailAddress", id: "email_address", accessor: (row: Resource) => row.email_address, minWidth: 20 },
        { Header: "Types", id: "types", accessor: (row: Resource) => getResourceTypes(row), minWidth: 7 },
    ], [])

    const activeOrganisation = useSelector(getActiveOrganisation)

    const parentKey = useMemo(() => {
        return { organisation_id: activeOrganisation!.organisation_id }
    }, [activeOrganisation]);

    const handleAddOrUpdate = (resource: ResourceEditModel, closeDialog: () => void, original: Resource | undefined) => {
        if (original) {
            const updateResource = {
                resource_id: original.resource_id,
                organisation_id: original.organisation_id,
                organisation: original.organisation,
                video_fx_room_id: original.video_fx_room_id,
                bookable_resource_id: original.bookable_resource_id,

                identifier: resource.identifier,
                email_address: resource.email_address,
                display_name: resource.display_name,
                description: resource.description,
                managed_engine_override: resource.managed_engine_override,
             }

            console.log("original:", original)
            console.log("resource:", resource)
            console.log("updateResource:", updateResource)
            let diff = compare(original, updateResource);
            diff = fixDiff(diff, updateResource.managed_engine_override)
            dispatch(patchResource.request({ id: original, operations: diff }))
        } else {
            const newResource: CreateResource = {
                identifier: resource.identifier,
                email_address: resource.email_address,
                display_name: resource.display_name,
                description: resource.description,
                managed_engine_override: resource.managed_engine_override
            }
            console.log("newResource:", newResource)

            dispatch(createResource.request(newResource))
        }
        closeDialog()
    }


    return <ResouceAdminPage<Resource, { organisation_id: IdType }>
        resourceName='Resource'
        disableAdd={true}
        parentKey={parentKey}
        selectState={s => s.resources.resources}
        fetchAllAction={fetchResources}
        deleteAction={deleteResource}
        confirmMessage={resource => `Are you sure you wish to delete the Resource for Email address: ${resource.email_address ?? "Not Set"}?`}
        resourceForm={(original, closeDialog) =>
            <ResourceForm initial={createEditModel(original, activeOrganisation.name)} orgName={activeOrganisation.name} onSubmit={edit => handleAddOrUpdate(edit, closeDialog, original)} onCancel={closeDialog} />
        }
        toolbarActionItems={(resource, onAddUpdate, onDelete) =>
            [{
                key: 'edit',
                icon: <EditIcon outline />,
                tooltip: `Edit ${resource.display_name ?? "Not Set"}`,
                onClick: () => onAddUpdate(resource)
            },
            // {
            //     key: 'delete',
            //     icon: <TrashCanIcon outline />,
            //     tooltip: `Delete ${resource.display_name ?? "Not Set"}`,
            //     onClick: () => onDelete(resource)
            // }
        ]
        }
        columns={cols}
        defaultSortOrder={[{ id: "identifier", desc: false }]}
    />
}

function getResourceTypes(resource: Resource) {
    let types = resource.video_fx_room_id !== undefined ? 'VideoFX' : ''
    if (resource.bookable_resource_id !== undefined) {
        if (types.length > 0) {
            types += ', '
        }
        types += 'Book-It'
    }
    return types
}

function createEditModel(resource: Resource|undefined, orgName: string): ResourceEditModel {
    const settings = defaultOrganisationSettings(orgName).managed_engine_settings
    const def = {
        use_email_command: false,
        account: settings.account ?? '',
        site: settings.site ?? '',
        operation: settings.operation ?? '',
        requester: settings.requester ?? '',
        category: settings.category ?? '',
    }

    if (resource === undefined) return {
        identifier: '',
        email_address: '',
        display_name: '',
        description: '',
        managed_engine_override: def
    }
    let { organisation_id, resource_id, organisation, video_fx_room_id, bookable_resource_id, ...edit } = resource
    if (edit.managed_engine_override === undefined) {
        edit.managed_engine_override = def
    }

    console.log("createEditModel", edit)
    return edit
}

function fixDiff(diff: Operation[], managed_engine_override: ManagedEnginSettings | undefined): Operation[] {
    let updatedDiff = diff
    const updateJsonConfiguration = diff.some(op => op.path.startsWith("/managed_engine_override"))
    if (updateJsonConfiguration) {
        updatedDiff = diff.filter(op => !op.path.startsWith("/managed_engine_override"))
        updatedDiff = [...updatedDiff, {
            op: 'replace',
            path: '/managed_engine_override',
            value: managed_engine_override
        }]
    }

    return updatedDiff
}

interface ResourceFormProps {
    initial: ResourceEditModel
    orgName: string
    onSubmit: (edit: ResourceEditModel) => void
    onCancel: () => void
}

const ResourceForm: React.FC<ResourceFormProps> = ({ initial, orgName, onSubmit, onCancel }) => {
    console.log("initialResource", initial)

    const formSchema = useMemo(() => Yup.object().shape({
        identifier: Yup.string()
            .required("The identifier cannot be empty"),
        email_address: Yup.string()
            .email("Must provide a valid email address")
            .required('Email address cannot be empty'),
        display_name: Yup.string()
            .required("The display name cannot be empty"),

    }), [])

    const [managedEngineOverride, setManagedEngineOverride] = useState(initial.managed_engine_override?.use_email_command ?? false)
    const [identifier, setIdentifier] = useState(initial.identifier)

    const handleEmailCommandChange = (enabled: boolean) => {
        setManagedEngineOverride(enabled)
    }

    const handleEmailChange = useCallback((email: string) => {
        if (identifier === '' && email.indexOf("@") > 0) {
            const id = email.split("@")[0]
            setIdentifier(id.replace(/\W/g, '') + orgName)
        }
    }, [identifier, orgName])
    const handleIdentifierChange = (identifier: string) => {
        setIdentifier(identifier)
    }

    return <ResourceAdminForm
        initial={initial}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
    >
        <div>
            <FormikNorthstarInput fluid label="Email Address" name="email_address" onChange={handleEmailChange} />
            <FormikNorthstarInput fluid label="Identifier" name="identifier" onChange={handleIdentifierChange} />
            <FormikNorthstarInput fluid label="Display Name" name="display_name" />
            <FormikNorthstarInput fluid label="Description" name="description" />

            <FormikNorthstarCheckbox toggle label="Override Organisation Managed Engine Settings" name="managed_engine_override.use_email_command" onChange={handleEmailCommandChange} />
            <FormikNorthstarInput fluid label="Account" name="managed_engine_override.account" id="managed_engine_override.account" disabled={!managedEngineOverride} />
            <FormikNorthstarInput fluid label="Site" name="managed_engine_override.site" id="managed_engine_override.site" disabled={!managedEngineOverride} />
            <FormikNorthstarInput fluid label="Operation" name="managed_engine_override.operation" id="managed_engine_override.operation" disabled={!managedEngineOverride} />
            <FormikNorthstarInput fluid label="Requester" name="managed_engine_override.requester" id="managed_engine_override.requester" disabled={!managedEngineOverride} />
            <FormikNorthstarInput fluid label="Category" name="managed_engine_override.category" id="managed_engine_override.category" disabled={!managedEngineOverride} />
        </div>
    </ResourceAdminForm>
}

export default ResourcesPage